let _isSafari = null;
let _isChrome = null;
let _isFirefox = null;

function isChromeBrowser() {
  var isChromium = self.chrome;
  var winNav = self.navigator;
  var vendorName = winNav.vendor;
  var isOpera = typeof self.opr !== "undefined";
  var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
  var isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return false;
  } else if (
    isChromium !== null &&
    typeof isChromium !== "undefined" &&
    vendorName === "Google Inc." &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true;
  } else {
    return false;
  }
}

export const isFirefox = () => {
  if (_isFirefox === null) {
    _isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
  }

  return _isFirefox;
};

export const isSafari = () => {
  if (_isSafari === null) {
    _isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  return _isSafari;
};

export const isChrome = () => {
  if (_isChrome === null) {
    _isChrome = isChromeBrowser();
  }

  return _isChrome;
};
