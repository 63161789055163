import getEmojiRegex from "emoji-regex/text";

export const emojiRegex: RegExp = getEmojiRegex();

export const getFirstEmoji = (emoji: string) => {
  const results = emojiRegex.exec(emoji);

  if (results && results.length > 0) {
    return results[0];
  } else {
    return null;
  }
};
