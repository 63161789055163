import {
  AbstractMesh,
  Color3,
  Color4,
  DynamicTexture,
  Engine,
  GPUParticleSystem,
  ISoundOptions,
  Mesh,
  ParticleSystem,
  Scene,
  Skeleton,
  SolidParticleSystem,
  Sound,
  SphereParticleEmitter,
  SpotLight,
  Texture,
  TransformNode,
  Vector3,
  HemisphericParticleEmitter,
} from "@babylonjs/core";
import "@babylonjs/loaders/glTF";
import { BaseAvatar } from "game/Avatar/BaseAvatar";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";
import { isSafari } from "lib/browser";
import debug from "lib/log";
import MoneyParticle from "./particles/money.png";
import { Nameplate } from "game/Player/Nameplate";
import { ParticleEffectName } from "shared/message/EmitParticleEffectMessage";

const log = debug("PlayerMesh");

if (process.env.NODE_ENV === "development") {
  debug.enable("PlayerMesh");
}

let particleTextureMap = new Map<ParticleEffectName, Texture>();
export const getClearColor = (scene: Scene) =>
  new Color3(scene.clearColor.r, scene.clearColor.g, scene.clearColor.b);

export enum SoundEffect {
  footsteps = "/sounds/effects/stone_footstep.wav",
  voiceChatConnected = "/sounds/effects/VoiceChat_connected.wav",
  openMap = "/sounds/effects/Map__open.wav",
  closeMap = "/sounds/effects/Map__close.wav",
  voiceChatMute = "/sounds/effects/VoiceChat__mute.wav",
  voiceChatUnmute = "/sounds/effects/VoiceChat__unmute.wav",
  genericUIClick = "/sounds/effects/UIClick.wav",
  itemChange = "/sounds/effects/Item__change.wav",
  placeBlock = "/sounds/effects/pop.flac",
  destroyBlock = "/sounds/effects/dop.wav",
  notEnoughMoney__1 = "/sounds/effects/Item__notEnoughMoney-1.wav",
  notEnoughMoney__2 = "/sounds/effects/Item__notEnoughMoney-2.wav",
  notEnoughMoney__3 = "/sounds/effects/Item__notEnoughMoney-3.wav",
  notEnoughMoney__4 = "/sounds/effects/Item__notEnoughMoney-4.wav",
  notEnoughMoney__5 = "/sounds/effects/Item__notEnoughMoney-5.wav",
  notEnoughMoney__6 = "/sounds/effects/Item__notEnoughMoney-6.wav",
  notEnoughMoney__7 = "/sounds/effects/Item__notEnoughMoney-7.wav",
}

const notEnoughMonies = [
  SoundEffect.notEnoughMoney__1,
  SoundEffect.notEnoughMoney__3,
  SoundEffect.notEnoughMoney__4,
  SoundEffect.notEnoughMoney__5,
  SoundEffect.notEnoughMoney__6,
  SoundEffect.notEnoughMoney__7,
];

let notEnoughMoneyIndex =
  Math.floor(Math.random() * notEnoughMonies.length - 1) + 0;

export const getNotEnoughMoneyEffect = () => {
  notEnoughMoneyIndex = (notEnoughMoneyIndex + 1) % notEnoughMonies.length;
  return notEnoughMonies[notEnoughMoneyIndex];
};

export const sharedSoundEffects = new Map<string, Sound>();
export const _sharedSoundEffects = new WeakMap<Sound, Sound>();

const getSoundURL = (sound: SoundEffect) => {
  if (isSafari()) {
    return `${sound}.caf`;
  } else {
    return `${sound}.ogg`;
  }
};

export enum AnimationState {
  idle = "idle",
  walk = "walk",
  hurt = "falling_col",
  runBackards = "run",
  in_air = "in_air",
  run = "run",
  jump = "jump",
  fall = "falling",
}

export class PlayerMesh {
  _color: Color3;
  player: SolidParticleSystem;
  height: number;
  width: number;

  avatar: BaseAvatar;
  plane: Mesh;
  id: string;

  _translucent = false;
  get translucent() {
    return this._translucent;
  }
  set translucent(value: boolean) {
    const isChange = value !== this._translucent;
    this._translucent = value;

    if (isChange && this.isLoaded) {
      this.avatar.material.alpha = value ? 0.5 : 1;
    }
  }
  nameplate: Nameplate;
  emitParticleEffect(
    effect: ParticleEffectName,
    count: number,
    seconds: number
  ) {
    let texture: Texture;
    if (effect === ParticleEffectName.money) {
      texture = new Texture(MoneyParticle, this.scene);
      texture.hasAlpha = true;
    } else {
      return;
    }

    // if (GPUParticleSystem.IsSupported) {
    //   particleSystem = new GPUParticleSystem(
    //     effect + this.id,
    //     { capacity: count, randomTextureSize: 256 },
    //     this.scene
    //   );
    // } else {
    const particleSystem = new ParticleSystem(
      effect + this.id,
      count,
      this.scene
    );
    // }

    particleSystem.emitter = this.nameplate.container;
    particleSystem.worldOffset.set(-0.5, 1, 0);
    particleSystem.particleTexture = texture;
    particleSystem.particleEmitterType = new HemisphericParticleEmitter(
      1,
      0.8,
      0.1
    );

    particleSystem.manualEmitCount = count;

    particleSystem.targetStopDuration = seconds;
    const renderingScale = this.scene.getEngine().getHardwareScalingLevel();
    particleSystem.minScaleX = renderingScale;
    particleSystem.minScaleY = renderingScale;
    particleSystem.maxScaleX = renderingScale;
    particleSystem.maxScaleY = renderingScale;
    particleSystem.disposeOnStop = true;

    particleSystem.start(500);
  }

  _spotlight: SpotLight;
  enableSpotlight = (light: SpotLight) => {
    if (this._spotlight && light !== this._spotlight) {
      this._spotlight.setEnabled(false);
      this._spotlight.dispose();
    }

    this._spotlight = light;
    // light.parent = this.mesh;
    // light.position = new Vector3(0, -0.1, 0.2);
    this._spotlight.setEnabled(true);
    // if (!light.includedOnlyMeshes.includes(this.mesh)) {
    //   light.includedOnlyMeshes.push(this.mesh);
    // }

    light.range = 100;
  };
  disableSpotlight = (light: SpotLight) => {
    if (!this._spotlight) {
      return;
    }
    this._spotlight.setEnabled(false);
  };

  texture: Texture;
  constructor({
    scene,
    AvatarType,
    id,
  }: {
    scene: Scene;
    id: string;
    AvatarType: typeof BaseAvatar;
  }) {
    this.avatar = new AvatarType(scene, id);
    this.id = id;
    this.scene = scene;
  }

  soundEffects = new Map<SoundEffect, Sound>();
  soundEffectStatus = new Map<SoundEffect, Boolean>();

  isPlayingSoundEffect(effect: SoundEffect) {
    if (!Engine.audioEngine.unlocked) {
      return false;
    }

    const sound = this.soundEffects.get(effect);

    if (!sound) {
      return false;
    }

    return sound.isPlaying;
  }

  stopSoundEffectAfterLoop(effect: SoundEffect) {
    if (!Engine.audioEngine.unlocked) {
      return false;
    }

    const sound = this.soundEffects.get(effect);

    if (!sound) {
      return false;
    }

    sound.loop = false;

    return true;
  }

  playSoundEffect = (
    effect: SoundEffect,
    spatialSound: boolean = false,
    loud: boolean = true,
    loop: boolean = true,
    local: boolean = false,
    speed: number = 1.0
  ) => {
    if (!this.isLoaded || !Engine.audioEngine.unlocked) {
      return;
    }

    if (!this.soundEffects.has(effect)) {
      let sound: Sound;
      let url = getSoundURL(effect);

      let opts: ISoundOptions;
      let isSpatial = spatialSound && this.scene.getEngine().webGLVersion > 1;
      if (isSpatial) {
        opts = {
          spatialSound: true,
          streaming: false,
          skipCodecCheck: true,
          loop,
          distanceModel: "exponential",
          refDistance: 25,
          rolloffFactor: 1.1,
          autoplay: true,

          volume: 1,
          playbackRate: speed,
        };
      } else {
        opts = {
          spatialSound: false,
          streaming: false,
          skipCodecCheck: true,
          loop,

          autoplay: true,
          volume: loud ? 0.5 : 0.25,
          playbackRate: speed,
        };
      }

      sound = new Sound(effect, url, this.scene, null, opts);

      if (isSpatial) {
        sound.switchPanningModelToHRTF();
      }

      this.soundEffects.set(effect, sound);

      if (isSpatial) {
        sound.setDirectionalCone(90, 180, 0.5);
        sound.setLocalDirectionToMesh(this.audioDirection);
        sound.attachToMesh(this.mesh);
      } else if (!isSpatial && isSafari() && spatialSound) {
        sound.attachToMesh(this.mesh);
      }
    } else {
      const sound = this.soundEffects.get(effect);
      let canPlay = !sound.isPlaying || sound.isPaused || !loop;

      if (isSafari() && canPlay && !sound.isReady()) {
        canPlay = false;
      }

      if (canPlay) {
        sound.loop = loop;
        sound.setVolume(loud ? 0.5 : 0.25);
        sound.setPlaybackRate(speed);

        sound.play();
        sound.hasStarted = true;
      } else {
        sound.loop = loop;
        sound.setVolume(loud ? 0.5 : 0.25);
        sound.setPlaybackRate(speed);
      }
    }
  };

  stopSoundEffect = (effect: SoundEffect) => {
    if (isSafari() && !Engine.audioEngine.unlocked) {
      return;
    }

    const sound = this.soundEffects.get(effect);
    if (!sound) {
      return;
    }

    if (!sound.isPaused && sound.isPlaying) {
      sound.pause();
    }
  };

  static defaultBackgroundColor = BaseAvatar.defaultBackgroundColor;

  meshes: Array<AbstractMesh>;
  mesh: Mesh | TransformNode;
  skeleton: Skeleton;
  rotation: Vector3;

  transformNode: TransformNode;

  emitEmojiParticles = (emoji: string) => {
    const particleSystem = new ParticleSystem("particles", 100, this.scene);
    const size = 64;

    const texture = new DynamicTexture(
      "emojiEmitter-" + this.id,
      size,
      this.scene,
      true
    );

    texture.uScale = 2;
    texture.vScale = 2;
    texture.wrapR = Texture.CLAMP_ADDRESSMODE;
    texture.wrapU = Texture.CLAMP_ADDRESSMODE;

    texture.wrapV = Texture.CLAMP_ADDRESSMODE;

    texture.hasAlpha = true;

    const ctx = texture.getContext();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.fillRect(0, 0, 256, 256);
    ctx.font = `${48}px system-ui`;
    ctx.fillStyle = "black";
    ctx.fillText(emoji, 0, 128 / 2);
    texture.update();

    //Texture of each particle
    particleSystem.particleTexture = texture;

    // particleSystem.textureMask = this.scene.clearColor;
    // particleSystem.color1 = new Color4(1, 1, 1, 1.0);
    // particleSystem.color2 = new Color4(1, 1, 1, 1.0);
    particleSystem.colorDead = this.scene.clearColor;
    particleSystem.disposeOnStop = true;
    particleSystem.preWarmCycles = 2;

    // Where the particles come from
    particleSystem.particleEmitterType = new SphereParticleEmitter(1, 0.5, 1);

    particleSystem.emitter = this.avatar.parentMesh;
    particleSystem.minEmitBox = new Vector3(-0.5, -0.5, -0.5); // Starting all from
    particleSystem.maxEmitBox = new Vector3(0.5, 0.5, 0.5); // To...
    particleSystem.addAngularSpeedGradient(1.0, -0.5, 0.5);
    particleSystem.addVelocityGradient(1.0, 3, 4);
    particleSystem.addDragGradient(0, 0.5, 0.8);
    particleSystem.addDragGradient(1.0, 0, 0.1);

    // Size of each particle (random between...
    particleSystem.addSizeGradient(0, 0.25, 0.5);
    particleSystem.addSizeGradient(1.0, 0.75, 1.25);
    particleSystem.targetStopDuration = 2;

    // Life time of each particle (random between...
    particleSystem.minLifeTime = 2.0;

    particleSystem.maxLifeTime = 4.0;

    // Emission rate
    particleSystem.emitRate = 20;
    // particleSystem.blendMode = ParticleSystem.BLENDMODE_STANDARD;
    // particleSystem.isBillboardBased = false;
    // particleSystem.billboardMode = AbstractMesh.BILLBOARDMODE_USE_POSITION;

    // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD

    // if (this._emojiParticleSystem) {
    //   this._emojiParticleSystem.stop();
    //   this._emojiParticleSystem.dispose(true);
    //   this._emojiParticleSystem = null;
    //   window.setTimeout(() => this.emitEmojiParticles, 1);
    //   return;
    // }

    particleSystem.start();
  };

  load = async (
    id: string,
    intoScene: boolean,
    withPlane = true,
    engine?: Engine
  ) => {
    this.avatar.id = id;
    await this.avatar.load(id, intoScene, engine);
    this.skeleton = this.avatar.skeleton;
    this.meshes = this.avatar.meshes;
    this.mesh = this.avatar.parentMesh;
    this.rotation = this.avatar.rotation;
    this.audioDirection = this.rotation;

    if (this.sound) {
      this.sound.attachToMesh(this.mesh);
    }

    return true;
  };

  get animationState() {
    return this.avatar.animationState;
  }

  set animationState(value) {
    this.avatar.animationState = value;
  }

  get isLoaded() {
    return this.avatar.isLoaded;
  }

  set isLoaded(val) {
    this.avatar.isLoaded = val;
  }

  setSkinColor = (...args) => this.avatar.setSkinColor(...args);
  setSkinStyle = (...args) => this.avatar.setSkinStyle(...args);
  setBackgroundColor = (...args) => this.avatar.setBackgroundColor(...args);
  setBackgroundImage = (...args) => this.avatar.setBackgroundImage(...args);

  animateOnce = (animationState: AnimationState) =>
    this.avatar.animateOnce(animationState);

  animate = (name: AnimationState, loop = true, speed = 1.0) =>
    this.avatar.animate(name, loop, speed);

  sound: Sound = null;

  createSoundFromURL = (url: string) => {
    this.sound = new Sound(
      `players-${url}`,
      getSoundURL(url),
      this.scene,
      () => {},
      {
        spatialSound: true,
        streaming: true,
        loop: true,
        distanceModel: "linear",
        maxDistance: 500,
        autoplay: true,
        refDistance: 25,
        rolloffFactor: 2,
        skipCodecCheck: true,
      }
    );

    this.sound.switchPanningModelToHRTF();
    this.sound.setDirectionalCone(90, 180, 0.5);
    this.sound.setLocalDirectionToMesh(this.rotation);
  };

  audioTag: HTMLAudioElement;

  createSoundFromStream = (sourceNode: MediaStream, context: AudioContext) => {
    if (this.sound) {
      this.sound.dispose();
    }

    let audioTag = this.audioTag;

    if (!audioTag) {
      audioTag = new Audio();
      this.audioTag = audioTag;
    }

    audioTag.srcObject = sourceNode;
    audioTag.muted = true;
    audioTag.hidden = true;
    document.body.appendChild(audioTag);

    this.sound = new Sound(
      `players-${this.id}`,
      sourceNode,
      this.scene,
      () => {},
      {
        spatialSound: true,
        streaming: true,
        loop: true,
        distanceModel: isSafari() ? "linear" : "exponential",
        maxDistance: 12 * CHUNK_SIZE,
        refDistance: 100,
        rolloffFactor: 2,

        autoplay: true,
        skipCodecCheck: true,
      }
    );

    if (!isSafari()) {
      this.sound.switchPanningModelToHRTF();
    }

    // this.sound.spatialSound = false;

    this.sound._isReadyToPlay = true;
    this.sound.setDirectionalCone(320, 340, 0.5);
    this.sound.setLocalDirectionToMesh(this.audioDirection);

    if (this.mesh) {
      this.sound.attachToMesh(this.mesh);
      // audioTag.play();
    }
  };
  audioDirection: Vector3;

  scene: Scene;

  render = () => {
    if (!this.isLoaded) {
      return;
    }

    this.avatar.render();
  };

  tick() {
    // this.rotation.multiplyToRef(
    //   this.scene.activeCamera.parent.rotation,
    //   this.audioDirection
    // );
  }

  dispose = (includeStream: boolean = false) => {
    this.soundEffects.forEach((sound) => {
      if (!_sharedSoundEffects.has(sound)) {
        sound.dispose();
      }
    });

    this.soundEffects.clear();
    if (this._emojiParticleSystem) {
      this._emojiParticleSystem.dispose(true);
    }

    if (this.plane) {
    }

    this.isLoaded = false;
    this.avatar.dispose();

    if (includeStream && this.sound) {
      this.sound.dispose();
      this.sound = null;
      if (this.audioTag) {
        this.audioTag.remove();
      }
    }
  };
}

export default PlayerMesh;
