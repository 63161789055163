import { InventorySlots } from "lib/Data/InventorySlots";
import { BlockID } from "shared/BlockID";
import { PlayerInventory } from "lib/Data/PlayerInventory";
import { ImageUpload } from "lib/Data/ImageUpload";
import { itemId } from "shared/items";
import { PictureVariant } from "shared/foilage";
import { BaseData } from "./BaseData";

export class Data extends BaseData {
  inventorySlotsTable: Dexie.Table<InventorySlots, number>;
  imageUploadsTable: Dexie.Table<ImageUpload, number>;

  static db = typeof window === "undefined" ? null : new Data();
  constructor(name = `talkingheads-${process.env.NODE_ENV}`) {
    super(name);

    this.inventorySlotsTable = this.table("inventorySlots");

    this.imageUploadsTable = this.table("imageUploads");

    this.inventorySlotsTable.mapToClass(InventorySlots);
    this.imageUploadsTable.mapToClass(ImageUpload);
  }

  _inventorySlots: InventorySlots | null;
  _playerInventory: PlayerInventory | null;
  inventorySlots = async () => {
    if (!this._inventorySlots) {
      if (!this.isOpen()) {
        await this.open();
      }
      const existingInventory = await this.inventorySlotsTable.limit(1).first();

      if (existingInventory) {
        this._inventorySlots = existingInventory;
        this.hasSavedInventory = true;
      } else {
        this._inventorySlots = new InventorySlots();
        this.hasSavedInventory = false;
      }

      if (!this._playerInventory) {
        this._playerInventory = new PlayerInventory();
      }

      this._playerInventory.inventorySlots = this._inventorySlots;

      if (!this.hasSavedInventory) {
        this._playerInventory.secondSlot = itemId(BlockID.bedrock, 0);
        this._playerInventory.thirdSlot = itemId(BlockID.quartz_block, 0);
        this._playerInventory.fourthSlot = itemId(BlockID.brick_block, 0);
        this._playerInventory.fifthSlot = itemId(BlockID.television, 0);
        this._playerInventory.sixthSlot = itemId(
          BlockID.picture,
          PictureVariant.picturePortrait
        );
        this._playerInventory.seventhSlot = itemId(BlockID.lollipop, 0);
      }
    }

    return this._inventorySlots;
  };

  playerInventory = async () => {
    await this.inventorySlots();

    return this._playerInventory;
  };

  hasSavedInventory = false;

  _updateInventory = async () => {
    let inventory = this._inventorySlots;

    if (!inventory) {
      inventory = await this.inventorySlots();
    }

    if (!this.hasSavedInventory) {
      this._inventorySlots = inventory;

      await this.inventorySlotsTable.add(inventory);
      this.hasSavedInventory = true;
      return inventory.id;
    } else {
      return this.transaction("rw", this.inventorySlotsTable, () => {
        this._inventorySlots = inventory;
        return this.inventorySlotsTable.put(inventory);
      });
    }
  };

  updateInventory = async () => {
    if (!this.isOpen()) {
      await this.open();
    }

    this._updateInventory();
  };
}

export default Data;
