import { Character } from "game/Avatar/Character";
import { CoolGlassesGirl } from "game/Avatar/CoolGlassesGirl";
import { RickAstley } from "game/Avatar/RickAstley";
import { RobotAvatar } from "game/Avatar/RobotAvatar";
import { Surgeon } from "game/Avatar/Surgeon";
export const AvatarClassType = [
  RobotAvatar,
  RickAstley,
  CoolGlassesGirl,
  Surgeon,
  Character,
];
