import {
  AnimationGroup,
  Bone,
  Color3,
  Engine,
  Mesh,
  Scene,
  SceneLoader,
  Skeleton,
  Space,
  Vector3,
} from "@babylonjs/core";
import Chroma from "chroma-js";

export const loadAssets = async (path, filename, scene, engine) => {
  return scene
    ? await SceneLoader.LoadAssetContainerAsync(path, filename, scene)
    : await SceneLoader.LoadAsync(path, filename, engine);
};

export const normalizeColor = (color: string | Color3) => {
  if (color instanceof Color3) {
    return color;
  }

  if (color && color.length > 0 && color !== "transparent") {
    const chroma = Chroma(color);

    if (chroma.alpha() === 0) {
      return null;
    }

    const [r, g, b] = chroma.rgb(false);

    return new Color3(r / 255, g / 255, b / 255);
  } else {
    return null;
  }
};

export const normalizeTextureURL = (url: string) => {
  if (typeof url === "undefined" || !url || url.length === 0) {
    return null;
  }

  if (url.startsWith("https://") || url.startsWith("http://")) {
    return url;
  } else {
    return null;
  }
};

export type AvatarAnimationMap = {
  [key: string]: {
    from: number;
    to: number;
  };
};

export enum AnimationState {
  idle = "idle",
  walk = "walk",
  hurt = "falling_col",
  runBackards = "run",
  in_air = "in_air",
  run = "run",
  jump = "jump",
  fall = "falling",
}

export type AvatarLoad = {
  meshes: Array<Mesh>;
  animationGroups: Array<AnimationGroup>;
  skeletons: Array<Skeleton>;
  mesh: Mesh;
};

export class BaseAvatar {
  constructor(scene, id: string) {
    this.scene = scene;
    this.id = id;
  }

  id: string;

  isLoaded = false;
  protected _animationGroups: Array<AnimationGroup>;
  protected _mesh: Mesh;
  protected _meshes: Array<Mesh>;
  protected _skeleton: Skeleton;
  protected static _skinStyles: {
    [key: string]: string;
  };

  invisibleBox: Mesh;

  protected static _defaultBackgroundColor: Color3;

  static skinStyleURL(skinStyle: string) {
    return this._skinStyleURL(skinStyle || this.defaultSkinStyle);
  }

  static get defaultBackgroundColor() {
    if (this.hasOwnProperty("_defaultBackgroundColor")) {
      return this._defaultBackgroundColor;
    } else {
      return new Color3(1, 1, 1);
    }
  }

  get meshes() {
    return this._meshes;
  }

  get mesh() {
    if (this.hasOwnProperty("_mesh")) {
      return this._mesh;
    } else {
      return null;
    }
  }
  get animationGroups() {
    if (this.hasOwnProperty("_animationGroups")) {
      return this._animationGroups;
    } else {
      return [];
    }
  }
  get skeleton(): Skeleton {
    if (this.hasOwnProperty("_skeleton")) {
      return this._skeleton;
    } else {
      return null;
    }
  }

  addInvisibleBox = () => {
    // this.invisibleBox = Mesh.CreatePlane("invisibleBox", 1, this.scene);
    // this.invisibleBox.material = new StandardMaterial(
    //   "okpoaskdpaosdk",
    //   this.scene
    // );
    // this.invisibleBox.material.diffuseColor = new Color3(1, 1, 1);
    // this.invisibleBox.setParent(this.mesh);
    // this.invisibleBox.position.set(0, 0, -1);
    // // this.invisibleBox.setEnabled(false);
  };

  protected _load: (
    id: string,
    insertIntoScene: boolean,
    engine
  ) => Promise<void>;
  load = async (id: string, insertIntoScene: boolean, engine?: Engine) => {
    await this._load(id, insertIntoScene, engine);
    this.isLoaded = true;

    this.addInvisibleBox();

    this.animationState = AnimationState.idle;
    this.runDefaultAnimation(AnimationState.idle);
    for (let mesh of this.meshes) {
      mesh.useOctreeForCollisions = false;
      mesh.useOctreeForPicking = false;
      mesh.useOctreeForRenderingSelection = false;
    }
  };

  static get skinStyles() {
    if (this.hasOwnProperty("_skinStyles")) {
      return this._skinStyles;
    } else {
      return {};
    }
  }

  _rotation: Vector3;

  get rotation() {
    return this._rotation;
  }

  protected _headBone: Bone;
  get headBone(): Bone {
    return this._headBone;
  }

  get defaultSkinStyle(): string {
    return null;
  }

  scene: Scene;

  private static _defaultSkinStyle: string;
  static get defaultSkinStyle() {
    if (this.hasOwnProperty("_defaultSkinStyle")) {
      return this._defaultSkinStyle;
    } else {
      return null;
    }
  }

  private static _skinStyleMapping: {
    [key: string]: {
      url: string;
      thumbnail: string;
    };
  };

  parentMesh: Mesh;

  static get skinStyleMapping() {
    if (this.hasOwnProperty("_skinStyleMapping")) {
      return this._skinStyleMapping;
    } else {
      return {};
    }
  }

  get headTilt() {
    return this.headBone.rotation.y;
  }

  private _headTilt = 0;

  set headTilt(tilt) {
    this._headTilt = tilt;

    const headBone = this.headBone;

    if (!headBone) {
      return;
    }

    this.headBone.rotate(new Vector3(1, 0, 0), tilt, Space.BONE, this.mesh);
  }

  protected _animationState = AnimationState.idle;
  animationTickCount = 0;
  static _animations: AvatarAnimationMap;

  static get animations() {
    return this._animations;
  }

  get animationState() {
    return this._animationState;
  }

  lastAnimationState: AnimationState;

  set animationState(state: AnimationState) {
    let lastAnimationState = this._animationState;
    const isChanging = state !== this._animationState;

    this._animationState = state;
    this.isAnimationEnded = false;

    if (isChanging && this.isLoaded) {
      this.lastAnimationState = lastAnimationState;
      this.runDefaultAnimation(state);
    }

    if (isChanging) {
      this.animationTickCount = 0;
    }
  }

  runDefaultAnimation = (state: AnimationState) => {
    const isLooping = AnimationState.jump !== state;
    this.animate(state, isLooping);
  };

  get maxAnimationTickCount() {
    if (this.animationState === AnimationState.jump) {
      return (
        this.constructor.animations[AnimationState.jump].to -
        this.constructor.animations[AnimationState.jump].from
      );
    }

    return Infinity;
  }

  isAnimationEnded = false;

  get isAnimatonExpired() {
    return (
      this.isAnimationEnded ||
      this.maxAnimationTickCount <= this.animationTickCount
    );
  }

  onAnimationEnd = () => {
    this.isAnimationEnded = true;
  };

  tiltDirection = new Vector3(1, 0, 0);

  render = () => {
    // if (this.headTilt !== 0 && this.headBone) {
    //   this.headBone.setYawPitchRoll(
    //     this.headBone.rotation.x,
    //     this.headBone.rotation.y,
    //     0,
    //     Space.LOCAL,
    //     this.mesh
    //   );
    // }
  };

  onAnimatedOnce = () => {
    this.runDefaultAnimation(this.animationState);
  };

  animateOnce(name: AnimationState) {
    if (!this.skeleton) {
      return;
    }
    this.scene.beginAnimation(
      this.skeleton,
      (
        this.constructor.animations[name] ??
        this.constructor.animations[AnimationState.idle]
      ).from,
      (
        this.constructor.animations[name] ??
        this.constructor.animations[AnimationState.idle]
      ).to,
      false,
      1,
      this.onAnimatedOnce
    );
  }

  animate(name: AnimationState, loop = true, speed = 1.0) {
    if (!this.skeleton) {
      return;
    }

    this.scene.beginAnimation(
      this.skeleton,
      (
        this.constructor.animations[name] ??
        this.constructor.animations[AnimationState.idle]
      ).from,
      (
        this.constructor.animations[name] ??
        this.constructor.animations[AnimationState.idle]
      ).to,
      loop,
      speed,
      !loop ? this.onAnimationEnd : undefined
    );
  }

  _reset: () => void;

  reset = () => {
    this._reset();
  };

  setBackgroundImage: (url: string, width: number, height: number) => void;

  __proto__: { constructor: typeof BaseAvatar };

  skinStyle: string;
  skinColor: Color3;
  backgroundColor: Color3;
  backgroundImage: string | null;
  setSkinStyle: (skinStyle: String) => void;
  setSkinColor: (color: Color3) => void;
  setBackgroundColor: (color: Color3) => void;

  dispose = () => {
    if (this.skeleton) {
      this.skeleton.dispose();
      this._skeleton = null;
    }

    if (this.parentMesh) {
      this.parentMesh.dispose(false, true);
      this.parentMesh = null;
    }

    if (this.mesh) {
      this.mesh.dispose(false, true);
      this._mesh = null;
    }

    if (this._texture) {
      this._texture.dispose();
      this._texture = null;
    }

    this.isLoaded = false;
  };
}
