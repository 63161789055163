import nengi from "nengi";
import { NengiBlockCoordinate } from "../nengiTypes";

export class RequestWorldDataCommand {
  constructor({
    id,
    x,
    y,
    z,
    strideX,
    strideY,
    strideZ,
    shapeX,
    shapeY,
    shapeZ,
  }) {
    this.x = x;
    this.id = id;
    this.z = z;
    this.y = y;
    this.strideX = strideX;
    this.strideY = strideY;
    this.strideZ = strideZ;
    this.shapeX = shapeX;
    this.shapeY = shapeY;
    this.shapeZ = shapeZ;
  }
  x: number;
  y: number;
  z: number;
  id: string;
  strideX: number;
  strideY: number;
  strideZ: number;
  shapeX: number;
  shapeY: number;
  shapeZ: number;

  static protocol = {
    y: NengiBlockCoordinate,
    z: NengiBlockCoordinate,
    x: NengiBlockCoordinate,
    strideX: nengi.Int32,
    strideY: nengi.Int32,
    strideZ: nengi.Int32,
    shapeX: nengi.Int32,
    shapeY: nengi.Int32,
    shapeZ: nengi.Int32,
    id: nengi.ASCIIString,
  };
}

export default RequestWorldDataCommand;
