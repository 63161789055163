import { MINIMAP_HEIGHT, MINIMAP_WIDTH } from "lib/MINIMAP_WIDTH";
import * as React from "react";
import Shadow1x from "./Shadow.png";
import Shadow2x from "./Shadow@2x.png";
import Shadow3x from "./Shadow@3x.png";

export const MinimapShadow = ({ className }) => (
  <img
    width={MINIMAP_WIDTH}
    height={MINIMAP_HEIGHT}
    className={className}
    id="minimap-shadow"
    src={Shadow1x}
    srcSet={`${Shadow1x} 1x, ${Shadow2x} 2x, ${Shadow3x}3x`}
  ></img>
);
