import { WorldMapStore } from "lib/Data/WorldMapStore";
import { RenderMapTileGroup, MapPlayer } from "lib/Data/WorldMap/RenderMapTile";
import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";

export class Minimap {
  worldMap: WorldMapStore;
  mapTileGroup: RenderMapTileGroup;
  currentChunkX: number;
  currentChunkZ: number;
  zoomLevel: number;
  hardwareScalingLevel: number;
  globalX: number;
  globalZ: number;
  playerCenterX: number;
  playerCenterZ: number;
  static cs = CHUNK_SIZE;
  width: number;
  height: number;

  minChunkX: number;
  minChunkZ: number;
  maxChunkX: number;
  maxChunkZ: number;
  playerCanvas: OffscreenCanvas | HTMLCanvasElement;
  playerCtx: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
  backgroundCanvas: OffscreenCanvas | HTMLCanvasElement;
  backgorundCtx: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;
  players: Array<MapPlayer>;

  absoluteSquareLength = 6;

  get yLength() {
    return Math.ceil(this.absoluteSquareLength / this.zoomLevel);
  }

  _xLength: number;

  get xLength() {
    if (!this._xLength) {
      this._xLength = this.yLength;
    }

    return this._xLength;
  }

  backgroundColor = "#ccc";

  start() {
    this.backgorundCtx.fillStyle = this.backgroundColor;
    this.backgorundCtx.fillRect(
      0,
      0,
      this.backgorundCtx.canvas.width,
      this.backgorundCtx.canvas.height
    );

    this.tileGroup.worldMap = this.worldMap;
    this.objectScale = this.hardwareScalingLevel;
  }

  tileGroup = new RenderMapTileGroup();

  tickChunkZ: number;
  tickChunkX: number;

  updateTickChunkRnage(x: number, z: number) {
    const cs = CHUNK_SIZE;

    const tickChunkZ = Math.floor(z / cs);
    const tickChunkX = Math.floor(x / cs);

    const didChange =
      this.tickChunkZ !== tickChunkZ || this.tickChunkX !== tickChunkX;

    this.tickChunkX = tickChunkX;
    this.tickChunkZ = tickChunkZ;

    return didChange;
  }

  updateChunkRange() {
    const tickChunkZ = Math.floor(this.globalZ / CHUNK_SIZE);
    const tickChunkX = Math.floor(this.globalX / CHUNK_SIZE);
    const didChange =
      this.currentChunkZ !== tickChunkZ || this.currentChunkX !== tickChunkX;

    this.currentChunkZ = tickChunkZ;
    this.currentChunkX = tickChunkX;

    return didChange;
  }

  translateX: number = 0;
  translateZ: number = 0;
  canDraw = true;
  objectScale: number;
  overdraw = true;

  xOffset = 0;
  yOffset = 0;
  visibleChunkSize = CHUNK_SIZE;
  render(renderPlayers: boolean = true) {
    const {
      currentChunkZ,
      currentChunkX,
      globalX,
      globalZ,
      players,
      objectScale,
      xLength,
      yLength,
      xOffset,
      yOffset,
    } = this;

    let translateX = ((globalX % CHUNK_SIZE) + CHUNK_SIZE) % CHUNK_SIZE;
    let translateZ = ((globalZ % CHUNK_SIZE) + CHUNK_SIZE) % CHUNK_SIZE;

    let minChunkX = currentChunkX - xLength;
    let minChunkZ = currentChunkZ - xLength;
    let maxChunkX = currentChunkX + yLength;
    let maxChunkZ = currentChunkZ + yLength;

    let overdrawY = 0;
    let overdrawX = 0;

    const cs = Math.round(
      CHUNK_SIZE * this.zoomLevel * this.hardwareScalingLevel
    );
    this.visibleChunkSize = cs;
    // } else {
    //   objectScale = 1;
    // translateX;
    // translateZ;
    // }

    if (this.overdraw) {
      translateX *= this.zoomLevel * this.hardwareScalingLevel;
      translateZ *= this.zoomLevel * this.hardwareScalingLevel;

      if (translateZ > cs * 0.4) {
        overdrawY = Math.round(this.zoomLevel) * 2;
        maxChunkZ += overdrawY;
      } else if (translateZ < cs * 0.5) {
        overdrawY = -1;
        minChunkZ--;
      }

      if (translateX > cs * 0.4) {
        overdrawX = Math.round(this.zoomLevel) * 2;
        maxChunkX += overdrawX;
      } else if (translateX < cs * 0.5) {
        minChunkX--;
        overdrawX = -1;
      }
    }

    let needsMapDraw =
      minChunkX !== this.minChunkX ||
      minChunkZ !== this.minChunkZ ||
      maxChunkX !== this.maxChunkX ||
      maxChunkZ !== this.maxChunkZ ||
      this.forceMapDraw;

    this.minChunkX = minChunkX;
    this.minChunkZ = minChunkZ;
    this.maxChunkX = maxChunkX;
    this.maxChunkZ = maxChunkZ;

    if (needsMapDraw && this.canDraw) {
      this.playerCtx.clearRect(0, 0, this.width, this.height);
      this.tileGroup.drawnPlayers.clear();
      if (this.tileGroup.drawnChunks) {
        this.tileGroup.drawnChunks.data.fill(null);
      }

      if (!USE_IMAGE_BITMAP) {
        this.backgorundCtx.fillRect(
          0,
          0,
          this.backgorundCtx.canvas.width,
          this.backgorundCtx.canvas.height
        );
      }
    }

    this.tileGroup.render(
      this.canDraw,
      currentChunkX,
      currentChunkZ,
      minChunkX,
      minChunkZ,
      maxChunkZ,
      maxChunkX,
      cs,
      players,
      needsMapDraw,
      objectScale,
      this.playerCtx,
      this.backgorundCtx,
      xOffset,
      yOffset,
      renderPlayers
    );

    this.translateX = translateX;
    this.translateZ = translateZ;
    this.forceMapDraw = false;
  }

  forceMapDraw = false;
}
