import chroma from "chroma-js";
import classnames from "classnames";
import { HUDEvent } from "components/HUDEvent";
import EventEmitter from "eventemitter3";
import { CurrentPlayer } from "game/CurrentPlayer";
import { MenuState } from "game/MenuState";
import { Commands } from "game/networking/Commands";
import * as React from "react";
import { CSSTransition } from "react-transition-group";
import { AvatarType } from "shared/entity/PlayerCharacter";
import CoolGlassesGirlThumbnail from "./character/coolglassesgirl.thumbnail.png";
import RobotThumbnail from "./character/robot.thumbnail.png";
import SurgeonThumbnail from "./character/surgeon.thumbnail.png";

const THUMBNAIL_WIDTH = 110;

const THUMBNAIL_PATHS = [
  RobotThumbnail,
  null,
  CoolGlassesGirlThumbnail,
  SurgeonThumbnail,
];

const CharacterListItem = ({ character, isActive, onClick }) => {
  const handleClick = React.useCallback(() => onClick(character), [
    onClick,
    character,
  ]);

  return (
    <div
      onClick={handleClick}
      className={classnames("CharacterListItem", {
        "CharacterListItem--active": !!isActive,
        "CharacterListItem--inactive": !isActive,
      })}
    >
      <img src={THUMBNAIL_PATHS[character]} height={200} />

      <div className="Border" />

      <style jsx>{`
        .CharacterListItem {
          transition: transform 0.1s linear;
          cursor: pointer;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 12px;
          background-image: radial-gradient(
            rgb(32, 79, 169) 26.1%,
            rgb(21, 48, 101) 86.53%
          );
          transition-property: transform, filter;
        }

        img {
          appearance: none;
          -webkit-appearance: none;
        }

        .Border {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;

          border: 1px solid white;
        }

        img,
        .Border,
        .CharacterListItem {
          border-radius: 4px;
        }

        .CharacterListItem--inactive {
          filter: grayscale(25%);
        }

        .CharacterListItem--inactive .Border {
          border-color: transparent;
        }

        .CharacterListItem--active,
        .CharacterListItem--inactive:hover {
          transform: scale(1.02);
          filter: grayscale(0%);
        }
      `}</style>
    </div>
  );
};

const CharacterList = ({ onClick, character, color }) => (
  <div
    className="CharacterList-container"
    style={{
      backgroundColor: chroma(color && color.length > 0 ? color : "#333")
        .alpha(0.6)
        .css(),
    }}
  >
    <div className="CharacterList">
      {/* <CharacterListItem
        character={AvatarType.character}
        isActive={character === AvatarType.character}
        onClick={onClick}
      /> */}

      <CharacterListItem
        character={AvatarType.robot}
        isActive={character === AvatarType.robot}
        onClick={onClick}
      />

      <CharacterListItem
        character={AvatarType.coolGlassesGirl}
        isActive={character === AvatarType.coolGlassesGirl}
        onClick={onClick}
      />

      <CharacterListItem
        character={AvatarType.surgeon}
        isActive={character === AvatarType.surgeon}
        onClick={onClick}
      />
    </div>

    <style jsx>{`
      .CharacterList {
        display: grid;

        grid-template-columns: repeat(3, ${THUMBNAIL_WIDTH}px);
        grid-template-rows: auto;
        gap: 12px;
      }

      .CharacterList-container {
        padding: 12px;
        border-radius-bottom-left: 10px;
        border-radius-bottom-right: 10px;
        padding-bottom: 80px;
        min-height: 340px;
        max-height: 500px;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
        overflow-scrolling: touch;
        overflow-y: auto;
      }
    `}</style>
  </div>
);

const CHARACTER_LABELS = {
  [AvatarType.character]: "Hey Friend",
  [AvatarType.coolGlassesGirl]: "Betty",
  [AvatarType.robot]: "X Æ A-Xii",
  [AvatarType.surgeon]: "Dr. Miles",
};

const stopPropagation = (e) => e.stopPropagation();

const CharacterEditorComponent = ({
  character,
  color,
  onChangeCharacter,
  className,
  onChangeColor,
}) => {
  return (
    <div
      onMouseDown={stopPropagation}
      onMouseUp={stopPropagation}
      onClick={stopPropagation}
      className={classnames(`Wrapper`, className)}
    >
      <div className="Header">
        <div className="Title">Avatar</div>
      </div>

      <CharacterList
        color={color}
        character={character}
        onClick={onChangeCharacter}
      />

      <div className="Footer">
        <div className="Footer-title">{CHARACTER_LABELS[character]}</div>
      </div>

      <style jsx>{`
        .Header {
          display: flex;
          justify-content: space-between;
          margin-bottom: 6px;
          margin-top: 6px;
          padding-left: 18px;
          padding-right: 18px;
        }

        .Footer-title {
          margin-right: 4px;
        }

        .Footer {
          position: absolute;
          bottom: 0;
          left: 0;
          color: white;
          font-size: 18px;
          padding-bottom: 12px;
          padding-top: 12px;
          height: 48px;
          padding-left: 12px;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.75);
        }

        .Footer-top,
        .Footer-bottom {
          display: flex;
          align-items: center;
        }

        .Footer-bottom {
          margin-top: 8px;
        }

        .Footer-cost {
          color: #85bb65;
        }

        .Footer--hide {
          visibility: hidden;
        }

        .Title {
          font-size: 24px;
          font-weight: 500;
          color: white;
        }

        .Wrapper {
          position: absolute;

          pointer-events: auto;
          bottom: 156px;
          will-change: transform, opacity;

          backdrop-filter: blur(10px);
          background-color: rgba(0, 0, 0, 0.4);
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
          border-radius: 10px;
          overflow: hidden;
          right: 38px;
          width: 380px;
          transform-origin: bottom right;

          transition: opacity 0.1s linear;
          transition-property: transform, opacity;
        }
      `}</style>

      <style jsx global>{`
        .CharacterEditorTransition-exit.Wrapper {
          opacity: 0;
          transform: scale(0.1);
        }
        .CharacterEditorTransition-enter.Wrapper {
          opacity: 0;
          transform: scale(0.98);
        }

        .CharacterEditorTransition-enter-active.Wrapper {
          opacity: 1;
          transform: scale(1);
        }
      `}</style>
    </div>
  );
};

type Props = {
  socket: EventEmitter;
  currentPlayer: CurrentPlayer;
};

class CharacterEditorContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      character: props.currentPlayer.remoteEntity.avatarType,
      color: props.currentPlayer.remoteEntity.color,
    };

    this.startListening();
  }

  startListening = () => {
    this.props.socket.on(HUDEvent.changePlayer, this.updatePlayer);
  };

  stopListening = () => {
    this.props.socket.off(HUDEvent.changePlayer, this.updatePlayer);
  };

  updatePlayer = () =>
    this.setState({
      character: this.props.currentPlayer.remoteEntity.avatarType,
      color: this.props.currentPlayer.remoteEntity.color,
    });

  handleChangeCharacter = (character: AvatarType) => {
    Commands.changeAvatar(character, this.props.currentPlayer);
    this.setState({ character });
  };
  handleChangeColor = (color) => {};

  componentWillUnmount() {
    this.stopListening();
  }

  render() {
    return (
      <CharacterEditorComponent
        color={this.state.color}
        className={this.props.className}
        character={this.state.character}
        onChangeColor={this.handleChangeColor}
        onChangeCharacter={this.handleChangeCharacter}
      />
    );
  }
}

export const CharacterEditor = ({ menuState, ...props }) => {
  return menuState === MenuState.character ? (
    <CSSTransition
      key={"character-editor"}
      timeout={100}
      classNames="CharacterEditorrTransition"
    >
      <CharacterEditorContainer {...props} />
    </CSSTransition>
  ) : null;
};
