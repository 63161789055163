import { BitFieldMixin } from "structurae";

export enum FoilageScaleBitValue {
  normal = 0,
  big = 1,
  huge = 2,
}

export enum FoilageRotationBitValue {
  none = 0,
  quarter = 1,
  half = 2,
  threeQuarters = 3,
  full = 4,
}

function getBitSize(number: number) {
  if (number < 281474976710656) return (Math.log2(number) | 0) + 1;
  const n = BigInt(number);
  const [zero, one, two] = [BigInt(0), BigInt(1), BigInt(2)];
  let high = BigInt(53);
  let low = BigInt(48);

  while (high - low > one) {
    const mid = (high + low) / two;
    const maskHigh = (one << high) - (one << mid);
    if ((maskHigh & n) > zero) {
      low = mid;
    } else {
      high = mid;
    }
  }
  return Number(low + one);
}

// 32 available bits.
export const ItemVariantField = BitFieldMixin({
  variant: getBitSize(255),
  rotation: getBitSize(FoilageRotationBitValue.full),
  scale: getBitSize(FoilageScaleBitValue.huge),
  xNormal: getBitSize(2),
  yNormal: getBitSize(2),
  zNormal: getBitSize(2),
});

export type ItemVariant = {
  variant: number;
  rotation: number;
  scale: number;
  xNormal: 1 | 0 | 2;
  yNormal: 1 | 0 | 2;
  zNormal: 1 | 0 | 2;
  unused: 0;
};

const result = {
  variant: 0,
  rotation: 0,
  scale: 0,
  xNormal: 0,
  yNormal: 0,
  zNormal: 0,
};

const resultMapping = [
  "variant",
  "rotation",
  "scale",
  "xNormal",
  "yNormal",
  "zNormal",
];
export const getItemVariant = (variant: number): ItemVariant =>
  (ItemVariantField.decode(variant, result) as any) as ItemVariant;

function decodeField(data, result) {
  const { fields, masks, schema } = ItemVariantField;
  let value = data;
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const size = schema[field];
    result[field] = value & masks[field];
    value >>= size;
  }
  return result;
}

export function encodeItemVariant(variant: ItemVariant) {
  const { fields, schema } = ItemVariantField;
  let result = 0;
  for (let i = fields.length - 1; i >= 0; i--) {
    const field = fields[i];
    const current = variant[resultMapping[i]];
    result <<= schema[field];
    result |= current;
  }
  return result;
}
