import * as React from "react";
import { GameContext } from "components/GameContext";
import { LiveStreamEvent } from "lib/LiveStreamEvent";
import dynamic from "next/dynamic";

const LiveStreamStatusBar = dynamic(
  () => import("components/hud/livestream/LiveStreamStatusBar"),
  { ssr: false, loading: () => null }
);

export const LiveStreamBar = ({}) => {
  const gameContextValue = React.useContext(GameContext);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const game = gameContextValue?.game;
    if (!game) {
      return;
    }

    const updateStatus = () => {
      setShow(true);
    };

    game.socket.on(LiveStreamEvent.change, updateStatus);

    return () => {
      game.socket.off(LiveStreamEvent.change, updateStatus);
    };
  }, [setShow, gameContextValue?.game]);

  if (!gameContextValue?.game) {
    return null;
  }

  if (!show) {
    return null;
  }

  return <LiveStreamStatusBar />;
};

export default LiveStreamBar;
