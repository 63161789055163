import vkey from "vkey";
import { EventEmitter } from "eventemitter3";
import { isArray } from "lodash";
/*
 *   Simple inputs manager to abstract key/mouse inputs.
 *        Inspired by (and where applicable stealing code from)
 *        game-shell: https://github.com/mikolalysenko/game-shell
 *
 *  inputs.bind( 'move-right', 'D', '<right>' )
 *  inputs.bind( 'move-left',  'A' )
 *  inputs.unbind( 'move-left' )
 *
 *  inputs.down.on( 'move-right',  function( binding, event ) {})
 *  inputs.up.on(   'move-right',  function( binding, event ) {})
 *
 *  inputs.state['move-right']  // true when corresponding keys are down
 *  inputs.state.dx             // mouse x movement since tick() was last called
 *  inputs.getBindings()        // [ 'move-right', 'move-left', ... ]
 */

export class Inputs {
  constructor(element) {
    // settings
    this.element = element || document;

    // register for dom events
    this.initEvents();

    for (let state of Object.keys(Inputs.defaultBindings)) {
      for (let key of Inputs.defaultBindings[state]) {
        this.bind(state, key);
      }
    }
  }
  static defaultBindings = {
    forward: ["W", "<up>"],
    left: ["A", "<left>"],
    backward: ["S", "<down>"],
    right: ["D", "<right>"],
    "mid-fire": ["<mouse 2>", "Q"],
    jump: ["<space>"],
    sprint: ["<shift>"],
    crouch: ["<control>"],
  };

  disabled: boolean = false;
  allowContextMenu = true;
  stopPropagation = false;
  preventDefaults = false;

  _keybindmap: { keyCode: Array<string> } = {};
  _keyStates = {};
  _bindPressCounts = {};
  state: {
    forward: boolean;
    left: boolean;
    backward: boolean;
    jump: boolean;
    sprint: boolean;
    right: boolean;
    equipSlot2?: boolean;
    equipSlot1?: boolean;
    equipSlot3?: boolean;
    equipSlot4?: boolean;
    equipSlot5?: boolean;
    equipSlot6?: boolean;
    equipSlot7?: boolean;
    equipSlot8?: boolean;
    closeMenu?: boolean;
    voice?: boolean;
    toggleInventory?: boolean;
    openChat?: boolean;
    panCamera?: boolean;
    flipFoilage?: boolean;
    scaleFoilage?: boolean;
    place?: boolean;
    destroy?: boolean;
    select?: boolean;
    dx: number;
    dy: number;
    scrollx: number;
    scrolly: number;
    scrollz: number;
  } = {
    dx: 0,
    dy: 0,
    scrollx: 0,
    scrolly: 0,
    scrollz: 0,
  };
  down = new EventEmitter();
  up = new EventEmitter();

  /*
   *
   *   PUBLIC API
   *
   */

  paused = false;
  disableSpecificKeys = (evt: KeyboardEvent) => {
    if (evt.key === "Tab" && !this.paused) {
      evt.preventDefault();
    }

    if (evt.key === "Escape" && evt.target === this.element) {
      this.element.focus();
    }
  };

  initEvents() {
    // keys
    window.addEventListener("keydown", this.disableSpecificKeys, {
      passive: false,
    });
    window.addEventListener("keydown", this.onKeyDownEvent, { passive: true });
    window.addEventListener("keyup", this.onKeyUpEvent, { passive: true });
    // mouse buttons
    this.element.addEventListener("mousedown", this.onMouseDown, {
      passive: false,
      capture: true,
    });
    this.element.addEventListener("mouseup", this.onMouseUp, {
      passive: false,
      capture: true,
    });
    this.element.oncontextmenu = this.onContextMenu;
    document.body.oncontextmenu = this.onContextMenu;

    // treat dragstart like mouseup - idiotically, mouseup doesn't fire after a drag starts (!)
    this.element.addEventListener("dragstart", this.onDragStart, {
      passive: false,
    });
    this.element.addEventListener("dragstop", this.onDragStop, {
      passive: false,
    });

    // scroll/mousewheel
    this.element.addEventListener("wheel", this.onMouseWheel, {
      passive: true,
    });
  }

  isTrackingMouseMove = false;

  startTrackingMouseMove = () => {
    if (this.isTrackingMouseMove) {
      return;
    }
    this.element.addEventListener("mousemove", this.onMouseMove, {
      passive: true,
    });
    this.element.addEventListener("touchmove", this.onMouseMove, {
      passive: true,
    });
    this.element.addEventListener("touchstart", onTouchStart, {
      passive: true,
    });

    this.isTrackingMouseMove = true;
  };

  stopTrackingMouseMove = () => {
    if (!this.isTrackingMouseMove) {
      return;
    }

    // touch/mouse movement
    this.element.removeEventListener("mousemove", this.onMouseMove, {});
    this.element.removeEventListener("touchmove", this.onMouseMove, {});
    this.element.removeEventListener("touchstart", onTouchStart, {});
    this.isTrackingMouseMove = false;
  };

  element: HTMLCanvasElement;

  // Usage:  bind( bindingName, vkeyCode, vkeyCode.. )
  //    Note that inputs._keybindmap maps vkey codes to binding names
  //    e.g. this._keybindmap['a'] = 'move-left'
  bind(name: string, key: string) {
    if (!isArray(this._keybindmap[key])) {
      this._keybindmap[key] = [];
    }

    const bindings = this._keybindmap[key];
    if (!bindings.includes(name)) {
      bindings.push(name);
    }

    this.state[name] = !!this.state[name];
    if (this._keyStates[key]) {
      this.state[name] = true;
    }
  }

  // search out and remove all keycodes bound to a given binding
  unbind(name: string) {
    delete this._bindPressCounts[name];
    delete this.state[name];

    for (let key in this._keybindmap) {
      const bindings = this._keybindmap[key];
      const index = bindings.indexOf(name);
      if (index > -1) {
        bindings.splice(index, 1);
      }

      if (bindings.length === 0) {
        delete this._keybindmap[key];
        delete this._keyStates[key];
      }
    }
  }

  // tick function - clears out cumulative mouse movement state variables
  tick() {
    this.state.dx = this.state.dy = 0;
    this.state.scrollx = this.state.scrolly = this.state.scrollz = 0;
  }

  getBoundKeys() {
    const arr = [];
    for (const b in this._keybindmap) {
      arr.push(b);
    }
    return arr;
  }

  onKeyDownEvent = (ev: KeyboardEvent) => {
    return this.handleKeyEvent(ev.keyCode, vkey[ev.keyCode], true, ev);
  };
  onKeyUpEvent = (ev: KeyboardEvent) =>
    this.handleKeyEvent(ev.keyCode, vkey[ev.keyCode], false, ev);

  onMouseUp = (ev: MouseEvent) => {
    // simulate a code out of range of vkey
    const keycode = -1 - ev.button;
    const vkeycode = `<mouse ${ev.button + 1}>`;
    this.handleKeyEvent(keycode, vkeycode, false, ev);

    return false;
  };

  onMouseDown = (ev: MouseEvent) => {
    // simulate a code out of range of vkey
    const keycode = -1 - ev.button;
    const vkeycode = `<mouse ${ev.button + 1}>`;
    this.handleKeyEvent(keycode, vkeycode, true, ev);
    return false;
  };

  onDragStart = (ev: MouseEvent) => {
    // simulate a code out of range of vkey
    const keycode = -1 - ev.button;
    const vkeycode = `<mouse ${ev.button + 1}>`;
    this.handleKeyEvent(keycode, vkeycode, false, ev);
    return false;
  };

  onDragStop = (ev: MouseEvent) => {
    // // simulate a code out of range of vkey
    // const keycode = -1 - ev.button;
    // const vkeycode = `<mouse ${ev.button + 1}>`;
    // this.handleKeyEvent(keycode, vkeycode, false, ev);
    // return false;
  };

  onContextMenu = ({ allowContextMenu }) => {
    return false;
  };

  onMouseMove = (ev: MouseEvent) => {
    // for now, just populate the state object with mouse movement
    let dx = ev.movementX || ev.mozMovementX || 0;

    let dy = ev.movementY || ev.mozMovementY || 0;
    // ad-hoc experimental touch support
    if (ev.touches && (dx | dy) === 0) {
      const xy = getTouchMovement(ev);
      dx = xy[0];
      dy = xy[1];
    }
    this.state.dx += dx;
    this.state.dy += dy;
  };

  onMouseWheel = ({ deltaMode, deltaX, deltaY, deltaZ }) => {
    const { state, element } = this;
    // basically borrowed from game-shell
    let scale = 1;
    switch (deltaMode) {
      case 0:
        scale = 1;
        break; // Pixel
      case 1:
        scale = 12;
        break; // Line
      case 2: // page
        // TODO: investigate when this happens, what correct handling is
        scale = element.clientHeight || window.innerHeight;
        break;
    }
    // accumulate state
    state.scrollx += deltaX * scale;
    state.scrolly += deltaY * scale;
    state.scrollz += deltaZ * scale || 0;
    return false;
  };

  /*
   *
   *
   *   KEY BIND HANDLING
   *
   *
   */

  handleKeyEvent = (
    keycode: number,
    vcode: string,
    wasDown: boolean,
    ev: KeyboardEvent
  ) => {
    const arr = this._keybindmap[vcode];
    if (!isArray(arr)) {
      return;
    }

    if (ev.altKey || ev.ctrlKey || ev.metaKey) {
      return;
    }

    if (this.preventDefaults) ev.preventDefault();
    if (this.stopPropagation) ev.stopPropagation();

    // if the key's state has changed, handle an event for all bindings
    const currstate = this._keyStates[keycode];
    if (XOR(currstate, wasDown)) {
      // for each binding: emit an event, and update cached state information
      for (let i = 0; i < arr.length; ++i) {
        this.handleBindingEvent(arr[i], wasDown, ev);
      }
    }

    this._keyStates[keycode] = wasDown;
  };

  handleBindingEvent = (binding, wasDown, ev) => {
    // keep count of presses mapped by binding
    // (to handle two keys with the same binding pressed at once)
    let ct = this._bindPressCounts[binding] || 0;
    ct += wasDown ? 1 : -1;
    if (ct < 0) {
      ct = 0;
    } // shouldn't happen
    this._bindPressCounts[binding] = ct;

    // emit event if binding's state has changed
    const currstate = this.state[binding];
    if (XOR(currstate, ct)) {
      const emitter = wasDown ? this.down : this.up;
      if (!this.disabled) emitter.emit(binding, ev);
    }
    this.state[binding] = !!ct;
  };
}

/*
 *
 *
 *    HELPERS
 *
 *
 */

// how is this not part of Javascript?
function XOR(a, b) {
  return a ? !b : b;
}

let _zeroArray: [number, number];

const zeroArray = () => {
  if (!_zeroArray) {
    _zeroArray = [0, 0];
  }

  return _zeroArray;
};
export default Inputs;

// experimental - for touch events, extract useful dx/dy
let lastTouchX = 0;
let lastTouchY = 0;
let lastTouchID = null;

function getTouchMovement({ changedTouches }) {
  let touch;
  const touches = changedTouches;
  for (let i = 0; i < touches.length; ++i) {
    if (touches[i].identifier === lastTouchID) touch = touches[i];
  }
  if (!touch) return zeroArray();
  const res = [touch.clientX - lastTouchX, touch.clientY - lastTouchY];
  lastTouchX = touch.clientX;
  lastTouchY = touch.clientY;
  return res;
}

function onTouchStart({ changedTouches }) {
  const touch = changedTouches[0];
  lastTouchX = touch.clientX;
  lastTouchY = touch.clientY;
  lastTouchID = touch.identifier;
}
