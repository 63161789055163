import {
  Action,
  ActionEvent,
  ActionManager,
  ExecuteCodeAction,
  InstancedMesh,
  Mesh,
  Scene,
} from "@babylonjs/core";
import { BlockID } from "shared/BlockID";

export enum EntityAction {
  changeURL,
}

export const blockActions = new Map([
  [BlockID.television, [EntityAction.changeURL]],
  [BlockID.banner, [EntityAction.changeURL]],
  [BlockID.picture, [EntityAction.changeURL]],
]);

export const getSupportedActionsForMesh = (mesh: Mesh | InstancedMesh) => {
  if (!mesh.metadata?.blockID) {
    return null;
  }

  return blockActions.get(mesh.metadata.blockID);
};

const tempPos = new Float32Array(3);
export const getPositionForMesh = (noa, mesh: Mesh | InstancedMesh) => {
  let parent = mesh;
  while (parent.parent) {
    parent = parent.parent;
  }

  const abs = parent.getAbsolutePosition();
  tempPos[0] = Math.floor(abs.x + noa.worldOriginOffset[0]);
  tempPos[1] = Math.floor(abs.y + noa.worldOriginOffset[1]);
  tempPos[2] = Math.floor(abs.z + noa.worldOriginOffset[2]);
  return tempPos;
};

export const hasSupportedActions = (blockID: BlockID) =>
  blockActions.has(blockID);

export class EntityActionManager {
  scene: Scene;
  actionManager: ActionManager;
  _isEnabled: boolean = true;
  noa;

  get isEnabled() {
    return this._isEnabled;
  }

  set isEnabled(newValue: boolean) {
    if (newValue !== this._isEnabled) {
      this._isEnabled = newValue;

      if (newValue) {
        this.registerActions();
      } else {
        this.unregisterActions();
      }
    }
  }

  onAction: (
    mesh: InstancedMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number,
    pointerX: number,
    pointerY: number
  ) => void;

  onPointerOver: (
    mesh: InstancedMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number,
    pointerX: number,
    pointerY: number
  ) => void;

  onPointerOut: (
    mesh: InstancedMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number,
    pointerX: number,
    pointerY: number
  ) => void;

  actions: Array<Action>;

  constructor(scene: Scene) {
    this.scene = scene;
    this.actionManager = new ActionManager(scene);
    this.actionManager.isRecursive = true;

    this.actions = [
      // new ExecuteCodeAction(
      //   {
      //     trigger: ActionManager.OnPointerOverTrigger,
      //   },
      //   this.handlePointerOver
      // ),
      // new ExecuteCodeAction(
      //   {
      //     trigger: ActionManager.OnPointerOutTrigger,
      //   },
      //   this.handlePointerOut
      // ),
      new ExecuteCodeAction(
        {
          trigger: ActionManager.OnPickTrigger,
        },
        this.handlePick
      ),
    ];

    this.registerActions();
  }

  registerActions = () => {
    this.actions.forEach(this.registerAction);
  };
  unregisterActions = () => {
    this.actions.forEach(this.unregisterAction);
  };
  registerAction = (action: Action) =>
    this.actionManager.registerAction(action);
  unregisterAction = (action: Action) =>
    this.actionManager.unregisterAction(action);

  handlePick = (event: ActionEvent) => {
    if (!this.isEnabled) {
      return;
    }

    const mesh = event.source;

    const actions = getSupportedActionsForMesh(mesh);

    if (!actions) {
      return;
    }

    const [x, y, z] = getPositionForMesh(this.noa, mesh);
    this.onAction(mesh, actions[0], x, y, z, event.pointerX, event.pointerY);
  };

  handlePointerOut = (event: ActionEvent) => {
    if (!this.isEnabled) {
      return;
    }

    const mesh = event.source;

    const actions = getSupportedActionsForMesh(mesh);

    if (!actions) {
      return;
    }

    const [x, y, z] = getPositionForMesh(this.noa, mesh);
    this.onPointerOut(
      mesh,
      actions[0],
      x,
      y,
      z,
      event.pointerX,
      event.pointerY
    );
  };

  handlePointerOver = (event: ActionEvent) => {
    if (!this.isEnabled) {
      return;
    }

    const mesh = event.source;

    const actions = getSupportedActionsForMesh(mesh);

    if (!actions) {
      return;
    }

    const [x, y, z] = getPositionForMesh(this.noa, mesh);
    this.onPointerOver(
      mesh,
      actions[0],
      x,
      y,
      z,
      event.pointerX,
      event.pointerY
    );
  };
}
