export enum BlockID {
  air = 0,
  water = 9,
  flowing_water = 8,
  acacia_door = 196,
  activator_rail = 157,
  anvil = 145,
  beacon = 138,
  bedrock = 7,
  birch_door = 194,
  black_glazed_terracotta = 250,
  black_shulker_box = 234,
  concrete = 251,
  concrete_powder = 252,
  ice = 76,
  stained_glass = 95,
  stained_glass_pane = 160,
  wool = 35,
  bone_block = 216,
  bookshelf = 47,
  brewing_stand = 117,
  brick_block = 45,
  cactus = 81,
  cake = 92,
  cauldron = 118,
  chorus_flower = 200,
  chorus_plant = 199,
  clay = 82,
  coal_block = 173,
  coal_ore = 16,
  cobblestone = 4,
  web = 30,
  command_block = 137,
  crafting_table = 58,
  cyan_glazed_terracotta = 244,
  cyan_shulker_box = 228,
  yellow_flower = 37,
  dark_oak_door = 197,
  daylight_detector_inverted = 178,
  daylight_detector = 151,
  deadbush = 32,
  detector_rail = 28,
  diamond_block = 57,
  diamond_ore = 56,
  dirt = 3,
  dragon_egg = 122,
  emerald_block = 133,
  emerald_ore = 129,
  enchanting_table = 116,
  end_portal_frame = 120,
  end_rod = 198,
  end_stone = 121,
  end_bricks = 206,
  farmland = 60,
  flower_pot = 140,
  furnace = 61,
  glass = 20,
  glass_pane = 102,
  glowstone = 89,
  gold_block = 41,
  gold_ore = 14,
  grass = 2,
  grass_path = 208,
  gravel = 13,
  hay_block = 170,
  hopper = 154,
  iron_bars = 101,
  iron_block = 42,
  iron_door = 71,
  iron_ore = 15,
  iron_trapdoor = 167,
  lit_pumpkin = 91,
  jukebox = 84,
  jungle_door = 195,
  ladder = 65,
  lapis_block = 22,
  lapis_ore = 21,
  lever = 69,
  light_gray_glazed_terracotta = 243,
  light_gray_shulker_box = 227,
  waterlily = 111,
  magma = 213,
  melon_block = 103,
  melon_stem = 105,
  mossy_cobblestone = 48,
  mycelium = 110,
  portal = 90,
  quartz_ore = 153,
  nether_wart_block = 214,
  netherrack = 87,
  noteblock = 25,
  wooden_door = 64,
  observer = 218,
  obsidian = 49,
  packed_ice = 174,
  piston = 33,
  red_flower = 38,
  golden_rail = 27,
  prismarine = 168,
  pumpkin = 86,
  pumpkin_stem = 104,
  purple_glazed_terracotta = 245,
  purple_shulker_box = 229,
  purpur_block = 201,
  purpur_pillar = 202,
  quartz_block = 155,
  rail = 66,
  sand = 12,
  sandstone = 24,
  repeating_command_block = 210,
  sea_lantern = 169,
  slime = 165,
  stone = 1,
  stone_slab = 44,
  snow = 80,
  soul_sand = 88,
  sponge = 19,
  spruce_door = 193,
  stonebrick = 98,
  structure_block = 255,
  reeds = 83,
  tnt = 46,
  torch = 50,
  tripwire_hook = 131,
  vine = 106,
  nether_brick_fence = 113,
  nether_bricks = 112,
  nether_brick_stairs = 114,
  double_plant = 175,
  planks = 5,
  stained_hardened_clay = 159,
  dark_oak_stairs = 159,
  quartz_stairs = 156,
  oak_stairs = 156,
  double_stone_slab = 43,
  birch_stairs = 135,
  oak_fence = 85,
  tripwire = 132,
  trapdoor = 132,
  stone_brick_stairs = 109,
  leaves = 18,
  tallgrass = 31,
  log = 17,
  birch_fence = 189,
  spruce_stairs = 134,
  cobblestone_wall = 139,
  white = 249,
  black = 253,
  lollipop = 259,
  mushroom = 260,
  tree = 262,
  chair = 263,
  door = 264,
  stool = 267,
  table = 268,
  vehicle = 269,
  bong = 401,
  corona = 402,
  star = 403,
  picture = 404,
  television = 408,
  imageBlock = 409,
  videoBlock = 409,
  rug = 410,
  spotLight = 506,
  roomLight = 507,
  banner = 405,
  pickaxe = 508,
}
