export class CachedMapTile {
  id: number;
  bitmap: ImageBitmap | ArrayBuffer;
}

import { BitField, BitFieldMixin } from "structurae";

export type ChunkIDField = {
  x: number;
  z: number;
  worldId: number;
};

export const MapTileField = BitFieldMixin({
  x: BitField.getMinSize(1024),
  z: BitField.getMinSize(1024),
  xSign: 1,
  zSign: 1,
  worldId: 16,
});

export const getTileIDNumber = (x: number, z: number, worldId: number) => {
  return MapTileField.encode([
    Math.abs(x),
    Math.abs(z),
    x < 0 ? 0 : 1,
    z < 0 ? 0 : 1,
    worldId,
  ]);
};

export const getTileID = (x: number, z: number, worldId: number) => {
  return `${worldId}/${x}/${z}`;
  // return getTileIDNumber(x, z, worldId).toString(16);
};

export const parseTileID = (id: number | string): ChunkIDField => {
  // if (typeof id === "string") {
  //   return parseTileID(parseInt(id, 16));
  // } else {
  //   const field = MapTileField.decode(id);
  //   return {
  //     x: field.x * (field.xSign - 1),
  //     z: field.z * (field.zSign - 1),
  //     worldId: field.worldId,
  //   };
  // }
};
