import * as React from "react";

export function RightClickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      style={{ marginTop: "auto", marginBottom: "auto" }}
      viewBox="0 0 155 227"
    >
      <path
        fill="currentColor"
        d="M154.437 72.108v82.782c0 39.762-32.346 72.108-72.108 72.108H72.111C32.349 226.998 0 194.652 0 154.89V72.108C0 32.349 32.349 0 72.111 0h10.218c39.762 0 72.108 32.346 72.108 72.108zM70.215 60.837v32.85c0 1.83 1.545 3.375 3.375 3.375h10.065c1.827 0 3.375-1.545 3.375-3.375v-32.85c0-1.83-1.548-3.375-3.375-3.375H73.59c-1.83 0-3.375 1.545-3.375 3.375zM15.012 71.928h40.203V60.84c0-9.294 6.942-16.977 15.909-18.189h6.096v-27.6h-6.096c-30.972.531-56.016 25.803-56.112 56.877zm124.425 82.962V86.928H102.03v6.762c0 10.131-8.244 18.375-18.375 18.375H73.59c-10.131 0-18.375-8.244-18.375-18.375v-6.762H15.003v67.962c0 31.488 25.62 57.108 57.111 57.108h10.218c31.485 0 57.105-25.62 57.105-57.108z"
      ></path>
    </svg>
  );
}

export default React.memo(RightClickIcon);
