import { Submesh } from "lib/meshing/Submesh";
import {
  ObjectLookupFunction,
  OpaqueLookupFunction,
  SolidLookupFunction,
} from "noa-engine/lib/packing";

// var VAR_MASK = constants.VAR_MASK // NYI
// var OBJECT_BIT = constants.OBJECT_BIT // not needed here
var maskCache = new Int16Array(128);
var aomaskCache = new Uint16Array(128);

/*
 *    Greedy voxel meshing algorithm
 *        based initially on algo by Mikola Lysenko:
 *          http://0fps.net/2012/07/07/meshing-minecraft-part-2/
 *          but evolved quite a bit since then
 *        AO handling by me, stitched together out of cobwebs and dreams
 *
 *    Arguments:
 *        arr: 3D ndarray of dimensions X,Y,Z
 *             packed with solidity/opacity booleans in higher bits
 *        getMaterial: function( blockID, dir )
 *             returns a material ID based on block id and which cube face it is
 *             (assume for now that each mat ID should get its own mesh)
 *        getColor: function( materialID )
 *             looks up a color (3-array) by material ID
 *             TODO: replace this with a lookup array?
 *        doAO: whether or not to bake ambient occlusion into vertex colors
 *        aoValues: array[3] of color multipliers for AO (least to most occluded)
 *        revAoVal: "reverse ao" - color multiplier for unoccluded exposed edges
 *
 *    Return object: array of mesh objects keyed by material ID
 *        arr[id] = {
 *          id:       material id for mesh
 *          vertices: ints, range 0 .. X/Y/Z
 *          indices:  ints
 *          normals:  ints,   -1 .. 1
 *          colors:   floats,  0 .. 1
 *          uvs:      floats,  0 .. X/Y/Z
 *        }
 */

var aoPackFunction;
var isOpaque: OpaqueLookupFunction = () => false;
var isSolid: SolidLookupFunction = () => false;
var isObject: ObjectLookupFunction = () => false;

export class GreedyMesher {
  setLookups(_isOpaque, _isSolid, _isObject) {
    isOpaque = _isOpaque;
    isSolid = _isSolid;
    isObject = _isObject;
  }

  mesh(
    voxels,
    getMaterial,
    getColor,
    doAO,
    aoValues,
    revAoVal,
    edgesOnly,
    getUVs
  ) {
    // hash of Submeshes, keyed by material ID
    const subMeshes = {};

    // precalc how to apply AO packing in first masking function
    var skipReverseAO = revAoVal === aoValues[0];

    if (doAO) {
      aoPackFunction = skipReverseAO ? packAOMaskNoReverse : packAOMask;
    } else {
      aoPackFunction = null;
    }

    //Sweep over each axis, mapping axes to [d,u,v]
    for (let d = 0; d < 3; ++d) {
      const u = (d + 1) % 3;
      const v = (d + 2) % 3;

      // make transposed ndarray so index i is the axis we're sweeping
      const shape = voxels.shape;
      const arrT = voxels
        .transpose(d, u, v)
        .lo(1, 1, 1)
        .hi(shape[d] - 2, shape[u] - 2, shape[v] - 2);

      // shorten len0 by 1 so faces at edges don't get drawn in both chunks
      const len0 = arrT.shape[0] - 1;
      const len1 = arrT.shape[1];
      const len2 = arrT.shape[2];

      // embiggen mask arrays as needed
      if (maskCache.length < len1 * len2) {
        maskCache = new Int16Array(len1 * len2);
        aomaskCache = new Uint16Array(len1 * len2);
      }

      // iterate along current major axis..
      for (let i = 0; i <= len0; ++i) {
        // fills mask and aomask arrays with values
        constructMeshMasks(i, d, arrT, getMaterial);
        // profile_hook("masks");

        // parses the masks to do greedy meshing
        constructMeshDataFromMasks(
          i,
          d,
          u,
          v,
          len1,
          len2,
          doAO,
          subMeshes,
          getColor,
          getUVs,
          aoValues,
          revAoVal
        );

        // process edges only by jumping to other edge
        if (edgesOnly) i += len0 - 1;

        // profile_hook("submeshes");
      }
    }

    // done, return hash of subMeshes as an array
    return Object.values(subMeshes);
  }
}

//      Greedy meshing inner loop one
//
// iterating across ith 2d plane, with n being index into masks
function constructMeshMasks(i, d, arrT, getMaterial) {
  const len = arrT.shape[1];
  const mask = maskCache;
  const aomask = aomaskCache;
  // set up for quick array traversals
  let n = 0;
  const materialDir = d * 2;
  const data = arrT.data;
  let dbase = arrT.index(i - 1, 0, 0);
  const istride = arrT.stride[0];
  const jstride = arrT.stride[1];
  const kstride = arrT.stride[2];

  for (let k = 0; k < len; ++k) {
    let d0 = dbase;
    dbase += kstride;
    for (let j = 0; j < len; j++, n++, d0 += jstride) {
      // mask[n] will represent the face needed between i-1,j,k and i,j,k
      // for now, assume we never have two faces in both directions
      // note that mesher zeroes out the mask as it goes, so there's
      // no need to zero it here when no face is needed
      // IDs at i-1,j,k  and  i,j,k
      const id0 = data[d0];
      const id1 = data[d0 + istride];

      // most common case: never a face between same voxel IDs,
      // so skip out early
      if (id0 === id1) continue;

      const faceDir = getFaceDir(id0, id1, getMaterial, materialDir);
      if (faceDir) {
        // set regular mask value to material ID, sign indicating direction
        mask[n] =
          faceDir > 0
            ? getMaterial(id0, materialDir)
            : -getMaterial(id1, materialDir + 1);

        // if doing AO, precalculate AO level for each face into second mask
        if (aoPackFunction) {
          // i values in direction face is/isn't pointing{
          aomask[n] =
            faceDir > 0
              ? aoPackFunction(arrT, i, i - 1, j, k)
              : aoPackFunction(arrT, i - 1, i, j, k);
        }
      }
    }
  }
}

function getFaceDir(id0, id1, getMaterial, materialDir) {
  // no face if both blocks are opaque
  const op0 = isOpaque(id0);
  const op1 = isOpaque(id1);
  if (op0 && op1) return 0;
  // if either block is opaque draw a face for it
  if (op0) return 1;
  if (op1) return -1;
  // can't tell from block IDs, so compare block materials of each face
  const m0 = getMaterial(id0, materialDir);
  const m1 = getMaterial(id1, materialDir + 1);
  // if same material, draw no face. If one is missing, draw the other
  if (m0 === m1) {
    return 0;
  } else if (m0 === 0) {
    return -1;
  } else if (m1 === 0) {
    return 1;
  }
  // remaining case is two different non-opaque block materials
  // facing each other. for now, draw neither..
  return 0;
}

//      Greedy meshing inner loop two
//
// construct data for mesh using the masks
function constructMeshDataFromMasks(
  i,
  d,
  u,
  v,
  len1,
  len2,
  doAO,
  submeshes: Array<Submesh>,
  getColor,
  getUVs,
  aoValues,
  revAoVal
) {
  let n = 0;
  const mask = maskCache;
  const aomask = aomaskCache;

  // some logic is broken into helper functions for AO and non-AO
  // this fixes deopts in Chrome (for reasons unknown)
  const maskCompareFcn = doAO ? maskCompare : maskCompare_noAO;
  const meshColorFcn = doAO ? pushMeshColors : pushMeshColors_noAO;

  for (let k = 0; k < len2; ++k) {
    let w = 1;
    let h = 1;
    for (let j = 0; j < len1; j += w, n += w) {
      const maskVal = mask[n] | 0;
      if (!maskVal) {
        w = 1;
        continue;
      }
      const ao = aomask[n] | 0;

      // Compute width and height of area with same mask/aomask values
      for (w = 1; w < len1 - j; ++w) {
        if (!maskCompareFcn(n + w, mask, maskVal, aomask, ao)) break;
      }

      OUTER: for (h = 1; h < len2 - k; ++h) {
        for (let m = 0; m < w; ++m) {
          const ix = n + m + h * len1;
          if (!maskCompareFcn(ix, mask, maskVal, aomask, ao)) break OUTER;
        }
      }

      // for testing: doing the following will disable greediness
      //w=h=1
      // material and mesh for this face
      const matID = Math.abs(maskVal);
      if (!submeshes[matID]) submeshes[matID] = new Submesh(matID);
      const mesh = submeshes[matID];
      const colors = mesh.colors;
      const c = getColor(matID);

      // colors are pushed in helper function - avoids deopts
      // tridir is boolean for which way to split the quad into triangles
      const triDir = meshColorFcn(colors, c, ao, aoValues, revAoVal);

      //Add quad, vertices = x -> x+du -> x+du+dv -> x+dv
      const x = [0, 0, 0];
      x[d] = i;
      x[u] = j;
      x[v] = k;
      const du = [0, 0, 0];
      const dv = [0, 0, 0];
      du[u] = w;
      dv[v] = h;

      const pos = mesh.positions;
      pos.push(
        x[0],
        x[1],
        x[2],
        x[0] + du[0],
        x[1] + du[1],
        x[2] + du[2],
        x[0] + du[0] + dv[0],
        x[1] + du[1] + dv[1],
        x[2] + du[2] + dv[2],
        x[0] + dv[0],
        x[1] + dv[1],
        x[2] + dv[2]
      );

      // add uv values, with the order and sign depending on
      // axis and direction so as to avoid mirror-image textures
      const dir = maskVal > 0 ? 1 : -1;

      const tile = getUVs(matID);

      mesh.tiles.push(tile, tile, tile, tile);

      if (d === 2) {
        mesh.uvs.push(0, h, -dir * w, h, -dir * w, 0, 0, 0);
      } else {
        mesh.uvs.push(0, w, 0, 0, dir * h, 0, dir * h, w);
      }

      // Add indexes, ordered clockwise for the facing direction;
      const vs = pos.length / 3 - 4;

      if (maskVal < 0) {
        if (triDir) {
          mesh.indices.push(vs, vs + 1, vs + 2, vs, vs + 2, vs + 3);
        } else {
          mesh.indices.push(vs + 1, vs + 2, vs + 3, vs, vs + 1, vs + 3);
        }
      } else {
        if (triDir) {
          mesh.indices.push(vs, vs + 2, vs + 1, vs, vs + 3, vs + 2);
        } else {
          mesh.indices.push(vs + 3, vs + 1, vs, vs + 3, vs + 2, vs + 1);
        }
      }

      // norms depend on which direction the mask was solid in..
      const norm0 = d === 0 ? dir : 0;
      const norm1 = d === 1 ? dir : 0;
      const norm2 = d === 2 ? dir : 0;

      // same norm for all vertices
      mesh.normals.push(
        norm0,
        norm1,
        norm2,
        norm0,
        norm1,
        norm2,
        norm0,
        norm1,
        norm2,
        norm0,
        norm1,
        norm2
      );

      //Zero-out mask
      for (let hx = 0; hx < h; ++hx) {
        for (let wx = 0; wx < w; ++wx) {
          mask[n + wx + hx * len1] = 0;
        }
      }
    }
  }
}

// Two helper functions with AO and non-AO implementations:
function maskCompare(index, mask, maskVal, aomask, aoVal) {
  if (maskVal !== mask[index]) return false;
  if (aoVal !== aomask[index]) return false;
  return true;
}

function maskCompare_noAO(index, mask, maskVal, aomask, aoVal) {
  if (maskVal !== mask[index]) return false;
  return true;
}

function pushMeshColors_noAO(colors, c, ao, aoValues, revAoVal) {
  colors.push(c[0], c[1], c[2], 1);
  colors.push(c[0], c[1], c[2], 1);
  colors.push(c[0], c[1], c[2], 1);
  colors.push(c[0], c[1], c[2], 1);
  return true; // triangle direction doesn't matter for non-AO
}

function pushMeshColors(colors, c, ao, aoValues, revAoVal) {
  const ao00 = unpackAOMask(ao, 0, 0);
  const ao10 = unpackAOMask(ao, 1, 0);
  const ao11 = unpackAOMask(ao, 1, 1);
  const ao01 = unpackAOMask(ao, 0, 1);

  const ao00Mult = !ao00 ? revAoVal : aoValues[ao00 - 1];
  const ao10Mult = !ao10 ? revAoVal : aoValues[ao10 - 1];
  const ao11Mult = !ao11 ? revAoVal : aoValues[ao11 - 1];
  const ao01Mult = !ao01 ? revAoVal : aoValues[ao01 - 1];

  colors.push(
    c[0] * ao00Mult,
    c[1] * ao00Mult,
    c[2] * ao00Mult,
    1,

    c[0] * ao10Mult,
    c[1] * ao10Mult,
    c[2] * ao10Mult,
    1,

    c[0] * ao11Mult,
    c[1] * ao11Mult,
    c[2] * ao11Mult,
    1,

    c[0] * ao01Mult,
    c[1] * ao01Mult,
    c[2] * ao01Mult,
    1
  );

  // pushAOColor(colors, c, ao00, aoValues, revAoVal, startLength);
  // pushAOColor(colors, c, ao10, aoValues, revAoVal, startLength + 4);
  // pushAOColor(colors, c, ao11, aoValues, revAoVal, startLength + 4 + 4);
  // pushAOColor(colors, c, ao01, aoValues, revAoVal, startLength + 4 + 4 + 4);

  // this bit is pretty magical..
  let triDir = true;
  if (ao00 === ao11) {
    triDir = ao01 === ao10 ? ao01 === 2 : true;
  } else {
    triDir = ao01 === ao10 ? false : ao00 + ao11 > ao01 + ao10;
  }
  return triDir;
}

/*
 *  packAOMask:
 *
 *    For a given face, find occlusion levels for each vertex, then
 *    pack 4 such (2-bit) values into one Uint8 value
 *
 *  Occlusion levels:
 *    1 is flat ground, 2 is partial occlusion, 3 is max (corners)
 *    0 is "reverse occlusion" - an unoccluded exposed edge
 *  Packing order var(bit offset):
 *      a01(2)  -   a11(6)   ^  K
 *        -     -            +> J
 *      a00(0)  -   a10(4)
 */
// when skipping reverse AO, uses this simpler version of the function:
function packAOMaskNoReverse(data, ipos, ineg, j, k) {
  var a00 = 1;
  var a01 = 1;
  var a10 = 1;
  var a11 = 1;

  // inc occlusion of vertex next to obstructed side
  if (isSolid(data.get(ipos, j + 1, k))) {
    ++a10;
    ++a11;
  }
  if (isSolid(data.get(ipos, j - 1, k))) {
    ++a00;
    ++a01;
  }
  if (isSolid(data.get(ipos, j, k + 1))) {
    ++a01;
    ++a11;
  }
  if (isSolid(data.get(ipos, j, k - 1))) {
    ++a00;
    ++a10;
  }

  // facing into a solid (non-opaque) block?
  var facingSolid = isSolid(data.get(ipos, j, k));

  // treat corners differently based when facing a solid block
  if (facingSolid) {
    // always 2, or 3 in corners
    a11 = a11 === 3 || isSolid(data.get(ipos, j + 1, k + 1)) ? 3 : 2;
    a01 = a01 === 3 || isSolid(data.get(ipos, j - 1, k + 1)) ? 3 : 2;
    a10 = a10 === 3 || isSolid(data.get(ipos, j + 1, k - 1)) ? 3 : 2;
    a00 = a00 === 3 || isSolid(data.get(ipos, j - 1, k - 1)) ? 3 : 2;
  } else {
    // treat corner as occlusion 3 only if not occluded already
    if (a11 === 1 && isSolid(data.get(ipos, j + 1, k + 1))) {
      a11 = 2;
    }
    if (a01 === 1 && isSolid(data.get(ipos, j - 1, k + 1))) {
      a01 = 2;
    }
    if (a10 === 1 && isSolid(data.get(ipos, j + 1, k - 1))) {
      a10 = 2;
    }
    if (a00 === 1 && isSolid(data.get(ipos, j - 1, k - 1))) {
      a00 = 2;
    }
  }

  return (a11 << 6) | (a10 << 4) | (a01 << 2) | a00;
}

// more complicated AO packing when doing reverse AO on corners
function packAOMask(data, ipos, ineg, j, k) {
  var a00 = 1;
  var a01 = 1;
  var a10 = 1;
  var a11 = 1;

  // inc occlusion of vertex next to obstructed side
  if (isSolid(data.get(ipos, j + 1, k))) {
    a10++;
    a11++;
  }
  if (isSolid(data.get(ipos, j - 1, k))) {
    a00++;
    a01++;
  }
  if (isSolid(data.get(ipos, j, k + 1))) {
    a01++;
    a11++;
  }
  if (isSolid(data.get(ipos, j, k - 1))) {
    a00++;
    a10++;
  }

  // facing into a solid (non-opaque) block?
  var facingSolid = isSolid(data.get(ipos, j, k));

  if (facingSolid) {
    // always 2, or 3 in corners
    a11 = a11 === 3 || isSolid(data.get(ipos, j + 1, k + 1)) ? 3 : 2;
    a01 = a01 === 3 || isSolid(data.get(ipos, j - 1, k + 1)) ? 3 : 2;
    a10 = a10 === 3 || isSolid(data.get(ipos, j + 1, k - 1)) ? 3 : 2;
    a00 = a00 === 3 || isSolid(data.get(ipos, j - 1, k - 1)) ? 3 : 2;
  } else {
    // check each corner, and if not present do reverse AO
    if (a11 === 1) {
      if (isSolid(data.get(ipos, j + 1, k + 1))) {
        a11 = 2;
      } else if (
        !isSolid(data.get(ineg, j, k + 1)) ||
        !isSolid(data.get(ineg, j + 1, k)) ||
        !isSolid(data.get(ineg, j + 1, k + 1))
      ) {
        a11 = 0;
      }
    }

    if (a10 === 1) {
      if (isSolid(data.get(ipos, j + 1, k - 1))) {
        a10 = 2;
      } else if (
        !isSolid(data.get(ineg, j, k - 1)) ||
        !isSolid(data.get(ineg, j + 1, k)) ||
        !isSolid(data.get(ineg, j + 1, k - 1))
      ) {
        a10 = 0;
      }
    }

    if (a01 === 1) {
      if (isSolid(data.get(ipos, j - 1, k + 1))) {
        a01 = 2;
      } else if (
        !isSolid(data.get(ineg, j, k + 1)) ||
        !isSolid(data.get(ineg, j - 1, k)) ||
        !isSolid(data.get(ineg, j - 1, k + 1))
      ) {
        a01 = 0;
      }
    }

    if (a00 === 1) {
      if (isSolid(data.get(ipos, j - 1, k - 1))) {
        a00 = 2;
      } else if (
        !isSolid(data.get(ineg, j, k - 1)) ||
        !isSolid(data.get(ineg, j - 1, k)) ||
        !isSolid(data.get(ineg, j - 1, k - 1))
      ) {
        a00 = 0;
      }
    }
  }

  return (a11 << 6) | (a10 << 4) | (a01 << 2) | a00;
}

// unpack (2 bit) ao value from ao mask
// see above for details
function unpackAOMask(aomask, jpos, kpos) {
  const offset = jpos ? (kpos ? 6 : 4) : kpos ? 2 : 0;
  return (aomask >> offset) & 3;
}
