import { items as _items } from "shared/items";

const normalizeId = (item: symbol | string) =>
  typeof item === "symbol" ? Symbol.keyFor(item) : item;

export class InventorySlots {
  secondSlot: string;
  thirdSlot: string;
  fourthSlot: string;
  fifthSlot: string;
  sixthSlot: string;
  seventhSlot: string;

  itemForSlot(slot: number) {
    return [
      -1,
      this.secondSlot,
      this.thirdSlot,
      this.fourthSlot,
      this.fifthSlot,
      this.sixthSlot,
    ][slot];
  }

  changeSlot(slot: number, item: string) {
    if (slot === 1) {
      this.secondSlot = normalizeId(item);
    } else if (slot === 2) {
      this.thirdSlot = normalizeId(item);
    } else if (slot === 3) {
      this.fourthSlot = normalizeId(item);
    } else if (slot === 4) {
      this.fifthSlot = normalizeId(item);
    } else if (slot === 5) {
      this.sixthSlot = normalizeId(item);
    } else if (slot === 6) {
      this.seventhSlot = normalizeId(item);
    }
  }

  update() {
    const items = [..._items.keys()];
    for (let slot = 1; slot < 8; slot++) {
      const item = this.itemForSlot(slot);
      const isItemMissing =
        !item ||
        !items.includes(typeof item === "symbol" ? item : Symbol.for(item));

      if (isItemMissing) {
        const id = items.length > 0 ? items.shift() : 0;
        this.changeSlot(slot, id);
      }
    }
  }
}
