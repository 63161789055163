import { invert } from "lodash";
import { BlockID } from "./BlockID";
import COSTS from "./costs.json";

export enum BlockBlend {
  slab = "slab",
  staircase = "staircase",
  sign = "sign",
  door = "door",
  glass = "glass",
  gate = "gate",
  torch = "torch",
  fence = "fence",
  plant = "plant",
}

export { BlockID };

export const isBlock = (id: BlockID) => {
  return id < 255;
};

export const isFoilage = (id: BlockID) => id > 400 && id < BlockID.pickaxe;
export const isSurface = (id: BlockID) => id > 255 && id < 400;

export const stateKey = (x: number, y: number, z: number) => {
  const chunkX = x >> 4;
  const chunkZ = z >> 4;
  const chunkY = y >> 4;

  return stateRelativeKey(
    (x - (chunkX << 4)) % 16,
    y % 256,
    (z - (chunkZ << 4)) % 16
  );
};

export const stateRelativeKey = (x: number, y: number, z: number) => {
  return `${x},${y},${z}`;
};

export enum URLSide {
  none = -1,
  // x0,y0,z0
  front = 0,
  // x1,y0,z0
  back = 1,
  // x0,y1,z0
  top = 2,
  // x0,y1,z1
  bottom = 3,
  // x0,y0,z1
  left = 4,
  // x1,y1,z1
  right = 5,
  // x1,y1,z0
}

export const getSide = (x: number, y: number, z: number) => {
  if (x === 1 && y === 0 && z === 0) {
    return URLSide.left;
  } else if (x === -1 && y === 0 && z === 0) {
    return URLSide.right;
  } else if (x === 0 && y === 0 && z === -1) {
    return URLSide.front;
  } else if (x === 0 && y === 0 && z === 1) {
    return URLSide.back;
  } else if (x === 0 && y === -1 && z === 0) {
    return URLSide.bottom;
  } else if (x === 0 && y === 1 && z === 0) {
    return URLSide.top;
  } else {
    return URLSide.none;
  }
};

const normalsSides = [
  // front
  [0, 0, -1],
  // back
  [0, 0, 1],
  // left
  [-1, 0, 0],
  // right
  [1, 0, 0],
  // top
  [0, -1, 0],
  // bottom
  [0, 1, 0],
];

export const normalsForSide = (side: URLSide) => {
  return normalsSides[side] ?? [0, 0, 0];
};

export const BLOCK_NAMES = invert(BlockID);

export const costs = new Map<BlockID, Array<number>>(COSTS.reverse());

export const getCost = (blockID: BlockID, variant: number) => {
  if (blockID === BlockID.air) {
    return 0;
  }

  const costList = costs.get(blockID);

  if (costList) {
    return costList[variant];
  } else {
    return 10000;
  }
};

export enum BlockIDType {
  none = -1,
  block = 0,
  surface = 1,
  foilage = 2,
}

export const blockIdType = (blockID: BlockID) => {
  if (isBlock(blockID)) {
    return BlockIDType.block;
  } else if (isSurface(blockID)) {
    return BlockIDType.surface;
  } else if (isFoilage(blockID)) {
    return BlockIDType.foilage;
  } else {
    return BlockIDType.none;
  }
};
