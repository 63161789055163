import {
  AbstractMesh,
  Color3,
  Mesh,
  MeshBuilder,
  MultiMaterial,
  PBRMaterial,
  PBRMetallicRoughnessMaterial,
  Scene,
  StandardMaterial,
  Texture,
  Vector3,
  Vector4,
} from "@babylonjs/core";
import {
  FoilageAlignment,
  FOILAGE_ALIGNMENT_ROTATION,
  FOILAGE_ROTATION,
  FOILAGE_SCALE,
  getAlignment,
} from "game/meshes/FoilageMesh";
import { BlockID } from "shared/BlockID";
import { ItemVariant } from "shared/items";

const urlTexturePool = new Map<symbol, Texture>();
const urlTexturePoolReferenceCount = new Map<symbol, number>();

export const sizes = {
  [BlockID.television]: [[0.96, 0.55, 0]],
  [BlockID.banner]: [[285.87 / 100, 246.12 / 100, 0.45]], //50.51 / 100]],
  [BlockID.picture]: [
    [0.97, 0.55, 0],
    [0.72, 0.85, 0],
    [0.85, 0.85, 0],
    [0.35, 0.35, 0],
  ],
};

export const offsetsByScale: { [key: number]: Array<Array<Vector3>> } = {
  [BlockID.television]: [
    [
      Vector3.FromArray([0.01, -0.1, -0.08]),
      Vector3.FromArray([0.01, 0.1, -0.08]),
      Vector3.FromArray([0.01, 0.72, -0.08]),
      // Vector3.FromArray([0.01, -0.1, -0.02]),
      // Vector3.FromArray([0.01, 0.1, 0.25]),
      // Vector3.FromArray([0.01, 0.62, -1]),
    ],
  ],
  [BlockID.banner]: [
    [
      Vector3.FromArray([0, 2.1, 0]),
      Vector3.FromArray([0, 0.5, 0]),
      Vector3.FromArray([0, 1, 0]),
    ],
  ],
  [BlockID.picture]: [
    [
      Vector3.FromArray([0, 0, 0]),
      Vector3.FromArray([0, 0.5, 0]),
      Vector3.FromArray([0, 1, 0]),
    ],
    [
      Vector3.FromArray([0, 0, 0]),
      Vector3.FromArray([0, 0, 0]),
      Vector3.FromArray([0, 1, 0]),
    ],
    [
      Vector3.FromArray([0, 0, 0]),
      Vector3.FromArray([0, 0.25, 0]),
      Vector3.FromArray([0, 1, 0]),
    ],
    [
      Vector3.FromArray([0, 0, 0]),
      Vector3.FromArray([0, 0.25, 0]),
      Vector3.FromArray([0, 1, 0]),
    ],
  ],
};

let planeMats = new Map<symbol, StandardMaterial>();

export const findBannerScreenMesh = (mesh: AbstractMesh) =>
  mesh.material?.name === "screen";

const decrementTextureCount = function (mesh: Mesh) {
  const url = mesh.material?.diffuseTexture?.url;

  if (!url) {
    return;
  }

  const key = Symbol.for(url);

  let count = urlTexturePoolReferenceCount.get(key);

  if (count <= 1) {
    urlTexturePoolReferenceCount.delete(key);
    urlTexturePool.get(key)?.dispose();
    urlTexturePool.delete(key);
    planeMats.delete(key);
    mesh.material?.dispose(true, true, true);
  } else {
    urlTexturePoolReferenceCount.set(key, count--);
  }
};

const planesList = new Map<string, Mesh>();

export const createScreenPlane = (
  mesh: Mesh,
  blockID: BlockID,
  item: ItemVariant
) => {
  const alignment = getAlignment(item);
  let rotation = 0;
  if (alignment !== FoilageAlignment.bottom) {
    rotation = FOILAGE_ALIGNMENT_ROTATION[alignment];
  } else if (FOILAGE_ROTATION[item.rotation] !== 0) {
    rotation = FOILAGE_ROTATION[item.rotation];
  }

  const scale = FOILAGE_SCALE[item.scale];
  let width = 1;
  let height = 1;
  let depth = 0;

  const size = sizes[blockID];
  if (size) {
    const variantSize = size[item.variant];
    if (variantSize && variantSize.length > 0) {
      width = variantSize[0];
      height = variantSize[1];
      depth = variantSize[2];
    }
  }

  let degrees = (180 / Math.PI) * rotation;
  const offset = offsetsByScale[blockID][item.variant][item.scale];
  const key = `${rotation}+${offset.x},${offset.y},${offset.z},${width},${height},${depth}`;
  let plane = planesList.get(key);
  if (plane) {
    const _plane = plane.clone("screen1");
    _plane.actionManager = mesh.actionManager;
    _plane.enablePointerMoveEvents = true;
    _plane.onDisposeObservable.add(decrementTextureCount);
    _plane.isScreen = true;
    return _plane;
  } else {
    let plane: Mesh;

    if (!depth) {
      plane = MeshBuilder.CreatePlane("screen", {
        width: width * scale,
        height: height * scale,
        sideOrientation: Mesh.DOUBLESIDE,
        backUVs: new Vector4(0, 0, 0, 0),
      });
    } else {
      const sideUV = Vector4.Zero();
      const frontUV = new Vector4(0, 0, 1, 1);
      const backUV = new Vector4(0, 1, 0, 1);
      plane = MeshBuilder.CreatePlane("screen", {
        width: width * scale,
        height: height * scale,
        sideOrientation: Mesh.DOUBLESIDE,
        backUVs: backUV,
        frontUVs: frontUV,
      });
    }

    plane.metadata = mesh.metadata;
    plane.name = "screen";
    plane.id = "screen";

    plane.isPickable = false;

    plane.actionManager = mesh.actionManager;
    plane.enablePointerMoveEvents = true;
    plane.onDisposeObservable.add(decrementTextureCount);

    // if (item.scale) {
    //   plane.scaling = new Vector3(
    //     FOILAGE_SCALE[item.scale],
    //     FOILAGE_SCALE[item.scale],
    //     FOILAGE_SCALE[item.scale]
    //   );
    // }
    plane.locallyTranslate(new Vector3(0, 0.5, 0));

    plane.rotatePOV(0, rotation || 0, 0);
    const isHalfRotation = degrees === -90 || degrees === 270;

    plane.scaling.set(-1, 1, -1);

    if (isHalfRotation) {
      plane.locallyTranslate(offset);
    } else {
      plane.locallyTranslate(offset);
    }

    plane.hasVertexAlpha = false;

    plane.isScreen = true;
    // if (scale > 1) {
    //   plane.locallyTranslate(new Vector3(0, 0, -0.07 * (scale - 1)));
    // }

    // if (scale > 1 && alignment === FoilageAlignment.bottom) {
    //   plane.locallyTranslate(new Vector3(0, 0.5 * (scale - 1), 0));
    // }

    plane.bakeCurrentTransformIntoVertices();

    planesList.set(key, plane);
    plane.scaling = Vector3.One();

    return plane;
  }
};

export const setURLForPlane = (plane: Mesh, url: string, scene: Scene) => {
  const urlKey = Symbol.for(url);

  if (planeMats.has(urlKey)) {
    plane.material = planeMats.get(urlKey);
  } else {
    const mat = new PBRMaterial(Symbol.for(`screenplane/${url}`), scene);
    mat.metallic = 0.2;
    mat.roughness = 0.5;
    // mat.disableLighting = true;
    // mat.emissiveColor = Color3.White();
    mat.albedoColor = Color3.White();
    mat.backFaceCulling = true;
    plane.material = mat;
    planeMats.set(urlKey, mat);
  }

  setURLTexture(plane.material, url, scene);
  // }
  urlTexturePoolReferenceCount.set(
    urlKey,
    (urlTexturePoolReferenceCount.get(urlKey) || 0) + 1
  );
};

let hardwareScale;

export const getURLTexture = (url: string, scene: Scene) => {
  const key = Symbol.for(url);
  if (urlTexturePool.has(key)) {
    return urlTexturePool.get(key);
  } else {
    let texture = new Texture(url, scene);
    texture.name = key;
    texture.hasAlpha = false;
    if (!hardwareScale) {
      hardwareScale = scene.getEngine().getHardwareScalingLevel();
    }
    texture.scale(hardwareScale);
    texture.wrapR = Texture.CLAMP_ADDRESSMODE;

    texture.wrapU = Texture.CLAMP_ADDRESSMODE;
    texture.wrapV = Texture.CLAMP_ADDRESSMODE;
    urlTexturePool.set(texture.name, texture);
    return texture;
  }
};

export const setURLTexture = (
  mat:
    | PBRMaterial
    | StandardMaterial
    | PBRMetallicRoughnessMaterial
    | MultiMaterial,
  url: string,
  scene: Scene
) => {
  let texture;
  if (mat instanceof PBRMetallicRoughnessMaterial) {
    texture = getURLTexture(url, scene);
    mat.baseTexture = texture;
    mat.baseColor = Color3.White();
    mat.backFaceCulling = true;
    mat.freeze();
  } else if (mat instanceof PBRMaterial) {
    texture = getURLTexture(url, scene);
    mat.albedoTexture = texture;
    mat.albedoColor = Color3.White();
    mat.backFaceCulling = true;
    mat.freeze();
    // mat.
  } else if (mat instanceof StandardMaterial) {
    texture = getURLTexture(url, scene);
    mat.diffuseTexture = texture;
    mat.useAlphaFromDiffuseTexture = true;
    mat.freeze();
  } else if (mat instanceof MultiMaterial) {
    for (let subMat of mat.subMaterials) {
      setURLTexture(subMat, url, scene);
    }
  }

  if (texture) {
    urlTexturePoolReferenceCount.set(
      texture.name,
      Math.max(
        (urlTexturePoolReferenceCount.get(texture.name) || -1) +
          (url && url.length > 0 ? 1 : -1),
        0
      )
    );
  }
};
