import classNames from "classnames";
import { Announcement } from "components/hud/Announcement";
import { ChatBox } from "components/hud/ChatBox";
import LeftClickIcon from "components/hud/icons/LeftClickIcon";
import RightClickIcon from "components/hud/icons/RightClickIcon";
import { MicrophoneIcon } from "components/hud/MicrophoneIcon";
import EventEmitter from "eventemitter3";
import { MenuState } from "game/MenuState";
import { NetworkConnectionStatus } from "game/NetworkConnectionStatus";
import { AvatarClassType } from "game/Player/AvatarClassType";
import {
  CONTROLS,
  EVENT_LABELS,
  InputControl,
  InputEvent,
  Keybinding,
  Keybindings,
} from "lib/InputController";
import * as React from "react";
import { Portal } from "react-portal";
import { SocketEvent, VoiceEvent } from "shared/events";
import { items } from "shared/items";
import { Header } from "./hud/Header";
import { InventoryList } from "./hud/InventoryList";
import { HUDEvent } from "./HUDEvent";
import { KeyboardHint } from "./KeyboardHint";
import dynamic from "next/dynamic";
import { BroadcastStatus } from "lib/BroadcastStatus";
import { LiveStreamEvent } from "lib/LiveStreamEvent";

const WorldMap = dynamic(() => import("components/WorldMap/WorldMap"), {
  loading: () => null,
  ssr: false,
});

const UploadSheet = dynamic(() => import("components/hud/UploadSheet"), {
  loading: () => null,
  ssr: false,
});

const Login = dynamic(() => import("components/Login"), {
  loading: () => null,
});

const InviteLinkModal = dynamic(
  () => import("components/hud/invite/InviteLinkModal"),
  {
    loading: () => null,
    ssr: false,
  }
);

const LiveStreamModal = dynamic(
  () => import("components/hud/livestream/LiveStreamModal"),
  {
    loading: () => null,
    ssr: false,
  }
);

let RobotAvatar = { defaultSkinStyle: "metallic" };

if (typeof window !== "undefined") {
  RobotAvatar = require("game/Avatar/RobotAvatar").RobotAvatar;
}

const ControlListItem = React.memo(({ event }: { event: InputEvent }) => {
  let bindingLabel = null;
  let BindingIcon = null;

  const binding = Keybindings[event];

  if (binding === Keybinding.escape) {
    bindingLabel = "ESC";
  } else if (binding === Keybinding.leftClick) {
    BindingIcon = LeftClickIcon;
  } else if (binding === Keybinding.rightClick) {
    BindingIcon = RightClickIcon;
  } else if (binding === Keybinding.shift) {
    bindingLabel = "SHIFT";
  } else if (binding === Keybinding.tab) {
    bindingLabel = "TAB";
  } else {
    bindingLabel = binding;
  }

  let EventIcon = null;
  let eventLabel = null;

  if (event === InputEvent.voice) {
    EventIcon = MicrophoneIcon;
  } else {
    eventLabel = EVENT_LABELS[event];
  }

  const _event = EventIcon ? (
    <EventIcon />
  ) : (
    <div className="label label--event">{eventLabel}</div>
  );

  return (
    <div className="Control">
      <div className={classNames("Key", { "Key--icon": !!BindingIcon })}>
        {bindingLabel && (
          <KeyboardHint height="auto" width="auto">
            {bindingLabel}
          </KeyboardHint>
        )}
        {BindingIcon && <BindingIcon />}
      </div>

      <div className="Event">{_event}</div>

      <style jsx>{`
        .Key {
          width: auto;
          min-width: 36px;
          align-self: center;
          height: 42px;
          display: flex;
          flex: 1;
          vertical-align: middle;
          text-transform: uppercase;
          display: flex;
        }

        .Control {
          display: grid;
          grid-template-columns: auto min-content;
          grid-template-rows: 42px;
          height: 42px;
          align-items: center;
          align-content: center;
          color: white;
          grid-column-gap: 8px;
          margin-right: 24px;
        }

        .Key--icon {
          display: flex;
          justify-content: flex-end;
        }

        .Event {
          font-weight: 500;
          letter-spacing: 0.5px;
          margin-top: auto;
          margin-bottom: auto;
          display: flex;
        }
      `}</style>
    </div>
  );
});

const ControlsList = ({ emitter }: { emitter: EventEmitter }) => {
  const [control, setControl] = React.useState(InputControl.default);
  const [showVoice, setShowVoice] = React.useState(true);

  React.useEffect(() => {
    emitter.on(HUDEvent.controlsChange, setControl);
    return () => emitter.off(HUDEvent.controlsChange, setControl);
  }, [emitter, setControl]);

  React.useEffect(() => {
    const hideVoiceHint = () => setShowVoice(false);
    const showVoiceHint = () => setShowVoice(false);
    const handleMuteChange = (isMuted) =>
      isMuted ? setShowVoice(true) : setShowVoice(false);

    emitter.on(VoiceEvent.startedPublishing, hideVoiceHint);
    emitter.on(VoiceEvent.stoppedPublishing, showVoiceHint);
    emitter.on(VoiceEvent.muteChange, handleMuteChange);
    return () => {
      emitter.off(VoiceEvent.startedPublishing, hideVoiceHint);
      emitter.off(VoiceEvent.stoppedPublishing, showVoiceHint);
      emitter.off(VoiceEvent.muteChange, handleMuteChange);
    };
  }, [emitter, setControl]);

  const events = CONTROLS[control];

  const renderEvent = React.useCallback(
    (event) => <ControlListItem key={event} event={event} />,
    []
  );

  return (
    <div className="ControlsList">
      <div className="List">{events.map(renderEvent)}</div>

      {showVoice && (
        <>
          <div className="Spacer" />{" "}
          <div className="List">
            <ControlListItem event={InputEvent.voice} />
          </div>
        </>
      )}

      <style jsx>{`
        .List {
          display: flex;
          height: 42px;
          align-content: center;
          align-items: center;
        }

        .Spacer {
          margin-top: 6px;
          margin-bottom: 6px;
          display: block;
        }
      `}</style>
    </div>
  );
};

export enum TabViewTab {
  chat,
  upload,
}
const TabView = ({ emitter }) => {
  const [activeTab, setActiveTab] = React.useState(TabViewTab.chat);

  return (
    <>
      <ChatBox
        isActive={activeTab === TabViewTab.chat}
        onRequestActive={setActiveTab}
        emitter={emitter}
      />
      <UploadSheet
        isActive={activeTab === TabViewTab.upload}
        onRequestActive={setActiveTab}
        socket={emitter}
      />
    </>
  );
};

const BottomLeftMenu = ({ children, emitter, onVoicePressChange }) => (
  <div className="Container">
    <div className="BottomLeft">
      <TabView emitter={emitter} />

      <ControlsList emitter={emitter} />
    </div>

    <style jsx>{`
      .Container {
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .BottomLeft {
        padding-bottom: 36px;
        padding-left: 38px;
      }
    `}</style>
  </div>
);

export class HUD extends React.Component {
  constructor(props) {
    super(props);
    this.startListening();
    this.state = {
      username: null,
      avatarURL: null,
      color: null,
      showLogin: false,
      backgroundImageURL: null,
      broadcastStatus: BroadcastStatus.none,
      skinStyle: null,
      itemId: null,
      connectionStatus: NetworkConnectionStatus.pending,
    };
  }

  handleEquipItem = (id: string) => {
    this.setState({ itemId: id });
  };

  startListening = () => {
    this.props.socket.on(HUDEvent.changePlayer, this.handleChangePlayer);
    this.props.socket.on(HUDEvent.equipItem, this.handleEquipItem);
    this.props.socket.on(
      LiveStreamEvent.broadcastChange,
      this.handleBroadcastChange
    );
    this.props.socket.on(SocketEvent.loginRequired, this.handleChangeShowLogin);
    this.props.socket.on(
      SocketEvent.connectionStatusChange,
      this.handleConnectionStatusChange
    );
  };

  handleBroadcastChange = (broadcastStatus: BroadcastStatus) =>
    this.setState({ broadcastStatus });
  handleChangeShowLogin = ([loginStep, loginUser = {}]) => {
    this.setState({ showLogin: true, loginStep, loginUser });
  };

  handleConnectionStatusChange = (
    connectionStatus: NetworkConnectionStatus
  ) => {
    this.setState({ connectionStatus });
  };

  handleChangePlayer = ({
    username,
    backgroundImageURL,
    avatarURL,
    color,
    avatarType,

    currency,
    skinStyle = RobotAvatar.defaultSkinStyle,
  }) => {
    this.setState({
      username,
      avatarURL: avatarURL || AvatarClassType[avatarType].thumbnailURL,
      color,
      // backgroundImageURL,
      currency,
      skinStyle,
    });
  };
  stopListening = () => {
    this.props.socket.off(HUDEvent.changePlayer, this.handleChangePlayer);
    this.props.socket.off(HUDEvent.equipItem, this.handleEquipItem);
  };

  componentWillUnmount() {
    this.stopListening();
  }

  handleComplete = () => {
    this.setState({ showLogin: false });
    this.props.onLogin && this.props.onLogin();
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.menuState !== this.props.menuState &&
      this.props.menuState === MenuState.worldMap
    ) {
      this.hasRenderedMapOnce = true;
    }
  }
  hasRenderedMapOnce = false;

  cursorX = 0;
  cursorY = 0;

  handleOpenImageUploadSheet = (x: number, y: number) => {};

  render() {
    const { menuState, onDismiss } = this.props;
    const {
      showLogin,
      itemId,
      loginStep,
      loginUser,
      broadcastStatus,
    } = this.state;

    if (showLogin) {
      return (
        <Portal>
          <Login
            onComplete={this.handleComplete}
            loginStep={loginStep}
            query={loginUser}
          />
        </Portal>
      );
    }

    const item = items.get(itemId);

    const isBuilding = !!item;

    return (
      <Portal>
        <div
          onClick={onDismiss}
          className={classNames("HUD", {
            "HUD--hidden": menuState === MenuState.hidden,
            "HUD--background": menuState === MenuState.worldMap,
            "HUD--building": isBuilding,
            "HUD--visible": menuState === MenuState.visible,
          })}
        >
          {this.state.username && (
            <Header
              socket={this.props.socket}
              username={this.state.username}
              avatarURL={this.state.avatarURL}
              currency={this.state.currency}
              color={this.state.color}
            />
          )}

          <Announcement emitter={this.props.socket} />

          <BottomLeftMenu emitter={this.props.socket}></BottomLeftMenu>

          <InventoryList
            menuState={menuState}
            socket={this.props.socket}
            color={this.state.color}
            currentPlayer={this.props.game?.currentPlayer}
          />

          {menuState === MenuState.invite && (
            <InviteLinkModal username={this.state.username} />
          )}

          {menuState === MenuState.liveStream && (
            <LiveStreamModal username={this.state.username} />
          )}

          {(menuState === MenuState.worldMap || this.hasRenderedMapOnce) && (
            <WorldMap
              socket={this.props.socket}
              game={this.props.game}
              isOpen={menuState === MenuState.worldMap}
              currentPlayer={this.props.game?.currentPlayer}
            />
          )}
        </div>

        <style jsx>{`
          .HUD {
            position: absolute;
            bottom: 0;
            top: 0;
            left: 0;
            right: 0;
            z-index: var(--hud-z-index);
            transition: opacity 0.1s linear;
            pointer-events: none;
          }

          .HUD--background {
            opacity: 0.25;
          }
        `}</style>
      </Portal>
    );
  }
}
