import { BlockID } from "../BlockID";
import {
  NengiIDType,
  PlaceBlockInterface,
  SetBlockProtocol,
} from "../nengiTypes";

export enum SetBlockErrorCode {
  none = 0,
  insufficientCapital = 1,
}

export class SetBlockMessage implements PlaceBlockInterface {
  constructor({
    x,
    y,
    z,
    blockType,
    url = "",
    urlSide = -1,
    variant = 0,
    error = 0,
  }) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.blockType = blockType;
    this.url = url;
    this.urlSide = urlSide;
    this.variant = variant;
    this.error = error;
  }

  x: number;
  y: number;
  z: number;
  error: SetBlockErrorCode;
  blockType: BlockID;
  variant: number;
  urlSide: number;
  url: string;

  static protocol = {
    ...SetBlockProtocol,
    player: {
      type: NengiIDType,
    },
  };
}
