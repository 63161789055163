import { CurrentPlayer } from "game/CurrentPlayer";
import Data from "lib/Data";
import { WorldMapStore, WorldMapInterface } from "lib/Data/WorldMapStore";
import { MapTile } from "lib/Data/WorldMap/MapTile";
import { Minimap } from "lib/Data/WorldMap/Minimap";
import {
  getPlayerArrowImage,
  isBusyDrawingPlayerArrow,
  MapPlayer,
  playerImageCanvas,
  RenderMapTileGroup,
} from "lib/Data/WorldMap/RenderMapTile";
import { WorldChunkLoader } from "lib/WorldChunkLoader";
import ndarray from "ndarray";
import { MINIMAP_HEIGHT, MINIMAP_SIZE, MINIMAP_WIDTH } from "./MINIMAP_WIDTH";
import { MinimapTileStore } from "lib/Data/MinimapTileStore";
import { Scalar } from "@babylonjs/core";
import { USE_REPLACE_SYNC, USE_CSS_OM } from "./USE_REPLACE_SYNC";
import { isSafari } from "lib/browser";

export class MinimapRender {
  canvas: HTMLCanvasElement;
  loader: WorldChunkLoader;
  imageData: ImageData;
  image: ndarray;
  viewport: HTMLDivElement;
  worldMap: WorldMapStore;
  zoomLevel = 2;
  hardwareScalingLevel: number;
  ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D;
  container: HTMLDivElement;
  playersCanvas: HTMLCanvasElement;
  currentPlayerImage: HTMLImageElement | HTMLCanvasElement;
  currentPlayerImageCtx?: ImageBitmapRenderingContext;
  minimap: Minimap;

  static instance: MinimapRender;

  playersCtx: CanvasRenderingContext2D;
  tileGroup: RenderMapTileGroup;
  constructor(
    canvas: OffscreenCanvas,
    worldChunkLoader: WorldChunkLoader,
    viewport: HTMLDivElement,
    container: HTMLDivElement,
    hardwareScalingLevel: number,
    worldMap: WorldMapInterface
  ) {
    this.canvas = canvas;
    this.worldMap = worldMap;
    this.container = container;
    this.loader = worldChunkLoader;
    this.viewport = viewport;
    // this.hardwareScalingLevel = isSafari() ? 1 : hardwareScalingLevel;
    this.hardwareScalingLevel = hardwareScalingLevel;

    this.width = MINIMAP_SIZE * this.hardwareScalingLevel;
    this.height = MINIMAP_SIZE * this.hardwareScalingLevel;

    canvas.width = this.width;
    canvas.height = this.height;

    // determine the 'backing store ratio' of the canvas context

    if (self.document !== undefined) {
      this.styleTag = document.createElement("style");
      this.styleTag.type = "text/css";
      document.body.appendChild(this.styleTag);

      if (!USE_REPLACE_SYNC) {
        for (let i = 0; i < document.styleSheets.length; i++) {
          const style = document.styleSheets.item(i);
          if (style.ownerNode === this.styleTag) {
            this.sheet = style;
            break;
          }
        }
      } else {
        CSS.registerProperty({
          name: "--minimap-rotation", // String, name of the custom property
          syntax: "<angle>", // String, how to parse this property. Defaults to *
          inherits: true, // Boolean, if true should inherit down the DOM tree
          initialValue: CSS.deg(0), // String, initial value of this property
        });

        CSS.registerProperty({
          name: "--minimap-x", // String, name of the custom property
          inherits: true, // Boolean, if true should inherit down the DOM tree
          initialValue: 0, // String, initial value of this property
        });

        CSS.registerProperty({
          name: "--minimap-y", // String, name of the custom property
          inherits: true, // Boolean, if true should inherit down the DOM tree
          initialValue: 0, // String, initial value of this property
        });

        this.sheet = new CSSStyleSheet();

        if (!document.adoptedStyleSheets) {
          document.adoptedStyleSheets = [this.sheet];
        }

        document.adoptedStyleSheets = [
          ...document.adoptedStyleSheets,
          this.sheet,
        ];
      }

      this.playersCanvas = container.querySelector("#players-minimap");
      this.playersCanvas.width = this.width;
      this.playersCanvas.height = this.height;

      this.currentPlayerImage = container.querySelector("#player-image");

      if (this.currentPlayerImage.nodeName.toLowerCase() === "canvas") {
        this.currentPlayerImageCtx = (this
          .currentPlayerImage as HTMLCanvasElement).getContext(
          "bitmaprenderer"
        );
      }

      this.playersCtx = this.playersCanvas.getContext("2d", {
        alpha: true,
        desynchronized: false,
      });
      this.playersCtx.imageSmoothingEnabled = true;
      this.ctx = canvas.getContext("2d", {
        alpha: false,
        desynchronized: false,
      });
      this.ctx.imageSmoothingEnabled = false;
      MapTile.db = Data.db;
    }

    this.minimap = new Minimap();

    this.minimap.playerCanvas = this.playersCanvas;
    this.minimap.playerCtx = this.playersCtx;
    this.minimap.backgorundCtx = this.ctx;
    this.minimap.backgroundCanvas = this.canvas;
    this.minimap.zoomLevel = this.zoomLevel;
    this.minimap.hardwareScalingLevel = this.hardwareScalingLevel;
    this.minimap.canDraw = true;
    this.minimap.worldMap = this.worldMap;
    this.minimap.width = this.width;
    this.minimap.height = this.height;
    this.minimap.start();
    this.tileGroup = this.minimap.tileGroup;
    this.tileGroup.isMinimap = true;
  }
  width: number;
  height: number;

  handleMinimapMessage = (event) => console.log(event.data);

  sheet: CSSStyleSheet;
  styleTag: HTMLStyleElement;

  hasDrawnCurrentPlayerImage = false;
  mapCenterX: number;
  mapCenterY: number;

  needsRender = false;

  clearPlayerArrow() {
    this.tileGroup.drawnPlayers.clear();
    this.minimap.forceMapDraw = true;
  }

  drawnChunks: ndarray;

  worker: Worker;
  hasSentFirstTickToWorker = false;
  hasPreloadedOnce = false;

  tick() {
    if (this.updateTickChunk()) {
      this.clearPlayerArrow();
      this.tileGroup.drawnPlayers.clear();
      this.minimap.forceMapDraw = true;
      if (!this.hasPreloadedOnce) {
        MinimapTileStore.store.preload(
          this.minimap.tickChunkX - this.squareLength,
          this.minimap.tickChunkZ - this.squareLength,
          this.minimap.tickChunkX + this.squareLength,
          this.minimap.tickChunkZ + this.squareLength
        );
        this.hasPreloadedOnce = true;
      }
    }
  }

  updateTickChunk() {
    const noa = this.loader.noa;
    const { _renderPosition: renderPosition } = noa.ents.getPositionData(
      noa.playerEntity
    );

    return this.minimap.updateTickChunkRnage(
      renderPosition.x + noa.worldOriginOffset[0],
      renderPosition.z + noa.worldOriginOffset[2]
    );
  }

  get squareLength() {
    return Math.ceil(6 / this.zoomLevel);
  }

  translateValue: CSSTransformValue | null = null;
  rotationValue: CSSTransformValue | null = null;

  render(currentPlayer: CurrentPlayer, players: Array<MapPlayer>) {
    const noa = this.loader.noa;
    const { _renderPosition: renderPosition } = noa.entities.getPositionData(
      noa.playerEntity
    );

    const minimap = this.minimap;

    minimap.globalX = renderPosition.x + noa.worldOriginOffset[0];
    minimap.globalZ = renderPosition.z + noa.worldOriginOffset[2];
    minimap.updateChunkRange();

    const objectScale = this.hardwareScalingLevel;

    this.minimap.players = players;
    this.minimap.render();

    let playerCenterY =
      this.tileGroup.centerCHunkYOffset + this.minimap.translateZ;
    let playerCenterX =
      this.tileGroup.centerChunkXOffset + this.minimap.translateX;

    if (
      !isBusyDrawingPlayerArrow &&
      !this.hasDrawnCurrentPlayerImage &&
      currentPlayer &&
      currentPlayer.remoteEntity &&
      currentPlayer.remoteEntity.color
    ) {
      const img = getPlayerArrowImage(
        currentPlayer.remoteEntity.color,
        true,
        objectScale,
        this.minimap.tileGroup.playerArrowCache
      );

      if (img instanceof Promise) {
        img.then((img) => {
          if (
            typeof ImageBitmap !== "undefined" &&
            img instanceof ImageBitmap
          ) {
            this.currentPlayerImage.width = img.width;
            this.currentPlayerImage.height = img.height;

            // This deletes width/height on Firefox...
            this.currentPlayerImageCtx.transferFromImageBitmap(img);
          } else {
            this.currentPlayerImage.width =
              img.width / this.hardwareScalingLevel;
            this.currentPlayerImage.height =
              img.height / this.hardwareScalingLevel;

            this.currentPlayerImage.src = img.src;
          }

          this.hasDrawnCurrentPlayerImage = true;
        });
      } else if (
        typeof ImageBitmap !== "undefined" &&
        img instanceof ImageBitmap
      ) {
        this.currentPlayerImage.width = img.width;
        this.currentPlayerImage.height = img.height;

        // This deletes width/height on Firefox...
        this.currentPlayerImageCtx.transferFromImageBitmap(img);
      } else if (img) {
        this.currentPlayerImage.src = img.src;
        this.currentPlayerImage.width = playerImageCanvas.width;
        this.currentPlayerImage.height = playerImageCanvas.height;
      }

      this.hasDrawnCurrentPlayerImage = !!img;
    }

    this.mapCenterX = playerCenterX;
    this.mapCenterY = playerCenterY;

    let yTranslation = (
      this.height -
      playerCenterY -
      (MINIMAP_SIZE - MINIMAP_HEIGHT)
    ).toFixed(0);
    let xTranslation = (
      this.width -
      playerCenterX -
      (MINIMAP_SIZE - MINIMAP_WIDTH)
    ).toFixed(0);

    if (
      noa.camera.heading !== this.lastRotation &&
      this.lastUpdatedRotation > 3
    ) {
      const diff = noa.camera.heading * (360 / (Math.PI * 2)) - this._rotation;
      const delta = (((diff % 360) + 540) % 360) - 180;
      this._rotation = delta + this._rotation;
      this.lastUpdatedRotation = 0;
    } else if (noa.camera.heading !== this.lastRotation) {
      this.lastUpdatedRotation++;
    }

    const transform = `:root { --minimap-rotation: ${
      this._rotation * -1
    }deg; --minimap-x: ${xTranslation}px; --minimap-y: ${yTranslation}px; }`;

    if (transform !== this.lastTransform) {
      if (USE_CSS_OM) {
        if (!this.translateValue) {
          const matrix = CSSStyleValue.parse(
            "transform",
            window.getComputedStyle(this.canvas)["transform"]
          )[0];

          this.translateValue = new CSSTransformValue([
            matrix,
            new CSSTranslate(CSS.px(xTranslation), CSS.px(yTranslation)),
          ]);
        }

        if (!this.rotationValue) {
          const matrix = CSSStyleValue.parse(
            "transform",
            window.getComputedStyle(this.currentPlayerImage)["transform"]
          )[0];

          this.rotationValue = new CSSTransformValue([
            matrix,
            new CSSRotate(CSS.deg(-this._rotation)),
          ]);
        }

        this.translateValue[1].x.value = xTranslation;
        this.translateValue[1].y.value = yTranslation;
        this.rotationValue[1].angle.value = -this._rotation;

        this.currentPlayerImage.attributeStyleMap.set(
          "transform",
          this.rotationValue
        );
        this.canvas.attributeStyleMap.set("transform", this.translateValue);
        this.playersCanvas.attributeStyleMap.set(
          "transform",
          this.translateValue
        );
      } else {
        if (this.sheet.cssRules.length > 0) {
          this.sheet.deleteRule(0);
        }

        this.sheet.insertRule(transform);
      }
    }

    // }
    this.lastTransform = transform;
    this.lastRotation = noa.camera.heading;

    this.needsRender = false;
  }

  lastUpdatedRotation = 10;
  lastRotation = 0;
  _rotation = 0;

  lastTransform = "";

  updateTransform() {
    const world = this.loader.noa.world;
    if (!this.position) {
      return;
    }
    const noa = this.loader.noa;
    // let minChunkY = chunkCoord(
    //   Math.floor(y) - 32
    // );
    // let minChunkZ = chunkCoord(this.minZ);

    // let maxChunkX = chunkCoord(this.maxX);
    // let maxChunkY = chunkCoord(
    //   Math.floor(y) + 32
    // );

    // let maxChunkZ = chunkCoord(this.maxZ);
  }
}
