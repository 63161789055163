import classNames from "classnames";
import * as React from "react";

export const KeyboardHint = ({
  children,
  isNumber = false,
  width = "small",
  height,
  big = false,
  color,
}) => (
  <div
    className={classNames("KeyboardHint", {
      "KeyboardHint--numeric": !!isNumber,
      "KeyboardHint--auto": width === "auto",
      "KeyboardHint--autoHeight": height === "auto",
      "KeyboardHint--small": width !== "auto",
      "KeyboardHint--big": big,
      "KeyboardHint--red": color === "red",
    })}
  >
    {children}
    <style jsx>{`
      .KeyboardHint {
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        color: white;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid #ccc;
        font-weight: 500;
        font-size: 18px;
        margin-top: auto;
        margin-bottom: auto;
      }

      .KeyboardHint--red {
        background-color: rgba(160, 57, 54, 0.4);
        border-color: rgb(214, 77, 72);
      }

      .KeyboardHint--auto {
        width: auto;
        height: 28px;
        flex: 1;
        padding-left: 2px;
        padding-right: 2px;
      }

      .KeyboardHint--autoHeight {
        height: auto;
      }

      .KeyboardHint--small {
        width: 24px;
      }

      .KeyboardHint--numeric {
        font-variant-numeric: tabular-nums;
      }

      .KeyboardHint--big {
        height: 48px;
        width: 48px;
        font-size: 28px;
      }
    `}</style>
  </div>
);
