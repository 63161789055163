import ndarray from "ndarray";

function absFloor(n) {
  return n >= 0 ? Math.floor(n) : Math.ceil(n);
}
export function unindex(ndarr: ndarray, n: number, result: Array<number>) {
  if (!result) {
    result = new Array(ndarr.shape.length);
  }

  n = n - ndarr.offset;
  for (let i = 0; i < ndarr.stride.length; i++) {
    const stride = ndarr.stride[i];
    if (stride) {
      if (i === result.length - 1 && !Number.isInteger(n / stride)) {
        /*
                 * TODO: is `NaN` what we want?
                 *       `index` seems to support fractions: if we returned `n/stride`, then `index(unindex(x)) === x || `, for this case.
                result[i] = n / stride;
                 */
        result[i] = NaN;
        return;
      }

      result[i] = absFloor(n / stride);
      n %= stride;
    } else {
      /*
             * TODO: is `undefined` what we want?
             *       if we want `unindex(index(x)) === x` we should return a number.
            result[i] = 0;
             */
    }
  }

  return result;
}

ndarray.prototype.unindex = function (index: number, ref: Array<number>) {
  return unindex(this, index, ref);
};
