import { Portal } from "react-portal";
import { Button } from "components/onboarding/Button";
import { GameContext, ModalType } from "components/GameContext";
import * as React from "react";
import copyToClipbord from "copy-to-clipboard";
import { MenuState } from "game/MenuState";

let INVITE_DOMAIN;
if (process.env.NODE_ENV === "development") {
  INVITE_DOMAIN = "localhost:3000";
} else {
  INVITE_DOMAIN = "present.land";
}

export const inviteBaseLink = (username: string) =>
  `${INVITE_DOMAIN}/i/${username}`;

export const inviteLink = (username: string) =>
  location.protocol + "//" + inviteBaseLink(username);

export const InviteModalContent = React.memo(({ onClick, username, color }) => {
  const suppress = React.useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  return (
    <div onClick={suppress} className="InviteModalContainer">
      <div className="InviteModal">
        <h2 className="Modal-title">Earn $25,000</h2>
        <p className="Modal-body">
          Share your invite with a friend. When they claim it, you both get a
          cash bonus.
        </p>
        <form>
          <label className="Modal-Label">INVITE LINK</label>
          <input type="url" value={inviteLink(username)} readOnly />

          <div className="ButtonContainer">
            <Button onClick={onClick}>Copy link</Button>
          </div>
        </form>
      </div>
      <style jsx>{`
        .InviteModalContainer {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          z-index: 1001;
        }

        input {
          background-color: #222;
          border-radius: 2px;
          color: white;
          appearance: none;
          -webkit-appearance: none;
          font-size: 1em;
          padding: 8px 12px;
          border: 1px solid #666;
          box-shadow: none;
        }

        .InviteModalContainer * {
          user-select: auto !important;
          -webkit-user-select: auto !important;
          pointer-events: auto;
        }

        form {
          padding-left: 18px;
          padding-right: 18px;
        }

        .Modal-title {
          margin: 0;
          padding: 16px 18px;
          padding-bottom: 12px;
        }

        .Modal-body {
          padding: 0 18px;
          margin: 0;
          margin-bottom: 12px;
          max-width: 320px;
        }

        .Username {
          font-weight: 600;
        }

        .Emphasis {
          font-variant-numeric: tabular-nums;
          font-weight: 600;
          text-transform: uppercase;
          color: ;
        }

        .InviteModal {
          border-radius: 4px;
          filter: drop-shadow(1px 1px 1px #666);
          backdrop-filter: blur(10px);
          border: 1px solid #ccc;
          background-color: rgba(0, 0, 0, 0.5);
          color: white;
          animation: scale-in 0.2s ease-in;
          animation-fill-mode: forwards;
        }

        @keyframes scale-in {
          from {
            transform: scale(0.92);
            opacity: 0.75;
          }

          to {
            transform: scale(1);
            opacity: 1;
          }
        }

        .ButtonContainer {
          padding: 16px 0;
        }
      `}</style>
    </div>
  );
});

export const InviteLinkModal = ({ username, onDismiss }) => {
  const { game } = React.useContext(GameContext);
  const handlePress = React.useCallback(() => {
    copyToClipbord(inviteLink(username));
    game.onChangeMenuState(MenuState.visible);
    game.sendAnnouncement("Copied to clipboard");
  }, [game, onDismiss, copyToClipbord, inviteLink, username]);

  return (
    <Portal>
      <InviteModalContent onClick={handlePress} username={username} />
    </Portal>
  );
};

export default InviteLinkModal;
