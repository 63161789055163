import NextHead from "next/head";
import * as React from "react";
import { DescriptionSEOTag, TitleSEOTag, URLSEOTag } from "./SEOTag";

const GlobalTags = () => [
  <link
    key="global-tag-1"
    rel="apple-touch-icon"
    sizes="180x180"
    href="/apple-touch-icon.png"
  />,
  <link
    key="global-tag-2"
    rel="icon"
    type="image/png"
    sizes="32x32"
    href="/favicon-32x32.png"
  />,
  <link
    key="global-tag-3"
    rel="icon"
    type="image/png"
    sizes="16x16"
    href="/favicon-16x16.png"
  />,
  <link
    key="global-tag-4"
    rel="manifest"
    href={
      process.env.NODE_ENV === "development"
        ? "/site.dev.webmanifest"
        : "/site.webmanifest"
    }
  />,
  <link
    key="global-tag-5"
    rel="mask-icon"
    href="/safari-pinned-tab.svg"
    color="#153065"
  />,
  <meta key="global-tag-6" name="msapplication-TileColor" content="#da532c" />,
  <meta key="global-tag-7" name="theme-color" content="#153065" />,
];

export const Head = React.memo(
  ({
    prefetch = [],
    title = null,
    description = null,
    url = null,
    noIndex = false,
  }: {
    prefetch: Array<string>;
    title: string | null;
    description: string | null;
    url: string | null;
    noIndex: boolean;
  }) => {
    const prefetches = prefetch.map((link) => (
      <link rel="prefetch" href={url} key={`prefetch-${url}`} />
    ));

    const tags = [
      ...GlobalTags(),
      ...prefetches,
      ...TitleSEOTag({ title }),
      ...DescriptionSEOTag({ description }),
      ...URLSEOTag({ url }),
    ];

    if (noIndex) {
      tags.push(
        <meta name="googlebot" content="noindex" key="googlebot-noindex" />
      );
    }

    return <NextHead>{tags}</NextHead>;
  }
);

export default Head;
