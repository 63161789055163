import type { Mesh, OctreeBlock, TransformNode } from "@babylonjs/core";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";
import { LightChunk } from "lib/meshing/LightChunk";
import ndarray from "ndarray";
import Engine from "noa-engine";
import {
  affectsTerrain,
  ChunkyInterface,
  createVoxelArray,
  scanVoxelData,
} from "noa-engine/lib/packing";
import { removeUnorderedListItem } from "noa-engine/lib/util";
import { constants } from "./constants";
import objectMesher from "./objectMesher";
// shared references to terrain/object meshers
import terrainMesher, { TerrainMesher } from "./terrainMesher";

export let airChunk: ndarray = createVoxelArray(CHUNK_SIZE);

// Registry lookup references shared by all chunks
var solidLookup;
var opaqueLookup;
var objectMeshLookup;
var blockHandlerLookup;

/*
 *
 *   Chunk
 *
 *  Stores and manages voxel ids and flags for each voxel within chunk
 *  See constants.js for internal data representation
 *
 */

// data representation
const ID_MASK = constants.ID_MASK;
// var VAR_MASK = constants.VAR_MASK // NYI
const SOLID_BIT = constants.SOLID_BIT;
const OPAQUE_BIT = constants.OPAQUE_BIT;
const OBJECT_BIT = constants.OBJECT_BIT;

/*
 *
 *    Chunk constructor
 *
 */

let hasSetBlockLookups = false;

export class Chunk implements ChunkyInterface {
  noa: Engine;

  static defaultNeighborData = ndarray(new Array(27).fill(null), [3, 3, 3]).lo(
    1,
    1,
    1
  );

  constructor(noa, id, i, j, k, size, dataArray) {
    this.id = id; // id used by noa
    this.requestID = ""; // id sent to game client

    this.noa = noa;
    this.isDisposed = false;
    this.octreeBlock = null;

    // voxel data and properties
    this.voxels = dataArray || airChunk;

    this.i = i;
    this.j = j;
    this.k = k;
    this.size = size;
    this.x = i * size;
    this.y = j * size;
    this.z = k * size;

    // flags to track if things need re-meshing
    this._terrainDirty = false;
    this._objectsDirty = false;

    if (!hasSetBlockLookups) {
      // init references shared among all chunks
      setBlockLookups(noa);
    }

    // makes data for terrain / object meshing
    this._terrainMesh = null;
    this._objectBlocks = null;
    this._objectSystems = null;
    objectMesher.initChunk(this);

    this.isFull = false;
    this.isEmpty = !dataArray || dataArray === airChunk;

    // references to neighboring chunks, if they exist (filled in by `world`)

    this._neighbors = ndarray(
      Chunk.defaultNeighborData.data.slice(0),
      Chunk.defaultNeighborData.shape,
      Chunk.defaultNeighborData.stride,
      Chunk.defaultNeighborData.offset
    );
    this._neighbors.set(0, 0, 0, this);
    this._neighborCount = 0;
    this._maxMeshedNeighbors = 0;
    this._timesMeshed = 0;
    if (this.voxels !== airChunk && this.voxels) {
      // converts raw voxelID data into packed ID+solidity etc.
      scanVoxelData(
        this,
        solidLookup,
        opaqueLookup,
        objectMeshLookup,
        addObjectBlock
      );

      this.updateHasBlockHandlers();
    }

    this.pos = new Float32Array(3);
    this.pos[0] = this.x;
    this.pos[1] = this.y;
    this.pos[2] = this.z;
  }
  _terrainDirty: boolean;
  _objectsDirty: boolean;
  _terrainMesh: Mesh;
  isDisposed: boolean;
  id: string | symbol;
  requestID: string;
  _maxMeshedNeighbors: number;
  _neighborCount: number;
  _neighbors: ndarray;
  _timesMeshed: number;
  voxels: ndarray;
  i: number;
  j: number;
  k: number;
  size: number;
  x: number;
  y: number;
  z: number;
  isFull: boolean;
  isEmpty: boolean;
  hasBlockHandlers = false;
  lastMesh: Mesh | null;

  _updateVoxelArray(dataArray, isEmpty, isPrePacked = false) {
    this.isEmpty = isEmpty;
    // dispose current object blocks
    callAllBlockHandlers(this, "onUnload");
    objectMesher.disposeChunk(this);
    this.voxels = dataArray || airChunk;
    this._terrainDirty = false;
    this._objectsDirty = false;
    objectMesher.initChunk(this);

    if (this.voxels !== airChunk && !isPrePacked) {
      scanVoxelData(
        this,
        solidLookup,
        opaqueLookup,
        objectMeshLookup,
        addObjectBlock
      );
    }

    this.updateHasBlockHandlers();
  }

  updateHasBlockHandlers() {
    if (this.isEmpty || !this.voxels.data || this.voxels.data === airChunk) {
      this.hasBlockHandlers = false;
    } else {
      this.hasBlockHandlers = false;
      for (let i = 0; i < this.voxels.data.length; i++) {
        if (blockHandlerLookup(this.voxels.data[i])) {
          this.hasBlockHandlers = true;
          break;
        }
      }
    }
  }

  _updateMeshedVoxelArray(chunk: LightChunk) {
    // dispose current object blocks
    callAllBlockHandlers(this, "onUnload");
    objectMesher.disposeChunk(this);
    this.voxels = chunk.voxels;
    this._terrainDirty = chunk._terrainDirty;
    this._objectsDirty = chunk._objectsDirty;
    this.updateHasBlockHandlers();
    // objectMesher.initChunk(this);
    // packVoxelData(
    //   this,
    //   solidLookup,
    //   opaqueLookup,
    //   objectMeshLookup,
    //   addObjectBlock
    // );
  }

  /*
   *
   *    Chunk API
   *
   */

  // get/set deal with block IDs, so that this class acts like an ndarray

  get(x, y, z) {
    if (this.isEmpty || !this.voxels.data) {
      return 0;
    }

    return this.voxels.get(x, y, z);
  }

  getSolidityAt(x, y, z) {
    if (this.isEmpty || !this.voxels.data) {
      return false;
    }

    return this.voxels.get(x, y, z) ? true : false;
  }

  set(x, y, z, id) {
    if (this.isEmpty || !this.voxels.data) {
      this.voxels = createVoxelArray(this.noa.world.chunkSize);
    }

    const index = this.voxels.index(x, y, z);
    const oldId = this.voxels.data[index];
    if (id === oldId) return;

    // manage data
    const newID = id;
    this.voxels.data[index] = newID;

    // voxel lifecycle handling
    if (objectMeshLookup(oldId)) removeObjectBlock(this, x, y, z);
    if (objectMeshLookup(newID)) addObjectBlock(this, id, x, y, z);
    callBlockHandler(this, oldId, "onUnset", x, y, z);
    callBlockHandler(this, id, "onSet", x, y, z);

    // track full/emptiness and info about terrain
    if (!opaqueLookup(newID)) this.isFull = false;
    if (newID !== 0) {
      this.isEmpty = false;
    }
    if (
      affectsTerrain(newID, solidLookup, objectMeshLookup) ||
      affectsTerrain(oldId, solidLookup, objectMeshLookup)
    ) {
      this._terrainDirty = true;
    }

    if (this._terrainDirty || this._objectsDirty) {
      this.noa.world._queueChunkForRemesh(this);
    }

    // neighbors only affected if solidity or opacity changed on an edge
    const prevSO = opaqueLookup(oldId) || solidLookup(oldId);
    const newSO = opaqueLookup(newID) || solidLookup(newID);
    if (newSO !== prevSO) {
      const edge = this.size - 1;
      const iedge = x === 0 ? -1 : x < edge ? 0 : 1;
      const jedge = y === 0 ? -1 : y < edge ? 0 : 1;
      const kedge = z === 0 ? -1 : z < edge ? 0 : 1;
      if (iedge | jedge | kedge) {
        const is = iedge ? [0, iedge] : [0];
        const js = jedge ? [0, jedge] : [0];
        const ks = kedge ? [0, kedge] : [0];

        for (const i of is) {
          for (const j of js) {
            for (const k of ks) {
              if ((i | j | k) === 0) continue;
              const nab = this._neighbors.get(i, j, k);
              if (!nab) return;
              nab._terrainDirty = true;
              this.noa.world._queueChunkForRemesh(nab);
            }
          }
        }
      }
    }
  }

  // Convert chunk's voxel terrain into a babylon.js mesh
  // Used internally, but needs to be public so mesh-building hacks can call it
  mesh(matGetter, colGetter, useAO, aoVals, revAoVal) {
    if (!this.voxels || this.isEmpty) {
      return null;
    }

    if (!this.voxels.data || !this.voxels.data.length) {
      this.voxels = createVoxelArray(this.noa.world.chunkSize);

      scanVoxelData(
        this,
        solidLookup,
        opaqueLookup,
        objectMeshLookup,
        addObjectBlock
      );
    }

    return terrainMesher.meshChunk(
      this,
      matGetter,
      colGetter,
      useAO,
      aoVals,
      revAoVal
    );
  }

  // Convert chunk's voxel terrain into a babylon.js mesh
  // Used internally, but needs to be public so mesh-building hacks can call it
  meshWithData(
    meshData,
    submesh,
    matGetter,
    colGetter,
    useAO,
    aoVals,
    revAoVal
  ) {
    return terrainMesher.meshChunkWithData(
      this,
      submesh,
      meshData,
      matGetter,
      colGetter,
      useAO,
      aoVals,
      revAoVal
    );
  }

  pos: Float32Array;

  _removeMesh(mesh) {
    removeUnorderedListItem(this.octreeBlock.entries, mesh);
  }

  attachedMeshes: Array<Mesh | TransformNode>;

  // gets called by World when this chunk has been queued for remeshing
  updateMeshesWithResults(_mesh) {
    const rendering = this.noa.rendering;

    if (this._terrainDirty) {
      if (this._terrainMesh) this._terrainMesh.dispose();
      let mesh;
      mesh = _mesh
        ? TerrainMesher.processMeshData(
            this.noa,
            this.id,
            _mesh,
            this.noa.rendering.getScene()
          )
        : null;
      if (mesh && mesh.getTotalIndices() > 0) {
        rendering.prepareChunkForRendering(this, mesh, this.pos);
      }

      this._terrainMesh = mesh || null;
      this._terrainDirty = false;
      this._timesMeshed++;
      this._maxMeshedNeighbors = Math.max(
        this._maxMeshedNeighbors,
        this._neighborCount
      );
    }
    if (this._objectsDirty) {
      objectMesher.removeObjectMeshes(this);
      const meshes = objectMesher.buildObjectMeshes(this);

      if (meshes.length > 0) {
        rendering.prepareChunkForRendering(this, meshes, this.pos);
      }

      // meshes.forEach(this.addObjectMeshToScene);

      this._objectsDirty = false;
    }

    if (this._terrainMesh) {
      this.noa.world.emit("chunkAdded", this);
    }
  }

  // gets called by World when this chunk has been queued for remeshing
  updateMeshes() {
    const rendering = this.noa.rendering;

    if (this._terrainDirty) {
      if (this._terrainMesh) this._terrainMesh.dispose();

      const mesh = this.isEmpty ? null : this.mesh();
      if (mesh) {
        rendering.prepareChunkForRendering(this, mesh, this.pos);
      }

      this._terrainMesh = mesh || null;
      this._terrainDirty = false;
      this._timesMeshed++;
      this._maxMeshedNeighbors = Math.max(
        this._maxMeshedNeighbors,
        this._neighborCount
      );
    }
    if (this._objectsDirty) {
      objectMesher.removeObjectMeshes(this);
      const meshes = objectMesher.buildObjectMeshes(this);

      if (meshes.length > 0) {
        rendering.prepareChunkForRendering(this, meshes, this.pos);
      }

      // meshes.forEach(this.addObjectMeshToScene);

      this._objectsDirty = false;
    }

    if (this._terrainMesh) {
      this.noa.world.emit("chunkAdded", this);
    }
  }

  octreeBlock: OctreeBlock<Mesh | TransformNode>;
  private addObjectMeshToScene(mesh) {
    this.noa.rendering.addMeshToScene(mesh, true, this.pos, this);
  }

  // expose logic internally to create and update the voxel data array

  dispose() {
    // look through the data for onUnload handlers
    callAllBlockHandlers(this, "onUnload");

    // let meshers dispose their stuff
    objectMesher.disposeChunk(this);
    if (this._terrainMesh) this._terrainMesh.dispose();

    if (this.voxels !== airChunk) {
      // apparently there's no way to dispose typed arrays, so just null everything
      this.voxels.data = null;
    }

    this.voxels = null;
    this._neighbors.data = null;
    this._neighbors = null;

    this.isDisposed = true;
  }
}

function setBlockLookups({ registry }) {
  hasSetBlockLookups = true;
  solidLookup = (id) => registry._solidityLookup[id];
  opaqueLookup = (id) => registry._opacityLookup[id];
  objectMeshLookup = (id) => registry._blockMeshLookup[id];
  blockHandlerLookup = (id) => registry._blockHandlerLookup[id];
}

// helper to call handler of a given type at a particular xyz
function callBlockHandler(chunk, blockID, type, x, y, z) {
  const hobj = blockHandlerLookup[blockID];
  if (!hobj) return;
  const handler = hobj[type];
  if (!handler) return;
  handler(chunk.x + x, chunk.y + y, chunk.z + z);
}

/*
 *
 *      Init
 *
 *  Converts raw voxel ID data into packed ID + solidity etc.
 *
 */

// accessors related to meshing

function addObjectBlock(chunk, id, x, y, z) {
  objectMesher.addObjectBlock(chunk, id, x, y, z);
  chunk._objectsDirty = true;
}

function removeObjectBlock(chunk, x, y, z) {
  objectMesher.removeObjectBlock(chunk, x, y, z);
  chunk._objectsDirty = true;
}

// helper to call a given handler for all blocks in the chunk
function callAllBlockHandlers(chunk, type) {
  const arr = chunk.voxels;
  if (!arr || !arr.data || !chunk.hasBlockHandlers) {
    return;
  }

  const size = arr.shape[0];
  for (let i = 0; i < size; ++i) {
    for (let j = 0; j < size; ++j) {
      for (let k = 0; k < size; ++k) {
        const id = arr.get(i, j, k);
        if (id > 0) callBlockHandler(chunk, id, type, i, j, k);
      }
    }
  }
}
