import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { isSafari } from "lib/browser";

export const fetchDrawableImage = (
  url: string,
  width: number,
  height: number
): Promise<HTMLImageElement | ImageBitmap> => {
  if (USE_IMAGE_BITMAP && !isSafari()) {
    return fetch(url)
      .then((resp) => resp.blob())
      .then(createImageBitmap);
  } else {
    return new Promise((resolve, reject) => {
      let image = new Image(width, height);
      image.src = url;
      image.onload = () => {
        resolve(image);
      };

      image.onerror = (e) => {
        reject(image);
      };
    });
  }
};
