import {
  Material,
  NullEngine,
  ShaderMaterial,
  SolidParticleSystem,
  StandardMaterial,
} from "@babylonjs/core";
import { MultiMaterial } from "@babylonjs/core/Materials/multiMaterial";
import { Texture } from "@babylonjs/core/Materials/Textures/texture";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import { VertexData } from "@babylonjs/core/Meshes/mesh.vertexData";
import { SubMesh } from "@babylonjs/core/Meshes/subMesh";
import { Submesh } from "lib/meshing/Submesh";
import Engine from "noa-engine";
import { mergeMeshData } from "noa-engine/lib/meshDataBuilder";

const fakeTempArray = { push: () => {} };
/*
 *
 *  Submesh - holds one submesh worth of greedy-meshed data
 *
 *  Basically, the greedy mesher builds these and the mesh builder consumes them
 *
 */
/*
 *
 *  Mesh Builder - turns an array of Submesh data into a
 *  Babylon.js mesh/submeshes, ready to be added to the scene
 *
 */
export class MeshBuilder {
  noa: Engine;

  // core
  build(
    chunkID,
    meshDataList: Array<Submesh>,
    ignoreMaterials,
    isMergeable,
    scene
  ) {
    const results = mergeMeshData(meshDataList, ignoreMaterials, isMergeable);
    // merge sole remaining submesh instance into a babylon mesh
    const merged = meshDataList[0];
    const name = `chunk_${chunkID}`;

    const mats = new Array(results.matIDs.length);
    if (ignoreMaterials) {
      // mats.fill();
    } else {
      for (let i = 0; i < mats.length; i++) {
        mats[i] = this.getTerrainMaterial(results.matIDs[i], ignoreMaterials);
      }
    }

    const mesh = this.buildMeshFromSubmesh(
      merged,
      name,
      mats,
      results.vertices,
      results.indices,
      scene
    );

    // done, mesh will be positioned later when added to the scene

    return mesh;
  }

  buildMeshFromSubmesh(
    submesh,
    name,
    mats: Array<StandardMaterial | ShaderMaterial>,
    verts,
    inds,
    scene
  ) {
    scene.blockfreeActiveMeshesAndRenderingGroups = true;

    const mesh = new Mesh(name, scene);
    mesh.hasVertexAlpha = false;

    mesh.enablePointerMoveEvents = true;

    mesh.isPickable = true;

    const vdat = new VertexData();
    vdat.positions = submesh.positions;
    vdat.indices = submesh.indices;
    vdat.normals = submesh.normals;
    vdat.colors = submesh.colors;
    vdat.uvs = submesh.uvs;

    let sps = new SolidParticleSystem(name, scene, {
      updatable: false,
      isPickable: false,
      useModelMaterial: false,
      particleIntersection: true,
    });
    vdat.applyToMesh(mesh);

    let chosenMat: Material;
    const materials = Array.from(new Set(mats));
    if (mats.length > 1) {
      let vertStart = 0;
      let indStart = 0;
      const subMeshes: Array<SubMesh> = new Array(mats.length);
      let onlyOne = materials.length === 1;
      let o = 0;
      for (let i = 0; i < mats.length; i++) {
        const matIndex = onlyOne ? 0 : materials.indexOf(mats[i]);

        new SubMesh(matIndex, vertStart, verts[i], indStart, inds[i], mesh);

        vertStart += verts[i];
        indStart += inds[i];
      }

      if (materials.length > 1) {
        const multiMat = new MultiMaterial("mat", scene);
        multiMat.subMaterials = materials;
        chosenMat = multiMat;
      } else {
        chosenMat = materials[0];
      }
    } else {
      chosenMat = mats[0];
    }

    // return mesh;

    // return mesh;

    sps.addShape(mesh, 1);
    const newMesh = sps.buildMesh();

    sps.mesh.material = chosenMat;
    sps.computeSubMeshes();

    sps.computeBoundingBox = true;
    sps.computeParticleTexture = true;
    sps.computeParticleColor = true;
    sps.computeParticleRotation = true;
    sps.computeParticleVertex = true;
    sps.computeBoundingBox = true;
    sps.setParticles();
    sps.refreshVisibleSize();

    mesh.dispose();
    newMesh.setVerticesData("tile", submesh.tiles, false, 1);
    newMesh.material = chosenMat;
    newMesh.enablePointerMoveEvents = true;
    newMesh.isPickable = true;

    if (submesh.dispose) {
      submesh.dispose();
    }

    // if (!newMesh.material.isFrozen) {
    //   newMesh.material.freeze();
    // }

    scene.blockfreeActiveMeshesAndRenderingGroups = false;
    return newMesh;
  }

  //                         Material wrangling
  materialCache = {};

  // manage materials/textures to avoid duplicating them
  getTerrainMaterial(matID, ignore) {
    const rendermat = this.noa.registry.getMaterialData(matID).renderMat;

    if (rendermat) {
      return rendermat;
    }
    if (ignore) return this.noa.rendering.flatMaterial;

    const name = Symbol.for(`tmt/${matID}`);
    if (!this.materialCache[name]) {
      this.materialCache[name] = this.makeTerrainMaterial(matID);
    }

    return this.materialCache[name];
  }

  // canonical function to make a terrain material
  makeTerrainMaterial(id) {
    // if user-specified render material is defined, use it
    const matData = this.noa.registry.getMaterialData(id);
    if (matData.renderMat) return matData.renderMat;
    // otherwise determine which built-in material to use
    const url = this.noa.registry.getMaterialTexture(id);
    const alpha = matData.alpha;
    if (!url && alpha === 1) {
      // base material is fine for non-textured case, if no alpha
      return this.noa.rendering.flatMaterial;
    }
    const mat = this.noa.rendering.flatMaterial.clone(`terrain${id}`);
    if (url) {
      const scene = this.noa.rendering.getScene();
      const tex = new Texture(
        url,
        scene,
        true,
        false,
        Texture.NEAREST_SAMPLINGMODE
      );
      tex.isBlocking = false;
      if (matData.textureAlpha) tex.hasAlpha = true;
      mat.diffuseTexture = tex;
    }
    if (matData.alpha < 1) {
      mat.alpha = matData.alpha;
    } else {
      mat.alphaMode = NullEngine.ALPHA_DISABLE;
    }

    // mat.freeze();
    return mat;
  }
}
