import vec3 from "gl-vec3";

export default function (noa) {
  function renderPlayerMesh(state) {
    var id = state.__id;

    var rpos = noa.ents.getPositionData(id)._renderPosition;
    for (let mesh of state.meshes) {
      if (mesh.position !== rpos) {
        mesh.position = rpos;
      }
    }
  }

  return {
    name: "playerMesh",

    order: 100,

    state: {
      meshes: [],
      offset: null,
    },

    onAdd: function (eid, state) {
      // implicitly assume there's already a position component
      var posDat = noa.ents.getPositionData(eid);
      if (state.meshes.length > 0) {
        for (let mesh of state.meshes) {
          noa.rendering.addMeshToScene(mesh);
        }
      } else {
        throw new Error(
          "Mesh component added without a mesh - probably a bug!"
        );
      }
      if (!state.offset) state.offset = vec3.create();

      // set mesh to correct position
      var rpos = posDat._renderPosition;
      state.meshes[0].position = rpos.addInPlaceFromFloats(
        state.offset[0],
        state.offset[1],
        state.offset[2]
      );
    },

    onRemove: function (eid, state) {
      for (let mesh of state.meshes) {
        mesh.dispose();
      }
    },

    renderSystem: function (dt, states) {
      // before render move each mesh to its render position,
      // set by the physics engine or driving logic

      states.forEach(renderPlayerMesh);
    },
  };
}
