import nengi from "nengi";

export class WorldChunkMessage {
  constructor({ chunkData, id }) {
    this.chunkData = chunkData;
    this.id = id;
  }

  chunkData: Buffer;
  id: string;

  static protocol = {
    chunkData: nengi.ByteString,
    id: nengi.UTF8String,
  };
}

export default WorldChunkMessage;
