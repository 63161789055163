import nengi from "nengi";
import {
  NengiBlockCoordinate,
  NengiBlockType,
  PlaceBlockInterface,
  SetBlockProtocol,
} from "../nengiTypes";

export class BulkPlaceBlockCommand {
  constructor(blocks: Array<PlaceBlockInterface>) {
    this.x = new Int32Array(blocks.length);
    this.y = new Int32Array(blocks.length);
    this.z = new Int32Array(blocks.length);
    this.blockType = new Uint16Array(blocks.length);
    this.urlSide = new Array(blocks.length);
    this.variant = new Uint32Array(blocks.length);
    this.url = new Array(blocks.length);
    this.error = 0;

    for (let i = 0; i < blocks.length; i++) {
      this.x[i] = blocks[i].x;
      this.y[i] = blocks[i].y;
      this.z[i] = blocks[i].z;
      this.blockType[i] = blocks[i].blockType;
      this.variant[i] = blocks[i].variant;
      this.urlSide[i] = blocks[i].urlSide;
      this.url[i] = blocks[i].url;
    }
  }

  x: Int32Array;
  y: Int32Array;
  z: Int32Array;
  variant: Uint32Array;
  blockType: Uint32Array;
  urlSide: Uint8Array;
  url: Array<String>;

  static protocol = {
    error: { type: nengi.UInt8 },
    x: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    y: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    z: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    blockType: { type: NengiBlockType, indexType: nengi.UInt12 },
    variant: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    urlSide: { type: SetBlockProtocol.urlSide.type, indexType: nengi.UInt12 },
    url: { type: SetBlockProtocol.url.type, indexType: nengi.UInt12 },
  };
}

export default BulkPlaceBlockCommand;
