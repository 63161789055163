import MeshoptDecoder from "./MeshoptDecoder";
import MESHOPT_compression from "./babylon.MESHOPT_compression";
import { GLTF2 } from "@babylonjs/loaders";

const NAME = "EXT_meshopt_compression";

GLTF2.GLTFLoader.RegisterExtension(
  "EXT_meshopt_compression",
  (loader) =>
    new MESHOPT_compression(loader, MeshoptDecoder, "EXT_meshopt_compression")
);

GLTF2.GLTFLoader.RegisterExtension(
  "MESHOPT_compression",
  (loader) =>
    new MESHOPT_compression(loader, MeshoptDecoder, "MESHOPT_compression")
);
