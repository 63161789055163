import { Mesh, PBRMaterial, StandardMaterial, Vector3 } from "@babylonjs/core";
import { BlockID } from "shared/BlockID";
import { isBlock } from "shared/blocks";
import { PictureVariant, TelevisionVariant } from "shared/foilage";
import { ItemVariant } from "shared/items";
import {
  FoilageRotationBitValue,
  FoilageScaleBitValue,
} from "shared/items/ItemVariant";

export { FoilageRotationBitValue, FoilageScaleBitValue };

export enum FoilageAlignment {
  top,
  bottom,
  left,
  right,
  front,
  back,
}

enum IntrinsicAlignment {
  center,
  edge,
}

const getIntrinsicAlignment = (blockID: BlockID) => {
  if (blockID === BlockID.picture) {
    return IntrinsicAlignment.edge;
  } else {
    return IntrinsicAlignment.center;
  }
};

export const getAlignment = (item: ItemVariant) => {
  return getRawAlignment(item.xNormal - 1, item.yNormal - 1, item.zNormal - 1);
};

export const getRawAlignment = (
  xNormal: number,
  yNormal: number,
  zNormal: number
) => {
  if (xNormal === -1) {
    return FoilageAlignment.left;
  } else if (xNormal === 1) {
    return FoilageAlignment.right;
  } else if (yNormal === -1) {
    return FoilageAlignment.top;
  } else if (yNormal === 1) {
    return FoilageAlignment.bottom;
  } else if (zNormal === 1) {
    return FoilageAlignment.back;
  } else if (zNormal === -1) {
    return FoilageAlignment.front;
  } else {
    return FoilageAlignment.bottom;
  }
};

export const ALIGNMENT_LABEL = {
  [FoilageAlignment.left]: "left",
  [FoilageAlignment.right]: "right",
  [FoilageAlignment.top]: "top",
  [FoilageAlignment.bottom]: "bottom",
  [FoilageAlignment.back]: "back",
  [FoilageAlignment.front]: "front",
};

export const isPinnedToWall = (item: ItemVariant) => {
  return getAlignment(item) !== FoilageAlignment.bottom;
};

const offsets = {
  [BlockID.picture]: {
    [FoilageAlignment.back]: [
      [0.5, 0, 0.1],
      [0.5, 0, 0.05],
      [0.5, 0, 0.025],
    ],
    [FoilageAlignment.front]: [
      [0.5, 0, 0.95],
      [0.5, 0, 0.95],
      [0.5, 0, 0.95],
    ],
    [FoilageAlignment.left]: [
      [0.9, 0, 0.5],
      [0.9, 0, 0.5],
      [0.9, 0, 0.5],
    ],
    [FoilageAlignment.right]: [
      [0.1, 0, 0.5],
      [0.1, 0, 0.5],
      [0.1, 0, 0.5],
    ],
    [FoilageAlignment.top]: [
      [0, 0.9, 0],
      [0, 0.9, 0],
      [0, 0.9, 0],
    ],
    [FoilageAlignment.bottom]: [
      [0, 0.9, 0],
      [0, 0.9, 0],
      [0, 0.9, 0],
    ],
  },
  [BlockID.television]: {
    [FoilageAlignment.back]: [
      [0.5, 0, 0.1],
      [0.5, 0, 0.05],
      [0.5, 0, 0.025],
    ],
    [FoilageAlignment.front]: [
      [0.5, 0, 0.95],
      [0.5, 0, 0.95],
      [0.5, 0, 0.95],
    ],
    [FoilageAlignment.left]: [
      [0.9, 0, 0.5],
      [0.9, 0, 0.5],
      [0.9, 0, 0.5],
    ],
    [FoilageAlignment.right]: [
      [0.1, 0, 0.5],
      [0.1, 0, 0.5],
      [0.1, 0, 0.5],
    ],
    [FoilageAlignment.top]: [
      [0, 0.9, 0],
      [0, 0.9, 0],
      [0, 0.9, 0],
    ],
    [FoilageAlignment.bottom]: [
      [0, 0.9, 0],
      [0, 0.9, 0],
      [0, 0.9, 0],
    ],
  },
};

const ZERO_ARRAY = new Float32Array([0, 0, 0]);
const OFFSET = new Float32Array([0.5, 0, 0.5]);
export const getOffset = (blockID: BlockID, item: ItemVariant) => {
  const alignment = getAlignment(item);
  if (isBlock(blockID)) {
    return ZERO_ARRAY;
  } else if (alignment !== FoilageAlignment.bottom) {
    return offsets[BlockID.picture][alignment][item.scale];
  } else {
    // } else if (offsets[blockID] && alignment !== FoilageAlignment.bottom) {
    //   return offsets[blockID][alignment][item.scale];
    // } else {
    return OFFSET;
  }
};

export class FoilageMesh extends Mesh {
  blockID: BlockID;
  variant: number;
}

export enum FoilageScale {
  normal = 1,
  big = 1.5,
  huge = 3,
}

export enum FoilageRotation {
  none = 0,
  quarter = Math.PI / 2,
  half = Math.PI,
  threeQuarters = Math.PI * 1.5,
  full = Math.PI * 2,
}

export const FOILAGE_ROTATION = [
  FoilageRotation.none,
  FoilageRotation.quarter,
  FoilageRotation.half,
  FoilageRotation.threeQuarters,
  FoilageRotation.full,
];

export const getRotation = (item: ItemVariant) => {
  const alignment = getAlignment(item);

  if (alignment === FoilageAlignment.bottom) {
    return FOILAGE_ROTATION[item.rotation];
  } else {
    return FOILAGE_ALIGNMENT_ROTATION[alignment];
  }
};

export const FOILAGE_ROTATION_VECS = [
  new Vector3(0, FOILAGE_ROTATION[0], 0),
  new Vector3(0, FOILAGE_ROTATION[1], 0),
  new Vector3(0, FOILAGE_ROTATION[2], 0),
  new Vector3(0, FOILAGE_ROTATION[3], 0),
  new Vector3(0, FOILAGE_ROTATION[4], 0),
];

export const FOILAGE_SCALE = [
  FoilageScale.normal,
  FoilageScale.big,
  FoilageScale.huge,
];

export const foilageDimensions = (mesh: Mesh) => {
  const {
    minimumWorld: min,
    maximumWorld: max,
  } = mesh.getBoundingInfo().boundingBox;

  let height = Math.ceil(max.y - min.y);
  let depth = Math.ceil(max.z - min.z);
  let width = Math.ceil(max.x - min.x);

  return [min.clone(), new Vector3(width, height, depth)];
};

export const getScreenMaterial = (
  mesh: FoilageMesh
): PBRMaterial | StandardMaterial => {
  if (
    mesh.metadata.blockID === BlockID.picture &&
    mesh.metadata.variant === PictureVariant.pictureLandscape
  ) {
  } else if (
    mesh.metadata.blockID === BlockID.television &&
    mesh.metadata.variant === TelevisionVariant.television
  ) {
    for (let i = 0; i < mesh.material.subMaterials.length; i++) {
      const mat = mesh.material.subMaterials[i];
      if (mat.id === "LCD") {
        return mat;
      }
    }
    return null;
  }
};

export const setScreenMaterial = (
  mesh: FoilageMesh,
  mat
): PBRMaterial | StandardMaterial => {
  if (
    mesh.metadata.blockID === BlockID.picture &&
    mesh.metadata.variant === PictureVariant.pictureLandscape
  ) {
  } else if (
    mesh.metadata.blockID === BlockID.television &&
    mesh.metadata.variant === TelevisionVariant.television
  ) {
    for (let i = 0; i < mesh.material.subMaterials.length; i++) {
      if (mesh.material.subMaterials[i].name === "LCD") {
        mesh.material.subMaterials[i] = mat;
      }
    }
    return null;
  }
};

export const FOILAGE_ALIGNMENT_ROTATION = [
  -Math.PI,
  0,
  -Math.PI / 2,
  Math.PI / 2,
  Math.PI,
  0,
];

export const FOILAGE_ALIGNMENT_ROTATION_VECS = [
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[0], 0),
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[1], 0),
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[2], 0),
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[3], 0),
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[4], 0),
  new Vector3(0, FOILAGE_ALIGNMENT_ROTATION[5], 0),
];

export const supportsInstancing = (blockType: BlockID) => {
  return false;
  // const actions = blockActions.get(blockType);

  // return !actions || !actions.includes(EntityAction.changeURL);
};
