// noa.ents.hasPhysics = noa.ents.getComponentAccessor(noa.ents.names.physics);

import {
  Color3,
  DefaultRenderingPipeline,
  Engine,
  ImageProcessingConfiguration,
  PBRSpecularGlossinessMaterial,
  RawTexture2DArray,
  Scene,
  StandardMaterial,
  Texture,
  ImageProcessingPostProcess,
} from "@babylonjs/core";
import { getClearColor } from "game/Player/PlayerMesh";
import { isFirefox, isSafari } from "lib/browser";
import { getTerrainMaterial } from "lib/Data/Shaders/TerrainMaterial/TerrainMaterial";
import { compact } from "lodash";
import { Engine as NoaEngine } from "noa-engine";
import BLOCK_DETAILS from "shared/block-details.json";
import { BlockID } from "shared/BlockID";
import { BlockBlend } from "shared/blocks";
import textures, {
  image,
  loadImage,
  size as textureSize,
} from "./textures/textures";
type ValueOf<T> = T[keyof T];

var brownish = [0.45, 0.36, 0.22];
var greenish = [0.1, 0.8, 0.2];

const BlockSide = {
  none: "",
  bottom: "_bottom",
  top: "_top",
  side: "_side",
};

const BASE_URL = "/blocks/faithful/";

const textureURL = (name: string, textures) => {
  return textures[name.replace(".png", "")] || BASE_URL + name;
};
const ALL_TEXTURES = Object.keys(textures);

const texturesList = new Array(textureSize[1] / 32);
const FLIP_TEXTURE_CORDS = isFirefox();

for (let name of ALL_TEXTURES) {
  const texture = textures[name];

  let y;
  if (FLIP_TEXTURE_CORDS) {
    y = texturesList.length - 1 - texture[1] / 32;
  } else {
    y = texture[1] / 32;
  }

  texturesList[y] = name;
}

export let blocks = {};

let atlasMaterial: StandardMaterial;
let atlasTexture: RawTexture2DArray | Texture;

const TEXTURE_COUNT = Object.keys(textures).length;

function randomIn(a, b) {
  return Math.random() * (b - a) + a;
}

export const registerMaterials = async (noa: NoaEngine, x, y, z) => {
  const img = await loadImage();

  const scene: Scene = noa.rendering.getScene();
  const engine = scene.getEngine();

  const depth = texturesList.length;
  let shaderMaterial;

  const _registerMaterial = (
    name: string,
    color,
    hasAlpha: boolean = false,
    hasURL = true,
    side = "",
    rawURL
  ) => {
    const mat = (name + side).replace(".png", "");
    const path = name.replace(".png", "");
    const _color = [color[0], color[1], color[2]];
    const size = textures[path];

    noa.registry.registerMaterial(
      mat,
      _color,
      atlasTexture,
      hasAlpha,
      shaderMaterial,
      texturesList.indexOf(path)
    );

    return mat;
  };

  // const curve = new ColorCurves();
  // curve.globalHue = 200;
  // curve.globalDensity = 80;
  // curve.globalSaturation = 80;

  // curve.highlightsHue = 20;
  // curve.highlightsDensity = 80;
  // curve.highlightsSaturation = -80;

  // curve.shadowsHue = 2;
  // curve.shadowsDensity = 80;
  // curve.shadowsSaturation = 40;
  const fps = scene.getEngine().getFps();
  if (scene.getEngine().webGLVersion > 1) {
    const pipeline = new DefaultRenderingPipeline(
      "defaultrendering",
      false,
      scene,
      [noa.rendering._camera],
      false
    );

    pipeline.cameraFov = scene.activeCamera.fov;
    pipeline.fxaaEnabled = true;
    pipeline.imageProcessingEnabled = true;
    pipeline.imageProcessing = new ImageProcessingPostProcess(
      "imageProcess",
      { width: 1, height: 1 },
      noa.rendering._camera,
      undefined,
      scene.getEngine()
    );
    pipeline.imageProcessing.toneMappingEnabled = true;
    pipeline.imageProcessing.toneMappingType =
      ImageProcessingConfiguration.TONEMAPPING_ACES;

    pipeline.prepare();
  }

  // pipeline.samples = 4;
  // pipeline.fxaaEnabled = true;

  // pipeline.imageProcessing.vignetteEnabled = true;
  // pipeline.imageProcessing.vignetteWeight = 2.9;
  // pipeline.imageProcessing.vignetteBlendMode =
  //   ImageProcessingConfiguration.VIGNETTEMODE_MULTIPLY;
  // pipeline.imageProcessing.vignetteColor = new Color4(
  //   0.6374860201597697,
  //   0.11771207789135774,
  //   0.6998405297611199,
  //   1
  // );

  // const chunkPlane = MeshBuilder.CreateBox(name, {
  //   width: 16,
  //   height: 256,
  //   depth: 16,
  //   faceColors: Array(6).fill(new Color4(0, 0, 0.2, 0.2)),
  // });
  // chunkPlane.setEnabled(false);

  // const chunkX = noa.world._worldCoordToChunkCoord(x);
  // const chunkZ = noa.world._worldCoordToChunkCoord(z);
  // for (let _x = chunkX - 1; _x < chunkX + 1; _x++) {
  //   for (let _z = chunkZ - 1; _z < chunkZ + 1; _z++) {
  //     const plane = chunkPlane.createInstance(_x + _z + "");
  //   }
  // }

  // box.renderingGroupId = 0;

  const clearColor = getClearColor(scene);

  const glass = new PBRSpecularGlossinessMaterial("pbr", scene);
  glass.diffuseColor = new Color3(1.0, 0.766, 0.336);
  glass.specularColor = new Color3(1.0, 0.766, 0.336);

  glass.glossiness = 0.8;
  glass.alpha = 0.75;
  glass.alphaMode = Engine.ALPHA_PREMULTIPLIED_PORTERDUFF;

  // glass.opacityFresnelParameters = new FresnelParameters();
  // glass.diffuseColor = new Color3(0, 0, 0);
  // glass.opacityFresnelParameters.leftColor = new Color3(1, 1, 0);
  // glass.backFaceCulling = false;
  // glass.opacityFresnelParameters.rightColor = new Color3(1, 0, 1);
  // glass.linkEmissiveWithDiffuse = true;
  // glass.alphaMode = Engine.ALPHA_PREMULTIPLIED_PORTERDUFF;

  // const glassMesh = BoxBuilder.CreateBox(
  //   "glassbox",
  //   {
  //     width: 1,
  //     height: 1,
  //     depth: 0.2,
  //   },
  //   scene
  // );
  // glassMesh.material = glass;
  // glassMesh.position.set(0, 0, 0.75);

  // noa.rendering._octree.dynamicContent.push(box);

  const GLASS_NAMES = [
    // "glass",
    "glass_pane",
    "stained_glass",
    "stained_glass_pane",
  ];

  // noa.registry.registerBlock(BlockID.water, {
  //   material: "water",
  //   solid: true,
  //   opaque: true,
  //   fluid: true,
  // });

  // noa.registry.registerBlock(BlockID.flowing_water, {
  //   material: "water",
  //   solid: false,
  //   opaque: false,
  //   fluid: true,
  // });

  noa.registry.registerMaterial("white-block", [1, 1, 1], null, false);
  noa.registry.registerMaterial("black-block", [0.1, 0.1, 0.1], null, false);
  noa.registry.registerBlock(BlockID.white, {
    material: "white-block",
    solid: true,
    opaque: true,
  });

  noa.registry.registerBlock(BlockID.black, {
    material: "black-block",
    solid: true,
    opaque: true,
  });

  // noa.registry.registerBlock(BlockID.roomLight, {
  //   solid: false,
  //   opaque: false,
  //   onLoad: (x, y, z) => {
  //     const mesh = createDiscoLightWithScene(scene, scene.activeCamera);
  //     noa.rendering.addMeshToScene(mesh, false, [x, y, z]);
  //     mesh.translate(new Vector3(0, 1, 0), 1);
  //   },
  // });

  // 56	25	173
  // 78	28	178
  // 210	48	135
  // const partyLighting = new Animation(
  //   "partyLighting",
  //   "diffuse",
  //   60,
  //   Animation.ANIMATIONTYPE_COLOR3,
  //   Animation.ANIMATIONLOOPMODE_CYCLE,
  //   true
  // );
  // partyLighting.setKeys([
  //   {
  //     frame: 0,
  //     value: new Color3(44 / 255, 20 / 255, 138 / 255),
  //   },
  //   {
  //     frame: 60 * 3,
  //     value: new Color3(62 / 255, 22 / 255, 142 / 255),
  //   },
  //   {
  //     frame: 60 * 4,
  //     value: new Color3(210 / 255, 48 / 255, 135 / 255),
  //   },
  //   {
  //     frame: 60 * 5,
  //     value: new Color3(62 / 255, 22 / 255, 142 / 255),
  //   },
  //   {
  //     frame: 60 * 6,
  //     value: new Color3(44 / 255, 20 / 255, 138 / 255),
  //   },
  // ]);

  // noa.rendering._light.animations = [];
  // noa.rendering._light.animations.push(partyLighting);
  // const anim = scene.beginAnimation(
  //   noa.rendering._light,
  //   0,
  //   60 * 6,
  //   true,
  //   1,
  //   null,
  //   null,
  //   false,
  //   null,
  //   () => console.log("LOOP")
  // );

  if (engine.webGLVersion > 1) {
    atlasTexture = new RawTexture2DArray(
      img,
      32,
      32,
      depth,
      Engine.TEXTUREFORMAT_RGBA,
      scene,
      true,
      !isSafari(),
      Texture.NEAREST_SAMPLINGMODE
    );

    shaderMaterial = getTerrainMaterial(atlasTexture, 32, 256, scene);

    shaderMaterial.freeze();

    // shaderMaterial = getTerrainMaterial(atlasTexture, 32, 32);
  } else {
    atlasMaterial = noa.rendering.flatMaterial.clone("rootmat");
    atlasMaterial.diffuseTexture = new Texture(
      null,
      noa.rendering.getScene(),
      false,
      true,
      undefined,
      undefined,
      undefined,
      image,
      true,
      Engine.TEXTUREFORMAT_RGBA,
      "image/png"
    );
    atlasTexture = atlasMaterial.diffuseTexture;
    // atlasMaterial.freeze();
  }

  Object.keys(BLOCK_DETAILS).forEach((name) => {
    const block = BLOCK_DETAILS[name];

    if (GLASS_NAMES.includes(name)) {
      noa.registry.registerMaterial(name, clearColor, null, true, glass);

      noa.registry.registerBlock(block.id, {
        solid: true,
        opaque: false,
        fluid: false,
        material: name,
        // blockMesh: glassMesh,
      });
    } else if (block.blend === BlockBlend.plant) {
      // registerPlant(block);
    } else {
      let includeStandalone = !block.sides || !block.sides.includes("side");

      const materials = block.files
        .reverse()
        .slice(0, 3)
        .map((file) => {
          let _side = BlockSide.side;

          if (file.includes("_top")) {
            _side = BlockSide.top;
          } else if (file.includes("_bottom")) {
            _side = BlockSide.bottom;
          }
          const hasAlpha = !!block.alpha === false || !!block.solid === false;

          return _registerMaterial(file, brownish, hasAlpha, true, _side);
        });

      let _materials = materials;
      if (materials.length) {
        const orderSides = [...materials];

        const topSideIndex = orderSides.findIndex((side) => {
          return side.includes("_top");
        });

        const topSideName = topSideIndex > -1 ? orderSides[topSideIndex] : null;

        if (topSideIndex > -1) {
          orderSides.splice(topSideIndex, 1);
        }

        const bottomSideIndex = orderSides.findIndex((side) => {
          return side.includes("_bottom");
        });

        const bottomSideName = orderSides[bottomSideIndex];

        if (bottomSideIndex > -1) {
          orderSides.splice(bottomSideIndex, 1);
        }

        let sideSide = orderSides.findIndex((side) => {
          return side.includes("_side");
        });

        if (!sideSide && (topSideName || bottomSideName)) {
          sideSide = orderSides.findIndex(
            (side) => !side.includes("_top") && !side.includes("_bottom")
          );
        }

        if (sideSide > -1) {
          sideSide = orderSides[sideSide];
        }

        _materials = compact([topSideName, bottomSideName, sideSide]);
      }

      noa.registry.registerBlock(block.id, {
        material: _materials.length === 1 ? _materials[0] : _materials,
        solid: block.solid !== false,
        opaque: !!block.alpha,
      });
    }
  });
};
