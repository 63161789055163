import nengi from "nengi";

export class UpdateAvatarCommand {
  constructor(
    avatarType: number = 0,
    color: string = "",
    backgroundImageURL: string = ""
  ) {
    this.avatarType = avatarType;
    this.color = color;
    this.backgroundImageURL = backgroundImageURL;
  }
  avatarType: number;
  color: string;
  backgroundImageURL: string;
  static protocol = {
    avatarType: { type: nengi.UInt10 },
    color: { type: nengi.UTF8String },
    backgroundImageURL: { type: nengi.UTF8String },
  };
}
