import { capitalize } from "lodash";
import { BlockID } from "./BlockID";
import { blockIdType, BlockIDType, costs, getSide } from "./blocks";
import { itemId } from "./items/itemId";
import {
  getItemVariant,
  ItemVariant,
  encodeItemVariant,
  ItemVariantField,
} from "./items/ItemVariant";

export { itemId };
export type { ItemVariant };
export { ItemVariantField, getItemVariant, encodeItemVariant };

export const sideForItem = (item: ItemVariant) => {
  return getSide(item.xNormal - 1, item.yNormal - 1, item.zNormal - 1);
};

export type Item = {
  id: string;
  name: string;
  variantName: string;
  blockID: BlockID;
  variant: number;
  cost: number;
  hidden: boolean;
};

export const items = new Map<symbol, Item>();

// export const itemId = memoizee(_itemId, { primitive: true, max: 1000 });

const blockNames = {
  [BlockID.bong]: "Bong",
  [BlockID.corona]: "Beer",
  [BlockID.picture]: "Picture",
  [BlockID.rug]: "Rug",
  [BlockID.television]: "TV",
  [BlockID.chair]: "Chair",
  [BlockID.stool]: "Chair",
  [BlockID.lollipop]: "Lollipop",
  [BlockID.mushroom]: "Chair",
  [BlockID.table]: "Table",
  [BlockID.tree]: "Tree",
};

const foilageNames = {
  [BlockID.bong]: [null],
  [BlockID.corona]: ["Corona"],
  [BlockID.picture]: ["Landscape", "Portrait", "Big", "Small"],
  [BlockID.rug]: ["Circle", "Sqsuare"],
  [BlockID.television]: [null],
};

const surfaceNames = {
  [BlockID.chair]: ["Dining Room"],
  [BlockID.stool]: ["Stool"],
  [BlockID.lollipop]: [null],
  [BlockID.mushroom]: ["Mushroom 1", "Mushrom 2"],
  [BlockID.vehicle]: ["Tesla Cybertruck", "Tird Scooter"],
  [BlockID.table]: [
    "Rectangular Dining Room",
    "Square - Dining Room",
    "Living Room",
  ],
  [BlockID.tree]: [
    "Tall Yellow",
    "Medium Red",
    "Large Yellow",
    "Large Pink",
    "Large Green",
    "Medium Magenta",
    "Large Orange",
    "Skinny 2x Green",
  ],
};

export let blockMap = new Map<number, string>(
  Object.entries(BlockID).map(([id, value]) => [value, id])
);

const missing = [];

costs.forEach((values, blockID: BlockID) => {
  for (let i = 0; i < values.length; i++) {
    const id = itemId(blockID, i);
    const item: Item = {
      id,
      blockID,
      variant: i,
      cost: values[i],
      hidden: false,
    };

    if (blockID === BlockID.vehicle) {
      item.name = surfaceNames[blockID][item.variant];
    } else {
      item.name =
        blockNames[blockID] ||
        (blockMap.has(blockID) ? capitalize(blockMap.get(blockID)) : null);

      const type = blockIdType(blockID);

      let names = null;
      if (type === BlockIDType.foilage) {
        names = foilageNames[blockID];
      } else if (type === BlockIDType.surface) {
        names = surfaceNames[blockID];
      }

      if (names) {
        item.variantName = names[item.variant] || null;
      }
    }

    if (!item.name) {
      missing.push(blockID);
    }

    items.set(id, item);
  }
});

export const pickaxeId = itemId(BlockID.pickaxe, 0);
items.set(pickaxeId, {
  id: pickaxeId,
  blockID: BlockID.pickaxe,
  variant: 0,
  name: "Pickaxe",
  cost: 0,
  variantName: null,
  hidden: true,
});
