import * as React from "react";

export function LeftClickIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      style={{ marginTop: "auto", marginBottom: "auto" }}
      viewBox="0 0 155 227"
    >
      <path
        fill="currentColor"
        d="M0 72.108v82.782c0 39.762 32.346 72.108 72.108 72.108h10.218c39.762 0 72.111-32.346 72.111-72.108V72.108C154.437 32.349 122.088 0 82.326 0H72.108C32.346 0 0 32.346 0 72.108zm84.222-11.271v32.85c0 1.83-1.545 3.375-3.375 3.375H70.782c-1.827 0-3.375-1.545-3.375-3.375v-32.85c0-1.83 1.548-3.375 3.375-3.375h10.065c1.83 0 3.375 1.545 3.375 3.375zm55.203 11.091H99.222V60.84c0-9.294-6.942-16.977-15.909-18.189h-6.096v-27.6h6.096c30.972.531 56.016 25.803 56.112 56.877zM15 154.89V86.928h37.407v6.762c0 10.131 8.244 18.375 18.375 18.375h10.065c10.131 0 18.375-8.244 18.375-18.375v-6.762h40.212v67.962c0 31.488-25.62 57.108-57.111 57.108H72.105C40.62 211.998 15 186.378 15 154.89z"
      ></path>
    </svg>
  );
}

export default React.memo(LeftClickIcon);
