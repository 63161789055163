import { NengiIDType } from "../nengiTypes";

export class EquipItemCommand {
  constructor(id: number) {
    this.id = id;
  }

  id: number;

  static protocol = {
    id: NengiIDType,
  };
}
