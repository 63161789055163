import { Portal } from "react-portal";
import { Button } from "components/onboarding/Button";
import { GameContext, ModalType } from "components/GameContext";
import * as React from "react";
import classNames from "classnames";
import { ClaimInviteCommand } from "shared/command/ClaimInviteCommand";

export const ClaimInviteModalContent = React.memo(
  ({ onClick, username, color }) => {
    return (
      <div className="InviteModalContainer">
        <div
          className={classNames("InviteModal", {
            "InviteModal--long": username.length > 10,
            "InviteModal--normal": username.length <= 10,
          })}
        >
          <h2 className="Modal-title">Teleport to {username}</h2>
          <p className="Modal-body">
            Redeem <span className="Emphasis">$50,000</span> invite bonus from{" "}
            <span style={{ color }} className="Username">
              {username}
            </span>
            ? This will also teleport you to{" "}
            <span style={{ color }} className="Username">
              {username}
            </span>
            .
          </p>
          <div className="ButtonContainer">
            <Button inForm={false} onClick={onClick}>
              Claim BONUS
            </Button>
          </div>
        </div>
        <style jsx>{`
          .InviteModalContainer {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            z-index: 1001;
          }

          .InviteModal--long {
            max-width: 400px;
          }

          .InviteModal--normal {
            max-width: 320px;
          }

          .Modal-title {
            margin: 0;
            padding: 16px 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .Modal-body {
            padding: 0 18px;
            margin: 0;
            margin-bottom: 12px;
          }

          .Username {
            font-weight: 600;
          }

          .Emphasis {
            font-variant-numeric: tabular-nums;
            font-weight: 600;
            text-transform: uppercase;
            color: ;
          }

          .InviteModal {
            border-radius: 4px;
            filter: drop-shadow(1px 1px 1px #666);
            backdrop-filter: blur(10px);
            border: 1px solid #ccc;
            background-color: rgba(0, 0, 0, 0.5);
            color: white;
          }

          .ButtonContainer {
            padding: 16px 18px;
          }
        `}</style>
      </div>
    );
  }
);

export const ClaimInviteModal = ({ username, onDismiss }) => {
  const { game } = React.useContext(GameContext);
  const handlePress = React.useCallback(() => {
    game.client.addCommand(new ClaimInviteCommand());
    onDismiss(ModalType.claimInvite);
  }, [game, onDismiss]);

  if (!username) {
    return null;
  }

  return (
    <Portal>
      <ClaimInviteModalContent onClick={handlePress} username={username} />
    </Portal>
  );
};
