import nengi from "nengi";
import { NengiBlockType } from "../nengiTypes";
import { BaseEntity } from "./BaseEntity";

export enum AvatarType {
  robot = 0,
  rickAstley = 1,
  coolGlassesGirl = 2,
  surgeon = 3,
  character = 4,
}

export class PlayerCharacter extends BaseEntity {
  x = 0.0;
  y = 0.0;
  z = 0.0;
  yaw = 0.0;
  pitch = 0.0;
  roll = 0.0;
  currency = 0;
  equippedItemId = 0;
  isStreaming = false;
  heading = 0.0;
  running = false;
  jumping = false;
  onGround = true;
  username: string;
  isActive = true;
  avatarType: AvatarType;
  color: string | null;
  avatarURL: string | null;
  skinStyle: string;
  backgroundImageURL: string | null;

  static protocol = {
    username: { type: nengi.UTF8String, interp: false },
    avatarType: { type: nengi.UInt10, interp: false },
    isStreaming: { type: nengi.Boolean },
    color: { type: nengi.UTF8String, interp: false },
    skinStyle: { type: nengi.UTF8String, interp: false },
    equippedItemId: { type: NengiBlockType, interp: false },
    currency: { type: nengi.UInt32, interp: false },
    avatarURL: { type: nengi.UTF8String, interp: false },
    backgroundImageURL: { type: nengi.UTF8String, interp: false },
    x: { type: nengi.Float32, interp: true },
    y: { type: nengi.Float32, interp: true },
    z: { type: nengi.Float32, interp: true },
    jumping: { type: nengi.Boolean, interp: false },
    running: { type: nengi.Boolean, interp: false },
    onGround: { type: nengi.Boolean, interp: false },
    pitch: { type: nengi.RotationFloat32, interp: false },
    heading: { type: nengi.RotationFloat32, interp: false },
    yaw: { type: nengi.RotationFloat32, interp: false },
    roll: { type: nengi.RotationFloat32, interp: false },
    isAlive: nengi.Boolean,
  };
}

export default PlayerCharacter;
