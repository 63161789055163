import type { Mesh } from "@babylonjs/core";
import { USE_SHARED_ARRAY_BUFFER } from "lib/Data/USE_SHARED_ARRAY_BUFFER";
import ndarray from "ndarray";
import { BlockID } from "shared/blocks";
import { copyNdarrayContents } from "./util";

export type SolidLookupFunction = (id: BlockID) => boolean;
export type ObjectLookupFunction = (id: BlockID) => boolean;
export type OpaqueLookupFunction = (id: BlockID) => boolean;

// helpers to determine which blocks are, or can affect, terrain meshes
export const affectsTerrain = (
  id: BlockID,
  isSolid: SolidLookupFunction,
  isObject: ObjectLookupFunction
) => {
  if (id === 0) return false;
  if (isSolid(id)) {
    return true;
  }

  return !isObject(id);
};

const emptyThreeVec = [0, 0, 0];

export const buildPaddedVoxelArray = (voxels, neighbors) => {
  var src = voxels;
  var cs = src.shape[0];
  var tgt = cachedPadded;

  // embiggen cached target array
  if (cs + 2 !== tgt.shape[0]) {
    var s2 = cs + 2;
    if (USE_SHARED_ARRAY_BUFFER) {
      tgt = ndarray(
        new Uint16Array(
          new SharedArrayBuffer(s2 * s2 * s2 * Uint16Array.BYTES_PER_ELEMENT)
        ),
        [s2, s2, s2]
      );
    } else {
      tgt = ndarray(new Uint16Array(s2 * s2 * s2), [s2, s2, s2]);
    }

    cachedPadded = tgt;
  }

  // loop through neighbors (neighbor(0,0,0) is the chunk itself)
  // copying or zeroing voxel body/edge data into padded target array
  var loc = _vecs[0];
  var pos = _vecs[1];
  var size = _vecs[2];
  var tgtPos = _vecs[3];
  var posValues = _vecs[4];
  var sizeValues = _vecs[5];
  var tgtPosValues = _vecs[6];
  if (cs !== _cachedVecSize) {
    _cachedVecSize = cs;
    allocateVectors(cs, posValues, sizeValues, tgtPosValues);
  }

  for (var i = 0; i < 3; i++) {
    loc[0] = i;
    for (var j = 0; j < 3; j++) {
      loc[1] = j;
      for (var k = 0; k < 3; k++) {
        loc[2] = k;
        for (var n = 0; n < 3; n++) {
          var coord = loc[n];
          pos[n] = posValues[coord];
          size[n] = sizeValues[coord];
          tgtPos[n] = tgtPosValues[coord];
        }
        var nab = neighbors.get(i - 1, j - 1, k - 1);
        var nsrc = nab ? nab.voxels ?? nab : null;
        copyNdarrayContents(nsrc, tgt, pos, size, tgtPos);
      }
    }
  }
  return tgt;
};
var cachedPadded = ndarray(new Uint16Array(27), [3, 3, 3]);
var _vecs = new Array(10);
for (let i = 0; i < _vecs.length; i++) {
  _vecs[i] = emptyThreeVec.slice();
}

var _cachedVecSize;
function allocateVectors(size, posValues, sizeValues, tgtPosValues) {
  for (var i = 0; i < 3; i++) {
    posValues[i] = [size - 1, 0, 0][i];
    sizeValues[i] = [1, size, 1][i];
    tgtPosValues[i] = [0, 1, size + 1][i];
  }
}

export interface ChunkyInterface {
  _terrainDirty: boolean;
  _objectsDirty: boolean;
  _terrainMesh: Mesh;
  isDisposed: boolean;
  id: string | symbol;
  requestID: string;
  _maxMeshedNeighbors: number;
  _neighborCount: number;
  _neighbors: ndarray;
  _timesMeshed: number;
  voxels: ndarray;
  i: number;
  j: number;
  k: number;
  size: number;
  x: number;
  y: number;
  z: number;
  isFull: boolean;
  isEmpty: boolean;
}

export const createVoxelArray = (size) => {
  if (USE_SHARED_ARRAY_BUFFER) {
    const arr = new Uint16Array(
      new SharedArrayBuffer(size * size * size * Uint16Array.BYTES_PER_ELEMENT)
    );
    return ndarray(arr, [size, size, size]);
  } else {
    const arr = new Uint16Array(size * size * size);
    return ndarray(arr, [size, size, size]);
  }
};

/*
 *
 *      Padded voxel data assembler
 *
 * Takes the chunk of size n, and copies its data into center of an (n+2) ndarray
 * Then copies in edge data from neighbors, or if not available zeroes it out
 * Actual mesher will then run on the padded ndarray
 *
 */

export const scanVoxelData = (
  chunk: ChunkyInterface,
  solidLookup,
  opaqueLookup,
  objectMeshLookup,
  addObjectBlock
) => {
  // flags for tracking if chunk is entirely opaque or transparent
  let fullyOpaque = true;
  let fullyAir = true;
  let hasObj = false;

  const voxels = chunk.voxels;

  const len = voxels.shape[0];
  for (let i = 0; i < len; ++i) {
    for (let j = 0; j < len; ++j) {
      let index = voxels.index(i, j, 0);
      for (let k = 0; k < len; ++k, ++index) {
        // pull raw ID - could in principle be packed, so mask it
        const id = voxels.data[index];
        // skip air blocks
        if (id === 0) {
          fullyOpaque = false;
          continue;
        }

        fullyOpaque = fullyOpaque && opaqueLookup[id];
        fullyAir = false;
        // within unpadded view, handle object blocks and handlers
        if (addObjectBlock && objectMeshLookup(id)) {
          addObjectBlock(chunk, id, i, j, k);
          hasObj = true;
        }
      }
    }
  }

  chunk.isFull = fullyOpaque;
  chunk.isEmpty = fullyAir;

  chunk._terrainDirty = !chunk.isEmpty;
  chunk._objectsDirty = hasObj;
};
