import nengi from "nengi";
import { NengiIDType } from "../nengiTypes";

export class ChatMessage {
  constructor(body: string, sentAt: number, player: number, username: string) {
    this.body = body;
    this.player = player;
    this.sentAt = sentAt;
    this.username = username;
  }

  body: string;
  player: number;
  sentAt: number;
  username: string;

  static protocol = {
    body: { type: nengi.UTF8String },
    sentAt: { type: nengi.UInt32 },
    username: { type: nengi.UTF8String },
    player: { type: NengiIDType },
  };
}
