import {
  AbstractMesh,
  AssetsManager,
  Color3,
  Engine,
  InstancedMesh,
  Matrix,
  Mesh,
  MeshBuilder,
  PointerEventTypes,
  PointerInfo,
  Ray,
  RayHelper,
  Scene,
  StandardMaterial,
  TransformNode,
  UniversalCamera,
  UtilityLayerRenderer,
  Vector3,
  Viewport,
} from "@babylonjs/core";
import raycast from "fast-voxel-raycast";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";
import { getMesh } from "game/components/getMesh";
import { CurrentPlayer } from "game/CurrentPlayer";
import {
  ALIGNMENT_LABEL,
  FoilageAlignment,
  foilageDimensions,
  FoilageMesh,
  FoilageScaleBitValue,
  getAlignment,
} from "game/meshes/FoilageMesh";
import { PlayerSpawnStatus } from "game/PlayerSpawnStatus";
import Vec3 from "gl-vec3";
import { PlayerInventory } from "lib/Data/PlayerInventory";
import { getFoilageVersion } from "lib/FoilageLoader";
import debug from "lib/log";
import { WorldChunkLoader } from "lib/WorldChunkLoader";
import { compact } from "lodash";
import ndarray from "ndarray";
import { Engine as NoaEngine } from "noa-engine";
import { BlockID } from "shared/BlockID";
import {
  blockIdType,
  BlockIDType,
  getSide,
  isBlock,
  isFoilage,
  isSurface,
  URLSide,
} from "shared/blocks";
import {
  getItemVariant,
  items,
  ItemVariant,
  ItemVariantField,
} from "shared/items";

let pickerGlowAlpha = 1;
let pickerGlowFPS = 60;
let pickerGlowColor = new Color3(
  Math.sin(pickerGlowAlpha) / pickerGlowFPS,
  Math.sin(pickerGlowAlpha) / pickerGlowFPS,
  0.8 + Math.sin(pickerGlowAlpha) / pickerGlowFPS
);

const log = debug("[Picker]");

log.enabled = process.env.NODE_ENV !== "production";
let highlightPickerRotation = new Vector3(0, 0, 0);

const _currentItemVariant: ItemVariant = {
  variant: 0,
  rotation: 0,
  scale: 0,
  xNormal: 0,
  yNormal: 0,
  zNormal: 0,
};

export enum PickMode {
  single,
  multi,
}

export enum PickType {
  create,
  destroy,
  none,
}

let _hitResult = {
  _localPosition: new Float32Array(3),
  type: BlockIDType.none,
  position: new Float32Array(3),
  normal: new Float32Array(3),
};

let comparePos = new Float32Array([0, 0, 0]);
let highlightShape = new Int32Array(3);
let highlightOffsets = new Float32Array(3);

export type PickResult = {
  blockType: BlockID;
  x: number;
  y: number;
  z: number;
  urlSide: URLSide;
  variant: number;
};

type HighlightMesh = Mesh & {
  getAbsoluteSize: () => Vector3;
  getScalingVectorToFit: (vector: Vector3) => Vector3;
};

let box = {
  base: new Float32Array(3),
  max: new Float32Array(3),
};

var identity = Matrix.Translation(-0.6 / 2, 0, 0);

let oldHighlightPosition = new Float32Array(3);

let _deleteMaterial: StandardMaterial;

let red = Color3.Red();
const getDeleteMaterial = (scene: Scene) => {
  if (!_deleteMaterial) {
    _deleteMaterial = new StandardMaterial("delete-material", scene);
    _deleteMaterial.diffuseColor = red;
    _deleteMaterial.emissiveColor = red;
    _deleteMaterial.alpha = 0.35;
    _deleteMaterial.alphaMode = Engine.ALPHA_SCREENMODE;
    _deleteMaterial.freeze();
  }

  return _deleteMaterial;
};

const createLines = (scene) => {
  // outline
  var s = 0.5;
  const lines = Mesh.CreateLines(
    "hightlightLines",
    [
      new Vector3(s, s, 0),
      new Vector3(s, -s, 0),
      new Vector3(-s, -s, 0),
      new Vector3(-s, s, 0),
      new Vector3(s, s, 0),
    ],
    scene
  );

  lines.color = new Color3(1, 1, 1);

  return lines;
};

let _originalHighlightMesh;
const _getHighlightMesh = (noa, scene, instanceID: string): InstancedMesh => {
  let mesh: HighlightMesh = _originalHighlightMesh;

  if (!mesh) {
    mesh = Mesh.CreatePlane("highlight", 1.0, scene, true);
    mesh._enablePointerMoveEvents = false;

    _originalHighlightMesh = mesh;
    let hlm = noa.rendering.makeStandardMaterial(Symbol.for("highlightMat"));
    hlm.backFaceCulling = false;
    hlm.emissiveColor = new Color3(1, 1, 1);
    hlm.alpha = 0.2;
    mesh.material = hlm;

    mesh.setEnabled(false);
  }

  const meshInstance = mesh.createInstance(`highlight-${instanceID}`);
  meshInstance.setEnabled(true);
  return meshInstance;
};

export class Picker {
  utilityLayer: UtilityLayerRenderer;
  utilityScene: Scene;
  camera: UniversalCamera;
  noa: NoaEngine;
  constructor(
    noa,
    camera: UniversalCamera,
    currentPlayer: CurrentPlayer,
    inventory: PlayerInventory
  ) {
    this.camera = camera;
    this.noa = noa;

    this.scene = noa.rendering.getScene();

    this.singlePick = noa._localPick.bind(noa);
    this.noa._localPick = this.localPick;
    this.currentPlayer = currentPlayer;
    this.inventory = inventory;
    this.utilityLayer = UtilityLayerRenderer.DefaultKeepDepthUtilityLayer;
    this.utilityLayer.pickUtilitySceneFirst = false;

    this.utilityLayer.utilityLayerScene.autoClearDepthAndStencil = false;
    this.utilityScene = this.utilityLayer.utilityLayerScene;

    noa.rendering.highlightBlockFace = this.updateHighlightMesh;

    if (!this.hasConfiguredMultiMode) {
      this.scene.attachControl(true, true, true);
      this.scene.preventDefaultOnPointerDown = false;
      this.scene.preventDefaultOnPointerUp = false;
      this.hasConfiguredMultiMode = true;
    }

    if (this.scene.onPointerObservable.hasObservers()) {
      this.scene.onPointerObservable.remove(this.onPointerObservable);
    }

    document.body.addEventListener("pointermove", this.onMouseMove, {
      passive: true,
    });

    this.noa.blockTestDistance = CHUNK_SIZE;
    this.pickContainer = new TransformNode("", this.utilityScene);

    this.pickContainer.position = Vector3.Zero();
    this.pickContainer.setEnabled(false);
    this.pickContainer._enablePointerMoveEvents = false;

    this.linesMesh = createLines(this.scene);
    this.linesMesh.setParent(this.pickContainer);
    this.linesMesh.setEnabled(false);
    this.linesMesh.position = Vector3.Zero();

    this.cameraPickingRay = this.scene.createPickingRayInCameraSpace(
      0,
      0,
      this.camera
    );

    this.noa.rendering.addMeshToScene(this.linesMesh);
  }

  pickContainerEntityId: number;

  highlightRenderer = () => {};

  currentPlayer: CurrentPlayer;
  inventory: PlayerInventory;

  private _mode = PickMode.single;
  pickContainer: TransformNode;

  get mode() {
    return this._mode;
  }

  set mode(mode: PickMode) {
    if (mode === this._mode) {
      return;
    }

    this._mode = mode;
  }
  scene: Scene;
  _type = PickType.destroy;
  get type() {
    return this._type;
  }
  set type(value: PickType) {
    let wasEnabled = value !== PickType.none;
    this._type = value;

    if (this.currentPlayer.spawnStatus === PlayerSpawnStatus.spawned) {
      this.currentPlayer.meshContainer.translucent = value !== PickType.none;
    }

    this.pickContainer.setEnabled(this.isEnabled);

    if (wasEnabled !== this.isEnabled) {
      log("Enabled?", value);
      this.configureUtilityLayerScene();
    }
  }

  pickPoint = new Float32Array([0, 0, 0]);
  startPoint = new Float32Array([-1, -1, -1]);

  hasConfiguredMultiMode = false;

  _highlightMesh: Mesh;

  cameraPickingRay: Ray;
  onMouseMove = (event: PointerEvent) => {
    this.mouseX = event.pageX;
    this.mouseY = event.pageY;
  };

  foilageMeshMeasurer = (mesh: Mesh) => {
    return (
      mesh.metadata?.entityId === this.pickContainer?.parent?.metadata?.entityId
    );
  };

  parentBoundingMeasurer = (mesh) =>
    mesh?.metadata?.entityId === this.pickContainer?.metadata?.entityId;

  get highlightMesh() {
    if (!this._highlightMesh) {
      this._highlightMesh = _getHighlightMesh(
        this.noa,
        this.utilityScene,
        "main"
      );
      this._highlightMesh.parent = this.pickContainer;
      this._highlightMesh.position = Vector3.Zero();
      this._highlightMesh.setEnabled(false);
    }

    return this._highlightMesh;
  }

  highlightPosition = Vec3.create();
  globalHighlightPosition = new Float32Array(3);

  updateCost = () => {};

  updatePickContainerPosition = () => {
    if (this.pickContainer.parent) {
      const bounds = this.pickContainer.parent.getHierarchyBoundingVectors(
        true,
        this.foilageMeshMeasurer
      );

      this.pickContainer.position.set(0, bounds.max.y - bounds.min.y, 0);
    }
  };
  glowingMeshes = new Array<InstancedMesh>();

  pickerAssetsManager: AssetsManager | null = null;
  pickerAssetsManagerID = Symbol.for("picker-assets");
  needsRefresh = false;
  lastFoilageVersion: symbol | null = null;
  updateSingleMesh = (
    show: boolean,
    position: Array<number>,
    normals: Array<number>
  ) => {
    this.globalHighlightPosition.set(this.globalHighlightPosition);
    const item = this.currentPlayer.currentItem;
    const id = this.currentPlayer.equippedItemId;
    const idType = blockIdType(item?.blockID);
    const currentItemVariant = this.currentItemVariant;

    const currentVersion =
      idType === BlockIDType.foilage || idType === BlockIDType.surface
        ? getFoilageVersion(
            item.blockID,
            currentItemVariant,
            getAlignment(currentItemVariant)
          )
        : null;

    if (this.foilageMesh && this.lastFoilageVersion !== currentVersion) {
      this.disposeFoilageInstance();
    }

    if (!show || !this.isEnabled || !item) {
      return;
    }

    oldHighlightPosition.set(this.highlightPosition);

    // floored local coords for highlight mesh
    this.noa.globalToLocal(position, null, this.highlightPosition);

    // offset to avoid z-fighting, bigger whens camera is far away
    const dist = Vec3.dist(
      this.noa.camera._localGetPosition(),
      this.highlightPosition
    );

    var slop = 0.001 + 0.001 * dist;
    for (var i = 0; i < 3; i++) {
      if (normals[i] === 0) {
        this.highlightPosition[i] += 0.5;
      } else {
        this.highlightPosition[i] += normals[i] > 0 ? 1 + slop : -slop;
      }
    }

    let hasPositionChanged = Vec3.equals(
      oldHighlightPosition,
      this.highlightPosition
    );

    this.linesMesh.setEnabled(this.mode === PickMode.single && show);

    this.linesMesh.rotation.x = normals[1] ? Math.PI / 2 : 0;
    this.linesMesh.rotation.y = normals[0] ? Math.PI / 2 : 0;

    if (idType === BlockIDType.block) {
      this.pickContainer.rotation.setAll(0);
      this.highlightMesh.rotation.x = normals[1] ? Math.PI / 2 : 0;
      this.highlightMesh.rotation.y = normals[0] ? Math.PI / 2 : 0;
      this.highlightMesh.setEnabled(show);
    } else if (
      idType === BlockIDType.foilage ||
      idType === BlockIDType.surface
    ) {
      this.highlightMesh.setEnabled(false);

      if (this.loader.foilageLoader.isFoilageLoaded(id)) {
        this.updateFoilage(id, position, normals);
      } else {
        const _id = Symbol.keyFor(id);
        return this.loader.foilageLoader
          .loadRequiredMeshes([_id])
          .then(() =>
            this.updateFoilage(_id, this.lastPosition, this.lastNormals)
          );
      }
    }

    let parentSurfaceMesh: InstancedMesh | null =
      this.type !== PickType.destroy ? this.pickedMesh : null;

    if (parentSurfaceMesh) {
      if (parentSurfaceMesh !== this.pickContainer.parent) {
        hasPositionChanged = true;
        if (this.pickContainerEntityId) {
          if (this.foilageMesh) {
            this.foilageMesh.parent = null;
          }
          this.noa.ents.deleteEntity(this.pickContainerEntityId);
          this.pickContainerEntityId = null;
        }

        if (this.pickContainer.isDisposed()) {
          this.pickContainer = new TransformNode(
            "pickContainer",
            this.utilityScene
          );

          if (this.foilageMesh) {
            this.foilageMesh.parent = this.pickContainer;
          }
        }

        this.pickContainer.parent = parentSurfaceMesh;

        this.updatePickContainerPosition();
      }
    } else {
      if (this.pickContainer.parent) {
        this.pickContainer.parent = null;
        hasPositionChanged = true;
      }

      let width = 1,
        height = 1,
        depth = 1;

      this.noa.localToGlobal(
        this.highlightPosition,
        this.pickContainerPosition
      );

      if (!this.pickContainerEntityId) {
        // const offset = getOffset(item.blockID, this.currentItemVariant);
        this.pickContainerEntityId = this.noa.ents.add(
          this.pickContainerPosition,
          width,
          height,
          this.pickContainer,
          null,
          false,
          false
        );
      } else {
        if (hasPositionChanged) {
          if (this.foilageMesh) {
            const alignment = getAlignment(this.currentItemVariant);
            if (alignment === FoilageAlignment.bottom) {
              this.foilageMesh.position.setAll(0);
            } else {
              this.foilageMesh.position.set(0, -0.5, 0);
            }
          }

          this.pickContainer.position.set(
            this.highlightPosition[0],
            this.highlightPosition[1],
            this.highlightPosition[2]
          );

          this.noa.ents.setPosition(
            this.pickContainerEntityId,
            this.pickContainerPosition
          );
        }
      }
    }

    if (this.type === PickType.destroy) {
      let x = position[0] - normals[0];
      let y = position[1] - normals[1];
      let z = position[2] - normals[2];

      let pickedMesh = this.pickedMesh;
      const surfaceType = this.loader.getSurfaceType(x, y, z);
      const surfaceVariant = this.loader.getSurfaceVariant(x, y, z);
      const foilageType = this.loader.getFoilageType(x, y, z);
      const foilageVariant = this.loader.getFoilageVariant(x, y, z);

      let surfaceMesh: Mesh, foilageMesh: Mesh;

      if (pickedMesh) {
        if (isSurface(pickedMesh.metadata.blockID)) {
          surfaceMesh = pickedMesh;
        } else if (isFoilage(pickedMesh.metadata.blockID)) {
          foilageMesh = pickedMesh;
        }
      }

      if (!surfaceMesh) {
        surfaceMesh = this.loader.instanceById(
          surfaceType,
          getItemVariant(surfaceVariant),
          x,
          y,
          z
        );
      }

      if (!foilageMesh) {
        foilageMesh = this.loader.instanceById(
          foilageType,
          getItemVariant(foilageVariant),
          x,
          y,
          z
        );
      }

      if (
        this.deleteSurfaceBox &&
        this.deleteSurfaceBox.attached !== surfaceMesh
      ) {
        this.deleteSurfaceBox.dispose();
        this.deleteSurfaceBox = null;
      }

      if (
        this.deleteFoilageBox &&
        this.deleteFoilageBox.attached !== foilageMesh
      ) {
        this.deleteFoilageBox.dispose();
        this.deleteFoilageBox = null;
      }

      if (
        surfaceMesh &&
        (!this.deleteSurfaceBox ||
          this.deleteSurfaceBox.attached !== surfaceMesh)
      ) {
        const bounds = surfaceMesh.getHierarchyBoundingVectors(true);
        this.deleteSurfaceBox = MeshBuilder.CreateBox(
          "deleter",
          {
            width: Math.abs(bounds.max.x) - Math.abs(bounds.min.x),
            height: Math.abs(bounds.max.y) - Math.abs(bounds.min.y),
            depth: Math.abs(bounds.max.z) - Math.abs(bounds.min.z),
            updatable: false,
          },
          this.utilityScene
        );

        this.deleteSurfaceBox.isPickable = false;
        this.deleteSurfaceBox.attached = surfaceMesh;
        this.deleteSurfaceBox.material = getDeleteMaterial(this.utilityScene);
        this.deleteSurfaceBox._enablePointerMoveEvents = false;
        this.deleteSurfaceBox.renderingGroupId = 1;

        this.deleteSurfaceBox.position = Vector3.Center(bounds.max, bounds.min);
        this.deleteSurfaceBox.hasVertexAlpha = true;
        this.deleteSurfaceBox.freezeWorldMatrix();
        this.deleteSurfaceBox.freezeNormals();
      }

      if (
        foilageMesh &&
        (!this.deleteFoilageBox ||
          this.deleteFoilageBox.attached !== foilageMesh)
      ) {
        const bounds = foilageMesh.getHierarchyBoundingVectors(true);
        this.deleteFoilageBox = MeshBuilder.CreateBox(
          "deleter",
          {
            width: Math.abs(bounds.max.x) - Math.abs(bounds.min.x),
            height: Math.abs(bounds.max.y) - Math.abs(bounds.min.y),
            depth: Math.abs(bounds.max.z) - Math.abs(bounds.min.z),
            updatable: false,
          },
          this.utilityScene
        );

        this.deleteFoilageBox.isPickable = false;
        this.deleteFoilageBox.renderingGroupId = 1;
        this.deleteFoilageBox.position = Vector3.Center(bounds.max, bounds.min);
        this.deleteFoilageBox.attached = foilageMesh;
        this.deleteFoilageBox._enablePointerMoveEvents = false;
        this.deleteFoilageBox.hasVertexAlpha = true;
        this.deleteFoilageBox.material = getDeleteMaterial(this.utilityScene);
        this.deleteFoilageBox.freezeWorldMatrix();
        this.deleteFoilageBox.freezeNormals();
      }

      // const addSurfaceMesh =
      //   surfaceMesh && !this.deleteGlowLayer.hasMesh(surfaceMesh);
      // const addFoilageMesh =
      //   foilageMesh && !this.deleteGlowLayer.hasMesh(foilageMesh);
    }

    const hasVersion =
      idType === BlockIDType.foilage || idType === BlockIDType.surface;

    this.lastFoilageVersion = currentVersion;
  };

  deleteSurfaceBox: Mesh;
  deleteFoilageBox: Mesh;

  pickContainerPosition = new Float32Array(3);

  linesMesh: Mesh;
  lastRefresh = 0;
  refreshIfNeeded = (dt) => {
    this.lastRefresh = this.lastRefresh + dt;
    if (!this.needsRefresh && this.lastRefresh > 100) {
      return;
    }

    this.refreshMesh();
    this.needsRefresh = false;
    this.lastRefresh = 0;
  };

  surfaceEmissive = new Color3(
    Math.sin(1) / 16,
    Math.sin(1) / 16,
    0.2 + Math.sin(1) / 16
  );

  updateFoilage = (
    id: string,
    position: Array<number>,
    normals: Array<number>
  ) => {
    const item = this.currentPlayer.currentItem;

    if (this.currentPlayer.equippedItemId !== id || !item) {
      return;
    }

    if (!this.foilageMesh) {
      let _mesh = this.loader.foilageLoader.getMesh(
        item.blockID,
        this.currentItemVariant
      );

      if (!_mesh) {
        console.warn("MISSING FOILAGE", id);
        return;
      }

      let mesh: FoilageMesh = _mesh.instantiateHierarchy(this.pickContainer, {
        doNotInstantiate: true,
      });
      mesh.position.setAll(0);
      mesh._enablePointerMoveEvents = false;
      mesh.setEnabled(true);
      mesh.isVisible = true;

      mesh.isPickable = false;
      mesh.isNotMeasurable = true;
      mesh.metadata = { blockID: item.blockID, variant: item.variant };
      mesh.alwaysSelectAsActiveMesh = true;

      this.foilageMesh = mesh;
      this.foilageMesh.position =
        getAlignment(this.currentItemVariant) === FoilageAlignment.bottom
          ? Vector3.Zero()
          : new Vector3(0, -0.5, 0);

      mesh.isPickable = false;

      const [_, { x: width, y: height, z: depth }] = foilageDimensions(mesh);

      let glowy = null;
      const meshes = this.foilageMesh.getChildMeshes(false);
      for (let i = 0; i < meshes.length; i++) {
        if (!glowy && meshes[i].onAfterRenderObservable) {
          glowy = meshes[i];
        }

        meshes[i].isNotMeasurable = true;
        meshes[i].isPickable = false;
        meshes[i]._enablePointerMoveEvents = false;
        meshes[i].alwaysSelectAsActiveMesh = true;
        this.utilityScene.addMesh(meshes[i]);

        if (meshes[i].subMeshes && meshes[i].subMeshes.length > 0) {
          meshes[i].showBoundingBox = true;
        }
        if (meshes[i].material) {
          meshes[i].material = meshes[i].material.clone();
          meshes[i].material.emissiveColor = pickerGlowColor;
        }
      }

      if (mesh.material) {
        for (let mat of mesh.material.subMaterials) {
          mat.emissiveColor = pickerGlowColor;
        }
      }

      glowy.onAfterRenderObservable.add(() => {
        pickerGlowColor.set(
          Math.sin(pickerGlowAlpha) / pickerGlowFPS,
          Math.sin(pickerGlowAlpha) / pickerGlowFPS,
          0.8 + Math.sin(pickerGlowAlpha) / pickerGlowFPS
        );

        if (isFoilage(item.blockID)) {
          this.surfaceEmissive.copyFrom(pickerGlowColor);
        }

        pickerGlowAlpha -= 0.1;
      });

      log("Loaded mesh");
    }
    // const scaleOffset = isPinnedToWall(this.currentItemVariant) ? -1 : 1;

    // this.foilageMesh.scaling.set(1 * scaleOffset, 1, 1 * scaleOffset);
    // this.foilageMesh.scaling.set(
    //   isPinnedToWall(this.currentItemVariant) ? -1 : 1,
    //   1,
    //   isPinnedToWall(this.currentItemVariant) ? -1 : 1
    // );
  };

  flipIndex = 0;
  scaleIndex = 0;

  get currentItemVariant() {
    const currentItem = this.currentPlayer.currentItem;
    if (currentItem) {
      _currentItemVariant.variant = currentItem.variant;
    } else {
      _currentItemVariant.variant = 0;
    }

    _currentItemVariant.rotation = this.flipIndex || 0;
    _currentItemVariant.scale = this.scaleIndex || 0;
    _currentItemVariant.xNormal = this.lastNormals[0] + 1;
    _currentItemVariant.yNormal = this.lastNormals[1] + 1;
    _currentItemVariant.zNormal = this.lastNormals[2] + 1;

    return _currentItemVariant;
  }

  disposeFoilageInstance = () => {
    if (this.foilageMesh) {
      log("DISPOSE FOILAGE");
      while (this.glowingMeshes.length) {
        const mesh = this.glowingMeshes.pop();
        if (mesh.material) {
          const mat = mesh.material;
          if (mat.originalEmissive) {
            mat.emissiveColor = mat.originalEmissive;
          }
        }
      }
      this.pickContainer.parent = null;

      this.noa.rendering.removeMeshFromScene(this.foilageMesh);
      this.lastFoilageVersion = null;
      this.foilageMesh.dispose();
      this.foilageMesh = null;
    }
  };
  foilageMesh: FoilageMesh | null;

  hasSetStartPoint = false;
  highlightInstances = ndarray(new Array(0));
  globalStartPoint = new Int32Array([0, 0, 0]);
  highlightPositions: ndarray = ndarray(new Array(0));
  highlightStartPoint = new Int32Array([0, 0, 0]);
  highlightEndPoint = new Int32Array([0, 0, 0]);
  updateMultiMesh = (position: Array<number>, normals: Array<number>) => {
    let maxX = Math.max(position[0], this.globalStartPoint[0]);
    let minX = Math.min(position[0], this.globalStartPoint[0]);

    let minY = Math.min(position[1], this.globalStartPoint[1]);
    let maxY = Math.max(position[1], this.globalStartPoint[1]);

    let maxZ = Math.max(position[2], this.globalStartPoint[2]);
    let minZ = Math.min(position[2], this.globalStartPoint[2]);

    if (
      minX === this.highlightStartPoint[0] &&
      minY === this.highlightStartPoint[1] &&
      minZ === this.highlightStartPoint[2] &&
      maxX === this.highlightEndPoint[0] &&
      maxY === this.highlightEndPoint[1] &&
      maxZ === this.highlightEndPoint[2]
    ) {
      return;
    }

    let offsets = highlightOffsets;
    offsets.fill(0);

    const dist = Vec3.dist(
      this.noa.camera._localGetPosition(),
      this.highlightPosition
    );
    var slop = 0.001 + 0.001 * dist;
    for (var i = 0; i < 3; i++) {
      if (normals[i] === 0) {
        offsets[i] += 0.5;
      } else {
        offsets[i] += normals[i] > 0 ? 1 + slop : -slop;
      }
    }

    let highlightInstances: ndarray = this.highlightInstances;
    let highlightPositions: ndarray<Mesh> = this.highlightPositions;

    let needsReshape = false;

    const xShape = maxX + 1 - minX;
    const yShape = maxY + 1 - minY;
    const zShape = maxZ + 1 - minZ;

    if (xShape !== highlightShape[0]) {
      highlightShape[0] = xShape;
      needsReshape = true;
    }

    if (yShape !== highlightShape[1]) {
      highlightShape[1] = yShape;
      needsReshape = true;
    }

    if (zShape !== highlightShape[2]) {
      highlightShape[2] = zShape;
      needsReshape = true;
    }

    const length = xShape * yShape * zShape;
    if (length === 0) {
      this.clearHighlightInstances(0);
      return;
    } else if (this.highlightInstances?.data?.length ?? 0 > length) {
      for (let i = length; i < this.highlightInstances?.data?.length; i++) {
        if (this.highlightInstances.data[i]) {
          this.highlightInstances.data[i].setEnabled(false);
        }

        if (this.highlightPositions.data[i]) {
          this.highlightPositions.data[i].fill(undefined);
        }
      }
    }

    if (needsReshape) {
      if (
        this.highlightPositions?.data &&
        this.highlightPositions.data.length < length
      ) {
        this.highlightPositions.data.length = length;
      }

      this.highlightPositions = ndarray(
        this.highlightPositions.data ?? new Array(length),
        highlightShape
      );

      if (
        this.highlightInstances?.data &&
        this.highlightInstances.data.length < length
      ) {
        this.highlightInstances.data.length = length;
      }
      this.highlightInstances = ndarray(
        this.highlightInstances.data ?? new Array(length),
        highlightShape
      );

      highlightInstances = this.highlightInstances;
      highlightPositions = this.highlightPositions;
    }

    this.highlightStartPoint[0] = minX;
    this.highlightStartPoint[1] = minY;
    this.highlightStartPoint[2] = minZ;

    this.highlightEndPoint[0] = maxX;
    this.highlightEndPoint[1] = maxY;
    this.highlightEndPoint[2] = maxZ;

    highlightPickerRotation.x = normals[1] ? Math.PI / 2 : 0;
    highlightPickerRotation.y = normals[0] ? Math.PI / 2 : 0;
    highlightPickerRotation.z = normals[2] ? Math.PI / 2 : 0;

    let o = 0;
    for (let i = minX; i <= maxX; i++) {
      for (let j = minY; j <= maxY; j++) {
        for (let k = minZ; k <= maxZ; k++) {
          const index = highlightPositions.index(i - minX, j - minY, k - minZ);
          let _mesh: InstancedMesh = highlightInstances.data[index];

          if (this.type === PickType.destroy && !this.noa.getBlock(i, j, k)) {
            if (_mesh) {
              _mesh.setEnabled(false);
            }
            continue;
          }

          if (!_mesh) {
            _mesh = _getHighlightMesh(
              this.noa,
              this.utilityScene,
              `block-${o}`
            );
            highlightInstances.data[index] = _mesh;
            _mesh.enablePointerMoveEvents = false;
            _mesh.isPickable = false;
            _mesh.alwaysSelectAsActiveMesh = true;
            _mesh.doNotSyncBoundingInfo = true;
          }
          _mesh.setEnabled(true);
          highlightPositions.data[index] = [i, j, k];

          _mesh.rotation = highlightPickerRotation;
          _mesh.position.x = i + this.noa.worldOriginOffset[0] + offsets[0];
          _mesh.position.y = j + this.noa.worldOriginOffset[1] + offsets[1];
          _mesh.position.z = k + this.noa.worldOriginOffset[2] + offsets[2];
          o++;
        }
      }
    }

    this.highlightPositions = highlightPositions;
    this.highlightInstances = highlightInstances;
  };

  globalEndPoint = new Int32Array([0, 0, 0]);

  get isEnabled() {
    return this.type !== PickType.none;
  }

  lastPosition = new Float32Array([0, 0, 0]);
  lastNormals = new Int8Array(3);
  lastShow = false;
  updateHighlightMesh = (
    _show: boolean,
    position: Array<number>,
    normals: Array<number>
  ) => {
    if (this.currentPlayer.spawnStatus !== PlayerSpawnStatus.spawned) {
      this.lastShow = _show;

      if (position && normals) {
        this.lastPosition[0] = position[0];
        this.lastPosition[1] = position[1];
        this.lastPosition[2] = position[2];
        this.lastNormals.set(normals);
      }
      return;
    }

    let show = _show;

    let wasEnabled = this.pickContainer.isEnabled(false);

    if (!this.hasSetStartPoint && wasEnabled && show) {
      this.startPoint[0] = this.highlightMesh.absolutePosition[0];
      this.startPoint[1] = this.highlightMesh.absolutePosition[1];
      this.startPoint[2] = this.highlightMesh.absolutePosition[2];
      this.globalStartPoint[0] = position[0];
      this.globalStartPoint[1] = position[1];
      this.globalStartPoint[2] = position[2];
      this.globalEndPoint[0] = position[0];
      this.globalEndPoint[1] = position[1];
      this.globalEndPoint[2] = position[2];

      this.hasSetStartPoint = true;
    }

    if (position && normals) {
      this.lastNormals.set(normals);
      this.lastPosition[0] = position[0];
      this.lastPosition[1] = position[1];
      this.lastPosition[2] = position[2];
    }

    this.updateSingleMesh(show, position, normals);

    if (!show || !this.isEnabled) {
      this.lastShow = show;
      this.highlightMesh.setEnabled(false);
      return;
    }

    if (!this.hasSetStartPoint && !wasEnabled) {
      this.startPoint[0] = this.highlightMesh.absolutePosition[0];
      this.startPoint[1] = this.highlightMesh.absolutePosition[1];
      this.startPoint[2] = this.highlightMesh.absolutePosition[2];
      this.globalStartPoint[0] = position[0];
      this.globalStartPoint[1] = position[1];
      this.globalStartPoint[2] = position[2];
      this.globalEndPoint[0] = position[0];
      this.globalEndPoint[1] = position[1];
      this.globalEndPoint[2] = position[2];
      this.hasSetStartPoint = true;
    }

    if (this.isMultiSelecting) {
      this.updateMultiMesh(position, normals);
    }
    this.lastPosition[0] = position[0];
    this.lastPosition[1] = position[1];
    this.lastPosition[2] = position[2];
    this.lastNormals.set(normals);
    this.lastShow = true;
    this.updateCost();
  };

  refreshMesh = () => {
    this.updateCost();
    if (this.type !== PickType.destroy && this.deleteFoilageBox) {
      this.deleteFoilageBox.dispose();
      this.deleteFoilageBox = null;
    }

    if (this.type !== PickType.destroy && this.deleteSurfaceBox) {
      this.deleteSurfaceBox.dispose();
      this.deleteSurfaceBox = null;
    }

    this.updateHighlightMesh(
      this.lastShow,
      this.lastPosition,
      this.lastNormals
    );
  };

  singlePick: Function;

  localPick = (pos, vec, dist, blockIdTestFunction) => {
    return this.localMultiPick(pos, vec, dist, blockIdTestFunction);
  };
  lastMouseX = 0;
  lastMouseY = 0;
  mouseX = 0;
  mouseY = 0;
  lookDirection = new Float32Array(3);

  pickedMesh:
    | (Mesh & {
        blockID: BlockID;
        variant: number;
        globalX: number;
        globalY: number;
        globalZ: number;
      })
    | null = null;

  rayHelper: RayHelper;
  get engine() {
    return this.scene.getEngine();
  }
  origin = Vec3.create();

  hardwareScalingLevel: number;
  viewport: Viewport;

  meshPredicate = (mesh: AbstractMesh) => {
    if (this.currentPlayer.spawnStatus === PlayerSpawnStatus.spawned) {
      return (
        (mesh.isPickable || mesh._noaContainingChunk) &&
        mesh !== this.currentPlayer.meshContainer.mesh &&
        !mesh.isDescendantOf(this.currentPlayer.meshContainer.mesh)
      );
    } else {
      return mesh._noaContainingChunk || mesh.isPickable;
    }
  };

  isSurfaceMesh = (mesh: AbstractMesh) =>
    mesh instanceof InstancedMesh && isSurface(mesh.metadata?.blockID);

  onPointerObservable = (pointerInfo: PointerInfo) => {
    const item = this.currentPlayer.currentItem;

    // if (item && isFoilage(item.blockID)) {
    //   const surfacePick = this.scene.pick(
    //     pointerInfo.event.clientX,
    //     pointerInfo.event.clientY,
    //     this.isSurfaceMesh,
    //     true
    //   );

    //   if (surfacePick.hit) {
    //     pointerInfo.pickInfo = surfacePick;
    //   }
    // }

    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN:
        // console.log("POINTER DOWN");
        break;
      case PointerEventTypes.POINTERUP:
        break;
      case PointerEventTypes.POINTERMOVE:
        break;
      case PointerEventTypes.POINTERWHEEL:
        // console.log("POINTER WHEEL");
        break;
      case PointerEventTypes.POINTERPICK:
        // console.log("POINTER PICK");
        break;
      case PointerEventTypes.POINTERTAP:
        // console.log("POINTER TAP");
        break;
      case PointerEventTypes.POINTERDOUBLETAP:
        // console.log("POINTER DOUBLE-TAP");
        break;
    }

    return true;
  };

  private _highlightPosition = new Float32Array([0, 0, 0]);

  pick = (): Array<PickResult> => {
    const { currentPlayer, noa } = this;
    const { pickedMesh } = this;

    let item = items.get(this.currentPlayer.equippedItemId);

    if (
      !item ||
      !currentPlayer ||
      currentPlayer.spawnStatus !== PlayerSpawnStatus.spawned
    ) {
      return [];
    }

    const { blockID: destinationBlockID, variant } = item;

    const normal = this.lastNormals;

    let x = 0;
    let y = 0;
    let z = 0;

    let _x = 0;
    let _y = 0;
    let _z = 0;

    const dist = Vec3.dist(
      this.noa.camera._localGetPosition(),
      _hitResult._localPosition
    );

    var slop = 0.001 + 0.001 * dist;
    for (let i = 0; i < normal.length; i++) {
      if (normal[i] !== 0) {
        let offset = normal[i] > 0 ? 1 + slop : -slop;

        if (i === 0) {
          _x = Math.max(Math.min(Math.round(normal[i] + offset), 1), -1);
        } else if (i === 1) {
          _y = Math.max(Math.min(Math.round(normal[i] + offset), 1), -1);
        } else if (i === 2) {
          _z = Math.max(Math.min(Math.round(normal[i] + offset), 1), -1);
        }
      }
    }

    if (this.type === PickType.create) {
      x = _x;
      y = _y;
      z = _z;
    }

    const positions =
      this.mode === PickMode.multi
        ? compact(this.highlightPositions.data)
        : [this.lastPosition];

    let length = positions.length;

    const results = new Array(length);

    if (this.type === PickType.create) {
      let _variant = variant;

      if (this.foilageMesh) {
        let rotation = 0;

        if (getAlignment(this.currentItemVariant) === FoilageAlignment.bottom) {
          rotation = this.flipIndex;

          // if (rotation === 1) {
          //   rotation = FOILAGE_ROTATION.length - 1;
          // }
        }

        console.log({
          rotation,
          rotate: this.pickContainer.rotation.y,
          alignment: ALIGNMENT_LABEL[getAlignment(this.currentItemVariant)],
        });

        let scale = FoilageScaleBitValue.normal;
        if (this.scaleIndex) {
          scale = Math.min(this.scaleIndex, FoilageScaleBitValue.huge);
        }

        const __variant = new ItemVariantField({
          variant,
          rotation,
          scale,
          xNormal: _x + 1,
          yNormal: _y + 1,
          zNormal: _z + 1,
        });

        // console.log(rotation, roundedRads, this.pickContainer.rotation.y);
        _variant = __variant.value;
      }

      if (length === 1 && this.foilageMesh && this.pickContainer.parent) {
        const position = this.noa.ents.getPosition(
          this.pickContainer.parent.metadata.entityId
        );

        results[0] = {
          x: position[0],
          y: position[1],
          z: position[2],
          blockType: destinationBlockID,
          variant: _variant,
          urlSide: getSide(x, y, z),
        };
        log("Pick", results[0]);
      } else {
        for (let i = 0; i < length; i++) {
          results[i] = {
            x: positions[i][0] + x,
            y: positions[i][1] + y,
            z: positions[i][2] + z,
            blockType: destinationBlockID,
            variant: _variant,
            urlSide: getSide(x, y, z),
          };
        }

        log("Pick", results);
      }
    } else {
      let shouldExpandToNormals = false;

      for (let _i = 0; _i < positions.length; _i++) {
        let blockType = BlockID.air;
        if (positions[_i]) {
          blockType = this.loader.getBlock(
            positions[_i][0] + _x,
            positions[_i][1] + _y,
            positions[_i][2] + _z
          );
        }

        if (blockType !== BlockID.air && !isBlock(blockType)) {
          shouldExpandToNormals = true;
          break;
        }
      }

      if (shouldExpandToNormals) {
        x = _x;
        y = _y;
        z = _z;
      }

      for (let __i = 0; __i < positions.length; __i++) {
        results[__i] = {
          x: positions[__i][0] + x,
          y: positions[__i][1] + y,
          z: positions[__i][2] + z,
          blockType: BlockID.air,
          variant,
          urlSide: getSide(x, y, z),
        };
      }
    }

    return compact(results);
  };

  loader: WorldChunkLoader;

  testVoxel = (x: number, y: number, z: number) => {
    var id = this.loader.getBlock(
      x + this.noa.worldOriginOffset[0],
      y + this.noa.worldOriginOffset[1],
      z + this.noa.worldOriginOffset[2]
    );

    const didPass = this.blockTargetIdCheck(id);
    if (didPass) {
      _hitResult.type = blockIdType(id);
    }

    return didPass;
  };
  refreshPosition = new Float32Array(3);

  pickBox = box;

  checkCurrentPickPosition = () => {
    if (!this.foilageMesh || !this.currentPlayer.currentItem) {
      return true;
    }
    const bounds = this.pickContainer.getHierarchyBoundingVectors(true);

    const blockID = this.currentPlayer.currentItem.blockID;

    let ray = Ray.CreateNewFromTo(bounds.min, bounds.max);

    const pickResult = this.scene.pickWithRay(
      ray,
      this.foilagePickPredicate,
      true
    );

    return !pickResult.hit;
    // if (isSurface(blockID)) {
    //   for (let x = box.base[0]; x < box.max[0]; x++) {
    //     for (let y = box.base[1]; y < box.max[1]; y++) {
    //       for (let z = box.base[2]; z < box.max[2]; z++) {
    //         this.loader.getSurfaceType
    //       }
    //     }
    //   }
    // }
  };

  pickerMatrix = Matrix.Identity();
  surfacePickTest = (mesh) =>
    mesh.isPickable &&
    mesh.metadata &&
    mesh.metadata.entityId &&
    this.noa.ents.hasComponent(mesh.metadata.entityId, "parentable");
  deletePickTest = (mesh) =>
    mesh.isPickable &&
    mesh.metadata &&
    mesh.metadata.entityId &&
    this.noa.ents.hasComponent(mesh.metadata.entityId, "foilage");
  cameraParentPostiion = new Float32Array(3);
  localMultiPick = (pos, vec, dist, blockIdTestFunction) => {
    // if (this.type === PickType.none) {
    //   return null;
    // }
    const item = this.currentPlayer.currentItem;

    // do a raycast in local coords - result obj will be in global coords
    if (dist === 0) return null;
    var world = this.noa.world;

    // This is important!
    // You want globalPosition
    // This was the solution to that problem where picking was always just a little bit off.
    this.noa.rendering._camera.globalPosition.toArray(
      this.cameraParentPostiion
    );

    this.scene.createPickingRayToRef(
      this.mouseX,
      this.mouseY,
      identity,
      this.cameraPickingRay,
      this.camera,
      false
    );

    this.cameraPickingRay.direction.toArray(this.lookDirection);

    vec = this.lookDirection;
    pos = this.cameraParentPostiion;

    if (vec[0] === 0 && vec[1] === 0 && vec[2] === 0) {
      return null;
    }

    dist = dist || this.noa.blockTestDistance;
    var rpos = _hitResult._localPosition;
    var rnorm = _hitResult.normal;

    var hit = raycast(this.testVoxel, pos, vec, dist, rpos, rnorm);
    let mesh = null;
    if (
      this.type === PickType.destroy ||
      (this.type === PickType.create &&
        isFoilage(this.currentPlayer.currentItem?.blockID))
    ) {
      let pickTest;

      if (this.type === PickType.destroy) {
        pickTest = this.deletePickTest;
      } else {
        pickTest = this.surfacePickTest;
      }

      const pick = this.scene.pickWithRay(
        this.cameraPickingRay,
        pickTest,
        true
      );

      if (pick.pickedMesh) {
        const positionData = this.noa.ents.getPositionData(
          pick.pickedMesh.metadata.entityId
        );

        let canContinue = false;

        if (positionData) {
          if (hit) {
            canContinue =
              Vec3.dist(
                this.cameraParentPostiion,
                positionData._localPosition
              ) < Vec3.dist(this.cameraParentPostiion, rpos);
          } else {
            canContinue =
              Vec3.dist(
                this.cameraParentPostiion,
                positionData._localPosition
              ) < dist;
          }
        }

        if (canContinue) {
          const blockId = pick.pickedMesh.metadata.blockID;
          let _mesh = getMesh(this.noa, pick.pickedMesh.metadata.entityId);

          _hitResult._localPosition.set(positionData._localPosition);
          _hitResult.position.set(positionData.position);
          let variant;
          if (isFoilage(blockId)) {
            _hitResult.type = BlockIDType.foilage;
            variant = this.loader.getFoilageVariant(
              positionData.position[0],
              positionData.position[1],
              positionData.position[2]
            );
          } else if (isSurface(blockId)) {
            _hitResult.type = BlockIDType.surface;
            variant = this.loader.getSurfaceVariant(
              positionData.position[0],
              positionData.position[1],
              positionData.position[2]
            );
          }

          const item = getItemVariant(variant);

          _hitResult.normal[0] = item.xNormal - 1;
          _hitResult.normal[1] = item.yNormal - 1;
          _hitResult.normal[2] = item.zNormal - 1;

          this.pickedMesh = _mesh;
          return _hitResult;
        } else if (!hit) {
          this.pickedMesh = null;
        }
      }
    }

    if (!hit) {
      return null;
    }

    // position is right on a voxel border - adjust it so that flooring works reliably
    // adjust along normal direction, i.e. away from the block struck
    Vec3.scaleAndAdd(rpos, rpos, rnorm, 0.01);
    // add global result
    this.noa.localToGlobal(rpos, _hitResult.position);

    if (item && isFoilage(item.blockID)) {
      const id = this.noa.entities.getEntityIn(
        _hitResult.position[0],
        _hitResult.position[1],
        _hitResult.position[2],
        "parentable"
      );

      if (id) {
        mesh = getMesh(this.noa, id);
        _hitResult.type = BlockIDType.surface;
      }
    } else if (this.type === PickType.destroy && !mesh) {
      const entityId = this.noa.entities.getEntityIn(
        _hitResult.position[0],
        _hitResult.position[1],
        _hitResult.position[2],
        "foilage"
      );

      if (entityId) {
        let _mesh = getMesh(this.noa, entityId);
        if (_mesh) {
          const bounds = _mesh.getHierarchyBoundingVectors(true);
          if (
            this.cameraPickingRay.intersectsBoxMinMax(
              bounds.min,
              bounds.max,
              0.2
            )
          ) {
            _hitResult.position.set(this.noa.entities.getPosition(entityId));
            mesh = _mesh;
          }
        }
      }
    }

    if (mesh) {
      this.pickedMesh = mesh;
    } else if (this.pickedMesh) {
      this.pickedMesh = null;
    }

    return _hitResult;
  };

  findMeshAtCoordinate = (_x: number, _y: number, _z: number) => {
    const surfaceId = this.loader.getSurfaceType(_x, _y, _z);
    const foilageId = this.loader.getFoilageType(_x, _y, _z);

    if (surfaceId) {
      return this.loader.instanceById(
        surfaceId,
        getItemVariant(this.loader.getSurfaceVariant(_x, _y, _z)),
        _x,
        _y,
        _z
      );
    }

    if (!this.pickedMesh && foilageId) {
      return this.loader.instanceById(
        foilageId,
        getItemVariant(this.loader.getFoilageVariant(_x, _y, _z)),
        _x,
        _y,
        _z
      );
    }

    return null;
  };

  renderUtilityLayer = (scene) => {
    // this.utilityLayer.render();
  };

  get shouldUtilityLayerBeEnabled() {
    return this.type !== PickType.none;
  }

  isUtilityLayerEnabled = false;

  configureUtilityLayerScene = () => {
    if (this.isUtilityLayerEnabled !== this.shouldUtilityLayerBeEnabled) {
      if (this.isUtilityLayerEnabled) {
        this.scene.onAfterRenderObservable.remove(this.renderUtilityLayer);
        this.isUtilityLayerEnabled = false;
        this.utilityLayer.shouldRender = true;
      } else {
        this.scene.onAfterRenderObservable.add(this.renderUtilityLayer);
        this.isUtilityLayerEnabled = true;
        this.utilityLayer.shouldRender = false;
      }
    }
  };

  isMultiSelecting = false;
  startMultiSelect = () => {
    log("START");
    this.isMultiSelecting = true;
    this.mode = PickMode.multi;
    this.hasSetStartPoint = false;
    // this.startPoint.fill(-1);

    this.updateHighlightMesh(
      this.lastShow,
      this.lastPosition,
      this.lastNormals
    );
  };

  foilagePickPredicate = (mesh) => {
    return (
      this.pickContainer !== mesh &&
      !mesh.isDescendantOf(this.pickContainer) &&
      mesh.metadata &&
      mesh.metadata.entityId &&
      !mesh._noaContainingChunk
    );
  };

  endMultiSelect = () => {
    log("CANCEL");
    this.isMultiSelecting = false;
    this.hasSetStartPoint = false;
    // this.startPoint.fill(-1);

    this.cleanMultiSelect();
    this.mode = PickMode.single;
  };

  clearHighlightInstanceRange(from: number, to: number) {
    for (let i = from; i <= to; i++) {
      const instance = this.highlightInstances.data[i];
      if (!instance) {
        continue;
      }
      _originalHighlightMesh.removeInstance(instance);
      this.utilityScene.removeMesh(instance);
      instance.dispose();
    }
  }
  clearHighlightInstances = (newLength: number = 0) => {
    this.clearHighlightInstanceRange(0, this.highlightInstances.data.length);
    if (newLength > -1) {
      this.highlightInstances.data.length = newLength;
    }
  };

  cleanMultiSelect = () => {
    log("CLEAR MULTI");

    this.clearHighlightInstances();
    this.highlightPositions = ndarray(Array(0));
    this.globalStartPoint.set(this.globalEndPoint);
  };

  blockTargetIdCheck = (blockID) => {
    const isSolid = this.noa.registry.getBlockSolidity(blockID);

    return blockID > 0 && isBlock(blockID);
  };
}
