import ImageUploadProcessor from "./ImageUploadProcessor.worker";

let cbs = new Map<number, Function>();
let worker: Worker;
let id = 0;

export const warmWorker = () => {
  if (worker) {
    return;
  }

  worker = new ImageUploadProcessor();
  worker.addEventListener("message", ({ data: { id, ...data } }) => {
    if (cbs.has(id)) {
      const cb = cbs.get(id);
      cb(data);
      cbs.delete(id);
    }
  });
  worker.addEventListener("error", console.error);
};

const processImageUpload = (message) => {
  if (!worker) {
    warmWorker();
  }

  return new Promise((resolve, reject) => {
    id++;
    cbs.set(id, resolve);
    worker.postMessage({
      ...message,
      id,
    });
  });
};

export interface ImageUploadInterface {
  width: number;
  height: number;
  src: string;
  mimeType: string;
  lastUsed: number;
  thumbnail: Blob;
  originalSrc: string;
}

export class ImageUpload implements ImageUploadInterface {
  width: number;
  height: number;
  src: string;
  mimeType: string;
  lastUsed: number;
  originalSrc: string;
  thumbnail: Blob;

  static processFile(file: File) {
    return processImageUpload({ file });
  }

  static processURL(url: string) {
    return processImageUpload({ url });
  }
}
