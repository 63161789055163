import { AbstractMesh } from "@babylonjs/core";
import Engine from "noa-engine";

type ParentMeshState = {
  x: number;
  y: number;
  z: number;
  parent: AbstractMesh;
  mesh: AbstractMesh;
};
export default function (noa: Engine) {
  return {
    name: "parentMesh",

    order: 61,

    state: {
      x: 0,
      y: 0,
      z: 0,
      parent: null,
      mesh: null,
    } as ParentMeshState,

    onAdd: function (eid, state: ParentMeshState) {
      state.mesh.setParent(state.parent);
      state.mesh.position.set(state.x, state.y, state.z);
      state.mesh.onDisposeObservable.add(() => {
        noa.ents.deleteEntity(eid);
      });
      noa.rendering.addMeshToScene(state.mesh);
    },

    onRemove: function (eid, state: ParentMeshState) {
      noa.rendering.removeMeshFromScene(state.mesh);
    },
  };
}
