import { DynamicTexture } from "@babylonjs/core";

export class UsernameDynamicTexture extends DynamicTexture {
  font = `bold 64pt "Space Grotesk"`;
  color = "white";
  strokeColor = "black";

  drawUsername(text: string) {
    var size = this.getSize();
    const context = this.getContext();

    context.fillStyle = "transparent";
    context.fillRect(0, 0, size.width, size.height);

    context.font = this.font;
    context.textAlign = "left";
    let textSize = context.measureText(text);

    let fontSize = parseInt(this.font.replace(/\D/g, ""));
    let y = size.height / 2 + fontSize / 3.65;

    context.lineWidth = fontSize * 0.2;
    let x = (size.width - textSize.width + context.lineWidth) / 2;
    if (x < 0) {
      fontSize -= 12;
      this.font = `bold ${fontSize}pt "Space Grotesk"`;
      context.font = this.font;
      textSize = context.measureText(text);
      x = (size.width - textSize.width + context.lineWidth) / 2;
      if (x < 0) {
        fontSize -= 12;
        this.font = `bold ${fontSize}pt "Space Grotesk"`;
        context.font = this.font;
        textSize = context.measureText(text);
        x = (size.width - textSize.width + context.lineWidth) / 2;
      }
    }

    context.strokeStyle = this.strokeColor;
    context.strokeText(text, x, y);

    context.fillStyle = this.color;
    context.fillText(text, x, y);

    this.update(this.invertY);
  }
}
