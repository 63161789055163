import { Mesh, Octree, Scene, TransformNode, Vector3 } from "@babylonjs/core";
import "@babylonjs/core/Culling/Octrees/octreeSceneComponent";
import Engine from "noa-engine";
import { Chunk } from "noa-engine/lib/chunk";
import {
  ChunkOctreeBlock,
  clearMeshBlock,
  meshBlock,
} from "noa-engine/lib/ChunkOctreeBlock";
import { removeUnorderedListItem } from "noa-engine/lib/util";

const dynamicContentCheck = new WeakMap<Mesh, Boolean>();

const notInDynamicContent = (mesh: Mesh) => !dynamicContentCheck.has(mesh);
const inDynamicContent = (mesh: Mesh) => dynamicContentCheck.has(mesh);

export class ChunkOctree extends Octree<Mesh> {
  constructor() {
    super(ChunkOctreeBlock.onAddEntry, undefined, undefined);
    this.blocks = new Array(8);

    this.update(this.worldMin, this.worldMax, []);
  }

  chunkMeshes = new WeakMap<Chunk, Mesh>();

  worldMin = new Vector3(Infinity, Infinity, Infinity);
  worldMax = new Vector3(-Infinity, -Infinity, -Infinity);
  staticContent = new Set<Mesh>();

  addMeshes(meshes: Array<Mesh>) {
    for (let block of this.blocks) {
      block.addEntries(meshes);
    }

    for (let mesh of meshes) {
      this.staticContent.add(mesh);
      if (mesh.freezeNormals) {
        mesh.freezeNormals();
      }

      if (!mesh.isWorldMatrixFrozen) {
        mesh.freezeWorldMatrix();
      }
    }
    this.needsRebuild = true;
  }

  addDynamicContent(mesh: Mesh | TransformNode) {
    if (
      typeof mesh.getTotalVertices === "function" &&
      !dynamicContentCheck.has(mesh)
    ) {
      this.dynamicContent.push(mesh);
    } else if (dynamicContentCheck.has(mesh)) {
      return;
    }

    const children = mesh.getChildMeshes(false, notInDynamicContent);
    if (children.length > 0) {
      this.dynamicContent.push(...children);
      for (let child of children) {
        dynamicContentCheck.set(child, true);
      }
    }

    dynamicContentCheck.set(mesh, true);
  }

  intersects(sphereCenter, sphereRadius) {
    console.log("SPHERE");
    return super.intersects(sphereCenter, sphereRadius);
  }

  intersectsRay(ray) {
    console.log("RAY");
    return super.intersectsRay(ray);
  }

  removeDynamicContent(mesh: Mesh | TransformNode) {
    if (mesh instanceof TransformNode) {
      const children = mesh.getChildMeshes(false, inDynamicContent);
      for (let child of children) {
        removeUnorderedListItem(this.dynamicContent, child);
        dynamicContentCheck.delete(child);
      }
    } else if (dynamicContentCheck.has(mesh)) {
      removeUnorderedListItem(this.dynamicContent, mesh);
      dynamicContentCheck.delete(mesh);
    }
  }

  isFirstChunk = true;
  addChunk(chunk: Chunk, mesh: Mesh) {
    const existingMesh = this.chunkMeshes.get(chunk);

    const size = mesh.getBoundingInfo().boundingBox;

    this.worldMin.minimizeInPlace(size.minimumWorld);
    this.worldMax.maximizeInPlace(size.maximumWorld);

    if (this.isFirstChunk) {
      this.update(this.worldMin, this.worldMax, [mesh]);
      this.isFirstChunk = false;
    } else if (existingMesh) {
      this.replaceMesh(existingMesh, mesh);
    } else {
      this.addStaticContent(mesh);
    }

    if (chunk.attachedMeshes) {
      for (let mesh of chunk.attachedMeshes) {
        if (!this.hasStaticContent(mesh)) {
          this.addStaticContent(mesh);
        }
      }
    }

    this.chunkMeshes.set(chunk, mesh);
  }

  needsRebuild = false;

  tick = () => {
    if (this.needsRebuild) {
      this.rebuild();
      this.needsRebuild = false;
    }
  };

  hasStaticContent(mesh: Mesh) {
    return meshBlock.has(mesh);
  }

  hasDynamicContent(mesh: Mesh) {
    return dynamicContentCheck.has(mesh);
  }

  addMesh(entry) {
    super.addMesh(entry);
    if (!entry.isWorldMatrixFrozen) {
      if (entry.freezeNormals) {
        entry.freezeNormals();
      }
      entry.freezeWorldMatrix();
    }

    this.needsRebuild = true;
  }

  replaceMesh(from: Mesh, to: Mesh) {
    // for (let block of this.blocks) {
    //   const index = block.entries.indexOf(from);

    //   if (index > -1) {
    //     block.entries[index] = to;
    //   }

    //   if (block.blocks && block.blocks.length > 0) {
    //     for (let _block of block.blocks) {
    //       const index = _block.entries.indexOf(from);

    //       if (index > -1) {
    //         _block.entries[index] = to;
    //       }
    //     }
    //   }
    // }

    // this.staticContent.add(to);
    this.removeMesh(from);
    this.addMesh(to);
  }

  addStaticContent(entry: Mesh | TransformNode) {
    if (entry instanceof Mesh) {
      this.addMesh(entry);
    } else if (!entry.isWorldMatrixFrozen) {
      entry.freezeWorldMatrix();
    }

    if (entry._children) {
      for (let child of entry._children) {
        if (child instanceof Mesh) {
          this.addStaticContent(child);
        }
      }
    }
  }

  removeMesh(entry) {
    super.removeMesh(entry);
    this.staticContent.delete(entry);
  }

  update(worldMin, worldMax, entries) {
    if (worldMin) {
      this.worldMin.minimizeInPlace(worldMin);
    }

    if (worldMax) {
      this.worldMax.maximizeInPlace(worldMax);
    }

    clearMeshBlock();

    ChunkOctreeBlock._CreateBlocks(
      this.worldMin,
      this.worldMax,
      entries,
      512,
      0,
      2,
      this,
      this._creationFunc
    );
  }

  noa: Engine;
  scene: Scene;
  rebuild() {
    if (!this.scene) {
      this.scene = this.noa.rendering.getScene();
    }

    const shouldBlock = !this.scene.blockfreeActiveMeshesAndRenderingGroups;

    if (shouldBlock) {
      this.scene.blockfreeActiveMeshesAndRenderingGroups = true;
    }

    this.update(null, null, Array.from(meshBlock.values()));

    if (shouldBlock) {
      this.scene.blockfreeActiveMeshesAndRenderingGroups = false;
    }
  }

  removeChunk(chunk: Chunk) {
    const mesh = this.chunkMeshes.get(chunk);
    if (!mesh) {
      return;
    }

    this.removeMesh(mesh);
  }
}
