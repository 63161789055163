import nengi from "nengi";
import {
  NengiBlockCoordinate,
  NengiBlockType,
  NengiIDType,
  PlaceBlockInterface,
  SetBlockProtocol,
} from "../nengiTypes";
import { SetBlockErrorCode } from "./SetBlockMessage";

export class BulkSetBlockMessage {
  constructor(
    blocks: Array<PlaceBlockInterface> | any,
    error: SetBlockErrorCode = SetBlockErrorCode.none
  ) {
    if (typeof blocks.length === "number") {
      this.x = new Int32Array(blocks.length);
      this.y = new Int32Array(blocks.length);
      this.z = new Int32Array(blocks.length);
      this.blockType = new Uint16Array(blocks.length);
      this.urlSide = new Uint8Array(blocks.length);
      this.url = new Array(blocks.length);
      this.variant = new Uint32Array(blocks.length);

      for (let i = 0; i < blocks.length; i++) {
        this.x[i] = blocks[i].x;
        this.y[i] = blocks[i].y;
        this.z[i] = blocks[i].z;
        this.variant[i] = blocks[i].variant;
        this.blockType[i] = blocks[i].blockType;
        this.urlSide[i] = blocks[i].urlSide;
        this.url[i] = blocks[i].url;
      }
    } else {
      this.x = blocks.x;
      this.y = blocks.y;
      this.z = blocks.z;
      this.blockType = blocks.blockType;
      this.urlSide = blocks.urlSide;
      this.variant = blocks.variant;
      this.url = blocks.url;
    }

    this.error = error;
  }

  x: Int32Array;
  y: Int32Array;
  z: Int32Array;
  variant: Uint32Array;
  blockType: Uint16Array;
  urlSide: Uint8Array;
  url: Array<String>;
  player: number;

  static protocol = {
    error: { type: nengi.UInt8 },
    x: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    y: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    z: { type: NengiBlockCoordinate, indexType: nengi.UInt12 },
    blockType: { type: NengiBlockType, indexType: nengi.UInt12 },
    variant: { type: NengiBlockType, indexType: nengi.UInt12 },
    urlSide: { type: SetBlockProtocol.urlSide.type, indexType: nengi.UInt12 },
    url: { type: SetBlockProtocol.url.type, indexType: nengi.UInt12 },
    player: { type: NengiIDType },
  };
}

export default BulkSetBlockMessage;
