import nengi from "nengi";
import { NengiIDType } from "../nengiTypes";

export class PlayerJoinedChatMessage {
  constructor(username: string, color: string, player: number) {
    this.username = username;
    this.player = player;
    this.color = color;
  }

  username: string;
  player: number;
  color: string;

  static protocol = {
    username: { type: nengi.UTF8String },
    color: { type: nengi.ASCIIString },
    player: { type: NengiIDType },
  };
}
