import nengi from "nengi";
import nengiConfig from "shared/nengiConfig";
import { WebSocketClient } from "./WebSocketClient";

export const client = new nengi.Client(nengiConfig, 100);
let retryer = new WebSocketClient(client);
client.retryer = retryer;

// /* create hooks for any entity create, delete, and watch properties */
// clientHookAPI(client, createHooks(state));

/* on('message::AnyMessage', msg => { }) */

export function getClient() {
  return client;
}

let ids = new Map();

export function startClient(
  networkEmitter,
  pendingChunks: Map<string, ndarray>
) {
  let websocketHost = process.env.WEBSOCKET_HOST;

  client.connect(websocketHost);
}
