import Head from "next/head";

import { Game } from "../components/Game";

const Home = () => (
  <main className="container">
    <Game />

    <style jsx>{`
      main {
        padding: 0;
        margin: 0;
        background-color: rgba(0, 0, 0, 1);
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
      }
    `}</style>
  </main>
);

export default Home;
