import { Scalar } from "@babylonjs/core";
import chroma from "chroma-js";
import PlaceholderTileIcon from "components/WorldMap/UndiscoveredTile.png";
import PlaceholderTileIcon2x from "components/WorldMap/UndiscoveredTile@2x.png";
import PlaceholderTileIcon3x from "components/WorldMap/UndiscoveredTile@3x.png";
import { CurrentPlayer } from "game/CurrentPlayer";
import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { WorldMapInterface } from "lib/Data/WorldMapStore";
import ndarray from "ndarray";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";

let placeholderTileIamge: HTMLImageElement;

if (typeof window !== "undefined") {
  placeholderTileIamge = new Image(CHUNK_SIZE, CHUNK_SIZE);
  placeholderTileIamge.src = PlaceholderTileIcon3x;
  placeholderTileIamge.srcset = `${PlaceholderTileIcon} 1x, ${PlaceholderTileIcon2x} 2x, ${PlaceholderTileIcon3x} 3x`;
}

export class MapPlayer {
  x: number;
  z: number;
  mapX: number;
  mapY: number;
  offsetX: number;
  xIndex: number;
  offsetY: number;
  zIndex: number;
  username: string;
  isCurrentPlayer: boolean;
  rotation: number = 0;
  color: string;

  static fromPlayer(player: any) {
    const map = new MapPlayer();
    map.update(player);
    return map;
  }

  update(player: any) {
    this.x = player.position[0];
    this.z = player.position[2];
    this.isCurrentPlayer = player instanceof CurrentPlayer;
    if (this.isCurrentPlayer) {
      this.rotation = player.rotation.y;
    }
    this.username = player.username;
    this.color = player?.remoteEntity?.color;
  }
}

let PLAYER_ARROW_WIDTH = 16;
let PLAYER_ARROW_HEIGHT = 22;

const EMPTY_IMAGE_SRC = "//:0";

let playerArrowPath: Path2D;

let CIRCLE_RADIUS = PLAYER_ARROW_HEIGHT / 2;

const PLAYER_ARROW_PATH_STRING = `M9.18622 0.94011L15.6604 21.75H0.354221L7.76341 0.91165C8.00491 0.232447 8.97207 0.25179 9.18622 0.94011Z`;
const playerGradients = new Map<MapPlayer, CanvasFillStrokeStyles>();
const playerArrows = new Map<MapPlayer, HTMLImageElement | ImageBitmapSource>();

export let playerImageCanvas: HTMLCanvasElement | OffscreenCanvas,
  playerImageCanvasCtx:
    | CanvasRenderingContext2D
    | OffscreenCanvasRenderingContext2D;

export let isBusyDrawingPlayerArrow = false;

let currentPlayerArrow;
export const getPlayerArrowImage = (
  color: string,
  isCurrentPlayer: boolean,
  scale: number,
  cache: PlayerArrowCache
): Promise<ImageBitmap | HTMLImageElement> | ImageBitmap | HTMLImageElement => {
  let playerArrow;

  const cacheKey = isCurrentPlayer
    ? `c_${color}@${scale}`
    : `${color}@${scale}`;
  playerArrow = cache.get(cacheKey);

  if (playerArrow) {
    return playerArrow;
  }

  let size;

  if (!playerImageCanvas) {
    size = Math.hypot(
      PLAYER_ARROW_WIDTH * scale * 1.1,
      PLAYER_ARROW_HEIGHT * scale * 1.1
    );
    if (typeof OffscreenCanvas === "undefined") {
      playerImageCanvas = document.createElement("canvas");
      playerImageCanvas.width = size;
      playerImageCanvas.height = size;
      playerImageCanvasCtx = playerImageCanvas.getContext("2d");
      playerImageCanvasCtx.imageSmoothingEnabled = true;
    } else {
      playerImageCanvas = new OffscreenCanvas(size, size);
      playerImageCanvasCtx = playerImageCanvas.getContext("2d");
      playerImageCanvasCtx.imageSmoothingEnabled = true;
    }
  } else {
    size = Math.hypot(
      PLAYER_ARROW_WIDTH * scale * 1.1,
      PLAYER_ARROW_HEIGHT * scale * 1.1
    );
  }

  if (isBusyDrawingPlayerArrow) {
    return null;
  }

  if (size !== playerImageCanvas.width) {
    playerImageCanvas.width = size;
    playerImageCanvas.height = size;
  }

  isBusyDrawingPlayerArrow = true;

  const ctx = playerImageCanvasCtx;
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  ctx.resetTransform();

  ctx.beginPath();
  const lineWidth = scale > 1 ? 1.5 * scale : 2;

  // if (isFirefox()) {
  //   if (isCurrentPlayer) {
  //     ctx.scale(scale * 0.8, scale * 0.8);
  //   } else {
  //     ctx.scale(scale * 0.8, scale * 0.8);
  //   }
  //   // ctx.translate(ctx.canvas.width * -0.025, 0);
  // } else {
  if (isCurrentPlayer) {
    ctx.translate(lineWidth + 1 * scale, lineWidth + 1 * scale);
    ctx.scale(scale, scale);
  } else {
    ctx.translate(0, ctx.canvas.height);
    ctx.scale(scale, -scale);
  }
  ctx.translate(ctx.canvas.width * 0.05, 0);
  // }

  ctx.fillStyle = getPlayerGradient(color, ctx);
  ctx.shadowBlur = 1 * scale;
  ctx.shadowColor = "rgba(0,0,0,0.25)";
  ctx.strokeStyle = "black";
  ctx.lineWidth = lineWidth;

  if (isCurrentPlayer) {
    if (!playerArrowPath) {
      playerArrowPath = new Path2D(PLAYER_ARROW_PATH_STRING);
    }

    ctx.stroke(playerArrowPath);
  } else {
    ctx.beginPath();
    ctx.arc(CIRCLE_RADIUS + 2, CIRCLE_RADIUS + 2, CIRCLE_RADIUS, 0, 359);
    ctx.stroke();
  }

  ctx.strokeStyle = "white";
  ctx.lineWidth = 1 * scale;
  if (isCurrentPlayer) {
    ctx.stroke(playerArrowPath);
  } else {
    ctx.stroke();
  }

  if (isCurrentPlayer) {
    ctx.fill(playerArrowPath);
  } else {
    ctx.fill();
  }
  ctx.shadowBlur = 0;
  ctx.shadowColor = null;
  ctx.fillStyle = null;
  ctx.strokeStyle = null;
  ctx.closePath();

  if (playerImageCanvas.transferToImageBitmap) {
    const image = playerImageCanvas.transferToImageBitmap();

    cache.set(cacheKey, image);

    isBusyDrawingPlayerArrow = false;

    return image;
  } else if (USE_IMAGE_BITMAP) {
    return createImageBitmap(playerImageCanvas).then((image) => {
      isBusyDrawingPlayerArrow = false;

      cache.set(cacheKey, image);

      return image;
    }, console.error);
  } else {
    isBusyDrawingPlayerArrow = true;
    return new Promise((resolve, reject) => {
      playerImageCanvas.toBlob(
        (blob) => {
          const image = new Image(
            playerImageCanvas.width,
            playerImageCanvas.height
          );
          image.onload = () => {
            cache.set(cacheKey, image);

            image.width = image.naturalWidth;
            image.height = image.naturalHeight;

            resolve(image);
            isBusyDrawingPlayerArrow = false;
          };

          image.src = URL.createObjectURL(blob);
        },
        "image/png",
        1
      );
    });
  }
};

type PlayerArrowCache = Map<MapPlayer, HTMLImageElement | ImageBitmapSource>;

const drawPlayerArrow = (
  x: number,
  y: number,
  isCurrentPlayer: boolean,
  ctx: CanvasRenderingContext2D,
  scale: number,
  color: string,
  cache: PlayerArrowCache,
  rotation = 0
) => {
  const arrowImage = getPlayerArrowImage(color, isCurrentPlayer, scale, cache);
  if (!arrowImage || arrowImage instanceof Promise) {
    return false;
  }

  if (rotation || isCurrentPlayer) {
    const _x = x;
    const _y = y;

    ctx.resetTransform();
    ctx.translate(_x, _y);
    if (isCurrentPlayer) {
      ctx.scale(1, -1);
    }

    ctx.rotate(rotation);

    ctx.drawImage(
      arrowImage,
      -arrowImage.width / 2,
      -arrowImage.height / 2,
      arrowImage.width,
      arrowImage.height
    );
    ctx.resetTransform();
  } else {
    const _x = x;
    const _y = y;

    ctx.resetTransform();
    ctx.translate(_x, _y);

    ctx.drawImage(
      arrowImage,
      -arrowImage.width / 2,
      -arrowImage.height / 2,
      arrowImage.width,
      arrowImage.height
    );
    ctx.resetTransform();
  }

  return true;
};

const getPlayerGradient = (_color: string, ctx: CanvasRenderingContext2D) => {
  if (playerGradients.has(_color)) {
    return playerGradients.get(_color);
  }

  const color = chroma(_color, "hex");

  const lumin = color.luminance();
  let topColor, bottomColor, middleColor;

  if (lumin > 0.5) {
    topColor = color.darken(0.25).css();
    middleColor = chroma.mix(color, "#3083FF", 0.1).css();
    bottomColor = color.css();
  } else {
    bottomColor = color.darken(0.25).css();
    middleColor = chroma.mix(color, "#3083FF", 0.1).css();
    topColor = color.css();
  }

  const gradient = ctx.createRadialGradient(
    PLAYER_ARROW_WIDTH / 2,
    PLAYER_ARROW_HEIGHT / 2,
    Math.max(PLAYER_ARROW_HEIGHT / 2, PLAYER_ARROW_WIDTH / 2),
    PLAYER_ARROW_WIDTH / 4,
    PLAYER_ARROW_HEIGHT / 4,
    Math.max(PLAYER_ARROW_HEIGHT / 4, PLAYER_ARROW_WIDTH / 4)
  );
  gradient.addColorStop(0, topColor);
  gradient.addColorStop(0.5, middleColor);
  gradient.addColorStop(1, bottomColor);
  playerGradients.set(_color, gradient);

  return gradient;
};

export const dist = (end: number, begin: number) => {
  const max = Math.max(end, begin);
  const min = Math.min(end, begin);
  if (Scalar.Sign(max) === Scalar.Sign(min)) {
    return Math.abs(Math.abs(min) - Math.abs(max));
  } else {
    return Math.abs(Math.abs(min) + Math.abs(max));
  }
};
export class RenderMapTileGroup {
  drawnPlayers = new Map<MapPlayer, string>();
  worldMap: WorldMapInterface;
  centerChunkXOffset: number;
  centerCHunkYOffset: number;
  worldCenterX: number;
  drawnChunks: ndarray;
  worldCenterY: number;
  playerArrowCache = new Map<any, string>();
  renderGrid = false;

  renderPlayer = (
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    hasClearedPlayerCtx: boolean,
    draw: boolean,
    objectScale: number,
    player: MapPlayer,
    x: number,
    y: number,
    cs: number,
    xIndex: number,
    zIndex: number
  ) => {
    const worldX = Math.floor(player.x);
    const worldZ = Math.floor(player.z);
    const relativeX =
      (((worldX % CHUNK_SIZE) + CHUNK_SIZE) % CHUNK_SIZE) *
      (cs / CHUNK_SIZE) *
      this.xTranslatePlayer;
    const relativeY =
      (((worldZ % CHUNK_SIZE) + CHUNK_SIZE) % CHUNK_SIZE) *
      (cs / CHUNK_SIZE) *
      this.yTranslatePlayer;
    const playerX = x + relativeX;
    const playerY = y + relativeY;

    const lastKey = this.drawnPlayers.get(player);
    const key = `${playerX}|${playerY}|${player.rotation}`;

    if (!draw) {
      return key !== lastKey;
    }

    if (key !== lastKey || hasClearedPlayerCtx) {
      if (!hasClearedPlayerCtx) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        hasClearedPlayerCtx = true;
        this.drawnPlayers.clear();
      }
      const height =
        (player.isCurrentPlayer ? PLAYER_ARROW_HEIGHT : CIRCLE_RADIUS) *
        objectScale;
      const width =
        (player.isCurrentPlayer ? PLAYER_ARROW_WIDTH : CIRCLE_RADIUS) *
        objectScale;
      player.xIndex = xIndex;
      player.zIndex = zIndex;
      player.mapX = playerX;
      player.mapY = playerY;
      player.offsetY = relativeY;
      player.offsetX = relativeX;

      const didDraw = drawPlayerArrow(
        playerX,
        playerY,
        player.isCurrentPlayer,
        ctx,
        // this.hardwareScalingLevel * 0.85,
        objectScale,
        player.color,
        this.playerArrowCache,
        player.rotation
      );

      if (didDraw) {
        this.drawnPlayers.set(player, key);
      }
    }
  };

  imgPool: ndarray;
  blobMap = new WeakMap<Blob, string>();
  imageBlobAssociation = new WeakMap<HTMLImageElement, Blob>();

  resetImage = (image: HTMLImageElement) => {
    const blob = this.imageBlobAssociation.get(image);

    if (blob) {
      const url = this.blobMap.get(blob);

      if (url) {
        URL.revokeObjectURL(url);
        this.blobMap.delete(blob);
      }

      this.imageBlobAssociation.delete(image);

      if (image.src && image.src !== EMPTY_IMAGE_SRC) {
        image.src = EMPTY_IMAGE_SRC;
      }
    }
  };
  resetImagePool = () => {
    if (this.isImagePoolClean) {
      return;
    }

    this.imgPool.data.forEach(this.resetImage);
    this.isImagePoolClean = true;
  };

  isImagePoolClean = true;
  isMinimap = false;
  centerChunkZIndex: number;
  centerChunkXIndex: number;

  chunkXPositions: ndarray;
  chunkZPositions: ndarray;
  xTranslatePlayer = 1;
  yTranslatePlayer = 1;
  showPlaceholderIcon = false;
  endX: number;
  endY: number;

  render(
    draw: boolean = true,
    currentChunkX: number,
    currentChunkZ: number,
    minChunkX: number,
    minChunkZ: number,
    maxChunkZ: number,
    maxChunkX: number,
    cs: number,
    players: Array<MapPlayer>,
    forceDraw: boolean = false,
    objectScale: number,
    playersCtx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
    xOffset: number,
    yOffset: number,
    canUpdatePlayers = true
  ) {
    let hasClearedPlayerCtx = false;
    let chunkXOffset = -1;
    let chunkZOffset = -1;

    let xSize = dist(maxChunkX, minChunkX);
    let zSize = dist(maxChunkZ, minChunkZ);

    if (xSize > 0) {
      xSize++;
    }

    if (zSize > 0) {
      zSize++;
    }

    const totalSize = xSize * zSize;
    if (!this.drawnChunks) {
      this.drawnChunks = ndarray(new Array(totalSize), [xSize, zSize]);
      this.chunkXPositions = ndarray(new Array(totalSize), [xSize, zSize]);
      this.chunkZPositions = ndarray(new Array(totalSize), [xSize, zSize]);
    } else if (
      this.drawnChunks.shape[0] !== xSize ||
      this.drawnChunks.shape[1] !== zSize
    ) {
      this.drawnChunks.data.length = totalSize;
      this.drawnChunks = ndarray(this.drawnChunks.data, [xSize, zSize]);
      this.chunkXPositions.data.length = totalSize;
      this.chunkXPositions = ndarray(this.chunkXPositions.data, [xSize, zSize]);
      this.chunkZPositions.data.length = totalSize;
      this.chunkZPositions = ndarray(this.chunkZPositions.data, [xSize, zSize]);
    }

    let needsResetImgPool = forceDraw;
    if (
      !USE_IMAGE_BITMAP &&
      (!this.imgPool ||
        this.imgPool.shape[0] !== xSize ||
        this.imgPool.shape[1] !== zSize ||
        this.imgPool.data.length !== totalSize)
    ) {
      if (this.imgPool) {
        this.resetImagePool();
        needsResetImgPool = false;

        this.imgPool.data.length = totalSize;

        this.imgPool = ndarray(this.imgPool.data, [xSize, zSize]);
      } else {
        this.imgPool = ndarray(new Array(totalSize), [xSize, zSize]);
      }

      for (let i = 0; i < this.imgPool.data.length; i++) {
        if (!this.imgPool.data[i]) {
          const image = new Image(cs, cs);
          this.imgPool.data[i] = image;
        }
      }
    }

    if (needsResetImgPool && !USE_IMAGE_BITMAP) {
      this.resetImagePool();
      needsResetImgPool = false;
    }

    for (let chunkX = minChunkX; chunkX < maxChunkX + 1; chunkX++) {
      chunkXOffset++;
      chunkZOffset = -1;

      for (let chunkZ = minChunkZ; chunkZ < maxChunkZ + 1; chunkZ++) {
        chunkZOffset++;

        const imageData = this.worldMap.imageAt(chunkX, chunkZ);

        let x = chunkXOffset * cs + xOffset;
        let y = chunkZOffset * cs + yOffset;
        const drawnChunkIndex = this.drawnChunks.index(
          chunkXOffset,
          chunkZOffset
        );
        const currentDrawnChunk = this.drawnChunks.data[drawnChunkIndex];

        if (
          imageData &&
          draw &&
          (currentDrawnChunk !== imageData || forceDraw)
        ) {
          if (imageData instanceof ImageData) {
            ctx.putImageData(imageData, x, y);
            ctx.drawImage(
              ctx.canvas,
              x,
              y,
              imageData.width,
              imageData.height,
              x,
              y,
              cs,
              cs
            );
            this.drawnChunks.data[drawnChunkIndex] = imageData;
          } else if (imageData instanceof Blob) {
            const index = this.imgPool.index(chunkXOffset, chunkZOffset);

            const img = this.imgPool.data[index];

            if (!img) {
              debugger;
            }

            let canClear = true;
            if (!this.blobMap.has(imageData)) {
              canClear = false;
              this.blobMap.set(imageData, URL.createObjectURL(imageData));
            }

            const src = this.blobMap.get(imageData);

            if (img.src !== src) {
              if (this.imageBlobAssociation.has(img)) {
                const oldBlob = this.imageBlobAssociation.get(img);

                const currentSrc = this.blobMap.get(oldBlob);
                if (currentSrc) {
                  URL.revokeObjectURL(currentSrc);
                  this.blobMap.delete(oldBlob);
                }
              }
              img.src = src;
              this.imageBlobAssociation.set(img, imageData);
              this.isImagePoolClean = false;
            }

            if (img.complete && img.naturalHeight > 0) {
              ctx.drawImage(img, x, y, cs, cs);
              this.drawnChunks.data[drawnChunkIndex] = imageData;
            }
          } else {
            ctx.drawImage(
              imageData,
              0,
              0,
              imageData.width,
              imageData.height,
              x,
              y,
              cs,
              cs
            );
            this.drawnChunks.data[drawnChunkIndex] = imageData;
          }

          if (this.renderGrid) {
            ctx.strokeStyle = "rgba(255, 255, 255, 0.1)";
            ctx.strokeRect(x, y, cs, cs);
          }
        } else if (!imageData) {
          if (currentDrawnChunk) {
            this.drawnChunks.data[drawnChunkIndex] = null;
          }

          if (this.showPlaceholderIcon) {
            ctx.drawImage(placeholderTileIamge, x, y, cs, cs);
          }

          if (!USE_IMAGE_BITMAP) {
            const img: HTMLImageElement = this.imgPool.get(
              chunkXOffset,
              chunkZOffset
            );

            if (img) {
              const blob = this.imageBlobAssociation.get(img);
              const blobSrc = this.blobMap.get(blob);

              if (blobSrc) {
                URL.revokeObjectURL(blobSrc);
                this.blobMap.delete(blob);
              }

              if (img.src && img.src !== EMPTY_IMAGE_SRC) {
                img.src = EMPTY_IMAGE_SRC;
              }

              this.imageBlobAssociation.delete(img);
            }
          }
        }

        this.chunkXPositions.data[drawnChunkIndex] = x;
        this.chunkZPositions.data[drawnChunkIndex] = y;

        if (currentChunkZ === chunkZ && currentChunkX === chunkX) {
          this.centerChunkXOffset = x;
          this.centerChunkXIndex = chunkXOffset;
          this.centerChunkZIndex = chunkZOffset;
          this.centerCHunkYOffset = y;
        }

        this.endX = x + cs;
        this.endY = y + cs;
      }
    }

    let needsPlayerDraw = forceDraw || hasClearedPlayerCtx;
    if (canUpdatePlayers) {
      // if we need to draw any players...
      // then we need to clear the whole canvas context.
      // that means, we should avoid it.
      // so first we just check.
      if (!needsPlayerDraw) {
        for (let playerIndex = 0; playerIndex < players.length; playerIndex++) {
          const player = players[playerIndex];
          const chunkX = Math.floor(player.x / CHUNK_SIZE);
          const chunkZ = Math.floor(player.z / CHUNK_SIZE);

          const i = dist(minChunkX, chunkX);
          const j = dist(minChunkZ, chunkZ);

          const x = this.chunkXPositions.get(i, j) + xOffset;
          const y = this.chunkZPositions.get(i, j) + yOffset;

          if (!needsPlayerDraw) {
            needsPlayerDraw = this.renderPlayer(
              playersCtx,
              false,
              false,
              objectScale,
              player,
              x,
              y,
              cs
            );
          }

          if (needsPlayerDraw) {
            break;
          }
        }
      }

      if (needsPlayerDraw) {
        this.drawnPlayers.clear();
        playersCtx.clearRect(
          0,
          0,
          playersCtx.canvas.width,
          playersCtx.canvas.height
        );

        for (let playerIndex = 0; playerIndex < players.length; playerIndex++) {
          const player = players[playerIndex];

          const chunkX = Math.floor(player.x / CHUNK_SIZE);
          const chunkZ = Math.floor(player.z / CHUNK_SIZE);

          if (
            chunkX > maxChunkX ||
            chunkX < minChunkX ||
            chunkZ > maxChunkZ ||
            chunkZ < minChunkZ
          ) {
            continue;
          }

          const i = dist(minChunkX, chunkX);
          const j = dist(minChunkZ, chunkZ);

          const x = this.chunkXPositions.get(i, j) + xOffset;
          const y = this.chunkZPositions.get(i, j) + yOffset;

          this.renderPlayer(
            playersCtx,
            true,
            true,
            objectScale,
            player,
            x,
            y,
            cs,
            i,
            j
          );
        }
      }
    }
  }
}
