import nengi from "nengi";
import { AuthenticationCommand } from "./command/AuthenticationCommand";
import { BulkPlaceBlockCommand } from "./command/BulkPlaceBlockCommand";
import { EquipItemCommand } from "./command/EquipItemCommand";
import MoveCommand from "./command/MoveCommand";
import { PlaceBlockCommand } from "./command/PlaceBlockCommand";
import RequestWorldDataCommand from "./command/RequestWorldDataCommand";
import { RespawnCommand } from "./command/RespawnCommand";
import { SendChatMessageCommand } from "./command/SendChatMessageCommand";
import { TeleportCommand } from "./command/TeleportCommand";
import { UpdateAvatarCommand } from "./command/UpdateAvatarCommand";
import { Inventory } from "./entity/Inventory";
import PlayerCharacter from "./entity/PlayerCharacter";
import { AuthenticationRequiredMessage } from "./message/AuthenticationRequiredMessage";
import { BulkSetBlockMessage } from "./message/BulkSetBlockMessage";
import { ChatMessage } from "./message/ChatMessage";
import { ConfirmUsernameMessage } from "./message/ConfirmUsernameMessage";
import Identity from "./message/Identity";
import { PlayerJoinedChatMessage } from "./message/PlayerJoinedChatMessage";
import { SetBlockMessage } from "./message/SetBlockMessage";
import { SpawnPlayerMessage } from "./message/SpawnPlayerMessage";
import { TeleportMessage } from "./message/TeleportMessage";
import UnloadChunkMessage from "./message/UnloadChunkMessage";
import WorldChunkMessage from "./message/WorldChunkMessage";
import { ClaimYourInviteMessage } from "./message/ClaimYourInviteMessage";
import { ClaimInviteCommand } from "./command/ClaimInviteCommand";
import { EmitParticleEffectMessage } from "./message/EmitParticleEffectMessage";
import { NengiIDType } from "./nengiTypes";
import { UpdateStreamingStatusCommand } from "./command/UpdateStreamingStatusCommand";

const config = {
  UPDATE_RATE: 20,

  ID_BINARY_TYPE: NengiIDType,
  TYPE_BINARY_TYPE: nengi.UInt8,

  ID_PROPERTY_NAME: "nid",
  TYPE_PROPERTY_NAME: "ntype",

  USE_HISTORIAN: true,
  HISTORIAN_TICKS: 40,

  protocols: {
    entities: [
      ["PlayerCharacter", PlayerCharacter],
      ["Inventory", Inventory],
    ],
    localMessages: [],
    messages: [
      ["Identity", Identity],
      ["UnloadChunkMessage", UnloadChunkMessage],
      ["WorldChunkMessage", WorldChunkMessage],
      ["SetBlockMessage", SetBlockMessage],
      ["AuthenticationRequiredMessage", AuthenticationRequiredMessage],
      ["BulkSetBlockMessage", BulkSetBlockMessage],
      ["PlayerJoinedChatMessage", PlayerJoinedChatMessage],
      ["ChatMessage", ChatMessage],
      ["SpawnPlayerMessage", SpawnPlayerMessage],
      ["ConfirmUsernameMessage", ConfirmUsernameMessage],
      ["TeleportMessage", TeleportMessage],
      ["ClaimYourInviteMessage", ClaimYourInviteMessage],
      ["EmitParticleEffectMessage", EmitParticleEffectMessage],
    ],
    commands: [
      ["MoveCommand", MoveCommand],
      ["RequestWorldDataCommand", RequestWorldDataCommand],
      ["AuthenticationCommand", AuthenticationCommand],
      ["EquipItemCommand", EquipItemCommand],
      ["PlaceBlockCommand", PlaceBlockCommand],
      ["BulkPlaceBlockCommand", BulkPlaceBlockCommand],
      ["SendChatMessageCommand", SendChatMessageCommand],
      ["RespawnCommand", RespawnCommand],
      ["UpdateAvatarCommand", UpdateAvatarCommand],
      ["TeleportCommand", TeleportCommand],
      ["ClaimInviteCommand", ClaimInviteCommand],
      ["UpdateStreamingStatusCommand", UpdateStreamingStatusCommand],
    ],
    basics: [],
  },
};

export default config;
