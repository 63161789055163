import classNames from "classnames";
import * as React from "react";

export const Button = ({
  children,
  onSubmit,
  onClick,
  pending,
  pendingText,
  href,
  tabIndex,
  large,
  name,
  disabled,
  type,
  muted,
  inForm = true,
  round,
  disableAnimation,
}) => {
  let ComponentType;
  if (href) {
    ComponentType = "a";
  } else if (inForm) {
    ComponentType = "button";
  } else {
    ComponentType = "div";
  }

  const handleClick = React.useCallback(
    (event) => {
      if (pending || disabled) {
        event.preventDefault();
      }

      if (onClick) {
        onClick();
      }
    },
    [pending, disabled, onClick]
  );

  return (
    <ComponentType
      tabIndex={tabIndex}
      href={href}
      type={type}
      name={name}
      className={classNames("SubmitButton", {
        "SubmitButton--animate": !disableAnimation && !muted,
        "SubmitButton--clickable": !disabled && !pending,
        "SubmitButton--pending": pending,
        "SubmitButton--muted": muted,
        "SubmitButton--round": round,
        "SubmitButton--large": large,
      })}
      onClick={handleClick}
      disabled={disabled}
    >
      {pending ? pendingText : children}

      <style jsx>{`
        .SubmitButton {
          background: #6850ff;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 4px;
          width: 100%;
          text-transform: uppercase;
          color: white;
          padding: 14px 24px;
          white-space: nowrap;
          box-shadow: none;
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          align-items: center;
          text-align: center;
          cursor: pointer;
          transition: background-color 0.1s linear;
          transition-property: background-color, filter;
        }

        .SubmitButton--muted {
          background: #a7a7a7;
        }

        .SubmitButton--round {
          border-radius: 100px;
        }

        .SubmitButton--large {
          padding: 18px 32px;
          font-size: 20px;
        }

        .SubmitButton--pending {
          cursor: default;
          filter: grayscale(100%);
          border-color: #666;
          animation-play-state: paused;
        }

        .SubmitButton--animate {
          animation: growing 1s ease-in-out;
          animation-delay: 10s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }

        @keyframes growing {
          from {
            transform: scale(1, 1);
          }

          to {
            transform: scale(1.05, 1.05);
          }
        }

        a.SubmitButton {
          text-decoration: none;
        }

        .SubmitButton--clickable:hover {
          background: #503ccb;
        }
      `}</style>
    </ComponentType>
  );
};
