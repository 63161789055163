import { BitField, BitFieldMixin } from "structurae";

let encoderArray = new Array(6);
let position = new Int16Array(3);

let result = {
  xSign: 0,
  ySign: 0,
  zSign: 0,
  x: 0,
  y: 0,
  z: 0,
};
function decodeField(data) {
  const { fields, masks, schema } = ChunkField;
  let value = data;
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const size = schema[field];
    result[field] = value & masks[field];
    value >>= size;
  }
  return result;
}

function encodeField(array) {
  const { fields, schema } = ChunkField;
  let result = 0;
  for (let i = fields.length - 1; i >= 0; i--) {
    const field = fields[i];
    const current = array[i];
    result <<= schema[field];
    result |= current;
  }
  return result;
}

export class ChunkID {
  static decode(value: number) {
    const field = decodeField(value);

    position[0] = field.x * (field.xSign === 0 ? -1 : 1);
    position[1] = field.y * (field.ySign === 0 ? -1 : 1);
    position[2] = field.z * (field.zSign === 0 ? -1 : 1);

    return position;
  }

  static encode(x: number, y: number, z: number) {
    encoderArray[0] = x > -1 ? 1 : 0;
    encoderArray[1] = y > -1 ? 1 : 0;
    encoderArray[2] = z > -1 ? 1 : 0;
    encoderArray[3] = Math.abs(x);
    encoderArray[4] = Math.abs(y);
    encoderArray[5] = Math.abs(z);
    const id = encodeField(encoderArray);

    return id;
  }
}

export const ChunkField = BitFieldMixin({
  xSign: BitField.getMinSize(1),
  ySign: BitField.getMinSize(1),
  zSign: BitField.getMinSize(1),
  x: BitField.getMinSize(1024),
  y: BitField.getMinSize(16),
  z: BitField.getMinSize(1024),
});
