import nengi from "nengi";

export class SpawnPlayerMessage {
  constructor(x: number, y: number, z: number, worldId: number) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.worldId = worldId;
  }

  x: number;
  y: number;
  z: number;
  worldId: number;

  static protocol = {
    x: { type: nengi.Float64 },
    y: { type: nengi.Float64 },
    z: { type: nengi.Float64 },
    worldId: { type: nengi.UInt32 },
  };
}
