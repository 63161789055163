import nengi from "nengi";
import { NengiBlockCoordinate } from "../nengiTypes";

export class SendChatMessageCommand {
  constructor(body: string, x: number, y: number, z: number) {
    this.body = body;
    this.sentAt = Math.floor(new Date().getTime() / 1000);
    this.x = x;
    this.y = y;
    this.z = z;
  }

  body: string;
  sentAt: number;
  x: number;
  y: number;
  z: number;

  static protocol = {
    body: { type: nengi.UTF8String },
    sentAt: { type: nengi.UInt32 },
    x: { type: NengiBlockCoordinate },
    y: { type: NengiBlockCoordinate },
    z: { type: NengiBlockCoordinate },
  };
}
