import classNames from "classnames";
import { HUDEvent } from "components/HUDEvent";
import * as React from "react";

export const formatter = Intl.NumberFormat("en-US", {
  currency: "USD",
});

const formatCurrency = (currency: number) => {
  const _format = formatter.format(currency);

  if (currency > 0) {
    return `+ $${_format}`;
  } else {
    return `- $${_format.replace("-", "")}`;
  }
};

const Notification = ({ amount, className, onClear, index }) => {
  const formatted = React.useMemo(() => {
    return formatCurrency(amount);
  }, [amount, formatCurrency]);

  React.useEffect(() => {
    window.setTimeout(() => {
      onClear(amount, index);
    }, 1100);
  }, [onClear, amount, index]);

  return (
    <div
      className={classNames(className, "Notification", {
        "Notification--positive": amount > -1,
        "Notification--negative": amount < 0,
      })}
    >
      {formatted}

      <style jsx>{`
        .Notification {
          position: absolute;
          left: 20px;
          top: 64px;
          font-size: 36px;
          font-weight: 500;

          white-space: nowrap;
          font-weight: bold;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
          -webkit-text-fill: 1px currentColor;
          -webkit-text-fill-color: currentColor; /* Will override color (regardless of order) */

          animation: notification-animation 1s linear forwards;
          font-variant-numeric: tabular-nums;
        }

        @keyframes notification-animation {
          0% {
            opacity: 0.75;
            transform: translateY(100px);
          }

          25% {
            opacity: 1;
            transform: translateY(80px);
          }

          75% {
            opacity: 1;
            transform: translateY(20px);
          }

          100% {
            opacity: 0;
            transform: translateY(0);
          }
        }

        .Notification--positive {
          color: #85bb65;
        }
        .Notification--negative {
          color: red;
        }
      `}</style>
    </div>
  );
};

const HeaderComponent = ({
  username,
  avatarURL,
  color,
  notifs,
  currency,
  skinStyle,
  onClearNotification,
  position,
}) => {
  const renderNotif = React.useCallback(
    (amount: number, index) => {
      return (
        <Notification
          onClear={onClearNotification}
          amount={amount}
          index={index}
          key={`${index}-${amount}`}
        />
      );
    },
    [Notification]
  );
  return (
    <div className="Header">
      <div className="CurrencyList">{notifs.map(renderNotif)}</div>

      {/* {position && process.env.NODE_ENV === "development" && (
        <div
          style={{ fontFamily: "monospace", color: "white", opacity: 0.75 }}
          className="Position"
        >
          {position}
        </div>
      )} */}

      <style jsx>{`
        .Header {
          position: absolute;
          top: 0;
          left: 0;
          padding: 16px;
          user-select: none;
        }

        .Thumbnail {
          overflow: hidden;
        }

        .Avatar {
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.5);
          filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25));
        }

        .Currency {
          margin-top: 36px;
          color: #85bb65;
          font-size: 24px;

          font-weight: bold;
          font-variant-numeric: tabular-nums;
          color: #85bb65;
          -webkit-text-stroke: 1px rgba(0, 0, 0, 0.5);
          -webkit-text-fill: 1px #85bb65;
          -webkit-text-fill-color: #85bb65; /* Will override color (regardless of order) */
        }
      `}</style>
    </div>
  );
};

export const Header = ({
  username,
  avatarURL,
  color,
  currency = 0,
  skinStyle,
  socket,
  position,
}) => {
  const [notifs, setNotifs] = React.useState([]);

  const handleCurrencyChange = React.useCallback(
    (diff: number) => {
      setNotifs((notifs) => {
        notifs.push(diff);
        return notifs;
      });
    },
    [setNotifs]
  );

  const onClearNotifiation = React.useCallback(
    (amount, index) =>
      setNotifs((notifs) => {
        notifs.splice(index, 1);
        return [...notifs];
      }),
    [setNotifs]
  );

  React.useEffect(() => {
    socket.on(HUDEvent.currencyChange, handleCurrencyChange);

    return () => {
      socket.off(HUDEvent.currencyChange, handleCurrencyChange);
    };
  }, [handleCurrencyChange, socket]);

  return (
    <HeaderComponent
      username={username}
      avatarURL={avatarURL}
      currency={currency}
      color={color}
      notifs={notifs}
      onClearNotification={onClearNotifiation}
      skinStyle={skinStyle}
      position={position}
    />
  );
};
