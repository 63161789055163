import { Game } from "game/Game";
import EventEmitter from "eventemitter3";
import { HUDEvent } from "components/HUDEvent";
import debug from "lib/log";
import Engine from "noa-engine";

const log = debug("[Input]");

log.enabled = process.env.NODE_ENV !== "production";

export type EventHandler = (event: MouseEvent | KeyboardEvent) => void;
export enum InputEvent {
  equipSlot2 = "equipSlot2",
  equipSlot1 = "equipSlot1",
  toggleCharacter = "toggleCharacter",
  equipSlot3 = "equipSlot3",
  toggleWorldMap = "toggleWorldMap",
  equipSlot4 = "equipSlot4",
  equipSlot5 = "equipSlot5",
  equipSlot6 = "equipSlot6",
  liveStream = "record",
  equipSlot7 = "equipSlot7",
  toggleInvite = "toggleInvite",
  debug = "debug",
  equipSlot8 = "equipSlot8",
  run = "run",
  closeMenu = "closeMenu",
  // front = "front",
  voice = "voice",
  toggleInventory = "toggleInventory",
  openChat = "openChat",
  panCamera = "panCamera",
  flipFoilage = "flipFoilage",
  scaleFoilage = "scaleFoilage",
  place = "placeBlock",
  destroy = "destroy",
  select = "select",
}

export enum InputControl {
  default,
  placeBlock,
  placeFoilage,
  deleteBlock,
}

export const EVENT_LABELS = {
  [InputEvent.panCamera]: "PAN",
  [InputEvent.run]: "RUN",
  [InputEvent.flipFoilage]: "FLIP",
  [InputEvent.scaleFoilage]: "SCALE",
  [InputEvent.place]: "BUILD",
  // [InputEvent.front]: "FRONT",
  [InputEvent.destroy]: "DESTROY",
  [InputEvent.select]: "SELECT",
  [InputEvent.closeMenu]: "CLOSE",
};

export const CONTROLS = {
  [InputControl.default]: [InputEvent.run],
  [InputControl.placeBlock]: [
    InputEvent.place,
    // InputEvent.front,
    InputEvent.select,
    InputEvent.closeMenu,
  ],
  [InputControl.deleteBlock]: [
    InputEvent.destroy,
    // InputEvent.front,
    InputEvent.select,
    InputEvent.closeMenu,
  ],
  [InputControl.placeFoilage]: [
    InputEvent.place,
    // InputEvent.front,
    InputEvent.flipFoilage,
    InputEvent.scaleFoilage,
    InputEvent.closeMenu,
  ],
};

export enum Keybinding {
  shift = "<shift>",
  escape = "<escape>",
  rightClick = "<mouse 3>",
  leftClick = "<mouse 1>",
  tab = "<tab>",
}

export const Keybindings = {
  [InputEvent.equipSlot2]: "2",
  [InputEvent.equipSlot1]: "1",
  [InputEvent.equipSlot3]: "3",
  [InputEvent.equipSlot4]: "4",
  [InputEvent.equipSlot5]: "5",
  [InputEvent.toggleCharacter]: "C",
  [InputEvent.run]: "<shift>",
  [InputEvent.equipSlot6]: "6",
  [InputEvent.equipSlot7]: "7",
  [InputEvent.equipSlot8]: "8",
  [InputEvent.toggleWorldMap]: "M",
  // [InputEvent.front]: "E",
  [InputEvent.closeMenu]: "<escape>",
  [InputEvent.voice]: "V",
  [InputEvent.toggleInventory]: "<tab>",
  [InputEvent.openChat]: "<enter>",
  [InputEvent.panCamera]: "<mouse 3>",
  [InputEvent.flipFoilage]: "Q",
  [InputEvent.toggleInvite]: "U",
  [InputEvent.scaleFoilage]: "E",
  [InputEvent.place]: "<mouse 1>",
  [InputEvent.destroy]: "<mouse 1>",
  [InputEvent.liveStream]: "L",
  [InputEvent.debug]: "\\",
};

export type InputState = { [key in InputEvent]: boolean } & {
  scrollx: number;
  scrolly: number;
  forward: boolean;
  backward: boolean;
  left: boolean;
  right: boolean;
  dx: number;
  dy: number;
};

export class InputController {
  game: Game;
  noa: Engine;

  constructor(game: Game) {
    this.game = game;
  }

  onDown = (name: InputEvent, callback: EventHandler) => {
    this.noa.inputs.down.on(name, this.handleDown(name, callback));
  };

  onUp = (name: InputEvent, callback: EventHandler) => {
    this.noa.inputs.up.on(name, this.handleUp(name, callback));
  };

  handleDown = (name: InputControl, callback: EventHandler) => {
    return (evt) => {
      if (this.noa.paused) {
        return;
      }
      return callback(evt);
    };
  };

  handleUp = (name: InputControl, callback: EventHandler) => {
    return (evt) => {
      if (this.noa.paused) {
        return;
      }

      return callback(evt);
    };
  };

  get state(): InputState {
    return this.noa.inputs.state;
  }

  _control: InputControl;

  get control() {
    return this._control;
  }

  socket: EventEmitter;

  set control(control: InputControl) {
    const isChanging = control != this._control;

    if (!isChanging) {
      return;
    }

    if (this._control === InputControl.default) {
      this.unbindDefaultControls();
    } else if (this._control === InputControl.placeBlock) {
      this.unbindPlaceBlockControls();
    } else if (this._control === InputControl.deleteBlock) {
      this.unbindDeleteControls();
    } else if (this._control === InputControl.placeFoilage) {
      this.unbindPlaceFoilageControls();
    }

    this._control = control;

    if (control === InputControl.default) {
      this.bindDefaultControls();
    } else if (control === InputControl.placeBlock) {
      this.bindPlaceBlockControls();
    } else if (control === InputControl.deleteBlock) {
      this.bindDeleteControls();
    } else if (control === InputControl.placeFoilage) {
      this.bindPlaceFoilageControls();
    }

    this.socket.emit(HUDEvent.controlsChange, control);
  }

  bindGlobals = () => {
    const { noa } = this;
    noa.inputs.unbind("alt-fire");
    noa.inputs.unbind("fire");

    for (let event of [
      InputEvent.equipSlot2,
      InputEvent.equipSlot1,
      InputEvent.equipSlot3,
      InputEvent.debug,
      InputEvent.equipSlot4,
      InputEvent.equipSlot5,
      InputEvent.equipSlot6,
      InputEvent.equipSlot7,
      InputEvent.equipSlot8,
      InputEvent.closeMenu,
      InputEvent.toggleWorldMap,
      InputEvent.toggleCharacter,
      InputEvent.voice,
      InputEvent.toggleInventory,
      InputEvent.openChat,
      InputEvent.panCamera,
      InputEvent.liveStream,
      InputEvent.toggleInvite,
      // noa.camera.panWithMovement ? InputEvent.panCamera : null,
      InputEvent.run,
    ]) {
      if (event) {
        const binding = Keybindings[event];
        noa.inputs.bind(event, binding);
      }
    }
  };

  bindDefaultControls = () => {};

  unbindDefaultControls = () => {};

  bindPlaceBlockControls = () => {
    const { inputs } = this.noa;

    for (let event of [InputEvent.place]) {
      const binding = Keybindings[event];
      inputs.bind(event, binding);
    }
  };
  unbindPlaceBlockControls = () => {
    const { inputs } = this.noa;

    for (let event of [InputEvent.place]) {
      inputs.unbind(event);
    }
  };

  bindDeleteControls = () => {
    const { inputs } = this.noa;

    for (let event of [InputEvent.destroy]) {
      const binding = Keybindings[event];
      inputs.bind(event, binding);
    }
  };
  unbindDeleteControls = () => {
    const { inputs } = this.noa;

    for (let event of [InputEvent.destroy]) {
      inputs.unbind(event);
    }
  };

  bindPlaceFoilageControls = () => {
    const { inputs } = this.noa;

    for (let event of [
      InputEvent.place,
      InputEvent.flipFoilage,
      InputEvent.scaleFoilage,
    ]) {
      const binding = Keybindings[event];
      inputs.bind(event, binding);
    }
  };
  unbindPlaceFoilageControls = () => {
    const { inputs } = this.noa;

    for (let event of [
      InputEvent.place,
      InputEvent.flipFoilage,
      InputEvent.scaleFoilage,
    ]) {
      inputs.unbind(event);
    }
  };

  start = () => {
    this.bindGlobals();
  };
}
