export class StringList {
  arr = [];
  hash = new Map();
  includes(key) {
    return this.hash.has(key);
  }
  add(key) {
    if (this.includes(key)) {
      return;
    }

    this.arr.push(key);
    this.hash.set(key, true);
  }
  remove(key) {
    if (!this.hash.has(key)) {
      return;
    }

    this.arr.splice(this.arr.indexOf(key), 1);
    this.hash.delete(key);
  }
  count() {
    return this.arr.length;
  }
  forEach(a, b) {
    return this.arr.forEach(a, b);
  }
  slice(a, b) {
    return this.arr.slice(a, b);
  }
  isEmpty() {
    return this.arr.length === 0;
  }
  empty() {
    this.arr.length = 0;
    this.hash.clear();
  }
  pop() {
    var key = this.arr.pop();
    this.hash.delete(key);
    return key;
  }
  sort(keyToDistanceFn) {
    var mapping = {};
    this.arr.forEach((key) => {
      mapping[key] = keyToDistanceFn(key);
    });
    this.arr.sort((a, b) => mapping[b] - mapping[a]); // DESCENDING!
  }
  copyFrom(list) {
    this.arr = list.arr.slice();
    this.hash = new Map(list.hash);
  }
}
