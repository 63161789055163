import nengi from "nengi";
import { BlockID } from "./BlockID";

export const NengiBlockCoordinate = nengi.Int32;
export const NengiBlockType = nengi.UInt16;

export const NengiIDType = nengi.UInt16;

export interface PlaceBlockInterface {
  x: number;
  y: number;
  z: number;
  urlSide: number;
  variant: number;
  blockType: BlockID;
  url: string;
}

export const SetBlockProtocol = {
  error: { type: nengi.UInt4 },
  blockType: { type: NengiBlockType },
  urlSide: { type: nengi.Int4 },
  variant: { type: NengiBlockCoordinate },
  y: { type: NengiBlockCoordinate },
  x: { type: NengiBlockCoordinate },
  z: { type: NengiBlockCoordinate },
  url: { type: nengi.UTF8String },
};
