let microphone: MediaStream;

const userMediaProps = (
  audio: boolean,
  video: boolean,
  useRNNoise: boolean
) => {
  const AUDIO_PROPS = {
    echoCancellation: { ideal: true },
    sampleSize: {
      ideal: 16,
    },
    channelCount: { ideal: 2 },
    autoGainControl: { ideal: false },
    noiseSuppression: { ideal: !useRNNoise },
  };

  const VIDEO_PROPS = {
    frameRate: { ideal: 15 },
    facingMode: { ideal: "front" },
    width: { ideal: 360, max: 640 },
    height: { ideal: 360, max: 480 },
  };

  if (audio && video) {
    return { audio: AUDIO_PROPS, video: VIDEO_PROPS };
  } else if (audio) {
    return { audio: AUDIO_PROPS, video: false };
  } else if (video) {
    return { audio: false, video: VIDEO_PROPS };
  } else {
    return {};
  }
};

export const getMicrophone = (useRNNoise: boolean) => {
  if (microphone) {
    return Promise.resolve(microphone);
  }

  return navigator.mediaDevices
    .getUserMedia(userMediaProps(true, false, useRNNoise))
    .then((stream) => {
      microphone = stream;
      return microphone;
    });
};

export const getWebcam = () => {
  return navigator.mediaDevices.getUserMedia(
    userMediaProps(false, true, false)
  );
};

export const getWebcamAndMicrophone = (useRNNoise: boolean) => {
  return navigator.mediaDevices
    .getUserMedia(userMediaProps(true, true, useRNNoise))
    .then((mediaStream) => {
      microphone = mediaStream;

      return mediaStream;
    });
};
