import { Vector3 } from "@babylonjs/core";
import { client } from "game/networking/client";
import Vec3 from "gl-vec3";
import Engine from "noa-engine";

export default function (noa: Engine) {
  let _dt = 0;
  function processMovement(state) {
    const id = state.__id;
    var tickMS = noa._tickRate;

    const posData = noa.ents.getPositionData(id);

    const position: Vector3 = posData._renderPosition;

    Vector3.LerpToRef(
      position,
      state.localPosition,
      (1000 - (tickMS + client.averagePing + _dt)) / 1000,
      position
    );
  }

  let _tickTime = 0;
  function updateLocalPosition(state) {
    const id = state.__id;
    const posData = noa.ents.getPositionData(id);

    const a = state.localPosition.x - posData._localPosition[0];
    const b = state.localPosition.y - posData._localPosition[1];
    const c = state.localPosition.z - posData._localPosition[2];

    const dist =
      a !== 0 || b !== 0 || c !== 0 ? Math.sqrt(a * a + b * b + c * c) : 0;
    state.moveSpeed = dist * (_tickTime / 1000);
    state.distance[0] = a;
    state.distance[1] = b;
    state.distance[2] = c;

    state.localPosition.toArray(posData._localPosition);
  }

  return {
    name: "networkMovement",

    order: 29,

    state: {
      localPosition: Vector3.Zero(),
      jumping: false,
      running: false,
      distance: Vec3.create(),
      onGround: false,
      moveSpeed: 0,
      heading: 0,
    },

    onAdd: function (eid, state) {
      const posData = noa.ents.getPositionData(eid);

      state.localPosition = Vector3.FromArray(posData._localPosition, 0);
    },

    onRemove: null,

    system: function (dt, states) {
      _tickTime = dt;
      states.forEach(updateLocalPosition);
    },

    renderSystem: function movementProcessor(dt, states) {
      _dt = dt;
      states.forEach(processMovement);
    },
  };
}
