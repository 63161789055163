import { Submesh } from "lib/meshing/Submesh";

export function mergeMeshData(meshDataList, ignoreMaterials, isMergeable) {
  // flag and merge submesh data that can share the default terrain material
  let numMergeable = 0;
  for (var i = 0; i < meshDataList.length; i++) {
    const mdat = meshDataList[i];
    if (ignoreMaterials) {
      mdat.mergeable = true;
    } else {
      mdat.mergeable = isMergeable(mdat);
    }
    if (mdat.mergeable) numMergeable++;
  }
  if (numMergeable > 1) mergeSubmeshes(meshDataList, false);

  // now merge everything, keeping track of vertices/indices/materials
  return mergeSubmeshes(meshDataList, true);
}

function mergeAllSubmeshes(meshDataList, includeResults = false) {
  let resultLength = 0;
  let positionsLength = 0;
  let normalsLength = 0;
  let tilesLength = 0;
  let colorsLength = 0;
  let uvsLength = 0;
  let indicesLength = 0;

  for (let i = 0; i < meshDataList.length; ++i) {
    const mdat = meshDataList[i];
    resultLength++;
    positionsLength += mdat.positions.length;
    normalsLength += mdat.normals.length;
    colorsLength += mdat.colors.length;
    uvsLength += mdat.uvs.length;
    indicesLength += mdat.indices.length;
    tilesLength += mdat.tiles.length;
  }
  let vertices, indices, matIDs;

  if (includeResults) {
    vertices = new Float32Array(resultLength);
    indices = new Uint16Array(resultLength);
    matIDs = new Uint16Array(resultLength);
  }

  let target: Submesh = new Submesh(meshDataList[0].id);
  let resultI = -1;
  target.positions = new Float32Array(positionsLength);
  target.indices = new Uint16Array(indicesLength);
  target.normals = new Float32Array(normalsLength);
  target.colors = new Float32Array(colorsLength);
  target.uvs = new Float32Array(uvsLength);
  target.tiles = new Float32Array(tilesLength);
  let positionsOffset = 0,
    normalsOffset = 0,
    colorsOffset = 0,
    uvsOffset = 0,
    indicesOffset = 0,
    tilesOffset = 0;

  for (let i = 0; i < meshDataList.length; ++i) {
    const mdat = meshDataList[i];
    resultI++;

    if (includeResults) {
      vertices[resultI] = mdat.positions.length;
      indices[resultI] = mdat.indices.length;
      matIDs[resultI] = mdat.id;
    }

    let shouldSplice = false;

    if (!target) {
      target.id = mdat.id;
      target.positions.set(mdat.positions, positionsOffset);
      target.normals.set(mdat.normals, normalsOffset);
      target.colors.set(mdat.colors, colorsOffset);
      target.uvs.set(mdat.uvs, uvsOffset);
      target.indices.set(mdat.indices, indicesOffset);
      target.tiles.set(mdat.tiles, tilesOffset);

      shouldSplice = false;
    } else {
      const indexOffset = positionsOffset / 3;
      // merge data in "mdat" onto "target"
      target.positions.set(mdat.positions, positionsOffset);
      target.normals.set(mdat.normals, normalsOffset);
      target.colors.set(mdat.colors, colorsOffset);
      target.uvs.set(mdat.uvs, uvsOffset);
      target.tiles.set(mdat.tiles, tilesOffset);

      // indices must be offset relative to data being merged onto
      for (let j = 0, len = mdat.indices.length; j < len; ++j) {
        target.indices[indicesOffset + j] = mdat.indices[j] + indexOffset;
      }
      shouldSplice = true;
      // get rid of entry that's been merged
    }

    positionsOffset += mdat.positions.length;
    indicesOffset += mdat.indices.length;
    normalsOffset += mdat.normals.length;
    colorsOffset += mdat.colors.length;
    uvsOffset += mdat.uvs.length;
    tilesOffset += mdat.tiles.length;
    if (mdat.dispose) {
      mdat.dispose();
    } else {
      mdat.positions.length = 0;
      mdat.indices.length = 0;
      mdat.normals.length = 0;
      mdat.colors.length = 0;
      mdat.uvs.length = 0;
      mdat.tiles.length = 0;
    }
  }
  meshDataList[0] = target;
  meshDataList.length = 1;

  if (includeResults) {
    return {
      vertices,
      indices,
      matIDs,
    };
  } else {
    return null;
  }
}

// given a set of submesh objects, merge some or all of them
//      while tracking vertex/index offsets for each material ID
// Note: modifies meshDataList in place!
export function mergeSubmeshes(meshDataList, mergeAll) {
  if (mergeAll) {
    return mergeAllSubmeshes(meshDataList, true);
  }

  let mergeableData = [];
  for (let i = 0; i < meshDataList.length; ++i) {
    const mdat = meshDataList[i];
    if (!mdat.mergeable) continue;

    mergeableData.push(mdat);
  }
  meshDataList.length = 0;
  mergeAllSubmeshes(mergeableData, false);
  meshDataList = mergeableData;
}

function mergeArrays(tgt, src) {
  let offset = tgt.length;
  tgt.length += src.length - 1;
  for (var i = 0; i < src.length; i++) tgt[offset + i] = src[i];
}
