import chroma from "chroma-js";
import classNames from "classnames";
import { CharacterEditor } from "components/hud/CharacterEditor";
import { formatter } from "components/hud/Header";
import { MenuState } from "game/MenuState";
import { PlayerInventory } from "lib/Data/PlayerInventory";
import { getThumbnailURL } from "lib/Data/THUMBNAIL_MAP";
import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BlockID } from "shared/BlockID";
import {
  blockIdType,
  BlockIDType,
  isBlock,
  isFoilage,
  isSurface,
} from "shared/blocks";
import { itemId, items, pickaxeId } from "shared/items";
import { HUDEvent } from "../HUDEvent";
import { KeyboardHint } from "../KeyboardHint";
import {
  CharacterButton,
  InventoryButton,
  InviteButton,
  PhotoButton,
  PickaxeButton,
  WebcamButton,
} from "./buttons";

export const SLOT_SIZE = 64;

const surfaceImageSize = { width: 60, height: 60 };
const foilageImageSize = { width: 60, height: 60 };
const defaultImageSize = { width: SLOT_SIZE, height: SLOT_SIZE };
const SlotThumbnail = React.memo(({ id, quantity, isMini }) => {
  const item = items.get(id);

  let size = defaultImageSize;

  if (isSurface(item.blockID)) {
    size = surfaceImageSize;
  } else if (isFoilage(item.blockID)) {
    size = foilageImageSize;
  }

  return (
    <div
      className={classNames("Thumbnail", {
        "Thumbnail--block": isBlock(item.blockID),
        "Thumbnail--mini": isMini,
        "Thumbnail--surface": isSurface(item.blockID),
        "Thumbnail--foilage": isFoilage(item.blockID),
      })}
    >
      <img
        src={getThumbnailURL(id)}
        width={size.width}
        loading="lazy"
        height={size.height}
        className="Thumbnail-image"
      />
      <style jsx>{`
        .Thumbnail {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 4px;
        }

        .Thumbnail-image {
          image-rendering: pixelated;
          object-fit: contain;
        }

        .Thumbnail,
        .Thumbnail--block {
          width: var(--inventory-slot-size);
          height: var(--inventory-slot-size);
        }

        .Thumbnail--surface .Thumbnail-image,
        .Thumbnail--foilage .Thumbnail-image {
        }

        .Thumbnail--block img {
          background-repeat: repeat;
        }

        .Thumbnail--surface {
        }

        .Thumbnail--foilage {
        }
      `}</style>
    </div>
  );
});
const InviteThumbnail = () => (
  <img
    src={InviteButton["1x"]}
    width={SLOT_SIZE}
    height={SLOT_SIZE}
    srcSet={`${InviteButton["1x"]} 1x, ${InviteButton["2x"]} 2x, ${InviteButton["3x"]} 3x`}
  />
);
const PhotoThumbnail = () => (
  <img
    src={PhotoButton["1x"]}
    width={SLOT_SIZE}
    height={SLOT_SIZE}
    srcSet={`${PhotoButton["1x"]} 1x, ${PhotoButton["2x"]} 2x, ${PhotoButton["3x"]} 3x`}
  />
);
const WebcamThumbnail = () => (
  <img
    src={WebcamButton["1x"]}
    width={SLOT_SIZE}
    height={SLOT_SIZE}
    srcSet={`${WebcamButton["1x"]} 1x, ${WebcamButton["2x"]} 2x, ${WebcamButton["3x"]} 3x`}
  />
);
const PickaxeThumbnail = () => (
  <img
    src={PickaxeButton["1x"]}
    width={SLOT_SIZE}
    height={SLOT_SIZE}
    srcSet={`${PickaxeButton["1x"]} 1x, ${PickaxeButton["2x"]} 2x, ${PickaxeButton["3x"]} 3x`}
  />
);
const InventoryIcon = () => {
  return (
    <img
      src={InventoryButton["1x"]}
      width={38}
      height={38}
      srcSet={`${InventoryButton["1x"]} 1x, ${InventoryButton["2x"]} 2x, ${InventoryButton["3x"]} 3x`}
    />
  );
};
const CharacterIcon = () => {
  return (
    <img
      src={CharacterButton["1x"]}
      height={38}
      width={29}
      srcSet={`${CharacterButton["1x"]} 1x, ${CharacterButton["2x"]} 2x, ${CharacterButton["3x"]} 3x`}
    />
  );
};
const EmptySlot = () => (
  <div className="EmptySlot">
    <style jsx>{`
    .EmptySlot {
      border: 2px solid #666;
      opacity; 0.25;
      width: var(--inventory-slot-size);
      height: var(--inventory-slot-size);
      content: "";
    }

`}</style>
  </div>
);
const SlotItem = React.memo(
  ({ isEquipped, id, keybinding, quantity, onClick }) => {
    let ThumbnailComponent = SlotThumbnail;
    const handleClick = React.useCallback(
      (event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(id);
      },
      [onClick, id]
    );
    if (id === -1) {
      ThumbnailComponent = EmptySlot;
    } else if (id === BlockID.imageBlock) {
      ThumbnailComponent = PhotoThumbnail;
    } else if (id === pickaxeId) {
      ThumbnailComponent = PickaxeThumbnail;
    } else if (id === BlockID.videoBlock) {
      ThumbnailComponent = WebcamThumbnail;
    } else if (id === BlockID.invite) {
      ThumbnailComponent = InviteThumbnail;
    }

    return (
      <div className="SlotItemContainer">
        <div
          onMouseDown={handleClick}
          className={classNames("SlotItem", {
            "SlotItem--equipped": isEquipped,
            "SlotItem--notEquipped": !isEquipped,
          })}
        >
          <ThumbnailComponent quantity={quantity} id={id} />
          <div className="EquippedBorder"></div>
        </div>

        {keybinding && (
          <div className="KeyboardBinding">
            <KeyboardHint isNumber>{keybinding}</KeyboardHint>
          </div>
        )}

        <style jsx>{`
          .SlotItemContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
          }

          .SlotItem,
          .EquippedBorder {
            width: var(--inventory-slot-size);
            height: var(--inventory-slot-size);
          }

          .EquippedBorder {
            position: absolute;

            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 2;
            tranasition: opacity 0.1s linear;
            border: 2px solid white;

            content: "";
          }

          .KeyboardBinding {
            margin-top: 8px;
          }

          .SlotItem--notEquipped .EquippedBorder {
            opacity: 0;
          }
          .SlotItem--notEquipped:hover .EquippedBorder {
            opacity: 0.5;
          }

          .SlotItem--equipped .EquippedBorder {
            opacity: 1;
          }

          .SlotItem {
            transition: transform 0.1s linear;
          }

          .SlotItem--equipped {
            transform: translateY(-6px);
          }
        `}</style>
      </div>
    );
  }
);
const InventoryHUDComponent = ({
  firstSlot,
  secondSlot,
  thirdSlot,
  fourthSlot,
  fifthSlot,
  sixthSlot,
  seventhSlot,
  equippedBlock,

  menuState,
}) => (
  <div
    className={classNames("Inventory", {
      "Inventory--open": menuState === MenuState.inventory,
      "Inventory--characterOpen": menuState === MenuState.character,
      "Inventory--hidden": menuState === MenuState.visible,
    })}
  >
    <div className="TopIcons">
      <div className="InventoryIcon">
        <div className="InventoryIcon-KeyboardHint">
          <KeyboardHint width="auto">TAB</KeyboardHint>
        </div>

        <div className="InventoryIcon-icon">
          <InventoryIcon />
        </div>
      </div>

      <div className="CharacterIcon">
        <div className="CharacterIcon-KeyboardHint">
          <KeyboardHint width="auto">C</KeyboardHint>
        </div>

        <div className="CharacterIcon-icon">
          <CharacterIcon />
        </div>
      </div>
    </div>
    <div className="Slots">
      <SlotItem
        isEquipped={equippedBlock === firstSlot}
        id={firstSlot}
        keybinding={1}
      />
      <SlotItem
        isEquipped={equippedBlock === secondSlot}
        id={secondSlot}
        keybinding={2}
      />
      <SlotItem
        isEquipped={equippedBlock === thirdSlot}
        id={thirdSlot}
        keybinding={3}
      />
      <SlotItem
        isEquipped={equippedBlock === fourthSlot}
        id={fourthSlot}
        keybinding={4}
      />
      <SlotItem
        isEquipped={equippedBlock === fifthSlot}
        id={fifthSlot}
        keybinding={5}
      />
      <SlotItem
        isEquipped={equippedBlock === sixthSlot}
        id={sixthSlot}
        keybinding={6}
      />
      <SlotItem
        isEquipped={equippedBlock === seventhSlot}
        id={seventhSlot}
        keybinding={7}
      />
    </div>

    <style jsx>{`
    .Inventory {

      position: absolute;
      bottom: 0;
      right: 0;
    }



    .InventoryIcon-KeyboardHint, .CharacterIcon-KeyboardHint {
      width: 48px;
    }

    .Inventory--open  .InventoryIcon-KeyboardHint, .Inventory--open  .CharacterIcon,
    .Inventory--characterOpen  .CharacterIcon-KeyboardHint, .Inventory--characterOpen  .InventoryIcon {
      opacity: 0;
    }

    .InventoryIcon-icon , .CharacterIcon-icon {
      transition: transform 0.1s linear;
      padding-top: 2px;
      display: flex;
      justify-content: flex-end;
    }


    .CharacterIcon, .InventoryIcon-KeyboardHint, .CharacterIcon-KeyboardHint, .InventoryIcon {
      transition: opacity 0.1s linear;
    }

    .Inventory--characterOpen  .CharacterIcon-icon {
      transform: translateY(52px) translateY(-12px) translateX(-12px) scale(0.8) translateX(-12%) translateY(-12%);
    }


    .Inventory--open  .InventoryIcon-icon {
      transform: translateY(-12px) translateX(-12px) scale(1.2) translateX(-12%) translateY(-12%);
    }


    .Slots {
      padding-bottom: 38px;
      padding-right: 38px;
      display: grid;
      grid-template-columns repeat(7, var(--inventory-slot-size));
      grid-template-rows: auto;
      column-gap: 2px;

    }

    .Slots, .TopIcons {
      transition: 0.1s linear opacity;
      transition-property: opacity, transform;
    }


.TopIcons {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 12px;
  justify-content: center;
  align-items: flex-end;
  padding-right: 38px;

  position: relative;
  z-index: 9999;
}

.InventoryIcon, .CharacterIcon {
  display: grid;
  grid-template-rows: auto;
  grid-column-gap: 8px;
  align-items: center;
}

.InventoryIcon {
  grid-template-columns: 48px 40px;
}

.CharacterIcon {
  grid-template-columns: 48px 40px;
  margin-bottom: 12px;
}

  `}</style>
    <style jsx global>{`
    .InventoryHUDTransition-exit-active .Slots {
      transform: translateY(100%);
      opacity: 0;
    }

    .InventoryHUDTransition-exit-active .TopIcons {
      transform: translateX(100%);
      opacity: 0;
    }

    .InventoryHUDTransition-enter-active .TopIcons {
      transform: translateX(0);
      opacity: 1;
    }

    .InventoryHUDTransition-enter-active .Slots {
      transform: translateY(0)
      opacity: 1;
    }

  `}</style>
  </div>
);

const SlotGridItem = ({
  isEquipped,
  isSelected,
  id,
  onClick,
  onMouseOver,
  onMouseOut,
}) => {
  const handleClick = React.useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      onClick(id);
    },
    [onClick, id]
  );

  const handleMouseOver = React.useCallback(
    (event) => {
      onMouseOver(id);
    },
    [onMouseOver, id]
  );

  const handleMouseOut = React.useCallback(
    (event) => {
      onMouseOut(id);
    },
    [onMouseOut, id]
  );

  return (
    <div
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      className={classNames("SlotGridItem", {
        "SlotGridItem--selected": isSelected,
        "SlotGridItem--equipped": isEquipped,
      })}
    >
      <SlotThumbnail id={id} />

      <style jsx>{`
        .SlotGridItem {
          border: 1px solid transparent;
          box-sizing: content-box;
          width: 64px;
          height: 64px;
          transition: transform 0.05s linear;
        }

        .SlotGridItem--equipped {
          border-color: white;
        }

        .SlotGridItem--selected,
        .SlotGridItem:hover {
          transform: scale(1.1);
        }

        .SlotGridItem:hover {
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

const SlotGrid = ({ equippedBlock, onClickItem, onMouseOver, onMouseOut }) => {
  const renderItem = React.useCallback(
    (item) => {
      const isEquipped = equippedBlock === item.id;
      return (
        <SlotGridItem
          isEquipped={isEquipped}
          id={item.id}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClickItem}
          key={`${item.id.toString()}-${isEquipped}`}
        />
      );
    },
    [equippedBlock, onClickItem, onMouseOver, onMouseOut]
  );

  const _items = React.useMemo(
    () => [...items.values()].filter((item) => !item.hidden),
    [items]
  );

  return (
    <div className="SlotGrid">
      {_items.map(renderItem)}
      <style jsx>{`
  .SlotGrid {
    display: grid;
    gap: 4px;

    grid-template-columns: repeat(
      auto-fill,
      var(--inventory-slot-size)
    );

  `}</style>
    </div>
  );
};

const InventoryListComponent = React.forwardRef(
  ({ equippedBlock, color, onClickItem, onScroll }, ref) => {
    const TOTAL_SLOT_COUNT = 30;
    const [selectedId, setSelectedId] = React.useState(equippedBlock);

    React.useEffect(() => {
      setSelectedId(equippedBlock);
    }, [equippedBlock, setSelectedId]);

    const unsetSelectedId = React.useCallback(
      (id) => {
        setSelectedId((selectedId) => {
          if (selectedId === id) {
            return equippedBlock || selectedId;
          } else {
            return selectedId;
          }
        });
      },
      [setSelectedId, equippedBlock]
    );

    const item = selectedId ? items.get(selectedId) : items.get(equippedBlock);
    const type = blockIdType(item?.blockID);
    const containerStyle = React.useMemo(
      () => ({
        backgroundColor: chroma(color && color.length > 0 ? color : "#333")
          .alpha(0.6)
          .css(),
      }),
      [color]
    );

    return (
      <div className="Wrapper">
        <div className="Header">
          <div className="Title">Build</div>
        </div>

        <div
          style={containerStyle}
          onScroll={onScroll}
          ref={ref}
          className="InventoryList-container"
        >
          <SlotGrid
            onMouseOver={setSelectedId}
            onMouseOut={unsetSelectedId}
            equippedBlock={equippedBlock}
            onClickItem={onClickItem}
          />
        </div>

        <div
          className={classNames("Tooltip", {
            "Tooltip--show": !!item,
            "Tooltip--hide": !item,
          })}
        >
          <div className="Tooltip-top">
            <div className="Tooltip-title">{item ? item.name : ""}</div>
            {item && item.variantName && (
              <div className="Tooltip-subtitle">– {item.variantName}</div>
            )}
          </div>

          <div className="Tooltip-bottom">
            <div className="Tooltip-cost">
              {item ? "$" + formatter.format(item.cost) : ""}
            </div>

            {type === BlockIDType.block && (
              <div className="Tooltip-feature Tooltip-stackable">Terrain</div>
            )}

            {type === BlockIDType.block && (
              <div className="Tooltip-feature Tooltip-stackable">Stackable</div>
            )}

            {type === BlockIDType.foilage && (
              <div className="Tooltip-feature Tooltip-stackable">
                Decoration
              </div>
            )}
          </div>
        </div>

        <style jsx>{`
          .Wrapper {
            position: absolute;

            pointer-events: auto;
            bottom: 156px;
            will-change: transform, opacity;

            backdrop-filter: blur(10px);
            background-color: rgba(0, 0, 0, 0.4);
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
            border-radius: 10px;
            overflow: hidden;
            right: 38px;
            width: 380px;
            transform-origin: bottom right;

            transition: opacity 0.1s linear;
            transition-property: transform, opacity;
          }

          .Tooltip-title,
          .Tooltip-cost,
          .Tooltip-subtitle,
          .Tooltip-feature {
            margin-right: 4px;
          }

          .Tooltip-feature {
            color: #ccc;
            text-transform: uppercase;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 2px;
            padding: 2px 4px;
            margin-left: 4px;
          }

          .Tooltip {
            position: absolute;
            bottom: 0;
            left: 0;
            color: white;
            font-size: 18px;
            padding-bottom: 12px;
            padding-top: 12px;
            height: 81px;
            padding-left: 12px;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.75);
          }

          .Tooltip-top,
          .Tooltip-bottom {
            display: flex;
            align-items: center;
          }

          .Tooltip-bottom {
            margin-top: 8px;
          }

          .Tooltip-cost {
            color: #85bb65;
          }

          .Tooltip--hide {
            visibility: hidden;
          }

          .Header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;
            margin-top: 6px;
            padding-left: 18px;
            padding-right: 18px;
          }

          .Title {
            font-size: 24px;
            font-weight: 500;
            color: white;
          }

          .InventoryList-container {
            padding: 10px;
            border-radius-bottom-left: 10px;
            border-radius-bottom-right: 10px;
            padding-bottom: 80px;
            min-height: 340px;
            max-height: 500px;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch; /* Lets it scroll lazy */
            overflow-scrolling: touch;
            overflow-y: auto;
          }
        `}</style>
        <style jsx global>{`
          .InventoryListTransition-exit.Wrapper {
            opacity: 0;
            transform: scale(0.1);
          }
          .InventoryListTransition-enter.Wrapper {
            opacity: 0;
            transform: scale(0.98);
          }

          .InventoryListTransition-enter-active.Wrapper {
            opacity: 1;
            transform: scale(1);
          }
        `}</style>
      </div>
    );
  }
);

const ScrollPreservingInventoryListComponent = ({
  firstSlot,
  secondSlot,
  thirdSlot,
  fourthSlot,
  menuState,
  fifthSlot,
  sixthSlot,
  onClickItem,
  seventhSlot,
  equippedBlock,
  color,
}) => {
  const scrollTop = React.useRef(0);
  const scrollRef = React.useRef<HTMLDivElement>();

  const onScroll = React.useCallback(
    (event) => {
      scrollTop.current = event.target.scrollTop;
    },
    [scrollTop]
  );

  React.useLayoutEffect(() => {
    if (menuState === MenuState.inventory && scrollRef.current) {
      scrollRef.current.scrollTop = scrollTop.current;
    }
  }, [menuState, scrollRef, scrollTop]);

  if (menuState !== MenuState.inventory) {
    return null;
  }
  return (
    <CSSTransition
      key={"inventory-list"}
      timeout={100}
      classNames="InventoryListTransition"
    >
      <InventoryListComponent
        firstSlot={firstSlot}
        color={color}
        ref={scrollRef}
        onScroll={onScroll}
        secondSlot={secondSlot}
        thirdSlot={thirdSlot}
        fourthSlot={fourthSlot}
        menuState={menuState}
        fifthSlot={fifthSlot}
        sixthSlot={sixthSlot}
        onClickItem={onClickItem}
        seventhSlot={seventhSlot}
        equippedBlock={equippedBlock}
      />
    </CSSTransition>
  );
};
export class InventoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      equippedBlock: null,
      items: [],
      firstSlot: itemId(BlockID.pickaxe, 0),
      // secondSlot: BlockID.bedrock,
      // thirdSlot: BlockID.brick,
      // fourthSlot: BlockID.grass,
      fifthSlot: null,
      sixthSlot: null,
      seventhSlot: null,
      isLoaded: false,
    };
    this.startListening();
  }
  startListening = () => {
    this.props.socket.on(HUDEvent.equipItem, this.handleEquipItem);
    this.props.socket.on(HUDEvent.inventoryChange, this.handleInventoryChange);
  };
  handleEquipItem = (equippedBlock) => {
    this.setState({ equippedBlock });
  };

  inventory: PlayerInventory;
  handleInventoryChange = (inventory: PlayerInventory) => {
    this.inventory = inventory;
    this.setState({
      isLoaded: true,
      secondSlot: inventory.secondSlot,
      thirdSlot: inventory.thirdSlot,
      fourthSlot: inventory.fourthSlot,
      fifthSlot: inventory.fifthSlot,
      sixthSlot: inventory.sixthSlot,
      seventhSlot: inventory.seventhSlot,
    });
  };
  stopListening = () => {
    this.props.socket.off(HUDEvent.equipItem, this.handleEquipItem);
    this.props.socket.off(HUDEvent.inventoryChange, this.handleInventoryChange);
  };
  componentWillUnmount() {
    this.stopListening();
  }
  handleInventoryClick = (item) => {
    this.props.socket.emit(HUDEvent.selectitem, item);
  };

  render() {
    const {
      firstSlot,
      secondSlot,
      thirdSlot,
      fourthSlot,
      fifthSlot,
      sixthSlot,
      seventhSlot,
      equippedBlock,
      isLoaded,
    } = this.state;
    const { menuState } = this.props;
    if (!isLoaded) {
      return null;
    }
    return (
      <TransitionGroup className="InventoryListTransition">
        <InventoryHUDComponent
          firstSlot={firstSlot}
          secondSlot={secondSlot}
          thirdSlot={thirdSlot}
          fourthSlot={fourthSlot}
          fifthSlot={fifthSlot}
          sixthSlot={sixthSlot}
          menuState={menuState}
          seventhSlot={seventhSlot}
          equippedBlock={equippedBlock}
        />

        <CharacterEditor
          socket={this.props.socket}
          menuState={menuState}
          currentPlayer={this.props.currentPlayer}
        />

        <ScrollPreservingInventoryListComponent
          firstSlot={firstSlot}
          color={this.props.color}
          secondSlot={secondSlot}
          thirdSlot={thirdSlot}
          fourthSlot={fourthSlot}
          menuState={menuState}
          fifthSlot={fifthSlot}
          sixthSlot={sixthSlot}
          onClickItem={this.handleInventoryClick}
          seventhSlot={seventhSlot}
          equippedBlock={equippedBlock}
        />
      </TransitionGroup>
    );
  }
}

export default InventoryList;
