import { IWebRequest } from "@babylonjs/core/Misc/interfaces/iWebRequest";
import EventEmitter from "eventemitter3";
import { fetchOpts } from "./GLBFetcher";
import { fileMap } from "./fileMap";
import { WebRequest } from "@babylonjs/core";

export class FetchWebRequest implements IWebRequest {
  responseURL: string;
  private emitter: EventEmitter;
  response: any;
  status: number;
  statusText: string;
  responseType: XMLHttpRequestResponseType = "text";

  getEmitter() {
    if (!this.emitter) {
      this.emitter = new EventEmitter();
    }

    return this.emitter;
  }

  addEventListener(name: "onreadystatechange", cb) {
    this.getEmitter().on(name, cb);
  }

  removeEventListener(name: "onreadystatechange" | "loadend", cb) {
    this.getEmitter().off(name, cb);
  }

  _readyState = XMLHttpRequest.UNSENT;
  get readyState() {
    return this._readyState;
  }

  set readyState(state: number) {
    this._readyState = state;
    if (this.emitter) {
      this.emitter.emit("readystatechange", this);
    }
  }

  abort() {}

  open(method, url) {
    this.responseURL = url;
    this.readyState = XMLHttpRequest.OPENED;
  }

  async send(method, body: any) {
    if (fileMap.has(this.responseURL)) {
      this.response = fileMap.get(this.responseURL);
      this.status = 200;
      this.readyState = XMLHttpRequest.DONE;
      return;
    }
    this.readyState = XMLHttpRequest.LOADING;
    const resp = await fetch(this.responseURL, fetchOpts);

    this.statusText = resp.statusText;
    this.status = resp.status;

    if (this.responseType === "arraybuffer") {
      this.response = await resp.arrayBuffer();
    } else if (this.responseType === "blob") {
      this.response = await resp.blob();
    } else if (this.responseType === "document") {
      this.response = await resp.text();
    } else if (this.responseType === "json") {
      this.response = await resp.text();
      this.responseText = this.response;
    } else {
      this.response = await resp.text();
      this.responseText = this.response;
    }

    this.readyState = XMLHttpRequest.DONE;
  }
}

WebRequest.prototype = FetchWebRequest.prototype;
