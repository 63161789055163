import {
  Color3,
  IDisposable,
  Mesh,
  MeshBuilder,
  Scene,
  StandardMaterial,
  TransformNode,
  Vector3,
} from "@babylonjs/core";
import { UsernameDynamicTexture } from "game/textures/UsernameDynamicTexture";
import Engine from "noa-engine";
import { AvatarType } from "shared/entity/PlayerCharacter";

const nameplateOffsets = [0, 0, 0.1, 0.3];

export class Nameplate implements IDisposable {
  constructor(
    scene: Scene,
    noa: Engine,
    parentId: number,
    avatar: AvatarType,
    x: number,
    y: number,
    z: number
  ) {
    this.scene = scene;
    this.noa = noa;

    this.container = new TransformNode("container", scene);
    const parent = noa.entities.getPositionData(parentId);
    this.parentId = parentId;

    this.entityId = this.noa.ents.add(
      [x, y, z],
      parent.width,
      parent.height,
      this.container,
      null,
      false,
      false
    );
    this.avatar = avatar;

    this.noa.ents.addComponentAgain(this.entityId, "followsEntity", {
      entity: parentId,
    });
  }

  avatar: AvatarType;
  parentId: number;

  emitCurrencyDelta = (amount: number) => {};

  noa: Engine;
  scene: Scene;

  container: TransformNode;
  entityId: number;

  setupUsername(username: string) {
    let nameMesh = this.usernameMesh;

    if (!nameMesh) {
      nameMesh = MeshBuilder.CreatePlane(name, {
        width: 0.75,
        height: 0.75,
        sideOrientation: Mesh.DOUBLESIDE,
      });
    }

    let material: StandardMaterial = nameMesh.material;

    if (!material) {
      material = new StandardMaterial("nametag", this.scene);
    }

    let usernameTexture: UsernameDynamicTexture = this.usernameTexture;

    if (!usernameTexture) {
      usernameTexture = new UsernameDynamicTexture(
        "username-texture",
        512,
        this.scene
      );
    }

    usernameTexture.drawUsername(username);
    usernameTexture.hasAlpha = true;

    material.diffuseTexture = usernameTexture;
    material.ambientColor = Color3.White();
    material.emissiveColor = material.ambientColor;
    // material.diffuseColor = getClearColor(this.meshContainer.scene);
    material.useAlphaFromDiffuseTexture = true;

    nameMesh.material = material;
    nameMesh.preserveParentRotationForBillboard = false;

    nameMesh.parent = this.container;
    nameMesh.hasVertexAlpha = true;
    material.disableLighting = true;
    nameMesh.hasVertexAlpha = true;

    // node.position.copyFrom(this.meshContainer.mesh.position);
    this.noa.rendering.addMeshToScene(nameMesh);

    nameMesh.position = new Vector3(0, 1.75 + nameplateOffsets[this.avatar], 0);

    if (this.parentId === this.noa.playerEntity) {
      nameMesh.alwaysSelectAsActiveMesh = true;
    }

    // nameMesh.rotation.x = this.meshContainer.mesh.rotation.x * -1;
    nameMesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
    material.backFaceCulling = false;
    this.usernameTexture = usernameTexture;
    this.usernameMesh = nameMesh;
  }
  usernameMesh: Mesh;
  usernameTexture: UsernameDynamicTexture;

  isDisposed = false;

  dispose = () => {
    this.noa.ents.deleteEntity(this.entityId);
    this.noa.rendering.removeMeshFromScene(this.usernameMesh);
    this.noa.rendering.removeMeshFromScene(this.container);

    this.usernameMesh.dispose(false, true);
    this.container.dispose(false, true);
    this.isDisposed = true;
  };
}
