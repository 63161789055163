import { EventEmitter } from "eventemitter3";

export class GameLoop {
  emitter = new EventEmitter();
  _rafHandle: number;

  _tickInterval: number;
  start() {
    this.stopped = false;
    this._lastTick = performance.now();
    this.tick();

    this._rafHandle = self.requestAnimationFrame(this.render);
    this._tickInterval = self.setInterval(this.tick, this._tickRate);
  }

  stop() {
    self.clearInterval(this._tickInterval);
    self.cancelAnimationFrame(this._rafHandle);
    this.stopped = true;
  }

  stopped = false;

  tick = () => {
    const skip = performance.now() + this.frameSkip;
    let i, s, t;
    const tr = this._tickRate;
    while (!this._paused && performance.now() >= this._lastTick + tr) {
      //Skip frames if we are over budget
      if (performance.now() > skip) {
        this._lastTick = performance.now() + tr;
        return;
      }

      //Tick the game
      s = performance.now();
      this.emitter.emit("tick");
      t = performance.now();
      this.tickTime = t - s;

      //Update counters and time
      ++this.tickCount;
      this._lastTick += tr;
    }
  };
  tickTime = 0;
  tickCount = 0;

  _lastTick: number;
  _tickRate = 30;
  startTime: number;
  frameSkip = (this._tickRate + 5) * 5;
  frameTime: number = 10.0;
  _paused = false;
  frameCount = 0;

  render = () => {
    if (this.stopped) {
      return;
    }

    this._rafHandle = self.requestAnimationFrame(this.render);

    //Compute frame time
    let dt;
    if (this._paused) {
      dt = this.frameTime;
    } else {
      dt = Math.min(1.0, (performance.now() - this._lastTick) / this._tickRate);
    }

    //Draw a frame
    this.frameCount++;
    const s = performance.now();
    this.emitter.emit("render", dt);
    const t = performance.now();
    this.frameTime = t - s;
    //Request next frame
  };
}
