import nengi from "nengi";

export class TeleportMessage {
  constructor(x: number, y: number, z: number, username: string) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.username = username;
  }

  x: number;
  y: number;
  z: number;
  username: string;

  static protocol = {
    username: { type: nengi.UTF8String },
    x: { type: nengi.Float32 },
    y: { type: nengi.Float32 },
    z: { type: nengi.Float32 },
  };
}
