const logs = new Map<string, Function>();
let colors = [
  `#333`,
  `#666`,
  `#fcc`,
  "pink",
  "red",
  "orange",
  "green",
  "purple",
  "blue",
];

export const debug = function (prefix: string) {
  let log = logs.get(prefix);

  if (!log) {
    log = function (...args) {
      if (!logs.get(prefix).enabled) {
        return;
      }

      console.log(log.prefix, log.suffix, ...args);
    };
  }

  log.suffix = `color: ${colors[Math.max(logs.size - 1, 0)]};`;
  log.prefix = `%c${prefix}`;
  log.namespace = prefix;
  log.enabled = process.env.NODE_ENV === "development";
  log.enable = () => debug.enable(log.prefix);

  logs.set(prefix, log);
  return log;
};

debug.enable = (prefix: string) => {
  const log = logs.get(prefix);
  if (log) {
    log.enabled = true;
  }
};

export default debug;
