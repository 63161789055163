import {
  Color3,
  MultiMaterial,
  PBRMaterial,
  StandardMaterial,
} from "@babylonjs/core";
import { getMesh } from "game/components/getMesh";

let _diffuseColor = new Color3(1, 1, 0.8);
let alpha = 1;
let speed = 60;

const setMaterialColor = (
  material: StandardMaterial | PBRMaterial,
  color: Color3
) => {
  if (material instanceof StandardMaterial) {
    material.diffuseColor = color;
  } else {
    material.albedoColor = color;
  }
};

const materialColor = (material: StandardMaterial | PBRMaterial) =>
  material instanceof StandardMaterial
    ? material.diffuseColor
    : material.albedoColor;

export default (noa) => {
  return {
    name: "glow",

    order: 40,

    state: {
      _originalEmissives: [],
    },

    onAdd: function (eid, state) {
      const mesh = getMesh(noa, eid);

      if (mesh.material instanceof MultiMaterial) {
        let mat: MultiMaterial = mesh.material;
        state._originalEmissives = new Array(mat.subMaterials.length);
        for (let i = 0; i < mat.subMaterials.length; i++) {
          const material: StandardMaterial | PBRMaterial = mat.subMaterials[i];

          if (materialColor(material) !== _diffuseColor) {
            state._originalEmissives[i] = materialColor(material);
            setMaterialColor(material, _diffuseColor);
          }
        }
      } else {
        if (materialColor(mesh.material) !== _diffuseColor) {
          state._originalEmissives = [materialColor(mesh.material)];
          setMaterialColor(mesh.material, _diffuseColor);
        }
      }
    },

    onRemove: function (eid, state) {
      const mesh = getMesh(noa, eid);

      if (mesh.material instanceof MultiMaterial) {
        let mat: MultiMaterial = mesh.material;
        for (let i = 0; i < mat.subMaterials.length; i++) {
          if (!mat.subMaterials[i]) {
            continue;
          }
          setMaterialColor(mat.subMaterials[i], state._originalEmissives[i]);
        }
      } else if (mesh.material && state._originalEmissives[0]) {
        setMaterialColor(mesh.material, state._originalEmissives[0]);
      }
    },

    system: function (dt, states) {
      if (states.length === 0) {
        return;
      }

      _diffuseColor.set(
        Math.sin(alpha) / speed,
        Math.sin(alpha) / speed + 0.1,
        Math.sin(alpha) / speed + 0.1
      );
      alpha -= 0.2;
    },
  };
};
