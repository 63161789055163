import { Scalar, Vector3 } from "@babylonjs/core";

const negativeOne = new Vector3(1, -1, 1);

export default function (noa) {
  return {
    name: "characterRotation",
    order: 21,
    state: {
      player: null,
      locked: false,
    },

    renderSystem: function (dt, states) {
      for (let i = 0; i < states.length; i++) {
        const id = states[i].__id;

        const player = states[i].player;

        if (player.movement.locked) {
          continue;
        }

        let rotate;
        if (noa.camera.panWithMovement && id === noa.playerEntity) {
          if (noa.inputs.state.backward) {
            rotate = noa.camera.heading + Math.PI;
          } else if (!player.movement.locked) {
            rotate = noa.camera.heading;
          }
        } else {
          rotate = player.movement.heading;
        }

        if (player && rotate !== player.rotation.y) {
          let y;

          if (noa.inputs.panCamera && id === noa.playerEntity) {
            y = rotate;
          } else {
            const rad =
              Scalar.DeltaAngle(
                (player.rotation.y * 180) / Math.PI,
                (rotate * 180) / Math.PI
              ) *
              (Math.PI / 180);
            const step = Scalar.SmoothStep(
              player.rotation.y,
              rad + player.rotation.y,
              0.2 * Scalar.Sign(rotate)
            );

            y = step;
          }

          player.rotation.y = y;
        }
      }
    },
  };
}
