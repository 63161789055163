import nengi from "nengi";

export class AuthenticationCommand {
  constructor({ username, id }) {
    this.username = username;
    this.id = id;
  }

  username: string;
  id: string;

  static protocol = {
    username: nengi.UTF8String,
    id: nengi.UTF8String,
  };
}
