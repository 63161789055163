export const itemId = (
  blockID: number,
  variant: number,
  stringOnly: boolean = false
) => {
  const id = `${blockID}-${variant}`;

  if (stringOnly) {
    return id;
  } else {
    return Symbol.for(id);
  }
};

export const fromItemIdToLists = (
  ids: Array<string>,
  blockIDs: Array<number>,
  variantIDs: Array<number>
) => {
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const [blockID, variant] = id.split("-");
    blockIDs[i] = Number(blockID);
    variantIDs[i] = Number(variant);
  }
};
