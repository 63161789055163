import {
  AbstractMesh,
  BaseTexture,
  Color3,
  Effect,
  EffectFallbacks,
  IAnimatable,
  IEffectCreationOptions,
  MaterialDefines,
  MaterialFlags,
  MaterialHelper,
  Matrix,
  Mesh,
  Nullable,
  PushMaterial,
  Scene,
  SerializationHelper,
  SubMesh,
  VertexBuffer,
  _TypeStore,
  ShaderMaterial,
  Texture,
} from "@babylonjs/core";
import TerrainFragmentShader from "./TerrainMaterial.fragment.fx";
import TerrainVertexShader from "./TerrainMaterial.vertex.fx";
import TerrainUboDeclaration from "./TerrainMaterial.uboDeclaration.fx";

Effect.ShadersStore["TerrainFragmentShader"] = TerrainFragmentShader;
Effect.IncludesShadersStore["TerrainUboDeclaration"] = TerrainUboDeclaration;
Effect.ShadersStore["TerrainVertexShader"] = TerrainVertexShader;

const shaderName = "Terrain";
const uniforms = [
  "world",
  "view",
  "viewProjection",
  "vEyePosition",
  "vLightsType",
  "vFogInfos",
  "vFogColor",

  "vDiffuseInfos",
  "diffuseMatrix",
  "pointSize",
  "vDiffuseColor",
];
const samplers = ["diffuseSampler"];

class TerrainMaterialDefines extends MaterialDefines {
  public DIFFUSE = false;
  public INSTANCES = false;
  public SPECULARTERM = true;
  public ALPHATEST = false;
  public DEPTHPREPASS = false;
  public POINTSIZE = false;
  public FOG = false;
  public NORMAL = false;
  public UV1 = true;
  public UV2 = false;
  public VERTEXCOLOR = false;
  public VERTEXALPHA = false;

  constructor() {
    super();
    this.rebuild();
  }
}

export class TerrainMaterial extends PushMaterial {
  protected _rebuildInParallel = false;
  set diffuseTexture(value: BaseTexture) {
    this._diffuseTexture = value;
    this._markAllSubMeshesAsTexturesAndMiscDirty();
  }
  get diffuseTexture() {
    return this._diffuseTexture;
  }
  private _diffuseTexture: BaseTexture;

  get diffuseColor() {
    return this.diffuse;
  }

  set diffuseColor(value: Color3) {
    this.diffuse = value;
  }
  diffuse = new Color3(1, 1, 1);

  get disableLighting() {
    return this._disableLighting;
  }

  _disableLighting = false;
  set disableLighting(value: boolean) {
    this._disableLighting = value;
    this._markAllSubMeshesAsLightsDirty();
  }

  get maxSimultaneousLights() {
    return this._maxSimultaneousLights;
  }

  _maxSimultaneousLights = 4;
  set maxSimultaneousLights(value: number) {
    this._maxSimultaneousLights = value;
    this._markAllSubMeshesAsLightsDirty();
  }

  constructor(name: string, scene: Scene) {
    super(name, scene);
  }

  public needAlphaBlending(): boolean {
    return this.alpha < 1.0;
  }

  public needAlphaTesting(): boolean {
    return false;
  }

  public getAlphaTestTexture(): Nullable<BaseTexture> {
    return null;
  }

  // Methods
  public isReadyForSubMesh(
    mesh: AbstractMesh,
    subMesh: SubMesh,
    useInstances?: boolean
  ): boolean {
    if (this.isFrozen) {
      if (subMesh.effect && subMesh.effect._wasPreviouslyReady) {
        return true;
      }
    }

    if (!subMesh._materialDefines) {
      subMesh._materialDefines = new TerrainMaterialDefines();
    }

    var defines = <TerrainMaterialDefines>subMesh._materialDefines;
    var scene = this.getScene();

    var engine = scene.getEngine();

    // Textures
    if (defines._areTexturesDirty) {
      defines._needUVs = false;
      if (scene.texturesEnabled) {
        if (this._diffuseTexture && MaterialFlags.DiffuseTextureEnabled) {
          if (!this._diffuseTexture.isReady()) {
            return false;
          } else {
            defines._needUVs = true;
            defines.DIFFUSE = true;
          }
        }
      }
    }

    // Misc.
    MaterialHelper.PrepareDefinesForMisc(
      mesh,
      scene,
      false,
      this.pointsCloud,
      this.fogEnabled,
      this._shouldTurnAlphaTestOn(mesh),
      defines
    );

    // Lights
    defines._needNormals = MaterialHelper.PrepareDefinesForLights(
      scene,
      mesh,
      defines,
      false,
      this._maxSimultaneousLights,
      this._disableLighting
    );

    // Values that need to be evaluated on every frame
    MaterialHelper.PrepareDefinesForFrameBoundValues(
      scene,
      engine,
      defines,
      useInstances ? true : false
    );

    // Attribs
    MaterialHelper.PrepareDefinesForAttributes(
      mesh,
      defines,
      true,
      false,
      false,
      false
    );

    // Get correct effect
    if (defines.isDirty) {
      const lightDisposed = defines._areLightsDisposed;
      defines.markAsProcessed();
      scene.resetCachedMaterial();

      // Fallbacks
      var fallbacks = new EffectFallbacks();
      if (defines.FOG) {
        fallbacks.addFallback(1, "FOG");
      }

      MaterialHelper.HandleFallbacksForShadows(
        defines,
        fallbacks,
        this.maxSimultaneousLights
      );

      //Attributes
      var attribs = [VertexBuffer.PositionKind, "tile"];

      if (defines.NORMAL) {
        attribs.push(VertexBuffer.NormalKind);
      }

      if (defines.UV1) {
        attribs.push(VertexBuffer.UVKind);
      }

      if (defines.UV2) {
        attribs.push(VertexBuffer.UV2Kind);
      }

      if (defines.VERTEXCOLOR) {
        attribs.push(VertexBuffer.ColorKind);
      }

      var join = defines.toString();

      var uniformBuffers = ["Scene", "Material"];

      MaterialHelper.PrepareUniformsAndSamplersList(<IEffectCreationOptions>{
        uniformsNames: uniforms,
        uniformBuffersNames: uniformBuffers,
        samplers: samplers,
        defines: defines,
        maxSimultaneousLights: this.maxSimultaneousLights,
      });
      let previousEffect = subMesh.effect;
      let effect = scene.getEngine().createEffect(
        shaderName,
        <IEffectCreationOptions>{
          attributes: attribs,
          uniformsNames: uniforms,
          uniformBuffersNames: uniformBuffers,
          samplers: samplers,
          defines: join,
          fallbacks: fallbacks,
          onCompiled: this.onCompiled,
          onError: this.onError,
          indexParameters: {
            maxSimultaneousLights: this._maxSimultaneousLights - 1,
          },
        },
        engine
      );

      if (effect) {
        // Use previous effect while new one is compiling
        if (
          this.allowShaderHotSwapping &&
          previousEffect &&
          !effect.isReady()
        ) {
          effect = previousEffect;
          this._rebuildInParallel = true;
          defines.markAsUnprocessed();

          if (lightDisposed) {
            // re register in case it takes more than one frame.
            defines._areLightsDisposed = true;
            return false;
          }
        } else {
          this._rebuildInParallel = false;
          scene.resetCachedMaterial();
          subMesh.setEffect(effect, defines);

          this.buildUniformLayout();
        }
      }
    }

    if (!subMesh.effect || !subMesh.effect.isReady()) {
      return false;
    }

    defines._renderId = scene.getRenderId();
    subMesh.effect._wasPreviouslyReady = true;

    return true;
  }

  buildUniformLayout() {
    let ubo = this._uniformBuffer;
    ubo.addUniform("vDiffuseInfos", 2);
    ubo.addUniform("diffuseMatrix", 16);
    ubo.addUniform("pointSize", 1);
    ubo.addUniform("vDiffuseColor", 4);

    ubo.create();
  }

  public bindForSubMesh(world: Matrix, mesh: Mesh, subMesh: SubMesh): void {
    var scene = this.getScene();

    var defines = <TerrainMaterialDefines>subMesh._materialDefines;
    if (!defines) {
      return;
    }

    var effect = subMesh.effect;
    if (!effect) {
      return;
    }
    this._activeEffect = effect;

    if (defines.OBJECTSPACE_NORMALMAP) {
      world.toNormalMatrix(this._normalMatrix);
      this.bindOnlyNormalMatrix(this._normalMatrix);
    }

    // Matrices
    this.bindOnlyWorldMatrix(world);
    const mustRebind = this._mustRebind(scene, effect, mesh.visibility);
    let ubo = this._uniformBuffer;

    if (mustRebind) {
      ubo.bindToEffect(effect, "Material");
      this.bindView(effect);
      this.bindViewProjection(effect);

      if (!ubo.useUbo || !this.isFrozen || !ubo.isSync) {
        // Textures
        if (this._diffuseTexture && MaterialFlags.DiffuseTextureEnabled) {
          ubo.updateFloat2(
            "vDiffuseInfos",
            this._diffuseTexture.coordinatesIndex,
            this._diffuseTexture.level
          );
          ubo.updateMatrix(
            "diffuseMatrix",
            this._diffuseTexture.getTextureMatrix()
          );
        }

        // Point size
        if (this.pointsCloud) {
          ubo.updateFloat("pointSize", this.pointSize);
        }

        ubo.updateColor4(
          "vDiffuseColor",
          this.diffuseColor,
          this.alpha * mesh.visibility
        );
      }

      if (!this.isFrozen) {
        // Lights
        if (scene.lightsEnabled && !this.disableLighting) {
          MaterialHelper.BindLights(
            scene,
            mesh,
            this._activeEffect,
            defines,
            this.maxSimultaneousLights
          );
        }

        // Fog
        if (this.fogEnabled) {
          MaterialHelper.BindFogParameters(scene, mesh, this._activeEffect);
        }

        if (this.useLogarithmicDepth) {
          MaterialHelper.BindLogDepth(defines, effect, scene);
        }

        if (
          this._imageProcessingConfiguration &&
          !this._imageProcessingConfiguration.applyByPostProcess
        ) {
          this._imageProcessingConfiguration.bind(this._activeEffect);
        }
      }

      MaterialHelper.BindEyePosition(effect, scene);

      if (MaterialFlags.DiffuseTextureEnabled) {
        this._activeEffect.setTexture("diffuseSampler", this._diffuseTexture);
      }
    }

    ubo.update();
    this._afterBind(mesh, this._activeEffect);
  }

  public getAnimatables(): IAnimatable[] {
    var results = [];

    if (
      this._diffuseTexture &&
      this._diffuseTexture.animations &&
      this._diffuseTexture.animations.length > 0
    ) {
      results.push(this._diffuseTexture);
    }

    return results;
  }

  public getActiveTextures(): BaseTexture[] {
    var activeTextures = super.getActiveTextures();

    if (this._diffuseTexture) {
      activeTextures.push(this._diffuseTexture);
    }

    return activeTextures;
  }

  public hasTexture(texture: BaseTexture): boolean {
    if (super.hasTexture(texture)) {
      return true;
    }

    if (this.diffuseTexture === texture) {
      return true;
    }

    return false;
  }

  public dispose(forceDisposeEffect?: boolean): void {
    if (this._diffuseTexture) {
      this._diffuseTexture.dispose();
    }

    super.dispose(forceDisposeEffect);
  }

  public clone(name: string): TerrainMaterial {
    return SerializationHelper.Clone<TerrainMaterial>(
      () => new TerrainMaterial(name, this.getScene()),
      this
    );
  }

  public serialize(): any {
    var serializationObject = SerializationHelper.Serialize(this);
    serializationObject.customType = "Present.TerrainMaterial";
    return serializationObject;
  }

  public getClassName(): string {
    return "TerrainMaterial";
  }

  // Statics
  public static Parse(
    source: any,
    scene: Scene,
    rootUrl: string
  ): TerrainMaterial {
    return SerializationHelper.Parse(
      () => new TerrainMaterial(source.name, scene),
      source,
      scene,
      rootUrl
    );
  }
}

export const getTerrainMaterial = (
  texture: Texture,
  size,
  totalSize,
  scene: Scene
) => {
  const mat = new TerrainMaterial("terrain", scene);
  mat.diffuseTexture = texture;
  mat.maxSimultaneousLights = 4;
  mat.diffuseColor = new Color3(1.1, 1.3, 1.7);
  // mat.checkReadyOnlyOnce = true;

  return mat;
};
