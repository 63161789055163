import nengi from "nengi";

class Identity {
  constructor(rawId, smoothId) {
    // super();
    this.rawId = rawId;
    this.smoothId = smoothId;
  }

  rawId: number;
  smoothId: number;

  static protocol = {
    rawId: nengi.UInt16,
    smoothId: nengi.UInt16,
  };
}

export default Identity;
