'use strict'; // code generated by pbf v3.2.1

// MapChunkRequest ========================================

var MapChunkRequest = exports.MapChunkRequest = {};

MapChunkRequest.read = function (pbf, end) {
    return pbf.readFields(MapChunkRequest._readField, {id: "", x: 0, y: 0, z: 0, strideX: 0, strideY: 0, strideZ: 0, shapeX: 0, shapeY: 0, shapeZ: 0, worldId: 0}, end);
};
MapChunkRequest._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.id = pbf.readString();
    else if (tag === 2) obj.x = pbf.readVarint(true);
    else if (tag === 3) obj.y = pbf.readVarint(true);
    else if (tag === 4) obj.z = pbf.readVarint(true);
    else if (tag === 5) obj.strideX = pbf.readSVarint();
    else if (tag === 6) obj.strideY = pbf.readSVarint();
    else if (tag === 7) obj.strideZ = pbf.readSVarint();
    else if (tag === 8) obj.shapeX = pbf.readSVarint();
    else if (tag === 9) obj.shapeY = pbf.readSVarint();
    else if (tag === 10) obj.shapeZ = pbf.readSVarint();
    else if (tag === 11) obj.worldId = pbf.readSVarint();
};
MapChunkRequest.write = function (obj, pbf) {
    if (obj.id) pbf.writeStringField(1, obj.id);
    if (obj.x) pbf.writeVarintField(2, obj.x);
    if (obj.y) pbf.writeVarintField(3, obj.y);
    if (obj.z) pbf.writeVarintField(4, obj.z);
    if (obj.strideX) pbf.writeSVarintField(5, obj.strideX);
    if (obj.strideY) pbf.writeSVarintField(6, obj.strideY);
    if (obj.strideZ) pbf.writeSVarintField(7, obj.strideZ);
    if (obj.shapeX) pbf.writeSVarintField(8, obj.shapeX);
    if (obj.shapeY) pbf.writeSVarintField(9, obj.shapeY);
    if (obj.shapeZ) pbf.writeSVarintField(10, obj.shapeZ);
    if (obj.worldId) pbf.writeSVarintField(11, obj.worldId);
};
