import ndarray from "ndarray";
import { TypedArray } from "structurae";
import { unindex } from "lib/ndarray-utils";
type VectorArray = [number, number, number];

const emptyArray = [];
export class LazyNDArray {
  ndarray: ndarray;
  stride: VectorArray;
  offset: number;
  shape: VectorArray;
  ArrayType = Uint32Array;

  get isEmpty() {
    return !this.ndarray;
  }

  index(x: number, y: number, z: number) {
    if (!this.ndarray) {
      return -1;
    }

    return this.ndarray.index(x, y, z);
  }

  unindex(index: number, ref: Array<number>) {
    if (!this.ndarray) {
      return null;
    }

    return unindex(this.ndarray, index, ref);
  }

  constructor(
    creator: TypedArray | null,
    shape: VectorArray,
    stride: VectorArray,
    offset: number
  ) {
    if (creator && creator.length) {
      this.ndarray = ndarray(creator, shape, stride, offset);
      this.shape = this.ndarray.shape;
      this.stride = this.ndarray.stride;
      this.offset = this.ndarray.offset;
    } else {
      this.shape = shape;
      this.stride = stride;
      this.offset = 0;
    }
  }

  get data() {
    if (this.ndarray) {
      return this.ndarray.data;
    } else {
      return emptyArray;
    }
  }

  get(x: number, y: number, z: number) {
    if (this.isEmpty) {
      return 0;
    } else {
      return this.ndarray.get(x, y, z);
    }
  }

  set(x: number, y: number, z: number, value: number) {
    if (this.isEmpty) {
      const { ArrayType, shape, stride, offset } = this;
      this.ndarray = ndarray(
        new ArrayType(shape[0] * shape[1] * shape[2]),
        shape,
        stride,
        offset
      );
    }

    return this.ndarray.set(x, y, z, value);
  }
}
