import {
  SceneComponentConstants,
  TransformNode,
  UniversalCamera,
} from "@babylonjs/core";
import { OctreeSceneComponent } from "@babylonjs/core/Culling/Octrees/";
import { Engine } from "@babylonjs/core/Engines/engine";
import { HemisphericLight } from "@babylonjs/core/Lights/hemisphericLight";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { Color3, Vector3 } from "@babylonjs/core/Maths/math";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import "@babylonjs/core/Meshes/meshBuilder";
import { Scene } from "@babylonjs/core/scene";
import { getMesh } from "game/components/getMesh";
import glvec3 from "gl-vec3";
import { isChrome } from "lib/browser";
import { ChunkOctree } from "./ChunkOctree";
import { makeProfileHook1, removeUnorderedListItem } from "./util";

// profiling flag
const PROFILE = 0;

const chunkPrepLoc = new Float32Array(3);
const emptyFunc = (entry, block) => {};
const defaults = {
  showFPS: false,
  antiAlias: true,
  clearColor: [0.8, 0.9, 1],
  ambientColor: [1, 1, 1],
  lightDiffuse: [1, 1, 1],
  lightSpecular: [1, 1, 1],
  groundLightColor: [0.5, 0.5, 0.5],
  useAO: isChrome(),
  AOmultipliers: [0.93, 0.8, 0.5],
  reverseAOmultiplier: 1.0,
  preserveDrawingBuffer: true,
};

/**
 * @class
 * @typicalname noa.rendering
 * @classdesc Manages all rendering, and the BABYLON scene, materials, etc.
 */

export class Rendering {
  constructor(noa, opts, canvas) {
    this.noa = noa;

    /**
     * `noa.rendering` uses the following options (from the root `noa(opts)` options):
     * ```js
     * {
     *   showFPS: false,
     *   antiAlias: true,
     *   clearColor: [0.8, 0.9, 1],
     *   ambientColor: [1, 1, 1],
     *   lightDiffuse: [1, 1, 1],
     *   lightSpecular: [1, 1, 1],
     *   groundLightColor: [0.5, 0.5, 0.5],
     *   useAO: true,
     *   AOmultipliers: [0.93, 0.8, 0.5],
     *   reverseAOmultiplier: 1.0,
     *   preserveDrawingBuffer: true,
     * }
     * ```
     */
    opts = Object.assign({}, defaults, opts);

    this.startResizeObserver();

    // internals
    // this.useAO = !!opts.useAO;
    this.useAO = !!opts.useAO;
    this.aoVals = opts.AOmultipliers;
    this.revAoVal = opts.reverseAOmultiplier;
    this.meshingCutoffTime = 6; // ms

    // set up babylon scene
    // init internal properties
    this._engine = new Engine(
      canvas,
      true,
      {
        preserveDrawingBuffer: false,
        // stencil: true,
        adaptToDeviceRatio: true,

        // depth: true,
      },
      true
    );
    this._engine.useReverseDepthBuffer = true;
    this._scene = new Scene(this._engine, {
      useGeometryUniqueIdsMap: true,
      useClonedMeshhMap: true,
    });
    // this._scene.useDelayedTextureLoading = true;

    const scene = this._scene;
    // remove built-in listeners
    scene.detachControl();

    let component = scene._getComponent(SceneComponentConstants.NAME_OCTREE);
    if (!component) {
      component = new OctreeSceneComponent(scene);
      scene._addComponent(component);
    }

    this.noa.on("tick", this._octree.tick, this._octree);
    component.register();
    this._cameraHolder = new TransformNode("cameraHolder", scene);
    this._camera = new UniversalCamera("camera", Vector3.Zero(), null, scene);

    this._camera.minZ = 0.01;
    this._octree.noa = this.noa;
    this._camera.parent = this._cameraHolder;

    // plane obscuring the camera - for overlaying an effect on the whole view
    this._camScreen = Mesh.CreatePlane("camScreen", 10, scene);
    this.addMeshToScene(this._camScreen);
    this._camScreen.position.z = 0.1;
    this._camScreen.parent = this._camera;
    this._camScreenMat = this.makeStandardMaterial("camscreenmat");
    this._camScreen.material = this._camScreenMat;
    this._camScreen.setEnabled(false);
    this._camLocBlock = 0;

    // apply some defaults
    const lightVec = new Vector3(0.1, 1, 0.3);
    this._light = new HemisphericLight("light", lightVec, scene);

    function arrToColor(a) {
      return new Color3(a[0], a[1], a[2]);
    }
    scene.clearColor = arrToColor(opts.clearColor);
    scene.ambientColor = arrToColor(opts.ambientColor);
    this._light.diffuse = arrToColor(opts.lightDiffuse);
    this._light.specular = arrToColor(opts.lightSpecular);
    this._light.groundColor = arrToColor(opts.groundLightColor);
    // make a default flat material (used or clone by terrain, etc)
    this.flatMaterial = this.makeStandardMaterial("flatmat");
    this.flatMaterial.freeze();

    // for debugging
    if (opts.showFPS) setUpFPS();
  }

  _octree = new ChunkOctree(emptyFunc);
  resizeObserver;

  startResizeObserver = async () => {
    if ("ResizeObserver" in window === false) {
      // Loads polyfill asynchronously, only if required.
      const module = await import("@juggle/resize-observer");
      window.ResizeObserver = module.ResizeObserver;
    }
    // Uses native or polyfill, depending on browser support.
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      if (this.noa.autoResize) {
        this.resize();
      }
    });

    this.resizeObserver.observe(this.noa.container.element);
  };

  /*
   *   PUBLIC API
   */

  /**
   * The Babylon `scene` object representing the game world.
   * @member
   */
  getScene() {
    return this._scene;
  }

  // per-tick listener for rendering-related stuff
  tick(dt) {
    // nothing here at the moment
  }

  _lastTargetX = 0;
  _lastTargetZ = 0;
  updateCameraForRender() {
    const cam = this.noa.camera;
    const cloc = cam._localGetPosition();

    const mesh = getMesh(this.noa, this.noa.playerEntity);

    // this._cameraHolder.position.copyFromFloats(cloc[0], cloc[1], cloc[2]);
    // this._camera.rotation.x = 0;
    // this._camera.rotation.y = 0;

    this._cameraHolder.rotation.x = cam.pitch;
    this._cameraHolder.rotation.y = cam.heading;
    this._camera.position.z = -cam.currentZoom;
    this._camera.position.y = 1.5;

    if (mesh && this._cameraHolder.position !== mesh.position) {
      this._cameraHolder.position = mesh.position;

      this._camera.freezeProjectionMatrix();
    }

    // this._camera.
    // this._camera.cameraRotation.y = Math.PI / 2;

    // this._camera.cameraRotation.y = 0.19;

    // this._camera.position.copyFromFloats(tgtLoc[0], tgtLoc[1], tgtLoc[2]);
    // this._camera.position.z -= cam.currentZoom;
    // this._camera.rotation.x =
    // }

    // applies screen effect when camera is inside a transparent voxel

    const off = this.noa.worldOriginOffset;
    const cx = Math.floor(cloc[0] + off[0]);
    const cy = Math.floor(cloc[1] + off[1]);
    const cz = Math.floor(cloc[2] + off[2]);
    const id = this.noa.getBlock(cx, cy, cz);
    checkCameraEffect(this, id);
  }

  render(dt) {
    profile_hook("start");
    this.updateCameraForRender();
    profile_hook("updateCamera");

    this._engine.beginFrame();

    profile_hook("beginFrame");
    this._scene.render();
    profile_hook("render");
    fps_hook();
    this._engine.endFrame();
    profile_hook("endFrame");
    profile_hook("end");
  }

  resize(e) {
    this._engine.resize();
    this._cameraHolder.computeWorldMatrix();
    this._camera.computeWorldMatrix();
    this._camera.unfreezeProjectionMatrix();
    this._camera.getProjectionMatrix(true);
    this._camera.freezeProjectionMatrix();
  }

  highlightBlockFace(show, posArr, normArr) {
    const m = getHighlightMesh(this);
    if (show) {
      // floored local coords for highlight mesh
      this.noa.globalToLocal(posArr, null, hlpos);
      // offset to avoid z-fighting, bigger when camera is far away
      const dist = glvec3.dist(this.noa.camera._localGetPosition(), hlpos);
      const slop = 0.001 + 0.001 * dist;
      for (let i = 0; i < 3; i++) {
        if (normArr[i] === 0) {
          hlpos[i] += 0.5;
        } else {
          hlpos[i] += normArr[i] > 0 ? 1 + slop : -slop;
        }
      }
      m.position.copyFromFloats(hlpos[0], hlpos[1], hlpos[2]);
      m.rotation.x = normArr[1] ? Math.PI / 2 : 0;
      m.rotation.y = normArr[0] ? Math.PI / 2 : 0;
    }
    m.setEnabled(show);
  }

  /**
   * Add a mesh to the scene's octree setup so that it renders.
   *
   * @param mesh: the mesh to add to the scene
   * @param isStatic: pass in true if mesh never moves (i.e. change octree blocks)
   * @param position: (optional) global position where the mesh should be
   * @param chunk: (optional) chunk to which the mesh is statically bound
   * @method
   */
  addMeshToScene = (mesh, isStatic, pos, _containingChunk) => {
    if (!mesh || !mesh.getTotalVertices) {
      return;
    }

    // find local position for mesh and move it there (unless it's parented)
    if (!mesh.parent && pos) {
      if (!pos) pos = [mesh.position.x, mesh.position.y, mesh.position.z];
      const lpos = [];
      this.noa.globalToLocal(pos, null, lpos);
      mesh.position.copyFromFloats(lpos[0], lpos[1], lpos[2]);
    }

    if (isStatic) {
      this._octree.addStaticContent(mesh);
    } else {
      this._octree.addDynamicContent(mesh);
    }

    if (isStatic === true) {
      mesh.freezeWorldMatrix();
      if (mesh.freezeNormals) {
        mesh.freezeNormals();
      }
    }
    mesh.onDisposeObservable.addOnce(this.removeMeshFromScene);
    // add dispose event to undo everything done here
  };

  /**  Undoes everything `addMeshToScene` does
   * @method
   */
  removeMeshFromScene = (mesh) => {
    if (this._octree.hasDynamicContent(mesh)) {
      this._octree.removeDynamicContent(mesh);
    } else if (this._octree.hasStaticContent(mesh)) {
      this._octree.removeMesh(mesh);
    }

    // if (mesh._noaContainingChunk && mesh._noaContainingChunk.octreeBlock) {
    //   removeUnorderedListItem(
    //     mesh._noaContainingChunk.octreeBlock.entries,
    //     mesh
    //   );
    // }
    // mesh._noaContainingChunk = null;
    // removeUnorderedListItem(this._octree.dynamicContent, mesh);
  };

  // Create a default standardMaterial:
  //      flat, nonspecular, fully reflects diffuse and ambient light
  makeStandardMaterial(name) {
    const mat = new StandardMaterial(name, this._scene);
    mat.specularColor.copyFromFloats(0, 0, 0);
    mat.ambientColor.copyFromFloats(1, 1, 1);
    mat.diffuseColor.copyFromFloats(1, 1, 1);
    return mat;
  }

  /*
   *
   *
   *   ACCESSORS FOR CHUNK ADD/REMOVAL/MESHING
   *
   *
   */

  prepareChunkForRendering(chunk, meshOrMeshes) {
    const cs = chunk.size;
    this.noa.globalToLocal(chunk.pos, null, chunkPrepLoc);
    Vector3.FromArrayToRef(chunkPrepLoc, 0, meshOrMeshes.position);

    this._octree.addChunk(chunk, meshOrMeshes);
  }
  hasCreateOctreeBlock = false;

  disposeChunkForRendering(chunk) {
    if (!chunk.octreeBlock) return;
    removeUnorderedListItem(this._octree.blocks, chunk.octreeBlock);
    chunk.octreeBlock.entries.length = 0;
    chunk.octreeBlock = null;
  }

  /*
   *
   *   INTERNALS
   *
   */

  // change world origin offset, and rebase everything with a position

  _rebaseOrigin(delta) {
    const dvec = new Vector3(delta[0], delta[1], delta[2]);

    for (let i = 0; i < this._scene.meshes.length; i++) {
      const mesh = this._scene.meshes[i];

      // parented meshes don't live in the world coord system
      if (mesh.parent) continue;

      // move each mesh by delta (even though most are managed by components)
      mesh.position.subtractInPlace(dvec);

      if (mesh._isWorldMatrixFrozen) {
        mesh.markAsDirty();

        if (mesh._noaContainingChunk) {
          for (let child of mesh.getChildren(undefined, false)) {
            child.markAsDirty();
          }
        }
      }
    }

    for (let i = 0; i < this._scene.transformNodes.length; i++) {
      const mesh = this._scene.transformNodes[i];

      // parented meshes don't live in the world coord system
      if (mesh.parent) continue;

      // move each mesh by delta (even though most are managed by components)
      mesh.position.subtractInPlace(dvec);

      if (mesh._isWorldMatrixFrozen) {
        mesh.markAsDirty();

        if (mesh._noaContainingChunk) {
          for (let child of mesh.getChildren(undefined, false)) {
            child.markAsDirty();
          }
        }
      }
    }

    // update octree block extents
    // for (let i = 0; i < this._octree.blocks.length; i++) {
    //   const block = this._octree.blocks[i];

    //   block.minPoint.subtractInPlace(dvec);
    //   block.maxPoint.subtractInPlace(dvec);
    //   for (let j = 0; j < block._boundingVectors.length; j++) {
    //     const v = block._boundingVectors[j];
    //     v.subtractInPlace(dvec);
    //   }
    // }
  }

  /*
   *
   *      sanity checks:
   *
   */

  debug_SceneCheck() {
    const meshes = this._scene.meshes;
    const dyns = this._octree.dynamicContent;
    const octs = [];
    let numOcts = 0;
    let numSubs = 0;
    const mats = this._scene.materials;
    const allmats = [];
    mats.forEach((mat) => {
      if (mat.subMaterials)
        mat.subMaterials.forEach((mat) => allmats.push(mat));
      else allmats.push(mat);
    });
    this._octree.blocks.forEach(({ entries }) => {
      numOcts++;
      entries.forEach((m) => octs.push(m));
    });
    meshes.forEach((m) => {
      if (m._isDisposed) warn(m, "disposed mesh in scene");
      if (empty(m)) return;
      if (missing(m, dyns, octs)) warn(m, "non-empty mesh missing from octree");
      if (!m.material) {
        warn(m, "non-empty scene mesh with no material");
        return;
      }
      numSubs += m.subMeshes ? m.subMeshes.length : 1;
      const mats = m.material.subMaterials || [m.material];
      mats.forEach((mat) => {
        if (missing(mat, mats)) warn(mat, "mesh material not in scene");
      });
    });
    const unusedMats = [];
    allmats.forEach((mat) => {
      let used = false;
      meshes.forEach(({ material }) => {
        if (material === mat) used = true;
        if (!material || !material.subMaterials) return;
        if (material.subMaterials.includes(mat)) used = true;
      });
      if (!used) unusedMats.push(mat.name);
    });
    if (unusedMats.length) {
      console.warn("Materials unused by any mesh: ", unusedMats.join(", "));
    }
    dyns.forEach((m) => {
      if (missing(m, meshes)) warn(m, "octree/dynamic mesh not in scene");
    });
    octs.forEach((m) => {
      if (missing(m, meshes)) warn(m, "octree block mesh not in scene");
    });
    const avgPerOct = Math.round((10 * octs.length) / numOcts) / 10;
    console.log(
      "meshes - octree:",
      octs.length,
      "  dynamic:",
      dyns.length,
      "   subMeshes:",
      numSubs,
      "   avg meshes/octreeBlock:",
      avgPerOct
    );

    function warn({ name }, msg) {
      console.warn(`${name} --- ${msg}`);
    }

    function empty(mesh) {
      return mesh.getIndices().length === 0;
    }

    function missing(obj, list1, list2) {
      if (!obj) return false;
      if (list1.includes(obj)) return false;
      if (list2 && list2.includes(obj)) return false;
      return true;
    }
    return "done.";
  }

  debug_MeshCount() {
    const ct = {};
    this._scene.meshes.forEach(({ name }) => {
      let n = name || "";
      n = n.replace(/-\d+.*/, "#");
      n = n.replace(/\d+.*/, "#");
      n = n.replace(/(rotHolder|camHolder|camScreen)/, "rendering use");
      n = n.replace(/atlas sprite .*/, "atlas sprites");
      ct[n] = ct[n] || 0;
      ct[n]++;
    });
    for (const s in ct) console.log(`   ${`${ct[s]}       `.substr(0, 7)}${s}`);
  }
}

// Constructor helper - set up the Babylon.js scene and basic components

var pendingResize = false;

var hlpos = [];

//  If camera's current location block id has alpha color (e.g. water), apply/remove an effect

function checkCameraEffect(self, id) {
  //   if (id === self._camLocBlock) return;
  //   if (id === 0) {
  //     self._camScreen.setEnabled(false);
  //   } else {
  //     const matId = self.noa.registry.getBlockFaceMaterial(id, 0);
  //     if (matId) {
  //       const matData = self.noa.registry.getMaterialData(matId);
  //       const col = matData.color;
  //       const alpha = matData.alpha;
  //       if (col && alpha && alpha < 1) {
  //         self._camScreenMat.diffuseColor.set(0, 0, 0);
  //         self._camScreenMat.ambientColor.set(col[0], col[1], col[2]);
  //         self._camScreenMat.alpha = alpha;
  //         self._camScreen.setEnabled(true);
  //       }
  //     }
  //   }
  //   self._camLocBlock = id;
}

// make or get a mesh for highlighting active voxel
function getHighlightMesh(rendering) {
  let mesh = rendering._highlightMesh;
  if (!mesh) {
    mesh = Mesh.CreatePlane("highlight", 1.0, rendering._scene);
    const hlm = rendering.makeStandardMaterial("highlightMat");
    hlm.backFaceCulling = false;
    hlm.emissiveColor = new Color3(1, 1, 1);
    hlm.alpha = 0.2;
    mesh.material = hlm;

    // outline
    const s = 0.5;
    const lines = Mesh.CreateLines(
      "hightlightLines",
      [
        new Vector3(s, s, 0),
        new Vector3(s, -s, 0),
        new Vector3(-s, -s, 0),
        new Vector3(-s, s, 0),
        new Vector3(s, s, 0),
      ],
      rendering._scene
    );
    lines.color = new Color3(1, 1, 1);
    lines.parent = mesh;

    rendering.addMeshToScene(mesh);
    rendering.addMeshToScene(lines);
    rendering._highlightMesh = mesh;
  }
  return mesh;
}

var profile_hook = PROFILE
  ? makeProfileHook1(200, "render internals")
  : () => {};

var fps_hook = () => {};

function setUpFPS() {
  const div = document.createElement("div");
  div.id = "noa_fps";
  let style = "position:absolute; top:0; right:0; z-index:0;";
  style += "color:white; background-color:rgba(0,0,0,0.5);";
  style += "font:14px monospace; text-align:center;";
  style += "min-width:2em; margin:4px;";
  div.style = style;
  document.body.appendChild(div);
  const every = 1000;
  let ct = 0;
  let longest = 0;
  let start = performance.now();
  let last = start;
  fps_hook = () => {
    ct++;
    const nt = performance.now();
    if (nt - last > longest) longest = nt - last;
    last = nt;
    if (nt - start < every) return;
    const fps = Math.round((ct / (nt - start)) * 1000);
    const min = Math.round((1 / longest) * 1000);
    div.innerHTML = `${fps}<br>${min}`;
    ct = 0;
    longest = 0;
    start = nt;
  };
}
