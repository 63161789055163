import nengi from "nengi";
import { NengiIDType } from "../nengiTypes";

export enum ParticleEffectName {
  money = 0,
}

export class EmitParticleEffectMessage {
  constructor(
    effect: ParticleEffectName,
    quantity: number,
    seconds: number,
    player: number
  ) {
    this.effect = effect;
    this.quantity = quantity;
    this.seconds = seconds;
    this.player = player;
  }

  effect: ParticleEffectName;
  quantity: number;
  seconds: number;
  player: number;

  static protocol = {
    effect: { type: nengi.UInt16 },
    quantity: { type: nengi.UInt16 },
    seconds: { type: nengi.UInt10 },
    player: { type: NengiIDType },
  };
}
