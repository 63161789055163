export enum SocketEvent {
  newPlayer = "newPlayer",
  currentUser = "currentUser",
  connectionStatusChange = "connectionStatusChange",
  loginRequired = "loginRequired",
}

export enum InventoryEvent {
  equipItem = "equipItem",
}

export enum CharacterEvent {
  changeCharacter = "changeCharacter",
}

export enum ChatEvent {
  newMessage = "newMessage",
  newSystemMessage = "newSystemMessage",
  didFocusInput = "didFocusInput",
  didBlurInput = "didBlurInput",
  sendMessage = "sendMessage",
  toggleInputFocus = "_focusInput",
}

export enum VoiceEvent {
  publishSuccess = "publishSuccess",
  statusChange = "statusChange",
  newStream = "newStream",
  stoppedPublishing = "stoppedPublishing",
  startedPublishing = "startedPublishing",
  removedStream = "removedStream",
  muteChange = "muteChange",
}

export enum ClientEvent {
  armAnimation = "arm_animation",
  entityAction = "entity_action",
  chat = "chat",
  tabComplete = "tab_complete",
  blockDig = "block_dig",
  heldItemSlot = "held_item_slot",
  windowClick = "window_click",
  setCreativeSlot = "set_creative_slot",
  requestWorldData = "request_world_data",
  keepAlive = "keep_alive",

  blockPlace = "block_place",
  useEntity = "use_entity",
  clientCommand = "client_command",
  settings = "settings",
  look = "look",
  login = "login",
  position = "position",
  positionLook = "position_look",

  setBlockURL = "setBlockURL",
  requestBlockURL = "requestBlockURL",
}

export type EntityID = string;
export type PlayerID = string;

export type NewPlayerSpawn = {
  entityId: EntityID;
  playerUUID: PlayerID;
  x: number;
  y: number;
  z: number;
  yaw: number;
  pitch: number;
  currentItem: number;
  metadata: any;
};

export enum ServerEvent {
  unloadChunk = "unload_chunk",
  keepAlive = "keep_alive",
  mapChunk = "map_chunk",
  customMapChunk = "custom_map_chunk",
  spawnPosition = "spawn_position",
  playerSpawned = "named_entity_spawn",
  spawnEntity = "spawn_entity",
  spawnEntityLiving = "spawn_entity_living",
  despawnEntity = "entity_destroy",
  entityTeleport = "entity_teleport",
  entityLook = "entity_look",
  entityHeadMove = "entity_head_rotation",
  relEntityMove = "rel_entity_move",
}

export type DespawnEntityPacket = {
  entityIds: Array<EntityID>;
};

export enum PlayerInfoAction {
  newPlayer = 0,
  gameMode = 1,
  ping = 2,
  displayName = 3,
  left = 4,
}

export type EntityTeleportPacket = {
  entityId: EntityID;
  x: number;
  y: number;
  z: number;
  yaw: number;
  pitch: number;
  onGround: boolean;
};

export type EntityMovePacket = {
  entityId: EntityID;
  x: number;
  y: number;
  z: number;
  yaw: number;
  pitch: number;
  onGround: boolean;
};

export type PlayerInfoData = {
  UUID: string;
  name?: string;
  properties?: string;
  gamemode?: 0 | 1;
  ping?: number;
};

export type PlayerInfoPacket = {
  action: PlayerInfoAction;
  data: Array<PlayerInfoData>;
};

export type EntityLookPacket = {
  entityId: PlayerID;
  yaw: number;
  pitch: number;
  onGround: boolean;
};

export type HeadRotatePacket = {
  entityId: PlayerID;
  headYaw: number;
};
