// var createGameShell = require('../../../../npm-modules/game-shell')
import { EventEmitter } from "eventemitter3";
import { GameLoop } from "./GameLoop";

/**
 * @class
 * @typicalname noa.container
 * @emits DOMready
 * @classdesc Wraps `game-shell` module
 * and manages HTML container, canvas, etc.
 */

export class Container extends EventEmitter {
  constructor(noa, opts = {}) {
    super();
    this._noa = noa;
    this.element = opts.domElement || createContainerDiv();
    this.canvas = getOrCreateCanvas(this.element);

    // mouse state/feature detection
    this.hasPointerLock = false;
    this.supportsPointerLock = false;
    this.pointerInGame = false;
    this.isFocused = document.hasFocus();

    // basic listeners
    const self = this;
    const lockChange = (ev) => {
      onLockChange(self, ev);
    };
    document.addEventListener("pointerlockchange", lockChange, false);
    document.addEventListener("mozpointerlockchange", lockChange, false);
    document.addEventListener("webkitpointerlockchange", lockChange, false);
    detectPointerLock(self);

    self.element.addEventListener("mouseenter", () => {
      self.pointerInGame = true;
    });
    self.element.addEventListener("mouseleave", () => {
      self.pointerInGame = false;
    });

    window.addEventListener("focus", () => {
      self.isFocused = true;
    });
    window.addEventListener("blur", () => {
      self.isFocused = false;
    });

    this.loop.emitter.on("render", this._noa.render, this._noa);
    this.loop.emitter.on("tick", this._noa.tick, this._noa);
  }

  loop = new GameLoop();

  /*
   *   PUBLIC API
   */

  appendTo(htmlElement) {
    this.element.appendChild(htmlElement);
  }

  setPointerLock(lock) {
    // not sure if this will work robustly
    this._shell.pointerLock = !!lock;
  }
}

function createContainerDiv() {
  // based on github.com/mikolalysenko/game-shell - makeDefaultContainer()
  const container = document.createElement("div");
  container.tabindex = 1;
  container.style.position = "fixed";
  container.style.left = "0px";
  container.style.right = "0px";
  container.style.top = "0px";
  container.style.bottom = "0px";
  container.style.height = "100%";
  container.style.overflow = "hidden";
  document.body.appendChild(container);
  document.body.style.overflow = "hidden"; //Prevent bounce
  document.body.style.height = "100%";
  container.id = "noa-container";
  return container;
}

function getOrCreateCanvas(el) {
  // based on github.com/stackgl/gl-now - default canvas
  let canvas = el.querySelector("canvas");
  if (!canvas) {
    canvas = document.createElement("canvas");
    canvas.style.position = "absolute";
    canvas.style.left = "0px";
    canvas.style.top = "0px";
    canvas.style.height = "100%";
    canvas.style.width = "100%";
    canvas.id = "noa-canvas";
    el.insertBefore(canvas, el.firstChild);
  }
  return canvas;
}

// track changes in Pointer Lock state
function onLockChange(self, ev) {
  const el =
    document.pointerLockElement ||
    document.mozPointerLockElement ||
    document.webkitPointerLockElement;
  if (el) {
    self.hasPointerLock = true;
    self.emit("gainedPointerLock");
  } else {
    self.hasPointerLock = false;
    self.emit("lostPointerLock");
  }
  // this works around a Firefox bug where no mouse-in event
  // gets issued after starting pointerlock
  if (el) {
    // act as if pointer is in game window while pointerLock is true
    self.pointerInGame = true;
  }
}

// set up stuff to detect pointer lock support.
// Needlessly complex because Chrome/Android claims to support but doesn't.
// For now, just feature detect, but assume no support if a touch event occurs
// TODO: see if this makes sense on hybrid touch/mouse devices
function detectPointerLock(self) {
  const lockElementExists =
    "pointerLockElement" in document ||
    "mozPointerLockElement" in document ||
    "webkitPointerLockElement" in document;
  if (lockElementExists) {
    self.supportsPointerLock = true;
    const listener = ({ type }) => {
      self.supportsPointerLock = false;
      document.removeEventListener(type, listener);
    };
    document.addEventListener("touchmove", listener);
  }
}

export default Container;
