import chroma from "chroma-js";
let hslTemp = new Float32Array(3);
let hexTemp = new Array(6);

const THREE_DIGIT_HEX_STRING_LENGTH = "#000".length;
const SIX_DIGIT_HEX_STRING_LENGTH = "#FFFFFF".length;

export class Color {
  static darken(color: Uint8ClampedArray, amount: number) {
    color.set(
      chroma(color[0], color[1], color[2], "rgb").darken(amount).rgb(true)
    );
    // rgbToHsl(
    //   from[0 + fromOffset],
    //   from[1 + fromOffset],
    //   from[2 + fromOffset],
    //   hslTemp
    // );
    // hslTemp[2] = clamp01(hslTemp[2] - amount);
    // hslToRgb(hslTemp[0], hslTemp[1], hslTemp[2], to, toOffset);
  }

  static normal(top, bottom, dest, destOffset: number = 0) {
    return top;
  }
  static multiply(top, bottom, dest, destOffset: number = 0) {
    for (let i = 0; i < 4; i++) {
      dest[destOffset + i] = this.multiplyChannel(top[i], bottom[i]);
    }
    return dest;
  }
  static screen(top, bottom, dest, destOffset: number = 0) {
    for (let i = 0; i < 4; i++) {
      dest[destOffset + i] = this.screenChannel(top[i], bottom[i]);
    }
    return dest;
  }
  static overlay(
    top: Uint8ClampedArray,
    bottom: Uint8ClampedArray,
    dest: Uint8ClampedArray
  ) {
    dest.set(
      chroma
        .blend(
          chroma(top[0], top[1], top[2], "rgb"),
          chroma(bottom[0], bottom[1], bottom[2], "rgb"),
          "overlay"
        )
        .rgb(true)
    );
  }
  static lighten(top, bottom, dest, destOffset: number = 0) {
    for (let i = 0; i < 4; i++) {
      dest[destOffset + i] = this.lightenChannel(top[i], bottom[i]);
    }
    return dest;
  }
  static dodge(top, bottom, dest, destOffset: number = 0) {
    for (let i = 0; i < 4; i++) {
      dest[destOffset + i] = this.dodgeChannel(top[i], bottom[i]);
    }
    return dest;
  }
  static burn(top, bottom, dest, destOffset: number = 0) {
    for (let i = 0; i < 4; i++) {
      dest[destOffset + i] = this.burnChannel(top[i], bottom[i]);
    }
    return dest;
  }

  // https://github.com/gka/chroma.js/blob/5a562f043f7b5ccfbc35db2f55ba0f1e497de63e/src/ops/luminance.js#L40
  static luminance(r: number, g: number, b: number) {
    return (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
  }

  private static luminanceChannel(x) {
    x /= 255;
    return x <= 0.03928 ? x / 12.92 : Math.pow((x + 0.055) / 1.055, 2.4);
  }

  private static normalChannel(a) {
    return a;
  }
  private static multiplyChannel(a, b) {
    return (a * b) / 255;
  }

  private static lightenChannel(a, b) {
    a > b ? a : b;
  }
  private static screenChannel(a, b) {
    255 * (1 - (1 - a / 255) * (1 - b / 255));
  }
  private static overlayChannel(a, b) {
    b < 128 ? (2 * a * b) / 255 : 255 * (1 - 2 * (1 - a / 255) * (1 - b / 255));
  }
  private static burnChannel(a, b) {
    255 * (1 - (1 - b / 255) / (a / 255));
  }
  private static dodgeChannel(a, b) {
    if (a === 255) return 255;
    a = (255 * (b / 255)) / (1 - a / 255);
    return a > 255 ? 255 : a;
  }

  static hexToRGB(hex: string, colors: Uint8ClampedArray, offset: number) {
    if (hex.length === SIX_DIGIT_HEX_STRING_LENGTH) {
      colors[offset] = parseIntFromHex(hex.substring(0, 2));
      colors[offset + 1] = parseIntFromHex(hex.substring(2, 4));
      colors[offset + 2] = parseIntFromHex(hex.substring(4, 6));
    } else if (hex.length === THREE_DIGIT_HEX_STRING_LENGTH) {
      colors[offset] = parseIntFromHex(hex.substring(0, 2));
      colors[offset + 1] = parseIntFromHex(hex.substring(2, 4));
      colors[offset + 2] = parseIntFromHex(hex.substring(4, 6));
    } else {
      colors.fill(0, offset, offset + 3);
    }

    return colors;
  }
}

function hue2rgb(p, q, t) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

// Converts an HSL color value to RGB.
// *Assumes:* h is contained in [0, 1] or [0, 360] and s and l are contained [0, 1] or [0, 100]
// *Returns:* { r, g, b } in the set [0, 255]
function hslToRgb(h, s, l, toArray: Uint8ClampedArray, offset: number) {
  var r, g, b;

  h = bound01(h, 360);
  s = bound01(s, 100);
  l = bound01(l, 100);

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  toArray[0 + offset] = r * 255;
  toArray[1 + offset] = g * 255;
  toArray[2 + offset] = b * 255;
  toArray[3] = 255;
  return toArray;
}

// `rgbToHsv`
// Converts an RGB color value to HSV
// *Assumes:* r, g, and b are contained in the set [0, 255] or [0, 1]
// *Returns:* { h, s, v } in [0,1]
function rgbToHsv(r, g, b) {
  r = bound01(r, 255);
  g = bound01(g, 255);
  b = bound01(b, 255);

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    v = max;

  var d = max - min;
  s = max === 0 ? 0 : d / max;

  if (max == min) {
    h = 0; // achromatic
  } else {
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return { h: h, s: s, v: v };
}

// `hsvToRgb`
// Converts an HSV color value to RGB.
// *Assumes:* h is contained in [0, 1] or [0, 360] and s and v are contained in [0, 1] or [0, 100]
// *Returns:* { r, g, b } in the set [0, 255]
function hsvToRgb(h, s, v) {
  h = bound01(h, 360) * 6;
  s = bound01(s, 100);
  v = bound01(v, 100);

  var i = Math.floor(h),
    f = h - i,
    p = v * (1 - s),
    q = v * (1 - f * s),
    t = v * (1 - (1 - f) * s),
    mod = i % 6,
    r = [v, q, p, p, t, v][mod],
    g = [t, v, v, q, p, p][mod],
    b = [p, p, t, v, v, q][mod];

  return { r: r * 255, g: g * 255, b: b * 255 };
}

// `rgbToHex`
// Converts an RGB color to hex
// Assumes r, g, and b are contained in the set [0, 255]
// Returns a 3 or 6 character hex
var rgbToHexTemp: Array<string>;
function rgbToHex(r, g, b, allow3Char) {
  if (!rgbToHexTemp) {
    rgbToHexTemp = new Array(6);
  }
  var hex = rgbToHexTemp;

  hex[0] = pad2(Math.round(r).toString(16));
  hex[1] = pad2(Math.round(g).toString(16));
  hex[2] = pad2(Math.round(b).toString(16));

  // Return a 3 character hex if possible
  if (
    allow3Char &&
    hex[0].charAt(0) == hex[0].charAt(1) &&
    hex[1].charAt(0) == hex[1].charAt(1) &&
    hex[2].charAt(0) == hex[2].charAt(1)
  ) {
    return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0);
  }

  return hex.join("");
}

// `rgbaToHex`
// Converts an RGBA color plus alpha transparency to hex
// Assumes r, g, b are contained in the set [0, 255] and
// a in [0, 1]. Returns a 4 or 8 character rgba hex
function rgbaToHex(r, g, b, a, allow4Char) {
  let hex = hexTemp;
  hexTemp[0] = pad2(Math.round(r).toString(16));
  hexTemp[1] = pad2(Math.round(g).toString(16));
  hexTemp[2] = pad2(Math.round(b).toString(16));
  hexTemp[3] = pad2(convertDecimalToHex(a));

  // Return a 4 character hex if possible
  if (
    allow4Char &&
    hex[0].charAt(0) == hex[0].charAt(1) &&
    hex[1].charAt(0) == hex[1].charAt(1) &&
    hex[2].charAt(0) == hex[2].charAt(1) &&
    hex[3].charAt(0) == hex[3].charAt(1)
  ) {
    return (
      hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0) + hex[3].charAt(0)
    );
  }

  return hex.join("");
}

// `rgbaToArgbHex`
// Converts an RGBA color to an ARGB Hex8 string
// Rarely used, but required for "toFilter()"
function rgbaToArgbHex(r, g, b, a) {
  return (
    pad2(convertDecimalToHex(a)) +
    pad2(Math.round(r).toString(16)) +
    pad2(Math.round(g).toString(16)) +
    pad2(Math.round(b).toString(16))
  );
}

function rgbToHsl(r: number, g: number, b: number, ref: Float32Array) {
  r = bound01(r, 255);
  g = bound01(g, 255);
  b = bound01(b, 255);

  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max == min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h /= 6;
  }

  ref[0] = h;
  ref[1] = s;
  ref[2] = l;

  return ref;
}

// Check to see if string passed in is a percentage
function isPercentage(n) {
  return typeof n === "string" && n.indexOf("%") != -1;
}

// Need to handle 1.0 as 100%, since once it is a number, there is no difference between it and 1
// <http://stackoverflow.com/questions/7422072/javascript-how-to-detect-number-as-a-decimal-including-1-0>
function isOnePointZero(n) {
  return typeof n == "string" && n.indexOf(".") != -1 && parseFloat(n) === 1;
}

function bound01(n, max) {
  if (isOnePointZero(n)) {
    n = "100%";
  }

  var processPercent = isPercentage(n);
  n = Math.min(max, Math.max(0, parseFloat(n)));

  // Automatically convert percentage into number
  if (processPercent) {
    n = parseInt(n * max, 10) / 100;
  }

  // Handle floating point rounding errors
  if (Math.abs(n - max) < 0.000001) {
    return 1;
  }

  // Convert into [0, 1] range if it isn't already
  return (n % max) / parseFloat(max);
}

function pad2(c) {
  return c.length == 1 ? "0" + c : "" + c;
}
function convertDecimalToHex(d) {
  return Math.round(parseFloat(d) * 255).toString(16);
}

// Converts a hex value to a decimal
function convertHexToDecimal(h) {
  return parseIntFromHex(h) / 255;
}

// Parse a base-16 hex value into a base-10 integer
function parseIntFromHex(val) {
  return parseInt(val, 16);
}

// Force a number between 0 and 1
function clamp01(val) {
  return Math.min(1, Math.max(0, val));
}

self.Color = Color;
