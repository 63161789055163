import * as React from "react";

export const MicrophoneIcon = ({ width = 36, height = 36 }) => (
  <svg
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M34.02 47.869v4.138h8a2 2 0 110 4h-20a2 2 0 010-4h8v-4.138c-7.996-1.005-13.996-7.803-14-15.862v-4a2 2 0 114 0v4c0 6.627 5.373 12 12 12 6.628 0 12-5.373 12-12v-4a2 2 0 114 0v4-.001c-.002 8.06-6.002 14.858-13.998 15.862zm-2-7.862a8 8 0 01-8-8v-16a8 8 0 1116 0v16a8 8 0 01-8 8z"
    ></path>
  </svg>
);

export const MicrophoneIconOff = ({ width = 32, height = 32, ...props }) => (
  <svg
    width={width}
    {...props}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
  >
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M38.917 33.288a.595.595 0 001.01-.338 7.66 7.66 0 00.06-.943v-16a7.996 7.996 0 10-15.992 0v1.535a2 2 0 00.586 1.415zm14.515 17.298L13.42 10.55a2.001 2.001 0 00-2.829 2.83l40.012 40.036a2.001 2.001 0 002.829-2.831zM32.898 39.952a.597.597 0 00.357-1.015l-8.246-8.243a.594.594 0 00-1.014.42v.894a7.998 7.998 0 007.998 7.998c.302 0 .605-.018.905-.054zm12.394-.29a.498.498 0 00.79-.108 15.881 15.881 0 001.9-7.547v-4a2 2 0 10-3.997 0v4c-.001 1.614-.33 3.212-.968 4.695a.757.757 0 00.152.838zm-3.306 12.345H33.99v-4.138a15.828 15.828 0 005.527-1.772.499.499 0 00.109-.791l-2.113-2.112a.757.757 0 00-.837-.152 11.884 11.884 0 01-6.426.838 12.18 12.18 0 01-10.253-12.135v-3.738a1.999 1.999 0 00-3.998 0v4-.003c0 8.058 5.996 14.856 13.99 15.864v4.138h-7.993a2 2 0 000 4h19.99a2 2 0 100-4z"
    ></path>
  </svg>
);
