import { MapTile } from "lib/Data/WorldMap/MapTile";
import ndarray from "ndarray";
import { imageShape, xzChunkShape } from "lib/Data/xzChunkShape";
import pool from "ndarray-scratch";
import { equals } from "gl-vec3";
import { Path2 } from "babylonjs";
import { USE_TRANSFER_TO_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";

const getDataArray = (scale = 4): ndarray<number> =>
  pool.malloc(imageShape, "uint8_clamped");

const getHexDataArray = () => pool.malloc(xzChunkShape, "uint32");

const pixelRatio: number = self.devicePixelRatio;

const hexColor = (color: Uint8ClampedArray) => {
  const red = color[0];
  const green = color[1];
  const blue = color[2];

  return (1 << 24) + (red << 16) + (green << 8) + blue;
};

export class DrawMapTile {
  static _instance: DrawMapTile;
  static get instance() {
    if (!this._instance) {
      this._instance = new DrawMapTile();
    }

    return this._instance;
  }

  setupCanvas(width: number, height: number) {
    this.canvas = new OffscreenCanvas(width, height);
    this.canvasCtx = this.canvas.getContext("2d");
    this.canvasCtx.imageSmoothingEnabled = true;
    this.tempCanvas = new OffscreenCanvas(width, height);
    this.tempCanvasCtx = this.tempCanvas.getContext("2d");
    this.tempCanvasCtx.imageSmoothingEnabled = true;
  }

  canvas: HTMLCanvasElement | OffscreenCanvas;
  private canvasCtx:
    | CanvasRenderingContext2D
    | OffscreenCanvasRenderingContext2D;
  tempCanvas: HTMLCanvasElement | OffscreenCanvas;
  private tempCanvasCtx:
    | CanvasRenderingContext2D
    | OffscreenCanvasRenderingContext2D;

  async draw(colors: ImageData) {
    // if (USE_TRANSFER_TO_IMAGE_BITMAP) {
    //   create
    // } else {
    return createImageBitmap(colors);
    // }
  }

  static async draw(colors: ImageData): ImageBitmap {
    return await DrawMapTile.instance.draw(colors);
  }
}

function fade(startColor, endColor, steps, step) {
  var scale = step / steps,
    r = startColor.r + scale * (endColor.r - startColor.r),
    b = startColor.b + scale * (endColor.b - startColor.b),
    g = startColor.g + scale * (endColor.g - startColor.g);

  return `rgb(${r},${g},${b})`;
}

function rgb(hexColor: number, r: number, g: number, b: number) {
  r = (hexColor >> 16) & 0xff;
  g = (hexColor >> 8) & 0xff;
  b = hexColor & 0xff;
}
