import {
  Color4,
  Engine as BablyonEngine,
  FileTools,
  InstancedMesh,
  Mesh,
  ReflectionProbe,
  RenderTargetTexture,
  Scene,
  SceneLoader,
  ShadowGenerator,
  StandardMaterial,
  Texture,
  Tools,
  TransformNode,
  UniversalCamera,
  Vector3,
  VideoRecorder,
  Layer,
} from "@babylonjs/core";
import {
  AdvancedDynamicTexture,
  Control,
  GUI3DManager,
  StackPanel,
} from "@babylonjs/gui";
import { GLTFLoaderAnimationStartMode } from "@babylonjs/loaders";
import { SkyMaterial } from "@babylonjs/materials";
import * as Sentry from "@sentry/node";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { formatter } from "components/hud/Header";
import { HUDEvent } from "components/HUDEvent";
import EventEmitter3 from "eventemitter3";
import characterRotation from "game/components/characterRotation";
import foilage from "game/components/foilage";
import glowEntity from "game/components/glowEntity";
import movementInputs from "game/components/movementInputs";
import networkMovement from "game/components/networkMovement";
import parentable from "game/components/parentable";
import parentMesh from "game/components/parentMesh";
import playerMesh from "game/components/playerMesh";
import { EntityAction, EntityActionManager } from "game/EntityActionManager";
import { CSS3DRenderer } from "game/meshes/CSS3DRenderer";
import {
  FoilageAlignment,
  FoilageMesh,
  getAlignment,
} from "game/meshes/FoilageMesh";
import { client, startClient } from "game/networking/client";
import { Commands } from "game/networking/Commands";
import { startTileClient } from "game/networking/tileClient";
import WebSocketClient from "game/networking/WebSocketClient";
import { Picker, PickMode, PickType } from "game/Picker";
import { getNotEnoughMoneyEffect, SoundEffect } from "game/Player/PlayerMesh";
import { CurrentPlayerControl } from "game/textures/CurrentPlayerControl";
import { PlayerControl } from "game/textures/PlayerControl";
import { VoiceChat, VoiceChatStatus, userFromAgora } from "game/VoiceChat";
import { isSafari } from "lib/browser";
import { Data } from "lib/Data";
import { registerMaterials } from "lib/Data/BlockRegistry";
import { MinimapTileStore } from "lib/Data/MinimapTileStore";
import { PlayerInventory } from "lib/Data/PlayerInventory";
import { MapPlayer } from "lib/Data/WorldMap/RenderMapTile";
import "lib/FetchWebRequest";
import { InputControl, InputController, InputEvent } from "lib/InputController";
import { MinimapRender } from "lib/MinimapRender";
import { WorldChunkLoader } from "lib/WorldChunkLoader";
import nengi from "nengi";
import { Engine } from "noa-engine";
import { startMeshWorker } from "noa-engine/lib/startTerrainMeshWorker";
import { BlockID } from "shared/BlockID";
import {
  blockIdType,
  BlockIDType,
  isBlock,
  isFoilage,
  isSurface,
} from "shared/blocks";
import BulkPlaceBlockCommand from "shared/command/BulkPlaceBlockCommand";
import { PlaceBlockCommand } from "shared/command/PlaceBlockCommand";
import { SendChatMessageCommand } from "shared/command/SendChatMessageCommand";
import { TeleportCommand } from "shared/command/TeleportCommand";
import { itemId, pickaxeId } from "shared/items";
import BulkSetBlockMessage from "shared/message/BulkSetBlockMessage";
import { SetBlockErrorCode } from "shared/message/SetBlockMessage";
import nengiConfig from "shared/nengiConfig";
import { getFirstEmoji } from "../lib/emojiRegex";
import {
  ChatEvent,
  ClientEvent,
  DespawnEntityPacket,
  ServerEvent,
  SocketEvent,
  VoiceEvent,
} from "../shared/events";
import { CHUNK_SIZE } from "./CHUNK_SIZE";
import { CurrentPlayer } from "./CurrentPlayer";
import { MenuState } from "./MenuState";
import { NetworkConnectionStatus } from "./NetworkConnectionStatus";
import { Player } from "./Player";
import { PlayerSpawnStatus } from "./PlayerSpawnStatus";
import { ModalProviderType, ModalType } from "components/GameContext";
import { ParticleEffectName } from "shared/message/EmitParticleEffectMessage";
import type { WebcamPlane } from "components/hud/livestream/WebcamPlane";
import { LiveStreamEvent } from "../lib/LiveStreamEvent";

SceneLoader.ShowLoadingScreen = false;
TransformNode.prototype.getLOD = () => this;
TransformNode.prototype.getTotalVertices = () => 0;

let Inspector;

export enum GameLaunchStatus {
  pending,
  launching,
  launched,
}

SceneLoader.OnPluginActivatedObservable.add(function (loader) {
  if (loader.name === "gltf") {
    const gltf: GLTFFileLoader = loader;
    gltf.useRangeRequests = false;
    gltf.animationStartMode = GLTFLoaderAnimationStartMode.NONE;
  }
});

enum CameraMode {
  firstPerson = "firstPerson",
  thirdPerson = "thirdPerson",
}

class NetworkEmitter extends EventEmitter3 {}

const INITIAL_ZOOM = 5;

let didChangeInventory = false;

export class Game {
  resolution: number;
  container: HTMLDivElement;
  client: nengi.Client;
  data: Data;
  cssRenderer: CSS3DRenderer;
  constructor(
    container: HTMLDivElement,
    gameCanvas: HTMLCanvasElement,
    minimapCanvas: HTMLCanvasElement,
    minimapViewport: HTMLDivElement,
    mapContainer: HTMLDivElement,
    onChangeMenuState
  ) {
    this.container = container;
    this.resolution = window.devicePixelRatio;
    this.client = client;
    this.gameCanvas = gameCanvas;
    this.minimapCamera = minimapCanvas;

    this.data = Data.db;
    this.socket = new NetworkEmitter();
    this.onChangeMenuState = onChangeMenuState;
    this.worldMap = new MinimapTileStore();
    this.minimap = new MinimapRender(
      minimapCanvas,
      this.worldChunkLoader,
      minimapViewport,
      mapContainer,
      window.devicePixelRatio || 1.0,
      this.worldMap
    );
    this.worldChunkLoader.minimap = this.minimap;
    this.worldChunkLoader.worldMap = this.worldMap;
    MinimapRender.instance = this.minimap;
  }

  minimap: MinimapRender;
  gameCanvas: HTMLCanvasElement;
  minimapCanvas: HTMLCanvasElement;
  socket: NetworkEmitter;

  onCurrentUser = ({ id, position }) => {};

  private _menuState = MenuState.hidden;

  get menuState() {
    return this._menuState;
  }

  modal: ModalProviderType;

  onChangeMenuState: (menuState: MenuState) => void;

  set menuState(menuState: MenuState) {
    const isChange = menuState !== this._menuState;
    if (isChange && menuState === MenuState.worldMap) {
      this.currentPlayer.meshContainer.playSoundEffect(
        SoundEffect.openMap,
        false,
        true,
        false,
        false,
        1
      );
    } else if (
      isChange &&
      menuState === MenuState.hidden &&
      this._menuState === MenuState.worldMap
    ) {
      this.currentPlayer.meshContainer.playSoundEffect(
        SoundEffect.closeMap,
        false,
        true,
        false,
        false,
        1
      );
    } else if (
      (isChange &&
        menuState === MenuState.hidden &&
        this._menuState === MenuState.inventory) ||
      (menuState === MenuState.inventory &&
        this._menuState === MenuState.hidden)
    ) {
      this.currentPlayer.meshContainer.playSoundEffect(
        SoundEffect.genericUIClick,
        false,
        true,
        false,
        false,
        1
      );
    }
    this._menuState = menuState;
  }

  remoteEntities = new Map<string, number>();

  get isLoaded() {
    return true;
  }

  get currentPlayerChunk() {
    return this.worldChunkLoader.worldChunkByCoords(
      this.currentPlayer.position[0],
      this.currentPlayer.position[1],
      this.currentPlayer.position[2]
    );
  }

  onTick(dt) {
    if (this.picker) {
      this.actionManager.isEnabled = !this.picker.isEnabled;
    }

    if (this.currentPlayer) {
      if (
        this.webcamPlane &&
        this.webcamPlane.pointerDragBehavior.dragging &&
        (this.noa.inputs.state.forward ||
          this.noa.inputs.state.backward ||
          this.noa.inputs.state.left ||
          this.noa.inputs.state.right ||
          this.inputs.state.panCamera)
      ) {
        this.webcamPlane.pointerDragBehavior.releaseDrag();
      }

      this.playerTick(this.currentPlayer);
    }

    this.playersList.forEach(this.playerTick);
  }

  playerTick = (player: Player) => {
    player.update();

    if (player.control) {
      player.control.update();
    }
  };

  handleAction = (
    mesh: FoilageMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number,
    pointerX: number,
    pointerY: number
  ) => {
    const variant = this.worldChunkLoader.itemVariantForMesh(mesh, x, y, z);

    if (action === EntityAction.changeURL) {
      const scene = this.scene;

      const entityId = mesh.metadata.entityId;
      // const {
      //   maximumWorld: max,
      //   minimumWorld: min,
      // } = mesh.getBoundingInfo().boundingBox;

      // this.noa.ents.addComponent(entityId, "react", {
      //   mesh,
      //   originalMaterial: getScreenMaterial(mesh),
      //   setMaterial: (mat) => setScreenMaterial(mesh, mat),
      //   width: max.x - min.x,
      //   height: max.y - min.y,
      //   variant,
      // });

      this.socket.emit(ClientEvent.requestBlockURL, {
        coordinate: { x, y, z, blockId: mesh.metadata.blockID },
        offset: {
          id: `react-${entityId}`,
        },
      });
    }
  };

  handleSetBlockURL = (
    x: number,
    y: number,
    z: number,
    blockId: BlockID,
    url: string
  ) => {
    let variant;

    if (isSurface(blockId)) {
      variant = this.worldChunkLoader.getSurfaceVariant(x, y, z);
    } else if (isFoilage(blockId)) {
      variant = this.worldChunkLoader.getFoilageVariant(x, y, z);
    }

    const cmd = new PlaceBlockCommand({
      x,
      y,
      z,
      blockType: blockId,
      variant,
      url,
    });

    this.worldChunkLoader
      .setBlock(cmd.blockType, cmd.variant, cmd.x, cmd.y, cmd.z, url)
      .then(() => {
        this.client.addCommand(cmd);
      });
  };
  emitLivestreamChange = () => this.socket.emit(LiveStreamEvent.change);

  handlePointerOver = (
    mesh: FoilageMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number
  ) => {
    const variant = this.worldChunkLoader.itemVariantForMesh(mesh, x, y, z);
    const { maximumWorld, minimumWorld } = mesh.getBoundingInfo().boundingBox;
  };

  handlePointerOut = (
    mesh: InstancedMesh,
    action: EntityAction,
    x: number,
    y: number,
    z: number
  ) => {};

  minimapTexture: RenderTargetTexture;
  minimapMaterial: StandardMaterial;
  minimapPlane: Mesh;
  gui: AdvancedDynamicTexture;
  gameScene: Scene;
  hardwareScalingLevel: number;

  scene: Scene;

  playerByUsername = (username: string) => {
    for (let player of this.playersList) {
      if (player.username === username) {
        return player;
      }
    }

    return null;
  };
  worldMap: MinimapTileStore;

  _renderPlayerDt = 0;
  renderParticles = (dt) => {
    this._renderPlayerDt = dt;
    if (this.currentPlayer) {
      this.currentPlayer.render(dt);
    }

    this.playersList.forEach(this.renderPlayer);
  };

  renderPlayer = (player) => player.render(this._renderPlayerDt);

  picker: Picker;

  inputs = new InputController(this);
  shadowGenerator: ShadowGenerator;
  inspectorEl: HTMLDivElement;
  async toggleInsrumentation() {
    if (!this.scene.debugLayer.BJSINSPECTOR) {
      this.scene.debugLayer.BJSINSPECTOR = await import("./BabylonInspector");
    }

    if (!this.inspectorEl) {
      this.inspectorEl = document.createElement("div");
      this.inspectorEl.style.zIndex = 99999;
      this.inspectorEl.style.position = "fixed";
      this.inspectorEl.style.top = 0;
      this.inspectorEl.style.width = "100%";
      this.inspectorEl.style.height = "100%";
      this.inspectorEl.style.pointerEvents = "none";
      this.inspectorEl.style.left = 0;
      document.body.appendChild(this.inspectorEl);
    }

    if (!this.scene.debugLayer.isVisible()) {
      this.inspectorEl.hidden = false;
      await this.scene.debugLayer.show({
        overlay: true,
        globalRoot: this.inspectorEl,
      });
    } else {
      this.scene.debugLayer.hide();
      this.inspectorEl.hidden = true;
    }
  }
  camera: UniversalCamera;

  initializeNoa = async (x, y, z, paused = false) => {
    if (paused) {
      this.minimap.container.hidden = true;
    }
    let cameraModeOpts = {};
    if (this.cameraMode === CameraMode.thirdPerson) {
      cameraModeOpts = {
        zoomSpeed: 0.05,
        initialZoom: INITIAL_ZOOM,
        zoomDistance: 4,
      };
    } else {
      cameraModeOpts = {
        zoomSpeed: 0.1,
        initialZoom: INITIAL_ZOOM,
        zoomDistance: 0,
      };
    }
    var opts = {
      ...cameraModeOpts,
      debug: false,
      showFPS: false,
      chunkSize: CHUNK_SIZE,
      chunkAddDistance: 10,
      worldId: this.worldId,
      antiAlias: false,
      playerAutoStep: true,
      stickyPointerLock: false,
      dragCameraOutsidePointerLock: true,
      AOmultipliers: [0.98, 0.8, 0.5],

      chunkRemoveDistance: 14,
      domElement: this.container,
      blockTestDistance: 12,
      skipDefaultHighlighting: false,
      worldGenWhilePaused: true,
      // This is disabled outside of Chrome because performance is just worse
      // and this is one of the things we can do to make it better
      useAO: true,
      // emissiveColor: [0.8, 0.8, 0.8],
      lightDiffuse: [1, 1, 1],
      lightSpecular: [1, 0.8, 1],
      // groundLightColor: [0.7, 0.7, 0.7],
      preserveDrawingBuffer: true,

      clearColor: [0, 0.2, 0, 0],

      pointerLock: false,
      playerHeight: Player.size.height,
      playerWidth: Player.size.width,

      playerStart: [x, y, z],
      // See `test` example, or noa docs/source, for more options
    };
    var noa: Engine = new Engine(opts);

    if (this.audioContext && !BablyonEngine.audioEngine._audioContext) {
      BablyonEngine.audioEngine._audioContext = this.audioContext;
      BablyonEngine.audioEngine.masterGain = this.audioContext.createGain();
      BablyonEngine.audioEngine.masterGain.gain.value = 1;
      BablyonEngine.audioEngine.masterGain.connect(
        this.audioContext.destination
      );
      BablyonEngine.audioEngine._audioContextInitialized = true;
      if (this.audioContext.state === "running") {
        // Do not wait for the promise to unlock.
        BablyonEngine.audioEngine.unlock();
      }
    } else {
      BablyonEngine.audioEngine.useCustomUnlockedButton = true;
    }

    noa.rendering.getScene().clearColor = new Color4(0, 0, 0, 0);

    FileTools.CorsBehavior = "use-credentials";
    Tools.CorsBehavior = "use-credentials";

    this.noa = noa;
    this.scene = this.noa.rendering.getScene();
    const scene: Scene = this.scene;
    const camera = noa.rendering._camera;
    this.camera = camera;

    this.gui = AdvancedDynamicTexture.CreateFullscreenUI(
      "UI",
      true,
      this.scene
    );
    this.gui._shouldBlockPointer = false;
    // const guiCamera = new UniversalCamera("gui", Vector3.Zero(), this.scene);
    // guiCamera.parent = this.noa.rendering._camera;
    // guiCamera.freezeProjectionMatrix();
    // guiCamera.inputs.clear();
    this.camera.inputs.clear();

    this.playersPanel = new StackPanel();
    this.playersPanel.isPointerBlocker = false;
    this.scene.fogEnabled = false;
    this.playersPanel.isVertical = true;
    this.playersPanel.topInPixels = 128 * devicePixelRatio;
    this.playersPanel.leftInPixels = 16 * devicePixelRatio;
    this.playersPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.playersPanel.clipChildren = false;
    this.playersPanel.clipContent = false;
    this.playersPanel.adaptHeightToChildren = true;
    this.playersPanel.width = "100%";
    this.playersPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.gui.addControl(this.playersPanel);

    scene.activeCamera = this.noa.rendering._camera;
    // scene.activeCameras = [this.noa.rendering._camera, guiCamera];
    // this.guiCamera = guiCamera;

    this.gui.layer.layerMask = 1;
    this.camera.layerMask = 1;

    this.scene.detachControl();
    this.scene.attachControl(true, true, false);
    this.gui.attach();

    // scene.onPointerObservable.add((e) => {
    //   if (!e.pickInfo.hit) {
    //     scene.cameraToUseForPointers =
    //       scene.cameraToUseForPointers == this.guiCamera
    //         ? this.camera
    //         : this.guiCamera;
    //   }
    // });
    this.gameCanvas.removeEventListener(
      "keydown",
      this.scene._inputManager._onKeyDown
    );
    this.gameCanvas.removeEventListener(
      "keyup",
      this.scene._inputManager._onKeyUp
    );

    const engine = scene.getEngine();
    this.hardwareScalingLevel = 1 / window.devicePixelRatio;
    engine.setHardwareScalingLevel(this.hardwareScalingLevel);

    this.worldChunkLoader.start(noa);
    this.inputs.noa = noa;
    this.inputs.socket = this.socket;
    scene.collisionsEnabled = false;

    this.inputs.bindGlobals();
    this.inputs.control = InputControl.default;

    this.actionManager = new EntityActionManager(this.scene);
    this.actionManager.noa = noa;
    this.worldChunkLoader.actionManager = this.actionManager;
    this.actionManager.onAction = this.handleAction;

    this.actionManager.onPointerOut = this.handlePointerOut;
    this.actionManager.onPointerOver = this.handlePointerOver;

    this.inputs.onDown(InputEvent.debug, (event) => {
      this.toggleInsrumentation();
    });

    this.inputs.onDown(InputEvent.openChat, (event) => {
      // event.preventDefault();
      this.socket.emit(ChatEvent.toggleInputFocus);
    });

    if (VideoRecorder.IsSupported(engine)) {
      this.inputs.onDown(InputEvent.liveStream, this.handlePressRecordButton);
    }

    this.inputs.onDown(InputEvent.toggleWorldMap, (event) => {
      if (this.menuState !== MenuState.worldMap && !this.isGameRunning) {
        return;
      }

      if (this.menuState === MenuState.worldMap) {
        // this.onChangeMenuState(MenuState.hidden);
      } else {
        this.onChangeMenuState(MenuState.worldMap);
      }
    });

    this.inputs.onDown(InputEvent.flipFoilage, (event) => {
      if (this.picker.foilageMesh) {
        const alignment = getAlignment(this.picker.currentItemVariant);
        if (alignment !== FoilageAlignment.bottom) {
          return;
        }

        if (!isFinite(this.picker.flipIndex)) {
          this.picker.flipIndex = 0;
        }

        this.picker.flipIndex = (this.picker.flipIndex + 1) % 4;
        this.picker.refreshMesh();
        this.picker.updatePickContainerPosition();

        this.currentPlayer.meshContainer.playSoundEffect(
          SoundEffect.genericUIClick,
          false,
          false,
          false,
          false,
          1
        );
      }
    });

    this.inputs.onDown(InputEvent.scaleFoilage, (event) => {
      if (this.picker.foilageMesh) {
        if (!isFinite(this.picker.scaleIndex)) {
          this.picker.scaleIndex = -1;
        }

        this.picker.scaleIndex = (this.picker.scaleIndex + 1) % 3;

        this.picker.refreshMesh();
        this.picker.updatePickContainerPosition();
        this.currentPlayer.meshContainer.playSoundEffect(
          SoundEffect.genericUIClick,
          false,
          false,
          false,
          false,
          1
        );
      }
    });

    // this.inputs.onDown(InputEvent.select, () => {

    // });

    // this.inputs.onUp(InputEvent.select, () => {
    //   this.picker.endMultiSelect();
    // });

    this.inputs.onDown(InputEvent.place, (evt) => {
      if (isBlock(this.currentPlayer.currentItem?.blockID)) {
        this.picker.startMultiSelect();
      }
    });

    this.inputs.onUp(InputEvent.place, (evt) => {
      this.handleFire();
      this.picker.endMultiSelect();
    });

    this.inputs.onDown(InputEvent.destroy, (evt) => {
      this.picker.startMultiSelect();
    });

    this.inputs.onUp(InputEvent.destroy, (evt) => {
      this.handleFire();
      this.picker.endMultiSelect();
    });

    // this.inputs.onUp(InputEvent.place, (evt) => {
    //   this.handleFire();
    // });

    this.inputs.onUp(InputEvent.voice, (evt) => {
      this.handlePressVoiceButton();
      console.log("PRESS VOICE");
    });

    this.inputs.onDown(InputEvent.equipSlot1, () => this.changeEquippedSlot(0));
    this.inputs.onDown(InputEvent.equipSlot2, () => this.changeEquippedSlot(1));
    this.inputs.onDown(InputEvent.equipSlot3, () => this.changeEquippedSlot(2));
    this.inputs.onDown(InputEvent.equipSlot4, () => this.changeEquippedSlot(3));
    this.inputs.onDown(InputEvent.equipSlot5, () => this.changeEquippedSlot(4));
    this.inputs.onDown(InputEvent.equipSlot6, () => this.changeEquippedSlot(5));
    this.inputs.onDown(InputEvent.equipSlot7, () => this.changeEquippedSlot(6));
    this.inputs.onDown(InputEvent.equipSlot8, () => this.changeEquippedSlot(7));

    this.inputs.onDown(InputEvent.closeMenu, () => {
      this.changeEquippedItem(null);
      this.onChangeMenuState(MenuState.visible);
    });

    this.inputs.onDown(InputEvent.toggleInvite, this.toggleInvite);
    this.inputs.onDown(InputEvent.toggleCharacter, (evt) => {
      if (this.menuState === MenuState.character) {
        this.onChangeMenuState(MenuState.visible);
      } else {
        this.onChangeMenuState(MenuState.character);
      }
    });

    this.inputs.onDown(InputEvent.toggleInventory, (evt) => {
      if (this.menuState === MenuState.inventory) {
        this.onChangeMenuState(MenuState.visible);
      } else {
        this.onChangeMenuState(MenuState.inventory);
      }
    });

    document.addEventListener(
      "pointerlockchange",
      (evt) => {
        console.log("Pointer Lock");
      },
      false
    );

    document.addEventListener(
      "pointerlockerror",
      (evt) => {
        console.log("Pointer Lock Error", evt);
      },
      false
    );

    // if (!this.noa.camera.panWithMovement) {
    this.inputs.onDown(InputEvent.panCamera, (evt) => {
      console.log("pan");

      this.noa.autoResize = false;
      this.noa.inputs.startTrackingMouseMove();
      this.noa.container.canvas.requestPointerLock();
      this.noa.container.hasPointerLock = true;
    });

    this.inputs.onUp(InputEvent.panCamera, (evt) => {
      document.exitPointerLock();
      noa.container.hasPointerLock = true;
      this.noa.inputs.stopTrackingMouseMove();

      // Safari shows an annoying banner at the top when
      let _dt = 0;
      const reEnableResize = (dt) => {
        _dt += dt;

        if (_dt > 200) {
          this.noa.autoResize = true;
          this.noa.off("tick", reEnableResize);
        }
      };
      this.noa.on("tick", reEnableResize);
    });
    // }

    if (this.cameraMode === CameraMode.thirdPerson) {
      noa.camera.pitch = 0.21;
    } else {
      this.camera.position.addInPlaceFromFloats(0, 0, 0);
    }

    noa.container.hasPointerLock = true;
    noa.container.supportsPointerLock = false;

    noa.ents.createComponent(playerMesh(noa));
    noa.ents.createComponent(characterRotation(noa));
    noa.ents.createComponent(networkMovement(noa));
    noa.ents.createComponent(parentable(noa));
    noa.ents.createComponent(foilage(noa));
    // noa.ents.createComponent(customInputs(noa));
    noa.ents.createComponent(movementInputs(noa));
    noa.ents.createComponent(glowEntity(noa));

    noa.ents.createComponent(parentMesh(noa));

    // noa.ents.addComponent(noa.playerEntity, "customInputs", {});
    var box = Mesh.CreateBox("SkyBox", 1000.0, scene, true);
    box.material = new SkyMaterial("sky", scene);

    box.material.inclination = 2.2; // The solar inclination, related to the solar azimuth in interval [0, 1]
    box.material.turbidity = 2;
    box.material.luminance = 0.95;

    var rp = new ReflectionProbe("ref", 512, scene, false, false);
    rp.renderList.push(box);
    rp.refreshRate = 60;
    noa.reflectionProbe = rp;
    // scene.environmentTexture = rp.cubeTexture;
    rp.cubeTexture.coordinatesMode = Texture.SKYBOX_MODE;
    // box.material.freeze();

    // noa.on("tick", () => {});

    // if (startMinutes > 30) {
    //   box.material.azimuth = Scalar.Lerp(0.25, 0.5, startMinutes / 60);
    // } else {
    //   box.material.azimuth = Scalar.InverseLerp(0.25, 0.5, startMinutes / 60);
    // }

    // let currentTime = 0;

    box.material.mieDirectionalG = 0.8;
    box.material.mieCoefficient = 0.005; // The mieCoefficient in interval [0, 0.1], affects the property skyMaterial.mieDirectionalG

    box.infiniteDistance = true;
    box.material.disableLighting = true;
    box.isPickable = false;
    box._enablePointerMoveEvents = false;

    box.material.backFaceCulling = false;
    noa.rendering.addMeshToScene(box, false);

    noa.skyBox = box;
    // renderTarget.renderList.push(box);

    // this.shadowGenerator = new ShadowGenerator(1024, scene.lights[1]);

    window.noa = noa;
    engine.enableOfflineSupport = false;
    window.nengi = this.client;
    noa.on("afterRender", this.renderParticles, this);

    await registerMaterials(noa, x, y, z);
    await startMeshWorker(noa);

    box.freezeNormals();
    box.freezeWorldMatrix();
    box.doNotSyncBoundingInfo = true;
    box.alwaysSelectAsActiveMesh = true;

    // Object.defineProperty(Mesh.prototype, "enablePointerMoveEvents", {
    //   get() {
    //     if (this._enablePointerMoveEvents !== undefined) {
    //       return this._enablePointerMoveEvents;
    //     }

    //     return false;
    //     // if (picker.isEnabled) {
    //     //   return true;
    //     // } else {
    //     //   return false;
    //     // }
    //   },

    //   set(newValue) {},
    // });

    this.socket.on(VoiceEvent.startedPublishing, () => {
      this.socket.emit(HUDEvent.announce, {
        message: "Connected to voice chat.",
        duration: 500,
      });

      this.currentPlayer.control.setupAudio(
        this.audioContext,
        VoiceChat.client.voiceChatStream,
        VoiceChat.client.agoraAudioTrack
      );
      this.currentPlayer.control.update();

      if (this.currentPlayer?.meshContainer) {
        this.currentPlayer.meshContainer.playSoundEffect(
          SoundEffect.voiceChatConnected,
          false,
          true,
          false,
          false,
          1
        );
      }
    });

    this.socket.on(VoiceEvent.stoppedPublishing, () => {
      this.currentPlayer.control.update();

      this.socket.emit(HUDEvent.announce, {
        message: "Disconnected from voice chat.",
        duration: 500,
      });
    });

    this.socket.on(VoiceEvent.newStream, (user: IAgoraRTCRemoteUser) => {
      const id = userFromAgora(user.uid);
      const player = this.pl(id);

      if (player) {
        const stream = VoiceChat.client.mediaStreams.get(user);
        player.control.setupAudio(this.audioContext, stream, user);

        player.addStream(stream, this.audioContext);
      } else {
        console.warn("[VoiceEvent]", "no player known for stream");
      }
    });

    this.socket.on(VoiceEvent.removedStream, (user: IAgoraRTCRemoteUser) => {
      const id = userFromAgora(user.uid);
      const player = this.playerByEntityId(id);

      if (player) {
        if (player.control.voicePlayer === user) {
          player.control.voicePlayer = null;
        }

        player.removeStream(VoiceChat.client.mediaStreams.get(user));
      } else {
        console.warn("[VoiceEvent]", "no player known for stream");
      }
    });

    if (
      this.currentPlayer &&
      this.currentPlayer.spawnStatus !== PlayerSpawnStatus.spawned
    ) {
      await this.spawnCurrentPlayer(x, y, z);
    }

    this.client.on("predictionErrorFrame", (predictionErrorFrame) => {
      predictionErrorFrame.entities.forEach((predictionErrorEntity) => {
        // move the entity back to the server side position
        predictionErrorEntity.errors.forEach((predictionError) => {
          console.log("prediciton error", predictionError);
          // entity[predictionError.prop] = predictionError.actualValue;
        });

        // and then re-apply any commands issued since the frame that had the prediction error
        const commandSets = this.client.getUnconfirmedCommands(); // client knows which commands need redone
        //   commandSets.forEach((commandSet, clientTick) => {
        //     commandSet.forEach((command) => {
        //       // reapply movements
        //       if (command.protocol.name === "MoveCommand") {
        //       //   applyCommand(entity, command);
        //       //   const prediction = {
        //       //     nid: entity.nid,
        //       //     x: entity.x,
        //       //     y: entity.y,
        //       //     z: entity.z,
        //       //   };
        //       //   // these reconciled positions are now our new predictions, going forward
        //       //   client.addCustomPrediction(clientTick, prediction, [
        //       //     "x",
        //       //     "y",
        //       //     "z",
        //       //   ]);
        //       // }
        //     // });
        //   });
      });

      // });
    });

    this.requestAudioContext();
    // // Reflection probe
    /*
     *
     *      World generation
     *
     *  The world is divided into chunks, and `noa` will emit an
     *  `worldDataNeeded` event for each chunk of data it needs.
     *  The game client should catch this, and call
     *  `noa.world.setChunkData` whenever the world data is ready.
     *  (The latter can be done asynchronously.)
     *
     */

    // register for world events

    /*
     *
     *      Create a mesh to represent the player:
     *
     */

    // get the player entity's ID and other info (position, size, ..)

    // add "mesh" component to the player entity
    // this causes the mesh to move around in sync with the player entity

    /*
     *
     *      Minimal interactivity
     *
     */

    // clear targeted block on on left click

    this.socket.on(HUDEvent.selectitem, (item) => {
      this.changeEquippedItem(item);
    });

    this.socket.on(ClientEvent.setBlockURL, ({ x, y, z, blockId, url }) =>
      this.handleSetBlockURL(x, y, z, blockId, url)
    );

    noa.on("tick", this.adjustZoomDistance, this);

    noa.once("tick", () => {
      this.launchStatus = GameLaunchStatus.launched;
      if (paused) {
        this.noa.paused = true;
      } else {
        // const opts = SceneOptimizerOptions.HighDegradationAllowed();
        // const i = opts.optimizations.findIndex(
        //   (opt) => opt instanceof MergeMeshesOptimization
        // );
        // opts.optimizations.splice(i, 1);
        // const optimizer = SceneOptimizer.OptimizeAsync(
        //   this.scene,
        //   opts,
        //   () => {
        //     console.log("Optimizer done");
        //   },
        //   () => console.error("Optimizer failed")
        // );
        // optimizer.onNewOptimizationAppliedObservable.add((optimizer) =>
        //   console.log("new optimization", optimizer)
        // );
      }

      noa.camera.zoomDistance = opts.initialZoom;
    });
    this.noa.on("tick", this.onTick, this);

    if (
      this.currentPlayer &&
      this.currentPlayer.spawnStatus === PlayerSpawnStatus.waiting
    ) {
      await this.initializeCurrentPlayer(this.currentPlayer.remoteEntity);
    }

    if (paused) {
      // const blur = new BlurPostProcess(
      //   "Horizontal blur",
      //   new Vector2(0.4, 0.4),
      //   64,
      //   0.2,
      //   this.camera,
      //   0,
      //   engine
      // );

      // this.blurPostProcess = blur;
      console.log("PAUSED");
    } else {
      this.setupPicker();
    }

    // scene.createDefaultEnvironment({createGround: false, });
    this.noa.container.loop.start();

    // this.cssRenderer = new CSS3DRenderer(this.scene.getEngine(), this.scene);
    // this.scene.onBeforeRenderObservable.add(() => {
    //   const renderer = this.cssRenderer;
    //   const states = this.noa.ents.getStatesList("react");

    //   if (states.length === 0) {
    //     return;
    //   }

    //   const scene = noa.rendering.getScene();
    //   const matrix = renderer.render(scene);

    //   for (let i = 0; i < states.length; i++) {
    //     const state = states[i];

    //     renderer.renderObject(
    //       state.cssObject,
    //       scene,
    //       scene.activeCamera,
    //       matrix,
    //       state.width,
    //       state.height
    //     );
    //   }
    // });

    // noa.ents.createComponent(react(noa, this.cssRenderer));
  };

  webcamPlane: WebcamPlane;

  toggleInvite = () => {
    if (this.menuState === MenuState.invite) {
      this.onChangeMenuState(MenuState.visible);
    } else {
      this.onChangeMenuState(MenuState.invite);
    }
  };
  readyToSpawnPlayers = false;
  mapPlayers: Array<MapPlayer> = [];
  sendAnnouncement = (message: string, duration: number = 500) => {
    this.socket.emit(HUDEvent.announce, {
      message,
      duration,
    });
  };

  handlePressRecordButton = () => {
    this.onChangeMenuState(MenuState.liveStream);
  };

  onLiveStreamStart() {
    this.socket.emit(HUDEvent.announce, {
      message: `You're live! Press L to stop the stream.`,
      duration: 1000,
    });
  }

  onLiveStreamEnded() {
    this.socket.emit(HUDEvent.announce, {
      message: `Stream ended!`,
      duration: 1000,
    });
  }

  handlePressVoiceButton = () => {
    if (!VoiceChat.client) {
      return;
    }

    if (!this.audioContext) {
      this.getAudioContext();
    }

    if (!this.audioContext) {
      this.socket.emit(HUDEvent.announce, {
        message: "Click anywhere to unmute audio",
        duration: 500,
      });
      return;
    }

    if (
      VoiceChat.client.hasVoice &&
      VoiceChat.client.status === VoiceChatStatus.connected &&
      VoiceChat.client.isStreaming
    ) {
      const isMuted = VoiceChat.client.toggleMute();
      if (isMuted) {
        this.currentPlayer?.meshContainer?.playSoundEffect(
          SoundEffect.voiceChatMute,
          false,
          true,
          false,
          false,
          1
        );
        this.currentPlayer?.control?.update();
        this.socket.emit(HUDEvent.announce, {
          message: "Muted microphone",
          duration: 500,
        });
      } else {
        this.currentPlayer?.meshContainer?.playSoundEffect(
          SoundEffect.voiceChatUnmute,
          false,
          true,
          false,
          false,
          1
        );

        this.currentPlayer?.control?.update();

        this.socket.emit(HUDEvent.announce, {
          message: "Unmuted microphone",
          duration: 500,
        });
      }
    } else {
      VoiceChat.client.startBroadcasting(this.audioContext).then(
        () => {
          this.currentPlayer?.control?.update();
          if (VoiceChat.client.status === VoiceChatStatus.failed) {
            this.socket.emit(HUDEvent.announce, {
              message: "Couldn't connect to voice chat.",
              duration: 500,
            });
          }
        },
        (err) => {
          this.currentPlayer?.control?.update();
          if (VoiceChat.client.status === VoiceChatStatus.failed) {
            this.socket.emit(HUDEvent.announce, {
              message: "Couldn't connect to voice chat.",
              duration: 500,
            });
          }
        }
      );
    }
  };

  adjustZoomDistance(dt) {
    const camera = this.noa.camera;
    var scroll = this.inputs.state.scrolly;
    if (scroll !== 0) {
      camera.zoomDistance += scroll > 0 ? 1 : -1;
      if (camera.zoomDistance < INITIAL_ZOOM)
        camera.zoomDistance = INITIAL_ZOOM;
      if (camera.zoomDistance > 30) camera.zoomDistance = 30;
    }
  }

  spawnPlayers = async () => {
    for (const playerId of [...this.pendingSpawns.values()]) {
      const player = this.players.get(playerId);

      if (player && player.spawnStatus === PlayerSpawnStatus.waiting) {
        player.noa = this.noa;
        const [x, y, z] = player.spawnPoint;

        if (player.control && !player.control.panel.parent) {
          this.playersPanel.addControl(player.control.panel);
        }

        if (!this.noa.world._getChunkByCoords(x, y, z)) {
          continue;
        }

        if (VoiceChat.client && this.audioContext) {
          player.addStream(
            VoiceChat.client.streamsByUserId.get(player.username),
            this.audioContext
          );
        }

        await player.spawn({ x, y, z, heading: player.remoteEntity.heading });

        if (!this.playersList.includes(player)) {
          this.playersList.push(player);
          this.mapPlayers.push(MapPlayer.fromPlayer(player));
        }
      }
    }
  };

  lastMovementTick = 0;
  onMovementTick = (dt) => {
    this.lastMovementTick += dt;

    const { noa, currentPlayer, client } = this;

    if (
      currentPlayer &&
      currentPlayer.spawnStatus === PlayerSpawnStatus.spawned
    ) {
      const position = noa.entities.getPosition(currentPlayer.localEntityId);
      const pitch = noa.camera.pitch;
      const yaw = noa.camera.heading;

      const movement = currentPlayer.movement;

      // Send update
      Commands.move(
        position[0],
        position[1],
        position[2],
        pitch,
        yaw,
        movement.heading,
        movement.running,
        movement.jumping
        // noa.
      );
    }
  };

  playerByEntityId = (id: string): Player => {
    if (id === this.ignoreEntity || id === this.currentPlayer.remoteEntityId) {
      return this.currentPlayer;
    }

    for (let i = 0; i < this.playersList.length; i++) {
      const player = this.playersList[i];

      if (player.remoteEntityId === id) {
        return player;
      }
    }

    return null;
  };

  noa: Engine;

  webcamLayer: Layer;

  pendingChunks = new Map<string, object>();
  pendingSpawns = new Set<number>();
  livestream: LiveStream;
  playersPanel: StackPanel;

  handleTeleport = (username: string) => {
    this.client.addCommand(new TeleportCommand(username));
  };
  webcam: MediaStream;

  spawnNewPlayer = async (entity) => {
    const { x, y, z, nid: id, username, yaw, pitch } = entity;
    const currentUserName = this.currentPlayer?.username;

    if (currentUserName === username) {
      return;
    }

    const player = new Player(id, username, this.noa, id, null, entity);
    player.shadowGenerator = this.shadowGenerator;
    this.players.set(id, player);

    player.spawnPoint[0] = x;
    player.spawnPoint[1] = y;
    player.spawnPoint[2] = z;

    player.control = new PlayerControl(player);
    player.control.onPressTeleport = this.handleTeleport;
    if (this.playersPanel) {
      this.playersPanel.addControl(player.control.panel);
    }

    if (this.launchStatus === GameLaunchStatus.launched) {
      if (VoiceChat.client && this.audioContext) {
        player.addStream(
          VoiceChat.client.streamsByUserId.get(player.username),
          this.audioContext
        );
      }

      await player.spawn({
        x,
        y,
        z,
        yaw,
        heading: entity.heading,
        pitch,
      });
      if (!this.playersList.includes(player)) {
        this.playersList.push(player);
        this.mapPlayers.push(MapPlayer.fromPlayer(player));
      }
    } else {
      this.pendingSpawns.add(player.id);
    }
  };

  _highlightPosition = new Float32Array([0, 0, 0]);
  handleFire = () => {
    if (this.menuState === MenuState.character) {
      this.onChangeMenuState(MenuState.visible);
    }
    const result = this.picker.pick();

    if (result.length === 0) {
      return;
    }

    const { noa, currentPlayer } = this;

    const soundEffect =
      this.picker.type === PickType.create
        ? SoundEffect.placeBlock
        : SoundEffect.destroyBlock;

    const cmds = new Array(result.length);
    for (let i = 0; i < result.length; i++) {
      cmds[i] = { ...result[i], url: "" };
    }

    if (this.picker.mode === PickMode.single || result.length === 1) {
      const cmd = new PlaceBlockCommand(cmds[0]);

      this.worldChunkLoader.setBlock(
        cmd.blockType,
        cmd.variant,
        cmd.x,
        cmd.y,
        cmd.z
      );

      this.client.addCommand(cmd);
      this.currentPlayer.meshContainer.playSoundEffect(
        soundEffect,
        false,
        false,
        false,
        false
      );
    } else if (this.picker.mode === PickMode.multi) {
      const _cmd = new BulkPlaceBlockCommand(cmds);
      this.client.addCommand(_cmd);
      console.log(_cmd);
      this.bulkSetBlock({ ..._cmd, player: this.ignoreEntity });
    }
  };

  get equippedSlot() {
    if (
      this.inventory &&
      this.currentPlayer &&
      this.currentPlayer.remoteEntity &&
      this.currentPlayer.inventory
    ) {
      return this.currentPlayer.currentSlot;
    }

    return -1;
  }

  initializeCurrentPlayer = (entity) => {
    this.currentPlayer = new CurrentPlayer(
      entity.nid,
      entity.username,
      this.noa,
      entity.id,
      this.noa?.playerEntity,
      entity
    );

    Sentry.setUser({
      id: entity.username,
      username: entity.username,
    });

    this.players.set(entity.nid, this.currentPlayer);

    const { x, y, z } = entity;
    this.currentPlayer.spawnPoint.set([x, y, z]);

    this.enqueueVoiceChat();

    if (!this.noa) {
      return this.initializeNoa(x, y, z);
    } else {
      return this.spawnCurrentPlayer(x, y, z);
    }
  };

  hasSpawnedBefore = false;

  hideGUI() {
    if (this.currentPlayer?.control) {
      this.gui.removeControl(this.currentPlayer.control.panel);
    }

    if (this.playersPanel) {
      this.gui.removeControl(this.playersPanel);
    }
  }

  showGUI() {
    if (this.currentPlayer?.control) {
      this.gui.addControl(this.currentPlayer.control.panel);
    }

    if (this.playersPanel) {
      this.gui.addControl(this.playersPanel);
    }
  }

  spawnCurrentPlayer = async (x: number, y: number, z: number) => {
    const currentPlayerControl = new CurrentPlayerControl(this.currentPlayer);
    this.currentPlayer.control = currentPlayerControl;
    currentPlayerControl.onPressVoiceButton = this.handlePressVoiceButton;
    currentPlayerControl.inviteLinkControl.onPointerClickObservable.add(
      this.toggleInvite
    );
    this.gui.addControl(currentPlayerControl.panel);

    this.noa.paused = false;
    this.minimap.container.hidden = false;
    this.blurPostProcess?.dispose();
    this.blurPostProcess = null;

    this.currentPlayer.shadowGenerator = this.shadowGenerator;
    this.currentPlayer.noa = this.noa;
    const player = this.noa.playerEntity;
    this.currentPlayer.localEntityId = player;
    this.noa.camera.heading = this.currentPlayer.remoteEntity.yaw;

    this.currentPlayer.inventory = this.inventory;
    // await this.scene.whenReadyAsync();
    await this.currentPlayer.spawn(
      this.currentPlayer.remoteEntity,
      this.cameraMode
    );
    this.noa.ents.addComponentAgain(
      this.noa.playerEntity,
      "movementInputs",
      {}
    );

    if (!this.hasSpawnedBefore) {
      let minimapRenderedFramesAgo = 0;
      let RENDER_MINIMAP_EVERY = isSafari() ? 2 : 1;

      this.noa.on("tick", this.onMovementTick);
      this.setupPicker();

      this.noa.on("tick", this.minimap.tick, this.minimap);

      for (let mesh of this.currentPlayer.meshContainer.meshes) {
        mesh.alwaysSelectAsActiveMesh = true;
        mesh.doNotSyncBoundingInfo = true;
      }

      this.scene.onAfterRenderObservable.add((scene, state) => {
        if (!this.isGameRunning) {
          return;
        }

        if (minimapRenderedFramesAgo >= RENDER_MINIMAP_EVERY) {
          for (let i = 0; i < this.mapPlayers.length; i++) {
            const mapPlayer = this.mapPlayers[i];

            const player = this.playerByUsername(mapPlayer.username);
            if (!player) {
              continue;
            }

            mapPlayer.update(player);
          }

          this.minimap.render(this.currentPlayer, this.mapPlayers);
          minimapRenderedFramesAgo = 0;
        } else {
          minimapRenderedFramesAgo++;
        }
      });
    }

    this.currentPlayer.control.currency = this.currentPlayer.remoteEntity.currency;

    this.hasSpawnedBefore = true;
  };

  updatePlayerControls = () => {};

  enqueueVoiceChat = () => {
    this.startVoiceChat();
  };

  setupPicker = () => {
    this.picker = new Picker(
      this.noa,
      this.camera,
      this.currentPlayer,
      this.inventory
    );

    this.picker.hardwareScalingLevel = this.hardwareScalingLevel;
    this.picker.loader = this.worldChunkLoader;
    this.picker.mode = PickMode.single;
    this.picker.type = PickType.none;
    this.noa.on("tick", this.picker.refreshIfNeeded);
  };

  launchStatus = GameLaunchStatus.pending;
  currentPlayerEntityId = null;
  networkTicker: number;
  ignoreEntity = null;
  gui: GUI3DManager;

  cameraMode: CameraMode = CameraMode.thirdPerson;

  changeEquippedItem = (item) => {
    if (this.currentPlayer.equippedItemId !== item) {
      this.currentPlayer.meshContainer.playSoundEffect(
        item ? SoundEffect.itemChange : SoundEffect.closeMap,
        false,
        false,
        false,
        false,
        1
      );
    }

    if (item === null) {
      if (this.picker.isMultiSelecting) {
        this.picker.endMultiSelect();
      }

      this.picker.type = PickType.none;
      this.currentPlayer.equippedItemId = null;
    } else {
      const deleteId = itemId(BlockID.pickaxe, 0);
      const isDelete = deleteId === item;

      if (isDelete) {
        this.picker.endMultiSelect();
        this.currentPlayer.equippedItemId = pickaxeId;
        this.picker.type = PickType.destroy;
      } else {
        this.currentPlayer.equippedItemId = item;
        const blockID = this.currentPlayer.currentItem?.blockID;
        if (!item) {
          this.picker.endMultiSelect();
        }
        this.picker.type = PickType.create;
      }
    }

    this.refreshControls();
    this.socket.emit(HUDEvent.equipItem, item);
    this.picker.scaleIndex = 0;
    this.picker.flipIndex = 0;
    this.picker.refreshMesh();
  };

  refreshControls = () => {
    const itemType = blockIdType(this.currentPlayer.currentItem?.blockID);

    if (this.picker.type === PickType.destroy) {
      this.inputs.control = InputControl.deleteBlock;
    } else if (itemType === BlockIDType.block) {
      this.inputs.control = InputControl.placeBlock;
    } else if (
      itemType === BlockIDType.foilage ||
      itemType === BlockIDType.surface
    ) {
      this.inputs.control = InputControl.placeFoilage;
    } else {
      this.inputs.control = InputControl.default;
    }
  };

  changeEquippedSlot = (index: number) => {
    if (!this.currentPlayer || !this.currentPlayer.inventory) {
      return;
    }
    if (this.menuState === MenuState.character) {
      this.onChangeMenuState(MenuState.visible);
    }

    this.scene.blockfreeActiveMeshesAndRenderingGroups = true;

    if (index === 0) {
      this.changeEquippedItem(itemId(BlockID.pickaxe, 0));
    } else {
      const itemId = this.currentPlayer.slots[index];

      this.changeEquippedItem(index > -1 ? itemId : null);
    }
    this.scene.blockfreeActiveMeshesAndRenderingGroups = false;
  };

  bulkSetBlock = (cmd: BulkSetBlockMessage) => {
    const scene = this.noa.rendering.getScene();
    scene.blockfreeActiveMeshesAndRenderingGroups = true;
    let hasChangedAny = false;
    let soundEffect = null;
    for (let i = 0; i < cmd.x.length; i++) {
      let x = cmd.x[i];
      let y = cmd.y[i];
      let z = cmd.z[i];
      let blockType = cmd.blockType[i];

      if (this.noa.getBlock(blockType, x, y, z) !== blockType) {
        hasChangedAny = true;
      }

      if (blockType === BlockID.air) {
        soundEffect = SoundEffect.destroyBlock;
        this.worldChunkLoader.deleteBlock(x, y, z);
      } else {
        soundEffect = SoundEffect.placeBlock;
        this.noa.setBlock(blockType, x, y, z);
      }
    }

    if (hasChangedAny && soundEffect) {
      let player: Player;
      if (cmd.player === this.ignoreEntity) {
        player = this.currentPlayer;
      } else {
        player = this.players.get(cmd.player);
      }

      if (!player || player.spawnStatus !== PlayerSpawnStatus.spawned) {
        return;
      }

      let isSelf = player instanceof CurrentPlayer;

      if (cmd.error === SetBlockErrorCode.insufficientCapital) {
        this.socket.emit(HUDEvent.announce, {
          message: "You need more money to do that.",
          duration: 500,
        });

        player.meshContainer.playSoundEffect(
          getNotEnoughMoneyEffect(),
          !isSelf,
          !isSelf,
          false,
          !isSelf
        );
      } else {
        player.meshContainer.playSoundEffect(
          soundEffect,
          !isSelf,
          !isSelf,
          false,
          !isSelf
        );
      }
    }
    scene.blockfreeActiveMeshesAndRenderingGroups = false;
  };

  onSendChatMessage = (body: string) => {
    const [x, y, z] = this.currentPlayer.position;

    this.client.addCommand(
      new SendChatMessageCommand(
        body,
        Math.floor(x),
        Math.floor(y),
        Math.floor(z)
      )
    );
  };
  playersList: Array<Player> = [];

  handleNetworkMessage = (message) => {
    // Figure out your own nengi ID
    // So you don't process updates from yourself
    if (message.protocol.name === "Identity") {
      this.currentPlayerEntityId = message.rawId;
      this.ignoreEntity = message.smoothId;
    } else if (message.protocol.name === "PlayerJoinedChatMessage") {
      this.socket.emit(ChatEvent.newSystemMessage, message);
    } else if (message.protocol.name === "ChatMessage") {
      let player = this.players.get(message.player);

      if (
        message.player === this.ignoreEntity ||
        message.player === this.currentPlayerEntityId
      ) {
        player = this.currentPlayer;
      }

      if (player) {
        message.color = player.remoteEntity?.color;

        if (player.spawnStatus === PlayerSpawnStatus.spawned) {
          const emoji = getFirstEmoji(message.body);
          if (emoji) {
            player.meshContainer.emitEmojiParticles(emoji);
          }
        }
      }

      this.socket.emit(ChatEvent.newMessage, message);
    } else if (message.protocol.name === "RequestWorldDataCommand") {
      this.socket.emit(ServerEvent.customMapChunk, message);
    } else if (message.protocol.name === "EmitParticleEffectMessage") {
      const player = this.playerByEntityId(message.player);
      if (player && player.spawnStatus === PlayerSpawnStatus.spawned) {
        player.meshContainer.emitParticleEffect(
          message.effect,
          message.quantity,
          message.seconds
        );
      }
    } else if (message.protocol.name === "ClaimYourInviteMessage") {
      if (this.modal) {
        this.modal.openModal(ModalType.claimInvite, {
          username: message.username,
        });
      }
    } else if (message.protocol.name === "TeleportMessage") {
      this.noa.entities.setPosition(this.noa.playerEntity, [
        message.x,
        message.y,
        message.z,
      ]);
    } else if (message.protocol.name === "SetBlockMessage") {
      this.worldChunkLoader.setBlock(
        message.blockType,
        message.variant,
        message.x,
        message.y,
        message.z,
        message.url
      );
      const isSelf = this.currentPlayer.remoteEntity.nid === message.player;

      if (
        message.player !== this.ignoreEntity &&
        this.players.has(message.player)
      ) {
        const player = this.players.get(message.player);

        if (player.spawnStatus === PlayerSpawnStatus.spawned) {
          const effect =
            message.blockType === BlockID.air
              ? SoundEffect.destroyBlock
              : SoundEffect.placeBlock;

          console.log(message.error);
          if (message.error === SetBlockErrorCode.none) {
            player.meshContainer.playSoundEffect(
              effect,
              !isSelf,
              !isSelf,
              false,
              !isSelf
            );
          } else if (message.error === SetBlockErrorCode.insufficientCapital) {
            player.meshContainer.playSoundEffect(
              getNotEnoughMoneyEffect(),
              !isSelf,
              !isSelf,
              false,
              !isSelf
            );
          }
        }
      } else if (message.player === this.ignoreEntity) {
        if (message.error === SetBlockErrorCode.insufficientCapital) {
          this.socket.emit(HUDEvent.announce, {
            message: "You need more money to do that.",
            duration: 500,
          });

          this.currentPlayer.meshContainer.playSoundEffect(
            getNotEnoughMoneyEffect(),
            !isSelf,
            !isSelf,
            false,
            !isSelf
          );
        }
      }
    } else if (message.protocol.name === "BulkSetBlockMessage") {
      this.bulkSetBlock(message);
    } else if (message.protocol.name === "AuthenticationRequiredMessage") {
    } else if (message.protocol.name === "SpawnPlayerMessage") {
      // this.worldId = message.worldId;
      // this.respawnPlayer(message.x, message.y, message.z);
    }
  };

  private _worldId: number;

  get worldId(): number {
    return this._worldId;
  }

  set worldId(worldId: number) {
    this._worldId = worldId;
    MinimapTileStore.worldId = worldId;
  }

  respawnPlayer = (x: number, y: number, z: number) => {
    if (
      this.currentPlayer &&
      this.currentPlayer.spawnStatus === PlayerSpawnStatus.spawned
    ) {
      this.currentPlayer.spawnPoint[0] = x;
      this.currentPlayer.spawnPoint[1] = y;
      this.currentPlayer.spawnPoint[2] = z;

      return this.currentPlayer.respawn();
    } else if (
      !this.currentPlayer &&
      this.launchStatus === GameLaunchStatus.pending
    ) {
      this.initializeNoa(x, y, z);
    }
  };

  handleEntityUpdate = (update, id) => {
    const player = this.players.get(id);

    if (id === this.currentPlayerEntityId) {
      if (update._changes.has("currency") || update._changes.has("username")) {
        this.socket.emit(
          HUDEvent.changePlayer,
          this.currentPlayer.remoteEntity
        );
      } else if (update._changes.has("avatarType")) {
        return this.currentPlayer.respawn();
      }
    } else {
      if (player && player.spawnStatus === PlayerSpawnStatus.spawned) {
        if (typeof update.heading === "number") {
          player.movement.heading = update.heading;
        }

        if (typeof update.running === "boolean") {
          player.movement.running = update.running;
        }

        if (typeof update.onGround === "boolean") {
          player.movement.onGround = update.onGround;
        }

        if (typeof update.jumping === "boolean") {
          player.movement.jumping = update.jumping;
        }

        if (typeof update.yaw === "number") {
        }

        if (typeof update.pitch === "number") {
          // player.rotation.x =  update.pitch;
        }

        // if (typeof update.running !== "undefined") {
        //   player.movement.running = update.running;
        // }

        // if (typeof update.jumping !== "undefined") {
        //   player.movement.jumping = update.jumping;
        // }

        let hasChangedX = typeof update.x === "number";
        let hasChangedY = typeof update.y === "number";
        let hasChangedZ = typeof update.z === "number";

        if (hasChangedX) {
          player.spawnPoint[0] = update.x;
        }

        if (hasChangedY) {
          player.spawnPoint[1] = update.y;
        }
        if (hasChangedZ) {
          player.spawnPoint[2] = update.z;
        }

        if (hasChangedX || hasChangedY || hasChangedZ) {
          this.noa.globalToLocal(
            player.spawnPoint,
            null,
            player.localSpawnPoint
          );

          const networkState = this.noa.ents.getState(
            player.localEntityId,
            "networkMovement"
          );
          networkState.lastUpdateDt = 0;

          Vector3.FromArrayToRef(
            player.localSpawnPoint,
            0,

            networkState.localPosition
          );
        }
      }

      if (typeof update.avatarType === "number") {
        return player.respawn();
      }
    }
  };

  handleCreateNetworkEntity = (entity) => {
    if (entity.protocol.name === "PlayerCharacter") {
      if (entity.nid === this.ignoreEntity) {
        return;
      }
      if (entity.nid === this.currentPlayerEntityId) {
        if (this.currentPlayer?.spawnStatus !== PlayerSpawnStatus.spawned) {
          this.initializeCurrentPlayer(entity);

          this.socket.emit(HUDEvent.changePlayer, entity);
        }
        return;
      } else {
        this.spawnNewPlayer(entity);
      }
    }
  };
  handleNetworkEntity = (snapshot) => {
    snapshot.createEntities.forEach(this.handleCreateNetworkEntity);

    const updates = new Map();

    for (let i = 0; i < snapshot.updateEntities.length; i++) {
      const { prop, value, nid } = snapshot.updateEntities[i];

      if (nid === this.ignoreEntity) {
        continue;
      }

      if (this.players.has(nid)) {
        const player = this.players.get(nid);
        if (nid === this.currentPlayerEntityId && prop === "currency") {
          let diff = value - player.remoteEntity.currency;

          if (diff !== 0) {
            if (this.currentPlayer?.control?.currencyTextBlock) {
              this.socket.emit(HUDEvent.currencyChange, diff);
            }

            this.currentPlayer.control.currency = value;
          }
        }

        if (player && player.remoteEntity) {
          player.remoteEntity[prop] = value;
        }
      }

      const update = updates.get(nid) || { _changes: new Set() };
      update._changes.add(prop);
      update[prop] = value;

      updates.set(nid, update);
    }

    updates.forEach(this.handleEntityUpdate);

    for (let id of snapshot.deleteEntities) {
      if (this.players.has(id)) {
        const player = this.players.get(id);
        this.minimap.clearPlayerArrow(player);
        player.dispose();
        this.players.delete(id);
        const index = this.playersList.indexOf(player);
        this.playersList.splice(index, 1);

        const mapPlayerindex = this.findMapPlayerIndex(player.username);
        this.mapPlayers.splice(mapPlayerindex, 1);
      }
    }
  };

  findMapPlayerIndex = (username: string) => {
    for (let i = 0; i < this.mapPlayers.length; i++) {
      const player = this.mapPlayers[i];
      if (player.username === username) {
        return i;
      }
    }
  };

  onNetworkTick = (dt: number) => {
    const { client, socket, noa, currentPlayer } = this;

    const network = client.readNetwork();
    for (let i = 0; i < network.messages.length; i++) {
      this.handleNetworkMessage(network.messages[i]);
    }

    client.update();

    for (let i = 0; i < network.entities.length; i++) {
      this.handleNetworkEntity(network.entities[i]);
    }

    if (didChangeInventory) {
      Data.db.updateInventory().then(() => {
        this.currentPlayer.inventory = this.inventory;

        this.hasLoadedInventory = true;

        this.socket.emit(HUDEvent.inventoryChange, this.inventory);
      });
    }
  };
  hasLoadedInventory = false;
  inventory: PlayerInventory;

  get isGameRunning() {
    if (this.launchStatus !== GameLaunchStatus.launched || !this.noa) {
      return false;
    }

    return !this.noa._paused;
  }

  _connectionStatus: NetworkConnectionStatus = NetworkConnectionStatus.pending;

  get connectionStatus() {
    return this._connectionStatus;
  }
  set connectionStatus(status: NetworkConnectionStatus) {
    this._connectionStatus = status;
    this.socket.emit(SocketEvent.connectionStatusChange, status);
  }

  reconnect = () => {
    const retry: WebSocketClient = this.client.retryer;
    retry.reconnectEnabled = true;

    startClient(this.socket, this.pendingChunks);

    if (this.networkTicker) {
      window.clearInterval(this.networkTicker);
    }

    this.networkTicker = window.setInterval(
      this.onNetworkTick,
      nengiConfig.UPDATE_RATE
    );

    if (this.noa?.paused) {
      this.noa.paused = false;
      this.minimap.container.hidden = false;
      this.spawnPlayers();
    }
  };

  audioContext: AudioContext;

  addAllStreams = () => {
    for (let player of this.playersList) {
      if (player.stream) {
        player.addStream(player.stream, this.audioContext);
      }
    }
  };

  startVoiceChat = () => {
    if (!this.currentPlayer) {
      return Promise.resolve(false);
    }
    // if (process.env.NODE_ENV !== "production") {
    //   return;
    // }

    if (!VoiceChat.client) {
      VoiceChat.client = new VoiceChat(
        VoiceChat.uid,
        String(this.worldId),
        this.socket
      );

      window.voiceChat = VoiceChat.client;
    }

    if (this.audioContext) {
      if (VoiceChat.client.status === VoiceChatStatus.pending) {
        return VoiceChat.client.start();
      }
    }

    return Promise.resolve(true);
  };

  getAudioContext = () => {
    if (BablyonEngine.audioEngine.unlocked) {
      if (!this.audioContext) {
        this.audioContext = BablyonEngine.audioEngine.audioContext;
        window.GlobalAudioContext = this.audioContext;
      }

      return true;
    }

    try {
      if (!this.audioContext) {
        this.audioContext = BablyonEngine.audioEngine.audioContext;
        window.GlobalAudioContext = this.audioContext;
      }

      BablyonEngine.audioEngine.unlock();
      return true;
    } catch (exception) {
      console.warn(exception);
      return false;
    }
  };

  requestAudioContext = () => {
    let events;
    if (!isSafari()) {
      events = ["click", "mouseup"];
    } else {
      events = ["click", "mouseup", "keyup", "keydown"];
    }

    let removeEvents = () => {
      for (let event of events) {
        document.body.removeEventListener(event, this.getAudioContext);
      }
    };

    BablyonEngine.audioEngine?.onAudioUnlockedObservable?.addOnce(() => {
      if (!this.audioContext) {
        this.audioContext = BablyonEngine.audioEngine.audioContext;
        window.GlobalAudioContext = this.audioContext;
      }

      if (
        (!VoiceChat.client ||
          VoiceChat.client?.status === VoiceChatStatus.pending) &&
        this.currentPlayer
      ) {
        this.startVoiceChat().then(this.addAllStreams);
      } else {
        this.addAllStreams();
      }

      removeEvents();
      removeEvents = null;

      BablyonEngine.audioEngine?.onAudioLockedObservable?.addOnce(
        this.requestAudioContext
      );
    });

    console.log("USER ACTIVATION?", navigator?.userActivation?.isActive);
    if (
      navigator?.userActivation?.isActive ||
      navigator?.userActivation?.hasBeenActive
    ) {
      this.getAudioContext();
    }

    for (let event of events) {
      document.body.addEventListener(event, this.getAudioContext);
    }
  };

  minX: number;
  maxX: number;
  minZ: number;
  maxZ: number;

  start = async () => {
    let inventory = await this.data.playerInventory();

    this.inventory = inventory;

    this.socket.on(ChatEvent.sendMessage, this.onSendChatMessage);

    this.client.onClose((evt) => {
      this.connectionStatus = NetworkConnectionStatus.disconnected;
    });

    this.client.onConnect((resp) => {
      const accepted = resp && resp.accepted;

      let reason: {
        reason: "authRequired" | "confirmUsername";
        user: Object;
        worldId: number;
        x: number;
        y: number;
        z: number;
        minX: number;
        maxX: number;
        minZ: number;
        maxZ: number;
        voiceChatToken: string;
        livestreamUID: number;
        livestreamChannel: string;
      };

      try {
        reason = JSON.parse(resp.text);
      } catch (exception) {
        console.error(exception);
      }

      if (process.env.NODE_ENV === "development") {
        this.worldId = reason?.worldId;
      } else {
        this.worldId = reason?.worldId;
      }

      this.minX = reason?.minX;
      this.maxX = reason?.maxX;
      this.minZ = reason?.minZ;
      this.maxZ = reason?.maxZ;

      if (accepted) {
        this.livestreamUID = reason.livestreamUID;
        this.livestreamChannel = reason.livestreamChannel;
        VoiceChat.token = reason.voiceChatToken;
        VoiceChat.uid = reason.voiceChatUID;
        Data.db.updateInventory().then(() => {
          this.socket.emit(HUDEvent.inventoryChange, this.inventory);
        });

        this.connectionStatus = NetworkConnectionStatus.connected;
      } else {
        const retry: WebSocketClient = this.client.retryer;

        if (reason) {
          retry.reconnectEnabled = false;
          this.socket.emit(SocketEvent.loginRequired, [
            reason.reason,
            reason.user,
          ]);
          this.initializeNoa(reason.x, reason.y, reason.z, true);
        } else {
          retry.reconnectEnabled = true;
        }
      }

      if (accepted) {
        if (this.networkTicker) {
          window.clearInterval(this.networkTicker);
        }

        this.networkTicker = window.setInterval(
          this.onNetworkTick,
          nengiConfig.UPDATE_RATE
        );
      } else {
      }
    });

    startClient(this.socket, this.pendingChunks);
    startTileClient(this.handleCustomMapChunk);

    if (process.env.NODE_ENV === "development") {
      window.game = this;
    }

    /*
     *
     *      Registering voxel types
     *
     *  Two step process. First you register a material, specifying the
     *  color/texture/etc. of a given block face, then you register a
     *  block, which specifies the materials for a given block type.
     *
     */

    // block materials (just colors for this demo)

    // this.socket.emit(SocketEvent.currentUser);

    this.socket.on(ServerEvent.keepAlive, ({ keepAliveId }) => {
      // this.socket.emit(ClientEvent.keepAlive, {
      //   keepAliveId: keepAliveId,
      // });
    });

    // this.socket.on(
    //   ClientEvent.position,
    //   ({ x, y, z, yaw, pitch, flags, teleportId }) => {
    //     console.log({ x, y, z });
    //     this.lastPosition.set([x, y, z]);
    //     if (hasReceivedInitialPosition) {
    //       this.noa?.ents?.setPosition(this.noa.playerEntity, [x, y, z]);
    //     } else {
    //       this.initializeNoa(x, y, z);
    //       hasReceivedInitialPosition = true;
    //     }
    //   }
    // );

    return Promise.resolve(true);
  };

  worldChunkLoader = new WorldChunkLoader();

  handleCustomMapChunk = (mapChunk) => {
    this.worldChunkLoader.handleWorldData(mapChunk);
    if (!this.noa.paused) {
      this.spawnPlayers();
    }

    // console.timeEnd(log);
    // console.timeEnd("WorldDataNeeded" + data.id);
  };

  destroy = () => {
    this.players.forEach((player) => player.dispose());
    this.noa?.off("tick", this.onTick);
    this.socket.removeAllListeners();
  };

  actionManager: EntityActionManager;
  players: Map<String, Player> = new Map();
  currentPlayer: CurrentPlayer;
  livestreamUID: number;
  livestreamChannel: string;
}
