import { Mesh, Animation } from "@babylonjs/core";
import {
  Button,
  Control,
  Line,
  Rectangle,
  StackPanel,
  TextBlock,
} from "@babylonjs/gui";
import type { ILocalAudioTrack } from "agora-rtc-sdk-ng";
import { CurrentPlayer } from "game/CurrentPlayer";
import DirectionsIcon1x from "./PlayerControl/DirectionsIcon.png";
import DirectionsIcon2x from "./PlayerControl/DirectionsIcon@2x.png";
import MicrophoneIcon__active1x from "./PlayerControl/MicrophoneIcon__active.png";
import MicrophoneIcon__active2x from "./PlayerControl/MicrophoneIcon__active@2x.png";
import MicrophoneIcon__inactive1x from "./PlayerControl/MicrophoneIcon__inactive.png";
import MicrophoneIcon__inactive2x from "./PlayerControl/MicrophoneIcon__inactive@2x.png";
import PortalIcon1x from "./PlayerControl/PortalIcon.png";
import PortalIcon2x from "./PlayerControl/PortalIcon@2x.png";
import VoiceIcon__active1x from "./PlayerControl/VoiceIcon__active.png";
import VoiceIcon__active2x from "./PlayerControl/VoiceIcon__active@2x.png";
import { formatter } from "components/hud/Header";
import { scale } from "game/textures/GUI_SCALE";
import { inviteBaseLink } from "components/hud/invite/InviteLinkModal";

const icon = (one, two) => (scale > 1 ? two : one);

const DirectionsIcon = icon(DirectionsIcon1x, DirectionsIcon2x);
const MicrophoneActiveIcon = icon(
  MicrophoneIcon__active1x,
  MicrophoneIcon__active2x
);
const MicrophoneInactiveIcon = icon(
  MicrophoneIcon__inactive1x,
  MicrophoneIcon__inactive2x
);
const TeleportIcon = icon(PortalIcon1x, PortalIcon2x);
const VoiceIcon = icon(VoiceIcon__active1x, VoiceIcon__active2x);

export enum VoiceActivityStatus {
  active,
  low,
  muted,
  none,
}

export class CurrentPlayerControl {
  usernameControl: TextBlock;
  voiceActivityButton: Rectangle;
  panel: StackPanel;
  teleportButton: Button;
  audioTrack: ILocalAudioTrack;
  player: CurrentPlayer;
  voiceActivity: VoiceActivityStatus = VoiceActivityStatus.none;
  analyserNode: AnalyserNode;
  audioContext: AudioContext;
  analyserBuffer: Uint8Array;
  streamSource: MediaStreamAudioSourceNode;
  stream: MediaStream;

  constructor(player: CurrentPlayer) {
    this.player = player;
    this.buildPanel();
  }

  show(panel: StackPanel) {
    panel.addControl(this.panel);
  }
  lines: Array<Line>;

  setupAudio(
    audioContext: AudioContext,
    stream: MediaStream,
    audioTrack: ILocalAudioTrack
  ) {
    this.audioContext = audioContext;
    this.stream = stream;
    this.audioTrack = audioTrack;

    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.streamSource.disconnect();
      this.streamSource = null;
    }

    this.analyserNode = audioContext.createAnalyser();
    this.analyserNode.fftSize = 1024;
    this.analyserNode.smoothingTimeConstant = 0.9;
    const bufferLength = this.analyserNode.frequencyBinCount;
    this.analyserBuffer = new Uint8Array(bufferLength);
    this.streamSource = audioContext.createMediaStreamSource(stream);
    this.streamSource.connect(this.analyserNode);
  }

  render() {
    if (
      !this.analyserNode ||
      this.voiceActivity === VoiceActivityStatus.none ||
      this.voiceActivity === VoiceActivityStatus.muted
    ) {
      return;
    }
    this.analyserNode.getByteFrequencyData(this.analyserBuffer);

    const fft = this.analyserBuffer;

    const scale =
      0.9 +
      (Math.max(0.1, fft[0] * 0.01 - 0.75) +
        Math.max(0.1, fft[10] * 0.01 - 0.75) +
        Math.max(0.1, fft[32] * 0.01 - 0.75)) /
        3;

    // this.voiceActiveButton.transformCenterX = scale / 2;
    this.voiceActiveButton.scaleX = scale;
    this.voiceActiveButton.scaleY = scale;
  }

  mesh: Mesh;

  update() {
    let voiceActivity: VoiceActivityStatus;
    if (this.audioTrack) {
      if (!this.audioTrack.isMuted) {
        voiceActivity = VoiceActivityStatus.active;
      } else {
        voiceActivity = VoiceActivityStatus.muted;
      }
    } else {
      voiceActivity = VoiceActivityStatus.none;
    }

    if (voiceActivity !== this.voiceActivity) {
      this.voiceActivity = voiceActivity;
      this.updateVoiceActivityButton();
    }
  }

  updateVoiceActivityButton() {
    if (this.voiceActivity === VoiceActivityStatus.active) {
      this.voiceActivityButton.clearControls();
      this.voiceActivityButton.addControl(this.voiceActiveButton);
    } else if (this.voiceActivity === VoiceActivityStatus.low) {
      this.voiceActivityButton.clearControls();
      if (this.lines) {
        this.voiceActivityButton.addControl(this.lines[0]);
      }

      this.voiceActivityButton.addControl(this.voiceInactiveButton);
    } else {
      this.voiceActivityButton.clearControls();
      this.voiceActivityButton.addControl(this.voiceMutedButton);
    }
  }

  handleMute = () => {
    this.onPressVoiceButton();
  };

  handleUnmute = () => {
    this.onPressVoiceButton();
  };

  onPressVoiceButton: Function;

  buildUsernameControl() {
    const textControl = new TextBlock("player-username", this.player.username);
    textControl.fontFamily = "Space Grotesk";
    textControl.fontSizeInPixels = 24 * scale;
    textControl.paddingRightInPixels = 8 * scale;
    textControl.fontWeight = "500";

    textControl.color = "white";

    textControl.textWrapping = false;
    textControl.outlineColor = "black";
    textControl.paddingLeftInPixels = 8 * scale;
    textControl.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.outlineWidth = scale * 2;
    textControl.shadowBlur = 1;
    textControl.shadowColor = "rgba(0,0,0,0.25)";

    textControl.resizeToFit = true;
    textControl.left = 0;

    return textControl;
  }

  buildInviteLinkControl() {
    const button = Button.CreateSimpleButton(
      "player-button",
      inviteBaseLink(this.player.username)
    );
    const textControl = button.textBlock;
    textControl.fontFamily = "Space Grotesk";
    textControl.fontSizeInPixels = 18 * scale;
    textControl.paddingTopInPixels = 4 * scale;
    button.leftInPixels = (-24 + 6) * scale;
    button.background = "transparent";
    button.hoverCursor = "pointer";
    textControl.paddingRightInPixels = 8 * scale;
    textControl.fontWeight = "500";

    textControl.color = "#eee";
    button.thickness = 0;

    button.shadowOffsetX = 0;
    button.shadowOffsetY = 1;
    button.clipChildren = false;

    button.clipContent = false;
    button.shadowColor = "black";
    textControl.textWrapping = false;
    textControl.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    button.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    button.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    button.adaptHeightToChildren = true;
    button.adaptWidthToChildren = true;

    textControl.shadowBlur = 1;
    textControl.shadowColor = "rgba(0,0,0,0.25)";
    textControl.computeExpectedHeight();

    textControl.resizeToFit = true;
    button.useBitmapCache = true;

    return button;
  }

  buildCurrencyTextBlock() {
    const textControl = new TextBlock("player-currency", "");

    textControl.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.clipChildren = false;

    this.resetCurrencyBlock(textControl);

    textControl.useBitmapCache = false;
    textControl.clipContent = false;
    textControl.resizeToFit = true;
    textControl.heightInPixels = textControl.computeExpectedHeight();

    return textControl;
  }

  resetCurrencyBlock(textControl: TextBlock) {
    textControl.fontSize = 24 * scale;
    // textControl.fontWeight = "500";
    textControl.fontStyle = "500";
    textControl.fontFamily = "Space Grotesk";
    textControl.paddingLeftInPixels = 8 * scale;
    textControl.color = "#85bb65";
    textControl.textWrapping = false;
    textControl.outlineColor = "rgba(0, 0, 0, 0.5)";
    textControl.outlineWidth = scale * 2.5;
  }

  set currency(value: number) {
    const text = "$" + formatter.format(value);
    this.currencyTextBlock.text = text;

    this.verticalPanel._markAsDirty();

    // this.currencyTextBlock.height = "24px";
  }

  currencyTextBlock: TextBlock;
  voiceActiveButton: Button;
  voiceMutedButton: Button;
  voiceInactiveButton: Button;
  inviteLinkControl: Button;

  verticalPanel: StackPanel;
  buildPanel() {
    this.panel = new StackPanel();
    this.panel.isVertical = false;
    this.panel.clipChildren = false;
    this.panel.clipContent = false;

    const verticalStackPanel = new StackPanel();
    verticalStackPanel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    verticalStackPanel.adaptWidthToChildren = true;
    verticalStackPanel.topInPixels = 6 * scale;
    verticalStackPanel.adaptHeightToChildren = true;

    verticalStackPanel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    verticalStackPanel.clipChildren = false;
    verticalStackPanel.clipContent = false;

    this.usernameControl = this.buildUsernameControl();
    this.inviteLinkControl = this.buildInviteLinkControl();
    this.currencyTextBlock = this.buildCurrencyTextBlock();
    this.voiceActivityButton = this.buildVoiceActivityControl();

    verticalStackPanel.addControl(this.inviteLinkControl);
    verticalStackPanel.addControl(this.usernameControl);
    verticalStackPanel.addControl(this.currencyTextBlock);

    this.voiceActivityButton.clipChildren = false;

    this.voiceActivityButton.clipContent = false;
    this.voiceActivityButton.widthInPixels = 20;
    this.voiceActivityButton.heightInPixels = 20;
    this.voiceActivityButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceActivityButton.thickness = 0;

    this.panel.heightInPixels = 24;

    this.voiceActivityButton.topInPixels = 8 * scale;
    this.voiceActivityButton.widthInPixels *= scale;
    this.voiceActivityButton.heightInPixels *= scale;

    this.panel.heightInPixels *= scale;
    this.verticalPanel = verticalStackPanel;
    // this.panel.widthInPixels =
    //   this.voiceActivityButton.widthInPixels +
    //   200 * scale +
    //   this.teleportButton.widthInPixels +
    //   this.directionsButton.widthInPixels;

    this.panel.addControl(this.voiceActivityButton);
    this.panel.addControl(verticalStackPanel);

    this.panel.leftInPixels = 16 * scale;
    this.panel.topInPixels = 8 * scale;

    this.panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.usernameControl.useBitmapCache = true;
    this.inviteLinkControl.useBitmapCache = true;
    this.updateVoiceActivityButton();
  }

  buildVoiceActivityControl() {
    const rect = new Rectangle();
    rect.clipChildren = false;
    rect.clipContent = false;
    rect.paddingTopInPixels = 62 * scale;

    this.voiceActiveButton = Button.CreateImageOnlyButton("voice", VoiceIcon);
    this.voiceActiveButton.thickness = 0;

    this.voiceActiveButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceInactiveButton = Button.CreateImageOnlyButton(
      "microphone",
      MicrophoneActiveIcon
    );
    this.voiceInactiveButton.thickness = 0;
    this.voiceInactiveButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceMutedButton = Button.CreateImageOnlyButton(
      "nomicrophone",
      MicrophoneInactiveIcon
    );

    this.voiceMutedButton.thickness = 0;
    this.voiceMutedButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;

    this.voiceActiveButton.clipChildren = false;
    this.voiceActiveButton.clipContent = false;
    this.voiceInactiveButton.heightInPixels = 24 * scale;
    this.voiceInactiveButton.widthInPixels = 24 * scale;
    this.voiceActiveButton.heightInPixels = 24 * scale;
    this.voiceActiveButton.widthInPixels = 24 * scale;
    this.voiceMutedButton.heightInPixels = 24 * scale;
    this.voiceMutedButton.widthInPixels = 24 * scale;

    this.voiceActiveButton.onPointerClickObservable.add(this.handleMute);
    this.voiceInactiveButton.onPointerClickObservable.add(this.handleMute);
    this.voiceMutedButton.onPointerClickObservable.add(this.handleUnmute);

    rect.widthInPixels = 24;
    rect.heightInPixels = 24;

    return rect;
  }

  dispose() {
    if (this.panel.parent) {
      this.panel.parent.removeControl(this.panel);
    }

    if (this.streamSource) {
      this.streamSource.disconnect();
      this.streamSource = null;
    }

    if (this.analyserNode) {
      this.analyserNode.disconnect();
    }

    this.panel.dispose();
    this.player = null;
    this.audioTrack = null;
  }
}
