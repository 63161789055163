import { Mesh } from "@babylonjs/core";
import {
  Button,
  Control,
  Line,
  Rectangle,
  StackPanel,
  TextBlock,
} from "@babylonjs/gui";
import { IAgoraRTCRemoteUser } from "agora-rtc-sdk-ng";
import { Player } from "game/Player";
import { isSafari } from "lib/browser";
import DirectionsIcon1x from "./PlayerControl/DirectionsIcon.png";
import DirectionsIcon2x from "./PlayerControl/DirectionsIcon@2x.png";
import MicrophoneIcon__active1x from "./PlayerControl/MicrophoneIcon__active.png";
import MicrophoneIcon__active2x from "./PlayerControl/MicrophoneIcon__active@2x.png";
import MicrophoneIcon__inactive1x from "./PlayerControl/MicrophoneIcon__inactive.png";
import MicrophoneIcon__inactive2x from "./PlayerControl/MicrophoneIcon__inactive@2x.png";
import PortalIcon1x from "./PlayerControl/PortalIcon.png";
import PortalIcon2x from "./PlayerControl/PortalIcon@2x.png";
import VoiceIcon__active1x from "./PlayerControl/VoiceIcon__active.png";
import VoiceIcon__active2x from "./PlayerControl/VoiceIcon__active@2x.png";
import { scale } from "game/textures/GUI_SCALE";

const icon = (one, two) => (scale > 1 ? two : one);

const DirectionsIcon = icon(DirectionsIcon1x, DirectionsIcon2x);
const MicrophoneActiveIcon = icon(
  MicrophoneIcon__active1x,
  MicrophoneIcon__active2x
);
const MicrophoneInactiveIcon = icon(
  MicrophoneIcon__inactive1x,
  MicrophoneIcon__inactive2x
);
const TeleportIcon = icon(PortalIcon1x, PortalIcon2x);
const VoiceIcon = icon(VoiceIcon__active1x, VoiceIcon__active2x);

export enum VoiceActivityStatus {
  active,
  low,
  muted,
  none,
}

export class PlayerControl {
  usernameControl: TextBlock;
  voiceActivityButton: Rectangle;
  panel: StackPanel;
  teleportButton: Button;
  voicePlayer: IAgoraRTCRemoteUser;
  player: Player;
  voiceActivity: VoiceActivityStatus = VoiceActivityStatus.none;
  analyserNode: AnalyserNode;
  audioContext: AudioContext;
  analyserBuffer: Uint8Array;
  streamSource: MediaStreamAudioSourceNode;
  stream: MediaStream;

  constructor(player: Player) {
    this.player = player;
    this.buildPanel();
  }

  show(panel: StackPanel) {
    panel.addControl(this.panel);
  }
  lines: Array<Line>;

  setupAudio(
    audioContext: AudioContext,
    stream: MediaStream,
    voicePlayer: IAgoraRTCRemoteUser
  ) {
    this.audioContext = audioContext;
    this.stream = stream;
    this.voicePlayer = voicePlayer;

    if (this.analyserNode) {
      this.analyserNode.disconnect();
      this.streamSource.disconnect();
      this.streamSource = null;
    }

    this.analyserNode = audioContext.createAnalyser();
    this.analyserNode.fftSize = isSafari() ? 32 : 1024;
    this.analyserNode.smoothingTimeConstant = 0.9;
    const bufferLength = this.analyserNode.frequencyBinCount;
    this.analyserBuffer = new Uint8Array(bufferLength);
    this.streamSource = audioContext.createMediaStreamSource(stream);
    this.streamSource.connect(this.analyserNode);
  }

  render() {
    if (
      !this.analyserNode ||
      this.voiceActivity === VoiceActivityStatus.none ||
      this.voiceActivity === VoiceActivityStatus.muted
    ) {
      return;
    }
    this.analyserNode.getByteFrequencyData(this.analyserBuffer);

    const fft = this.analyserBuffer;

    const scale =
      0.9 +
      (Math.max(0.1, fft[0] * 0.01 - 0.75) +
        Math.max(0.1, fft[10] * 0.01 - 0.75) +
        Math.max(0.1, fft[32] * 0.01 - 0.75)) /
        3;

    // this.voiceActiveButton.transformCenterX = scale / 2;
    this.voiceActiveButton.scaleX = scale;
    this.voiceActiveButton.scaleY = scale;
  }

  mesh: Mesh;

  update() {
    let voiceActivity: VoiceActivityStatus;
    if (this.voicePlayer) {
      const sound = this.player?.meshContainer?.sound;

      if (
        this.voicePlayer.hasAudio &&
        !this.voicePlayer.audioMuted &&
        this.voicePlayer.audioTrack &&
        sound &&
        sound.getVolume() > 0
      ) {
        voiceActivity = VoiceActivityStatus.active;
      } else {
        voiceActivity = VoiceActivityStatus.muted;
      }
    } else {
      voiceActivity = VoiceActivityStatus.none;
    }

    if (voiceActivity !== this.voiceActivity) {
      this.voiceActivity = voiceActivity;
      this.updateVoiceActivityButton();
    }
  }

  updateVoiceActivityButton() {
    if (this.voiceActivity === VoiceActivityStatus.active) {
      this.voiceActivityButton.clearControls();
      this.voiceActivityButton.addControl(this.voiceActiveButton);
    } else if (this.voiceActivity === VoiceActivityStatus.low) {
      this.voiceActivityButton.clearControls();
      if (this.lines) {
        this.voiceActivityButton.addControl(this.lines[0]);
      }

      this.voiceActivityButton.addControl(this.voiceInactiveButton);
    } else {
      this.voiceActivityButton.clearControls();
      this.voiceActivityButton.addControl(this.voiceMutedButton);

      const sound = this.player?.meshContainer?.sound;

      if (sound) {
        this.voiceMutedButton.alpha = 1;
      } else {
        this.voiceMutedButton.alpha = 0.8;
      }
    }
  }

  handleMute = () => {
    const sound = this.player?.meshContainer?.sound;
    if (!sound) {
      return;
    }
    sound.setVolume(0);
    this.update();
  };

  handleUnmute = () => {
    const sound = this.player?.meshContainer?.sound;
    if (!sound) {
      return;
    }

    sound.setVolume(1);
    this.update();
  };

  buildUsernameControl() {
    const textControl = new TextBlock(null, this.player.username);
    textControl.fontFamily = "Space Grotesk";
    textControl.fontSizeInPixels = 18 * scale;
    textControl.paddingTopInPixels = 6 * scale;
    textControl.paddingBottomInPixels = 6 * scale;
    textControl.paddingLeftInPixels = 8 * scale;
    textControl.paddingRightInPixels = 8 * scale;
    textControl.fontWeight = "500";

    textControl.color = "white";

    textControl.textWrapping = false;
    textControl.outlineColor = "black";
    textControl.textHorizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    textControl.textVerticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;
    textControl.outlineWidth = scale;
    textControl.shadowBlur = 1;
    textControl.shadowColor = "rgba(0,0,0,0.25)";
    textControl.heightInPixels = 24;

    textControl.resizeToFit = true;

    return textControl;
  }

  buildTeleportButton() {
    return Button.CreateImageOnlyButton("teleport", TeleportIcon);
  }

  voiceActiveButton: Button;
  voiceMutedButton: Button;
  voiceInactiveButton: Button;
  handlePressTeleport = () => {
    this.onPressTeleport(this.player.username);
  };

  buildPanel() {
    this.panel = new StackPanel();
    this.panel.isVertical = false;
    this.panel.clipChildren = false;
    this.panel.clipContent = false;

    this.usernameControl = this.buildUsernameControl();

    this.voiceActivityButton = this.buildVoiceActivityControl();
    this.teleportButton = this.buildTeleportButton();

    this.voiceActivityButton.clipChildren = false;
    this.voiceActivityButton.clipContent = false;
    this.voiceActivityButton.widthInPixels = 20;
    this.voiceActivityButton.heightInPixels = 20;
    this.voiceActivityButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceActivityButton.thickness = 0;

    this.teleportButton.widthInPixels = 16;
    this.teleportButton.topInPixels = -2 * scale;
    this.teleportButton.thickness = 0;
    this.teleportButton.onPointerClickObservable.add(this.handlePressTeleport);

    this.teleportButton.heightInPixels = 20;

    this.panel.heightInPixels = 28;

    this.voiceActivityButton.widthInPixels *= scale;
    this.voiceActivityButton.heightInPixels *= scale;

    this.teleportButton.heightInPixels *= scale;
    this.teleportButton.widthInPixels *= scale;
    this.panel.heightInPixels *= scale;
    // this.panel.widthInPixels =
    //   this.voiceActivityButton.widthInPixels +
    //   200 * scale +
    //   this.teleportButton.widthInPixels +
    //   this.directionsButton.widthInPixels;

    this.panel.addControl(this.voiceActivityButton);
    this.panel.addControl(this.usernameControl);
    this.panel.addControl(this.teleportButton);

    this.panel.left = 0;
    this.panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_CENTER;

    this.updateVoiceActivityButton();
  }

  buildVoiceActivityControl() {
    const rect = new Rectangle();
    rect.clipChildren = false;
    rect.clipContent = false;

    this.voiceActiveButton = Button.CreateImageOnlyButton("voice", VoiceIcon);
    this.voiceActiveButton.thickness = 0;

    this.voiceActiveButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceInactiveButton = Button.CreateImageOnlyButton(
      "microphone",
      MicrophoneActiveIcon
    );
    this.voiceInactiveButton.thickness = 0;
    this.voiceInactiveButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;
    this.voiceMutedButton = Button.CreateImageOnlyButton(
      "nomicrophone",
      MicrophoneInactiveIcon
    );

    this.voiceMutedButton.thickness = 0;
    this.voiceMutedButton.horizontalAlignment =
      Control.HORIZONTAL_ALIGNMENT_LEFT;

    this.voiceActiveButton.clipChildren = false;
    this.voiceActiveButton.clipContent = false;
    this.voiceInactiveButton.heightInPixels = 24 * scale;
    this.voiceInactiveButton.widthInPixels = 24 * scale;
    this.voiceActiveButton.heightInPixels = 24 * scale;
    this.voiceActiveButton.widthInPixels = 24 * scale;
    this.voiceMutedButton.heightInPixels = 24 * scale;
    this.voiceMutedButton.widthInPixels = 24 * scale;

    this.voiceActiveButton.onPointerUpObservable.add(this.handleMute);
    this.voiceInactiveButton.onPointerUpObservable.add(this.handleMute);
    this.voiceMutedButton.onPointerUpObservable.add(this.handleUnmute);

    rect.widthInPixels = 24;
    rect.heightInPixels = 24;

    return rect;
  }

  dispose() {
    if (this.panel.parent) {
      this.panel.parent.removeControl(this.panel);
    }

    if (this.streamSource) {
      this.streamSource.disconnect();
      this.streamSource = null;
    }

    if (this.analyserNode) {
      this.analyserNode.disconnect();
    }

    this.panel.dispose();
    this.player = null;
    this.voicePlayer = null;
  }
}
