import { CurrentPlayer } from "game/CurrentPlayer";
import { client } from "game/networking/client";
import MoveCommand from "shared/command/MoveCommand";
import { UpdateAvatarCommand } from "shared/command/UpdateAvatarCommand";
import { AvatarType } from "shared/entity/PlayerCharacter";

export class Commands {
  static changeAvatar(avatar: AvatarType, player: CurrentPlayer) {
    const prediction = {
      nid: player.remoteEntityId,
      avatarType: avatar,
    };
    // these reconciled positions are now our new predictions, going forward
    client.addCustomPrediction(client.tick + 1, prediction, ["avatarType"]);

    client.addCommand(
      new UpdateAvatarCommand(
        avatar,
        player.remoteEntity.color,
        player.remoteEntity.backgroundImageURL
      )
    );
  }
  static move(
    x: number,
    y: number,
    z: number,
    pitch: number,
    heading: number,
    yaw: number,
    running: boolean,
    jumping: boolean
    // onGround: boolean,
    // roll: number
  ) {
    client.addCommand(
      new MoveCommand(
        x,
        y,
        z,
        pitch,
        yaw,
        heading,
        running,
        jumping
        // onGround,
        // roll
      )
    );
  }
}
