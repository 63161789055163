import { BaseData } from "lib/BaseData";
import { isSafari } from "lib/browser";
import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { CachedMapTile, getTileID } from "lib/Data/WorldMap/CachedMapTile";
import { DrawMapTile } from "lib/Data/WorldMap/DrawMapTile";
import { imageShape, xzChunkShape } from "lib/Data/xzChunkShape";
import UPNG from "lib/UPNG";
import ndarray from "ndarray";
import * as Sentry from "@sentry/browser";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";

let pixelRatio: number;

let documentCanvas: HTMLCanvasElement;
let documentCanvasCtx: CanvasRenderingContext2D;

const getBlob = (): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    documentCanvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      "image/png",
      1
    );
  });
};

const getImageElement = () => {
  return getBlob().then((blob) => {
    const img = new Image(
      CHUNK_SIZE * self.devicePixelRatio,
      CHUNK_SIZE * self.devicePixelRatio
    );

    return new Promise((resolve, reject) => {
      img.srcObject = blob;

      img.onload = resolve;
      img.onerror = reject;
    });
  });
};

export class MapTile {
  rawImageData: ImageData;
  imageData: ndarray;
  height: ndarray;
  data: CachedMapTile;
  image?: Blob | ImageBitmap;

  static db: BaseData;

  constructor(
    imageData: ImageData | null,
    height: Uint8Array | null,
    tile: CachedMapTile | null
  ) {
    this.rawImageData = imageData;
    this.data = tile;

    if (height) {
      this.height = ndarray(height, [imageData.width, imageData.height]);
    }

    if (imageData) {
      this.imageData = ndarray(imageData.data, [
        imageData.width,
        imageData.height,
      ]);
    }

    if (USE_IMAGE_BITMAP && tile && tile.bitmap instanceof ImageBitmap) {
      this.image = tile.bitmap;
    }
  }

  updateData = (
    bitmap: ImageBitmap | Blob,
    buffer: ArrayBuffer,
    data: ImageData
  ) => {
    this.data.bitmap = buffer;

    this.rawImageData = data;
    if (!this.imageData || this.imageData.data !== data) {
      this.imageData = ndarray(data.data, imageShape);
    }

    if (this.image && this.image.close) {
      this.image.close();
    }

    this.image = bitmap;

    return this.image;
  };

  static fromDB(tile: CachedMapTile) {
    if (!tile || !tile.id) {
      return null;
    }
    const _tile = new MapTile(null, null, tile);
    return _tile;
  }

  static wrap(tiles: Array<CachedMapTile>) {
    const _tiles = new Array(tiles.length);

    for (let i = 0; i < tiles.length; i++) {
      _tiles[i] = MapTile.fromDB(tiles[i]);
    }

    return _tiles;
  }

  static loadByID(id: string) {
    return MapTile.db.mapTilesTable.get(id).then(MapTile.fromDB);
  }

  static loadOne(x: number, z: number, worldId: string) {
    return this.loadByID(getTileID(x, z, worldId));
  }

  static load(
    minX: number,
    maxX: number,
    minZ: number,
    maxZ: number,
    worldId: string,
    skipList: Map<string, MapTile>
  ) {
    const xLength = Math.abs(maxX - minX);
    const zLength = Math.abs(maxZ - minZ);
    const ids = ndarray(new Array(xLength * zLength), [xLength, zLength]);
    for (let i = 0; i < ids.shape[0]; i++) {
      for (let j = 0; j < ids.shape[1]; j++) {
        const id = getTileID(minX + i, minZ + j, worldId);

        if (!skipList.has(id)) {
          ids.set(i, j);
        }
      }
    }

    return this.loadByIDs(ids.data);
  }

  static loadByIDs(id: Array<string>) {
    return MapTile.db.mapTilesTable.bulkGet(id).then(MapTile.wrap);
  }

  static bulkSave(mapTile: Array<CachedMapTile>, keys: Array<string>) {
    return MapTile.db.mapTilesTable.bulkPut(mapTile);
  }

  async save() {
    if (!this.rawImageData) {
      return null;
    }

    await MapTile.saveTile(this);
  }

  loadImage = () => {
    if (!this.data.bitmap) {
      return null;
    }

    if (
      this.data.bitmap instanceof ArrayBuffer &&
      !this.rawImageData &&
      this.data.bitmap.byteLength > 1
    ) {
      try {
        this.rawImageData = new ImageData(
          new Uint8ClampedArray(this.data.bitmap),
          CHUNK_SIZE,
          CHUNK_SIZE
        );
        // Images can get...corrupted?
      } catch (exception) {
        Sentry.captureException(exception);
        this.data.bitmap = null;
        return null;
      }
    }

    if (this.rawImageData && USE_IMAGE_BITMAP) {
      return createImageBitmap(this.rawImageData);
    } else if (this.rawImageData && typeof self.document !== "undefined") {
      if (!documentCanvas) {
        documentCanvas = document.createElement("canvas");
        documentCanvas.width = CHUNK_SIZE;
        documentCanvas.height = CHUNK_SIZE;
        documentCanvasCtx = documentCanvas.getContext("2d");
        documentCanvasCtx.imageSmoothingEnabled = false;
      }

      documentCanvasCtx.putImageData(this.rawImageData, 0, 0);
      return getImageElement();
    } else {
      return null;
    }
  };

  reloadImage = () => {
    if (
      !this.rawImageData &&
      USE_IMAGE_BITMAP &&
      this.data?.bitmap instanceof ImageBitmap
    ) {
      return this.data.bitmap;
    }

    if (
      !this.rawImageData &&
      this.data.bitmap instanceof ArrayBuffer &&
      isSafari()
    ) {
      this.image = new Blob([this.data.bitmap], {
        type: "image/png",
      });
      return this.image;
    }

    return MapTile.getBitmap(this.rawImageData, this);
  };

  static getBitmap(data: ImageData, tileRef: MapTile) {
    if (!pixelRatio) {
      pixelRatio = self.devicePixelRatio;
    }
    if (USE_IMAGE_BITMAP) {
      return MapTile.drawTileToOffsreenCanvas(data, tileRef);
    } else {
      return MapTile.drawTileToCanvas(data, tileRef);
    }
  }

  static async drawTileToOffsreenCanvas(data: ImageData, ref: MapTile) {
    let bitmap = await DrawMapTile.draw(data);
    return ref.updateData(bitmap, data.data.buffer, data);
  }

  static drawTileToCanvas(data: ImageData, ref: MapTile) {
    const img = UPNG.encode([data.data.buffer], data.width, data.height, 0);
    return ref.updateData(
      new Blob([img], { type: "image/png" }),
      data.data.buffer,
      data
    );
  }

  static drawTileOnMainThread(data: ImageData, ref: MapTile) {
    return ref.updateData(
      new Blob([img], { type: "image/png" }),
      data.data.buffer,
      data
    );
  }

  static tileCanvas: OffscreenCanvas | HTMLCanvasElement;

  static tileCtx: OffscreenCanvasRenderingContext2D | CanvasRenderingContext2D;

  static saveTile(tile: MapTile) {
    return MapTile.db.mapTilesTable.put(tile.data, tile.data.id);
  }

  async update(
    colors: Uint8ClampedArray,
    height: Uint8Array,
    x: number,
    z: number,
    worldId: number
  ) {
    var colorsND: ndarray;
    var heightsND: ndarray;

    // return;
    colorsND = ndarray(colors, imageShape);

    const currentImageData = this.imageData;

    heightsND = ndarray(height, xzChunkShape);

    let hasChangedImage = false;

    // let heightLevels = new Set();

    // let lastHeight = 0;
    for (let x = 0; x < colorsND.shape[0]; x++) {
      for (let z = 0; z < colorsND.shape[1]; z++) {
        const newHeight = heightsND.get(z, x);
        const currentHeight = this.height.get(z, x);
        const index = currentImageData.index(z, x, 0);
        const input = colorsND.data.subarray(index, index + 3);

        const isNewColorBlack =
          input[0] === 0 && input[1] === 0 && input[2] === 0;

        if (
          !isNewColorBlack &&
          (newHeight > currentHeight || currentHeight === 0)
        ) {
          const output = (currentImageData.data as Uint8ClampedArray).subarray(
            index,
            index + 3
          );

          output.set(input);

          hasChangedImage = true;
          this.height.set(z, x, newHeight);
          // lastHeight = newHeight
        } else {
          // lastHeight = currentHeight;
        }

        // heightLevels
      }
    }

    if (hasChangedImage) {
      await this.reloadImage();
      MapTile.enqueueForSaving(this);
      return this;
    } else if (!this.image) {
      await this.reloadImage();
      return this;
    }
  }
}
