import { THREAD_COUNT } from "game/networking/THREAD_COUNT";
import { Submesh } from "lib/meshing/Submesh";
import Engine from "noa-engine";
import { Chunk } from "noa-engine/lib/chunk";
import { Pool, spawn } from "threads";
import TerrainMeshWorker from "./TerrainMeshWorker.worker";

let meshWorkerPool: Pool<{
  id: string;
  results: Object;
  merged: Array<Submesh>;
}>;

let _noa: Engine;

export const startMeshWorker = (noa: Engine) => {
  _noa = noa;

  meshWorkerPool = Pool(
    () => {
      const worker: Worker = new TerrainMeshWorker();

      const {
        blockSolidity,
        blockOpacity,
        blockIsFluid,
        matData: _matData,
        blockMats,
      } = noa.registry;

      let matData = _matData.map((mat) => ({ ...mat, renderMat: true }));

      worker.postMessage({
        type: "__START",
        blockSolidity,
        blockOpacity,
        blockIsFluid,
        blockMats,
        matData,
      });
      worker.onerror = (err) => console.log(err);

      return spawn(worker);
    },
    {
      size: Math.max(THREAD_COUNT / 2, 2),
    }
  );
};

export const meshChunk = (chunk: Chunk) => {
  meshWorkerPool.queue((func) => {
    func(
      chunk.id,
      chunk.voxels.data,
      chunk.voxels.shape,
      chunk.voxels.stride,
      chunk.voxels.offset,
      chunk._neighbors.data.map((chunk) =>
        chunk
          ? {
              data: chunk.voxels.data,
              shape: chunk.voxels.shape,
              stride: chunk.voxels.stride,
              offset: chunk.voxels.offset,
            }
          : null
      ),
      chunk._neighbors.shape,
      chunk._neighbors.stride,
      chunk._neighbors.offset,
      chunk.isFull || chunk.isEmpty
    ).then(({ id, ...mesh }) => {
      _noa.world.handleWorkerMesh(id, mesh);
    });
  });
};
