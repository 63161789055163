import * as React from "react";
import type { Game } from "../game/Game";
import { ClaimInviteModal } from "components/hud/ClaimInviteModal";

export const GameContext = React.createContext<{ game: Game }>({
  game: null,
});

export enum ModalType {
  claimInvite = "claimInvite",
}

export type ModalProviderType = {
  openModals: Array<ModalType>;
  openModal: (type: ModalType, options: Object) => void;
  closeModal: (type: ModalType) => void;
};

export const ModalProvider = React.forwardRef(({}, ref) => {
  const [openModals, setOpenModals] = React.useState([]);
  const [modalProps, setModalProps] = React.useState([]);

  const handleOpenModal = React.useCallback(
    (type: ModalType, options: Object) => {
      setOpenModals((modals) => {
        const _modals = modals.slice(0);
        _modals.push(type);

        return _modals;
      });
      setModalProps((modalProps) => {
        const _modals = modalProps.slice(0);
        _modals.push(options);

        return _modals;
      });
    },
    [setOpenModals, setModalProps]
  );

  const handleCloseModal = React.useCallback(
    (type: ModalType) => {
      setOpenModals((modals) => {
        const index = modals.indexOf(type);

        if (index > -1) {
          let _modals = modals.slice(0);
          _modals.splice(index, 1);
          setModalProps((modalProps) => {
            let _modals = modalProps.slice(0);
            _modals.splice(index, 1);
            return _modals;
          });
          return _modals;
        }

        return modals;
      });
    },
    [setOpenModals, modalProps]
  );

  React.useImperativeHandle(
    ref,
    () => ({
      openModals,
      openModal: handleOpenModal,
      closeModal: handleCloseModal,
    }),
    [handleOpenModal, openModals, handleCloseModal]
  );

  const renderOpenModal = React.useCallback(
    (type, index) => {
      if (type === ModalType.claimInvite) {
        return (
          <ClaimInviteModal
            key={`${type}-${index}`}
            {...modalProps[index]}
            onDismiss={handleCloseModal}
          />
        );
      } else {
        return null;
      }
    },
    [modalProps, handleCloseModal]
  );
  return openModals.map(renderOpenModal);
});
