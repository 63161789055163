import nengi from "nengi";

export class MoveCommand {
  constructor(
    x,
    y,
    z,
    pitch,
    heading,
    yaw,
    running = false,
    jumping = false,
    onGround = false,
    roll = 0
  ) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.running = running;
    this.jumping = jumping;
    this.heading = heading;
    this.onGround = onGround;
    this.pitch = pitch;
    this.yaw = yaw;
    this.roll = roll;
  }
  x: number;
  y: number;
  z: number;
  pitch: number;
  heading: number;
  yaw: number;
  roll: number;

  running: boolean;
  jumping: boolean;
  onGround: boolean;
  static protocol = {
    x: nengi.Float32,
    y: nengi.Float32,
    z: nengi.Float32,
    running: nengi.Boolean,
    heading: nengi.RotationFloat32,
    jumping: nengi.Boolean,
    onGround: nengi.Boolean,
    pitch: nengi.RotationFloat32,
    yaw: nengi.RotationFloat32,
    roll: nengi.RotationFloat32,
  };
}

export default MoveCommand;
