import {
  AbstractMesh,
  AnimationGroup,
  IParticleSystem,
  ISceneLoaderPlugin,
  ISceneLoaderPluginAsync,
  Scene,
  SceneLoader,
  SceneLoaderProgressEvent,
  Skeleton,
} from "@babylonjs/core";
import debug from "lib/log";
import { join } from "path";
import { BlockID, isFoilage, isSurface } from "shared/blocks";
import FOILAGE from "shared/foilages.json";
import { blockMap, itemId } from "shared/items";
import SURFACES from "shared/surfaces.json";
import { fileMap } from "./fileMap";
import pLimit from "p-limit";
export const fetchOpts = {
  cache: "force-cache",
  redirect: "follow",
  credentials: "omit",
  mode: "same-origin",
  method: "GET",
};

const log = debug("[GLBFetcher]");

log.enabled = false;

const resolvePath = (id: BlockID, variant: number, extension = ".glb") => {
  const name = blockMap.get(id);
  let url = resolvePathName(id),
    filename = resolveFilename(id, variant, extension);

  if (!url || !filename) {
    return null;
  }

  return join(url, filename);
};

const resolvePathName = (id: BlockID) => {
  if (isFoilage(id)) {
    return "/foilage/";
  } else if (isSurface(id)) {
    return "/surfaces/";
  }

  return null;
};

const resolveFilename = (id: BlockID, variant: number, extension = ".glb") => {
  const name = blockMap.get(id);
  let filename;
  if (isFoilage(id)) {
    filename = FOILAGE[name] ? FOILAGE[name].variants[variant] : null;
  } else if (isSurface(id)) {
    filename = SURFACES[name] ? SURFACES[name].variants[variant] : null;
  }

  if (!filename) {
    return null;
  }

  return filename + extension;
};

const asFile = (response: Response) => response.arrayBuffer();

const fetchAsset = (blockID: BlockID, variantID: number): Promise<Blob> => {
  const id = itemId(blockID, variantID, true);

  const url = resolvePath(blockID, variantID);

  if (fileMap.has(url)) {
    return fileMap.get(url);
  }

  if (!url) {
    return Promise.reject(null);
  }

  log("Downloading", id, url);
  return fetch(url, fetchOpts).then(asFile);
};

const fetchAssetWithLimit = pLimit(4);

const _logProgressInDevelopment = (task: SceneLoaderProgressEvent) => {
  log("Loaded", task.loaded, "/", task.total);
};

const ENABLE_LOG_PROGRESS = false;
const logProgressInDevelopment =
  ENABLE_LOG_PROGRESS && process.env.NODE_ENV === "development"
    ? _logProgressInDevelopment
    : null;

export enum GLBProgressStatus {
  pending,
  loading,
  done,
  error,
}

const assetsPendingDownload = (asset: GLBAsset) =>
  asset.downloadStatus === GLBProgressStatus.pending ||
  asset.downloadStatus === GLBProgressStatus.loading;

export class GLBAsset {
  blockID: BlockID;
  variant: number;
  file: File;
  downloadStatus: GLBProgressStatus = GLBProgressStatus.pending;
  importStatus: GLBProgressStatus = GLBProgressStatus.pending;
  scene: Scene;

  meshes: AbstractMesh[];
  particleSystems: IParticleSystem[];
  skeletons: Skeleton[];
  animationGroups: AnimationGroup[];

  constructor(blockID: BlockID, variant: number, scene: Scene) {
    this.blockID = blockID;
    this.variant = variant;
    this.scene = scene;
  }

  static type = ".glb";

  download() {
    if (this.file) {
      return this.file;
    }

    this.downloadStatus = GLBProgressStatus.loading;
    return fetchAssetWithLimit(() =>
      fetchAsset(this.blockID, this.variant)
    ).then(this.setFile, this.downloadFailed);
  }

  setFile = (file: File) => {
    this.file = file;
    this.downloadStatus = GLBProgressStatus.done;
    fileMap.set(resolvePath(this.blockID, this.variant), file);

    return this.runCallbacks(this.onDownloadCompleteCallbacks);
  };

  private async runCallbacks(
    callbacks: Array<(asset: GLBAsset) => Promise<void>>
  ) {
    if (!callbacks) {
      return;
    }

    for (let callback of callbacks) {
      await callback(this);
    }

    callbacks.length = 0;
  }

  private runCallback(callback) {
    callback(this);
  }

  downloadFailed = (error: Error) => {
    this.downloadStatus = GLBProgressStatus.error;
    log("Failed to download", error);
    this.runCallbacks(this.onDownloadCompleteCallbacks);
  };

  onDownloadCompleteCallbacks: Array<Function>;
  onImportCompleteCallbacks: Array<Function>;

  onSuccess = ({
    meshes,
    particleSystems,
    skeletons,
    animationGroups,
  }: {
    meshes: AbstractMesh[];
    particleSystems: IParticleSystem[];
    skeletons: Skeleton[];
    animationGroups: AnimationGroup[];
  }) => {
    this.meshes = meshes;
    this.particleSystems = particleSystems;
    this.skeletons = skeletons;
    this.animationGroups = animationGroups;
    this.importStatus = GLBProgressStatus.done;
    this.downloadStatus = GLBProgressStatus.done;
    log("Imported", meshes.length, this.blockID, this.variant);

    return this.runCallbacks(this.onImportCompleteCallbacks).then(
      this.clearFile
    );
  };

  clearFile = () => {
    fileMap.delete(resolvePath(this.blockID, this.variant));
    this.file = null;
  };

  onError = (scene: Scene, message: string, error: Error) => {
    this.importStatus = GLBProgressStatus.error;
    log(message, error);

    return this.runCallbacks(this.onImportCompleteCallbacks);
  };

  get fileInfo() {
    return {
      url: resolvePathName(this.blockID),
      rootUrl: resolvePathName(this.blockID),
      name: resolveFilename(this.blockID, this.variant),
      file: this.file,
    };
  }

  import() {
    if (this.importStatus === GLBProgressStatus.done) {
      return this.meshes;
    }

    if (
      this.importStatus === GLBProgressStatus.error ||
      this.downloadStatus === GLBProgressStatus.error
    ) {
      return null;
    }

    if (this.importStatus === GLBProgressStatus.loading) {
      return new Promise((resolve) => {
        if (!this.onImportCompleteCallbacks) {
          this.onImportCompleteCallbacks = [];
        }

        this.onImportCompleteCallbacks.push(resolve);
      });
    }

    this.importStatus = GLBProgressStatus.loading;
    log("Importing", this.blockID, this.variant);
    const fileInfo = this.fileInfo;
    return SceneLoader.LoadAssetContainerAsync(
      fileInfo.rootUrl,
      fileInfo.name,
      this.scene,
      logProgressInDevelopment,
      ".glb"
    ).then(this.onSuccess, this.onError);
  }

  loadFromPlugin = (
    plugin: ISceneLoaderPlugin | ISceneLoaderPluginAsync,
    data,
    responseURL
  ) => {
    if (plugin.importMesh) {
      var syncedPlugin = <ISceneLoaderPlugin>plugin;
      var meshes = new Array<AbstractMesh>();
      var particleSystems = new Array<IParticleSystem>();
      var skeletons = new Array<Skeleton>();

      if (
        !syncedPlugin.importMesh(
          [],
          this.scene,
          data,
          this.fileInfo.rootUrl,
          meshes,
          particleSystems,
          skeletons,
          this.onError
        )
      ) {
        return;
      }

      this.scene.loadingPluginName = plugin.name;
      this.onSuccess({ meshes, particleSystems, skeletons });
    } else {
      let asyncPlugin = plugin as ISceneLoaderPluginAsync;

      const fileInfo = this.fileInfo;
      return asyncPlugin
        .importMeshAsync(
          [],
          this.scene,
          data,
          fileInfo.rootUrl,
          logProgressInDevelopment,
          fileInfo.name
        )
        .then(this.onSuccess, this.onError);
    }
  };
}

export class GLBFetcher {
  glbs: Map<symbol, GLBAsset> = new Map();
  maxConcurrent = 4;
  downloadingList = new Set<GLBAsset>();
  static fetcher = new GLBFetcher();

  async onFinishDownload(asset: GLBAsset) {
    this.downloadingList.delete(asset);
  }

  downloadAsset = async (asset: GLBAsset) => {
    if (
      asset.downloadStatus === GLBProgressStatus.pending ||
      asset.downloadStatus === GLBProgressStatus.error
    ) {
      this.downloadingList.add(asset);
      try {
        await asset.download();
        this.onFinishDownload(asset);
      } catch (exception) {
        this.onFinishDownload(asset);
        throw exception;
      }
    }
  };

  async importAssets(assets: Array<GLBAsset>) {
    for (let asset of assets) {
      await asset.import();
    }
  }

  async downloadAssets(_assets: Array<GLBAsset>) {
    const assets = _assets.filter(assetsPendingDownload);
    if (assets.length === 0) {
      return true;
    }

    await Promise.allSettled(assets.map(this.downloadAsset));
  }

  async fetch(
    blockIDs: Array<BlockID>,
    variantIDs: Array<number>,
    scene: Scene
  ) {
    const assets = new Array(blockIDs.length);

    for (let i = 0; i < assets.length; i++) {
      const block = blockIDs[i];
      const variant = variantIDs[i];

      const id = itemId(block, variant);

      let asset = this.glbs.get(id);

      if (!asset) {
        asset = new GLBAsset(block, variant, scene);
        this.glbs.set(id, asset);
      }

      assets[i] = asset;
    }

    await this.downloadAssets(assets);
    await this.importAssets(assets);

    return assets;
  }
}
