import ndarray from "ndarray";
import { BlockID } from "shared/BlockID";
import { USE_SHARED_ARRAY_BUFFER } from "lib/Data/USE_SHARED_ARRAY_BUFFER";
import COLORS from "shared/block-colors.json";
import { MINIMAP_RGB } from "lib/MINIMAP_WIDTH";
import { xzChunkShape, imageShape } from "lib/Data/xzChunkShape";
import chroma from "chroma-js";
import { Color } from "lib/Color";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";
import { itemId, ItemVariantField, getItemVariant } from "shared/items";
import { LazyNDArray } from "lib/Data/LazyNDArray";

const missingBlocks = new Set();

var defaultColorData: Uint8ClampedArray;

let lastSize = 0;
const logBlocks = () => {
  if (missingBlocks.size > lastSize) {
    lastSize = missingBlocks.size;
    console.log(missingBlocks);
  }
};

export class WorldChunk {
  noa;
  meshes = new Map<string, any>();
  onInstanceDispose = (mesh: any) => {
    if (this.isDisposed) {
      return;
    }
    this.meshes.delete(mesh.name);
  };

  entities = new Uint16Array();

  x: number;
  y: number;
  z: number;

  get chunkX() {
    return this.noa.world._worldCoordToChunkCoord(this.x);
  }

  get chunkY() {
    return this.noa.world._worldCoordToChunkCoord(this.y);
  }

  get chunkZ() {
    return this.noa.world._worldCoordToChunkCoord(this.z);
  }

  getFoilageType = (x: number, y: number, z: number) => {
    return this.foilageData.get(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z
    );
  };

  getSurfaceType = (x: number, y: number, z: number) =>
    this.surfaceData.get(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z
    );

  getSurfaceVariant = (x: number, y: number, z: number) =>
    this.surfaceVariants.get(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z
    );

  getFoilageVariant = (x: number, y: number, z: number) =>
    this.foilageVariants.get(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z
    );

  foilageVariants: ndarray;
  _foilageVariants: Uint32Array;
  surfaceVariants: ndarray;
  _surfaceVariants: Uint32Array;
  setRelativeFoilageType = (
    x: number,
    y: number,
    z: number,
    blockId: BlockID,
    variant: number = -1
  ) => {
    this.foilageData.set(x, y, z, blockId);

    if (variant > -1) {
      this.foilageVariants.set(x, y, z, variant);
    }
  };

  getPossibleBlockPairs = (x: number, y: number, z: number) => {
    let foilageType = this.getFoilageType(x, y, z);
    let surfaceType = this.getSurfaceType(x, y, z);
    let foilageVariant = this.getFoilageVariant(x, y, z);
    let surfaceVariant = this.getSurfaceVariant(x, y, z);
    let blockType = this.noa.getBlock(x, y, z);
    return [
      [blockType, 0],
      [surfaceType, surfaceVariant],
    ];
  };

  setRelativeSurfaceType = (
    x: number,
    y: number,
    z: number,
    blockId: BlockID,
    variant: number = -1
  ) => {
    this.surfaceData.set(x, y, z, blockId);

    if (variant > -1) {
      this.surfaceVariants.set(x, y, z, variant);
    }
  };

  setFoilageType = (
    blockId: BlockID,
    variant: number,
    x: number,
    y: number,
    z: number
  ) => {
    return this.setRelativeFoilageType(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z,
      blockId,
      variant
    );
  };

  setSurfaceType = (
    blockId: BlockID,
    variant: number,
    x: number,
    y: number,
    z: number
  ) => {
    return this.setRelativeSurfaceType(
      Math.round(x) - this.x,
      Math.round(y) - this.y,
      Math.round(z) - this.z,
      blockId,
      variant
    );
  };

  id: string;
  origin: Uint32Array;
  stride: Uint32Array;
  shape: Uint32Array;
  blockCount: number;
  blockOwners: Array<string>;
  states: Map<string, Object>;
  foilageStates: Map<string, Object>;
  surfaceStates: Map<string, Object>;
  blocks: Uint16Array;
  foilage: Uint16Array;
  surfaces: Uint16Array;
  worldData: Uint16Array;
  topBlocks: LazyNDArray;
  foilageData: LazyNDArray;
  surfaceData: LazyNDArray;
  surfacePositions: Map<symbol, Array<number>>;
  foilagePositions: Map<symbol, Array<number>>;

  getWorldData = () => {
    let worldDataContent, chunkColorsContent, chunkHeightContent;
    let requiredSurfaceMeshes = new Map();
    let requiredFoilageMeshes = new Map();

    var worldData: ndarray,
      chunkHeight: ndarray,
      blocks: ndarray,
      chunkColors: ndarray;

    worldDataContent = USE_SHARED_ARRAY_BUFFER
      ? new Uint16Array(
          new SharedArrayBuffer(this.blockCount * Uint16Array.BYTES_PER_ELEMENT)
        )
      : new Uint16Array(this.blockCount);

    if (!worldData) {
      worldData = ndarray(worldDataContent, this.shape, this.stride);
    } else {
      worldData.data = worldDataContent;
    }

    const colorSize = CHUNK_SIZE * CHUNK_SIZE * 4;

    if (!defaultColorData) {
      defaultColorData = new Uint8ClampedArray(colorSize);
      defaultColorData[3] = 255;

      for (let i = 4; i <= defaultColorData.length; i += 4) {
        defaultColorData.copyWithin(i, 0, 4);
      }
    }

    chunkColorsContent = new Uint8ClampedArray(defaultColorData);
    chunkColors = ndarray(chunkColorsContent, imageShape);

    const heightSize = CHUNK_SIZE * CHUNK_SIZE;

    chunkHeightContent = USE_SHARED_ARRAY_BUFFER
      ? new Uint8Array(
          new SharedArrayBuffer(heightSize * Uint8Array.BYTES_PER_ELEMENT)
        )
      : new Uint8Array(heightSize);
    chunkHeight = ndarray(chunkHeightContent, xzChunkShape);

    blocks = ndarray(this.blocks, this.shape, this.stride);

    let hasImages = false;
    let isEmpty = true;

    for (var i = 0; i < worldData.shape[0]; i++) {
      for (var k = 0; k < worldData.shape[2]; k++) {
        let lastId = 0;
        let didChangeColor = false;
        for (var j = 0; j < worldData.shape[1]; j++) {
          const index = blocks.index(i, j, k);
          const blockPosition = blocks.data[index];
          const foilageType = this.foilage[index];
          const surfaceType = this.surfaces[index];

          let block = 0;
          let blockID = 0;
          if (blockPosition) {
            blockID = blockPosition;
          }

          if (foilageType !== 0) {
            const variant = getItemVariant(this.foilageVariants[index]).variant;
            const key = itemId(foilageType, variant, true);

            if (!requiredFoilageMeshes.has(key)) {
              requiredFoilageMeshes.set(key, [index]);
            } else {
              requiredFoilageMeshes.get(key).push(index);
            }
          }

          if (surfaceType !== 0) {
            const variant = getItemVariant(this.surfaceVariants[index]).variant;
            const key = itemId(surfaceType, variant, true);

            if (!requiredSurfaceMeshes.has(key)) {
              requiredSurfaceMeshes.set(key, [index]);
            } else {
              requiredSurfaceMeshes.get(key).push(index);
            }
          }

          const isKnownBlock = BlockID[blockID];
          if (!isKnownBlock) {
            missingBlocks.add(blockID);
          } else if (blockID !== BlockID.air) {
            block = blockID;
            isEmpty = false;

            const __color = COLORS[block];

            if (__color) {
              if (lastId === block) {
                const color = (chunkColors.data as Uint8ClampedArray).subarray(
                  chunkColors.index(k, i, 0),
                  chunkColors.index(k, i, 3)
                );
                Color.darken(color, 0.07);
              } else {
                const color = (chunkColors.data as Uint8ClampedArray).subarray(
                  chunkColors.index(k, i, 0),
                  chunkColors.index(k, i, 3)
                );

                color.set(__color);
                // if (didChangeColor) {
                Color.darken(color, 0.05);
                // }
              }

              chunkHeight.set(k, i, j + this.y);

              hasImages = true;
              didChangeColor = true;
            } else if (block !== 0) {
            }

            lastId = block;
          }

          worldData.data[index] = block;
        }
      }
    }

    // if (process.env.NODE_ENV === 'development') {
    // if (missingBlocks.size > 0) {
    //   logBlocks();
    // }

    return {
      worldData,
      topBlocks: !hasImages ? null : chunkColors.data,
      heights: !hasImages ? null : chunkHeight.data,
      isEmpty,
      requiredSurfaceMeshes,
      requiredFoilageMeshes,
    };
  };

  imageData: ImageData;
  isDisposed = false;

  dispose = () => {
    this.isDisposed = true;
    this.meshes = null;

    this.id = null;
    this.origin = null;
    this.stride = null;
    this.shape = null;
    this.blockCount = null;
    this.blockOwners = null;
    this.states = null;
    this.foilageStates = null;
    this.surfaceStates = null;
    this.blocks = null;
    this.foilage = null;
    this.surfaces = null;
    this.worldData = null;
    this.foilageData = null;
    this.surfaceData = null;
    this._foilageVariants = null;
    this._surfaceVariants = null;
    this.surfaceVariants = null;
    this.foilageVariants = null;
  };
}
