import { THREAD_COUNT } from "game/networking/THREAD_COUNT";
import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { getTileID, getTileIDNumber } from "lib/Data/WorldMap/CachedMapTile";
import { range } from "lodash";
import MinimapTileRenderWorker from "./WorldMap/MinimapTileRender.worker";
import { MapTile } from "lib/Data/WorldMap/MapTile";

export type TileBitmap = Blob | ImageBitmap;

export interface WorldMapInterface {
  imageAt(x: number, z: number): TileBitmap | null;
}

export class MinimapTileStore implements WorldMapInterface {
  worker: Worker;
  chunkMap: Map<string, TileBitmap> = new Map();
  static worldId: number;

  imageAt(x: number, z: number) {
    const id = getTileID(x, z, MinimapTileStore.worldId);
    return this.chunkMap.get(id);
  }

  static store: MinimapTileStore;
  static channels = range(0, THREAD_COUNT).map(() => new MessageChannel());

  getBlobs = async (tiles: Array<MapTile>) => {
    for (let tile of tiles) {
      if (tile && tile.data) {
        const id = tile.data.id;

        if (!this.chunkMap.has(id)) {
          const img = await tile.loadImage();
          if (img) {
            this.chunkMap.set(id, img);
          }
        }
      }
    }
    window.chunkMap = this.chunkMap;
  };

  preload = (
    minX: number,
    maxX: number,
    minZ: number,
    maxZ: number,
    worldId: number = MinimapTileStore.worldId
  ) => {
    return MapTile.load(minX, maxX, minZ, maxZ, worldId, this.chunkMap).then(
      this.getBlobs
    );
  };

  constructor() {
    MinimapTileStore.store = this;
    this.worker = new MinimapTileRenderWorker();
    const ports = MinimapTileStore.channels.map((channel) => channel.port2);

    this.worker.postMessage({ ports, start: true }, [...ports]);
    this.worker.addEventListener("message", this.handleUpdate);
  }

  onBeforeClose: (id: string, bitmap: ImageBitmap) => void;

  handleUpdate = (message: MessageEvent) => {
    for (let [id, bitmap] of message.data) {
      if (USE_IMAGE_BITMAP && this.chunkMap.has(id)) {
        const bitmap = this.chunkMap.get(id);

        if (bitmap instanceof ImageBitmap && bitmap !== message.data.bitmap) {
          this.onBeforeClose && this.onBeforeClose(id, bitmap);
          bitmap.close();
        }
      }

      // console.log(id, bitmap);
      this.chunkMap.set(id, bitmap);
    }
  };

  deleteChunk(x: number, z: number) {
    // const id = getId(x, z);
    // if (this.xzChunkImages.has(id)) {
    //   this.xzChunkImages.delete(id);
    // }
    // const heightData = this.xzChunkHeight.get(id);
    // if (heightData) {
    //   heightData.data = null;
    //   this.xzChunkHeight.delete(id);
    // }
  }
}
