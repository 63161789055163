import {
  AbstractMesh,
  InstancedMesh,
  Mesh,
  Scene,
  SolidParticle,
  SolidParticleSystem,
  StandardMaterial,
  Texture,
  TransformNode,
  Vector3,
  MeshBuilder,
} from "@babylonjs/core";
import * as Sentry from "@sentry/browser";
import Debug from "debug";
import { CHUNK_SIZE } from "game/CHUNK_SIZE";
import {
  blockActions,
  EntityAction,
  EntityActionManager,
} from "game/EntityActionManager";
import {
  FoilageMesh,
  getAlignment,
  getOffset,
  FOILAGE_SCALE,
  getRotation,
} from "game/meshes/FoilageMesh";
import {
  createScreenPlane,
  findBannerScreenMesh,
  setURLForPlane,
  setURLTexture,
} from "game/meshes/ScreenMesh";
import { requestMapChunk } from "game/networking/tileClient";
import { LazyNDArray } from "lib/Data/LazyNDArray";
import { WorldChunk } from "lib/Data/WorldChunk";
import { WorldMapStore } from "lib/Data/WorldMapStore";
import { FoilageLoader } from "lib/FoilageLoader";
import { MinimapRender } from "lib/MinimapRender";
import ndarray from "ndarray";
import NoaEngine from "noa-engine";
import { airChunk, Chunk } from "noa-engine/lib/chunk";
import { BlockID } from "shared/BlockID";
import { isBlock, isFoilage, isSurface, stateKey } from "shared/blocks";
import {
  getItemVariant,
  itemId,
  items,
  ItemVariant,
  ItemVariantField,
  encodeItemVariant,
} from "shared/items";
import { addChildrenToSPS } from "lib/SolidParticleSystemUtils";
import { ChunkID } from "noa-engine/lib/ChunkID";

const USE_SPS = false;
const screensList = new Array(2);

const symbolizeKeys = ([key, value]) => [Symbol.for(key), value];
class FoilageNode extends TransformNode {
  _totalVertices = 0;
  hasCounted = false;
  getTotalVertices() {
    if (!this.hasCounted) {
      let sum = 0;

      for (let child of this.getChildMeshes(false)) {
        sum += child.getTotalVertices();
      }
      this.hasCounted = true;
      this._totalVertices = sum;
    }

    return this._totalVertices;
  }
}

const placeholderChunk = ndarray(new Uint16Array(0), [
  CHUNK_SIZE,
  CHUNK_SIZE,
  CHUNK_SIZE,
]);

const instanceId = (
  blockType: BlockID,
  variant: ItemVariant,
  x: number,
  y: number,
  z: number
) => {
  const key = `${blockType}/${encodeItemVariant(variant)}/${Math.round(
    x
  )},${Math.round(y)},${Math.round(z)}`;

  return Symbol.for(key);
};

function findScreenMesh(mesh: Mesh) {
  return mesh.name === "screen";
}

const debug = Debug("WorldChunkLoader");
debug.enabled = true;

export class WorldChunkLoader {
  noa: NoaEngine;
  worldChunks = new Map<symbol, WorldChunk>();
  pendingChunks = new Map<symbol, [ndarray, number, number, number]>();
  minimap: MinimapRender;

  start = (noa) => {
    this.foilageLoader = new FoilageLoader(noa.rendering.getScene());
    this.noa = noa;
    this.noa.world.on(
      "worldDataNeeded",
      (id, data, x: number, y: number, z: number) => {
        const opts = {
          id,
          x,
          y,
          z,
          shapeX: placeholderChunk.shape[0],
          shapeY: placeholderChunk.shape[1],
          shapeZ: placeholderChunk.shape[2],
          strideX: placeholderChunk.stride[0],
          strideY: placeholderChunk.stride[1],
          strideZ: placeholderChunk.stride[2],
          worldId: this.noa.worldName,
        };

        requestMapChunk(opts);
        this.pendingChunks.set(Symbol.for(id), [data, x, y, z]);
      }
    );

    this.noa.world.on("chunkAdded", this.onLoadChunk);

    if (typeof window.requestIdleCallback === "function") {
      this.noa.world.on("chunkBeingRemoved", (id) =>
        window.requestIdleCallback(() => this.onUnloadChunk(id))
      );
    } else {
      this.noa.world.on("chunkBeingRemoved", this.onUnloadChunk);
    }
  };

  getFoilageType = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return 0;
    }

    return chunk.getFoilageType(x, y, z);
  };

  getSurfaceType = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return 0;
    }

    return chunk.getSurfaceType(x, y, z);
  };

  getFoilageVariant = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return 0;
    }

    return chunk.getFoilageVariant(x, y, z);
  };

  getSurfaceVariant = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return 0;
    }

    return chunk.getSurfaceVariant(x, y, z);
  };

  getBlock = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return 0;
    }

    return (
      chunk.getFoilageType(x, y, z) ||
      chunk.getSurfaceType(x, y, z) ||
      this.noa.getBlock(x, y, z)
    );
  };

  deleteBlock = (x: number, y: number, z: number) => {
    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      this.noa.setBlock(0, x, y, z);
      debug("DELETE BLOCK", x, y, z);
      return;
    }

    const foilageType = chunk.getFoilageType(x, y, z);
    const surfaceType = chunk.getSurfaceType(x, y, z);

    if (foilageType) {
      const variant = getItemVariant(chunk.getFoilageVariant(x, y, z));
      this.deleteInstanceById(
        instanceId(foilageType, variant, x, y, z),
        this.worldChunkByCoords(x, y, z)
      );
      chunk.setFoilageType(0, 0, x, y, z);
      debug("DELETE FOILAGE", x, y, z);
    }

    if (surfaceType) {
      const variant = getItemVariant(chunk.getSurfaceVariant(x, y, z));
      this.deleteInstanceById(
        instanceId(surfaceType, variant, x, y, z),
        this.worldChunkByCoords(x, y, z)
      );
      chunk.setSurfaceType(0, 0, x, y, z);
      debug("DELETE SURFACE", x, y, z);
    }

    if (!surfaceType && !foilageType) {
      debug("DELETE BLOCK", x, y, z);
      this.noa.setBlock(0, x, y, z);
    }
  };

  instanceById = (
    blockType: BlockID,
    variant: ItemVariant,
    x: number,
    y: number,
    z: number
  ) => {
    const chunk = this.worldChunkByCoords(x, y, z);

    if (!chunk) {
      return null;
    }

    return chunk.meshes.get(instanceId(blockType, variant, x, y, z));
  };

  deleteInstanceById = (instanceId: symbol, worldChunk: WorldChunk) => {
    if (worldChunk) {
      if (worldChunk.meshes.has(instanceId)) {
        const mesh = worldChunk.meshes.get(instanceId);

        if (mesh) {
          if (mesh.metadata?.entityId) {
            this.noa.ents.deleteEntity(mesh.metadata.entityId);
          }

          if (mesh.parent) {
            mesh.parent = null;
          }

          const children = mesh.getChildMeshes(false);
          for (let i = 0; i < children.length; i++) {
            this.noa.rendering.removeMeshFromScene(children[i]);
            mesh.dispose();
          }

          mesh.dispose();

          worldChunk.meshes.delete(instanceId);
          return true;
        }
      }
    }

    return false;
  };

  setBlock = async (
    blockType: number,
    variant: number,
    x: number,
    y: number,
    z: number,
    url: string = null
  ) => {
    if (blockType === BlockID.air) {
      return this.deleteBlock(x, y, z);
    }

    debug("SET BLOCK", blockType, variant, x, y, z, url);

    if (isBlock(blockType)) {
      this.noa.setBlock(blockType, x, y, z);
      return;
    }

    const chunk = this.worldChunkByCoords(x, y, z);
    if (!chunk) {
      return;
    }

    let oldMesh;

    let _blockType, _variant;

    let parent: AbstractMesh | null;

    let surfaceType, surfaceVariant;

    _blockType = chunk.getSurfaceType(x, y, z);
    _variant = chunk.getSurfaceVariant(x, y, z);
    surfaceType = _blockType;
    surfaceVariant = _variant;

    if (isSurface(blockType)) {
      chunk.setSurfaceType(blockType, variant, x, y, z);
    }

    if (isFoilage(blockType)) {
      _blockType = chunk.getFoilageType(x, y, z);
      _variant = chunk.getFoilageVariant(x, y, z);

      chunk.setFoilageType(blockType, variant, x, y, z);

      oldMesh = this.instanceById(
        _blockType,
        getItemVariant(_variant),
        x,
        y,
        z
      );

      parent = this.instanceById(
        surfaceType,
        getItemVariant(surfaceVariant),
        x,
        y,
        z
      );
    }

    const item = getItemVariant(variant);

    const _itemVariant = getItemVariant(_variant);

    const id = itemId(blockType, _itemVariant.variant);
    let needsLoad = false;

    const scene: Scene = this.noa.rendering.getScene();

    await this.foilageLoader.loadRequiredMeshes([Symbol.keyFor(id)]);

    const _instanceId = instanceId(_blockType, _itemVariant, x, y, z);

    let needsANewMesh = _blockType !== blockType || _variant !== variant;

    if (!needsANewMesh && blockActions.has(blockType)) {
      if (blockActions.get(blockType).includes(EntityAction.changeURL)) {
        const mesh: Mesh = chunk.meshes.get(_instanceId);

        if (blockType === BlockID.banner) {
          const banner = mesh.getChildMeshes(false, findBannerScreenMesh)[0];

          if (!banner) {
            needsANewMesh = true;
          } else {
            needsANewMesh =
              url !==
              ((banner?.material as StandardMaterial).diffuseTexture as Texture)
                ?.url;
          }
        } else {
          // needsANewMesh = true;
          let plane = mesh.getChildMeshes(false, findScreenMesh)[0];

          if (plane && plane.material) {
            needsANewMesh =
              url !==
              ((plane?.material as StandardMaterial).diffuseTexture as Texture)
                ?.url;
          } else {
            needsANewMesh = true;
          }
        }
      }
    }

    if (!needsANewMesh) {
      return;
    }

    let didDelete = false;

    scene.blockfreeActiveMeshesAndRenderingGroups = true;
    didDelete = this.deleteInstanceById(_instanceId, chunk);

    const mesh = this.insertFoilage(
      blockType,
      item,
      x,
      y,
      z,
      url || null,
      parent,
      chunk,
      this.noa.world._chunkStorage[
        ChunkID.encode(chunk.chunkX, chunk.chunkY, chunk.chunkZ)
      ]
    );
    chunk?.meshes.set(mesh.id, mesh);

    scene.blockfreeActiveMeshesAndRenderingGroups = false;
  };

  worldChunkByCoords = (x: number, y: number, z: number) => {
    const id = `${this.noa.world._worldCoordToChunkCoord(
      x
    )}|${this.noa.world._worldCoordToChunkCoord(
      y
    )}|${this.noa.world._worldCoordToChunkCoord(z)}|${this.noa.worldName}`;
    return this.worldChunks.get(Symbol.for(id));
  };

  handleWorldData = (mapChunk) => {
    const id = Symbol.for(mapChunk.id);
    const _worldData = this.pendingChunks.get(id);
    if (!_worldData) {
      debug("[Missing chunk]", mapChunk.id);
      return false;
    }
    const [worldData, x, y, z] = _worldData;

    const worldChunk = new WorldChunk();
    worldChunk.id = id;
    worldChunk.origin = [x, y, z];
    worldChunk.shape = placeholderChunk.shape;
    worldChunk.stride = placeholderChunk.stride;
    worldChunk.noa = this.noa;
    const shape = placeholderChunk.shape;
    const stride = placeholderChunk.stride;

    this.worldChunks.set(worldChunk.id, worldChunk);

    worldChunk.foilageData = new LazyNDArray(
      mapChunk.foilageData,
      shape,
      stride
    );

    worldChunk.surfaceVariants = new LazyNDArray(
      mapChunk.surfaceVariants,
      shape,
      stride
    );

    worldChunk.foilageVariants = new LazyNDArray(
      mapChunk.foilageVariants,
      shape,
      stride
    );

    worldChunk.surfaceData = new LazyNDArray(
      mapChunk.surfaceData,
      shape,
      stride
    );
    worldChunk.foilageStates = mapChunk.foilageStates;
    worldChunk.surfaceStates = mapChunk.surfaceStates;
    worldChunk.states = mapChunk.states;
    if (mapChunk.requiredFoilageMeshes) {
      worldChunk.foilagePositions = new Map(
        [...mapChunk.requiredFoilageMeshes.entries()].map(symbolizeKeys)
      );
    }
    if (mapChunk.requiredSurfaceMeshes) {
      worldChunk.surfacePositions = new Map(
        [...mapChunk.requiredSurfaceMeshes.entries()].map(symbolizeKeys)
      );
    }

    if (!mapChunk.chunk || mapChunk.chunk.isEmpty || !mapChunk.voxels?.length) {
      if (worldData) {
        worldData.data = airChunk;
      }
      this.noa.world.setMeshedChunk(mapChunk.id, null);

      this.pendingChunks.delete(mapChunk.id);
      return;
    }

    // const log = `[Receive chunk] ${data.id}`;
    // console.time(log);

    this.noa.world.setMeshedChunk(
      mapChunk.id,
      ndarray(
        mapChunk.voxels,
        mapChunk.chunk.voxels.shape,
        mapChunk.chunk.voxels.stride,
        mapChunk.chunk.voxels.offset
      )
    );

    this.pendingChunks.delete(worldChunk.id);
    return true;
  };
  foilageLoader: FoilageLoader;

  getLoadedFoilage = (
    blockType: BlockID,
    item: ItemVariant,
    x: number,
    y: number,
    z: number,
    parent: AbstractMesh | null,
    canInstance: boolean = true,
    getOriginal: boolean = false
  ) => {
    const mesh = this.foilageLoader.getMesh(blockType, item);
    if (getOriginal) {
      return mesh;
    }

    if (!mesh) {
      return null;
    } else {
      // debug("Inserted", blockType, "at", x, y, z);
    }

    const id = instanceId(blockType, item, x, y, z);
    let instance: Mesh | InstancedMesh = mesh.instantiateHierarchy(parent, {
      doNotInstantiate: !canInstance,
    });

    instance.name = id;
    instance.id = id;

    if (!instance.metadata) {
      instance.metadata = { ...parent.metadata };
    }

    instance.position = Vector3.Zero();
    instance.rotation = Vector3.Zero();
    instance.scaling = Vector3.One();

    instance.metadata.blockID = blockType;
    instance.metadata.variant = item.variant;

    return instance;
  };

  foilageTest = (x: number, y: number, z: number) => {};
  parentBoundsPicker = (mesh: AbstractMesh) => {
    return isSurface(mesh?.metadata?.blockID);
  };

  disposeInstance = (instance: FoilageMesh) => {
    const { blockID, variant } = instance.metadata;

    instance.dispose();
  };
  worldMap: WorldMapStore;

  setupPlane = (
    instance: InstancedMesh,
    url: string,
    item: ItemVariant,
    blockType: BlockID,
    containingChunk: Chunk
  ) => {
    const isPlaceholder = !url || url.length === 0 || url.includes(".mp4");
    const mesh: InstancedMesh = instance;
    const plane = createScreenPlane(mesh, blockType, item);

    plane.setParent(mesh);

    plane.position = Vector3.Zero();

    setURLForPlane(
      plane,
      isPlaceholder ? "/icons/UploadPending.png" : url,
      instance.getScene()
    );

    plane.actionManager = instance.actionManager;
    plane.isPickable = true;

    plane.metadata = instance.metadata;
    plane._enablePointerMoveEvents = true;

    return plane;
  };

  insertFoilage = (
    blockType: BlockID,
    item: ItemVariant,
    x: number,
    y: number,
    z: number,
    url: string = null,
    parent: InstancedMesh | null = null,
    chunk?: WorldChunk = null,
    containingChunk: Chunk,
    sps: SolidParticleSystem = null
  ) => {
    let _parent = parent;
    let container = new FoilageNode(null, this.noa.rendering.getScene());

    // instance.setParent(container);
    let instance = this.getLoadedFoilage(
      blockType,
      item,
      x,
      y,
      z,
      container,
      blockType !== BlockID.banner,
      !!sps
    );
    if (!instance) {
      debug("Failed to load mesh", blockType);
      return null;
    } else {
      // debug("Inserted", blockType, "at", x, y, z);
    }

    const supportedActions = blockActions.get(blockType);

    const scene: Scene = this.noa.rendering.getScene();
    let id = instance.id;

    // const scale = FOILAGE_SCALE[item.scale];
    // const rotation = getRotation(item);
    // container.scaling = new Vector3(scale, scale, scale);
    // container.rotation = new Vector3(0, rotation, 0);
    container.id = id;
    container.name = id;
    const {
      // minimumWorld: min,
      min: min,
      // maximumWorld: max,
      max: max,
    } = instance.getHierarchyBoundingVectors(true);

    let width = Math.abs(max.x - min.x);
    let height = Math.abs(max.y - min.y);
    let depth = Math.abs(max.z - min.z);

    let instanceOffset = getOffset(blockType, item);
    const alignment = getAlignment(item);

    if (parent) {
      const bounds = parent.getHierarchyBoundingVectors(
        true,
        this.parentBoundsPicker
      );
      id = this.noa.ents.createEntity();
      this.noa.ents.addComponent(id, "parentMesh", {
        mesh: container,
        y: bounds.max.y - bounds.min.y - min.y,
        parent,
      });
      container.isNotMeasurable = true;
      instance.isNotMeasurable = true;
    } else {
      instance.locallyTranslate(Vector3.FromArray(instanceOffset));
    }

    if (blockType === BlockID.banner) {
      const screen = instance.getChildMeshes(false, findBannerScreenMesh)[0];
      let wasFrozen = screen.material.isFrozen;

      if (wasFrozen) {
      }

      // if (wasFrozen) {
      //   screen.material.freeze();
      // }

      window._screen = screen;
    }

    if (chunk) {
      instance.onDisposeObservable.add(chunk.onInstanceDispose);
    }

    let plane: Mesh | null = null;

    let includeActionManager = supportedActions && supportedActions.length > 0;
    // let needsPlane = blockType === BlockID.banner;
    let needsPlane = false;
    if (includeActionManager) {
      instance.actionManager = this.actionManager.actionManager;

      needsPlane = supportedActions.includes(EntityAction.changeURL);
    }

    const trildren: Array<Mesh> = instance.getChildren(undefined, false);

    if (needsPlane && blockType !== BlockID.banner) {
      plane = this.setupPlane(instance, url, item, blockType, containingChunk);
    }

    if (blockType === BlockID.banner) {
      const screens: Array<Mesh> = instance.getChildMeshes(false, (mesh) =>
        mesh.name.includes("screen")
      );
      screens[0].material = screens[0].material.clone("screen");
      screens[1].material = screens[0].material;

      const isPlaceholder = !url || url.length === 0 || url.includes(".mp4");
      const _url = isPlaceholder ? "/icons/UploadPending.png" : url;
      setURLTexture(screens[0].material, _url, scene);
    }

    const worldOriginOffset = this.noa.worldOriginOffset;

    // this.noa.ents.addComponent(id, "foilage");

    // if (isSurface(blockType)) {
    //   this.noa.ents.addComponent(id, "parentable");
    // }

    // instance.metadata.entityId = id;
    instance.isPickable = true;
    instance._enablePointerMoveEvents = true;
    // instance.freezeWorldMatrix();
    // container.metadata = instance.metadata;

    instance.setEnabled(true);

    if (sps) {
      let idMap = new Map();
      let parentId = sps.vars.particleParents?.length ?? 0;
      let useInstance =
        instance.getTotalVertices && instance?.getTotalVertices();
      let count = trildren.length + (useInstance ? 1 : 0);
      let current = 0;
      let start = parentId;

      if (!sps.vars.particleParents) {
        sps.vars.positions = new Array(1);
        sps.vars.particleParents = new Array(1);
      }

      sps.vars.positions[parentId] = new Vector3(
        x - worldOriginOffset[0],
        y - worldOriginOffset[1],
        z - worldOriginOffset[2]
      );

      let parentNode = instance;
      instance.position = sps.vars.positions[parentId];

      sps.vars.particleParents[parentId] = 0;

      // if (useInstance) {
      //   let parent = sps.addShape(instance, 1, sps.vars.opts);
      //   current++;
      //   sps.vars.particleParents[current] = parent;
      // }

      idMap.clear();
    } else if (containingChunk) {
      // for (let mesh of ) {
      // this.noa.rendering._octree.addMesh(mesh);
      container.position.copyFromFloats(
        x + worldOriginOffset[0],
        y + worldOriginOffset[1],
        z + worldOriginOffset[2]
      );

      const children = container.getChildMeshes(false);

      this.noa.rendering._octree.addMeshes(children);
      // for (let child of children) {
      //   this.noa.rendering.addMeshToScene(
      //     child,
      //     true,
      //     undefined,
      //     containingChunk
      //   );
      // }

      container.freezeWorldMatrix();
      // instance.doNotSyncBoundingInfo = true;
      // }
      // ...instance.getChildMeshes()
      // );
    } else {
      const children = container.getChildMeshes(false);
      children.forEach(this.noa.rendering._octree.addDynamicContent);
    }

    return container;
  };

  loadedChunks = new WeakMap<WorldChunk, true>();

  onLoadChunk = async (chunk) => {
    let chunkID = Symbol.for(chunk.requestID);
    const _chunkID = chunk.id;

    let worldChunk = this.worldChunks.get(chunkID);

    if (!worldChunk) {
      return;
    }

    if (this.loadedChunks.has(worldChunk)) {
      return true;
    }

    worldChunk.x = chunk.x;
    worldChunk.y = chunk.y;
    worldChunk.z = chunk.z;

    if (
      (!worldChunk.foilagePositions || !worldChunk.foilagePositions.size) &&
      (!worldChunk.surfacePositions || !worldChunk.surfacePositions.size)
    ) {
      return;
    }

    let requiredMeshes = [
      ...(worldChunk.foilagePositions?.keys() ?? []),
      ...(worldChunk.surfacePositions?.keys() ?? []),
    ].map(Symbol.keyFor);

    worldChunk = null;
    await this.foilageLoader.loadRequiredMeshes(requiredMeshes);
    requiredMeshes = null;

    worldChunk = this.worldChunks.get(chunkID);
    if (!worldChunk) {
      return;
    }

    let x, y, z;

    let containingChunk = this.noa.world._chunkStorage[_chunkID];
    let sps: SolidParticleSystem;

    const tempPosition = new Int32Array(3);
    if (worldChunk.surfacePositions) {
      for (let [itemId, positions] of worldChunk.surfacePositions.entries()) {
        const blockID = worldChunk.surfaceData.data[positions[0]];
        const variant = worldChunk.surfaceVariants.data[positions[0]];
        const item = getItemVariant(variant);

        let url: string;
        for (let position of positions) {
          worldChunk.surfaceData.unindex(position, tempPosition);
          x = tempPosition[0] + chunk.x;
          y = tempPosition[1] + chunk.y;
          z = tempPosition[2] + chunk.z;

          if (worldChunk.surfaceStates && blockActions.has(blockID)) {
            let state = worldChunk.surfaceStates.get(stateKey(x, y, z));

            if (state && state.url) {
              url = state.url;
            }
          } else {
            url = null;
          }

          try {
            const mesh = this.insertFoilage(
              blockID,
              item,
              x,
              y,
              z,
              url,
              null,
              worldChunk,
              chunk
            );

            if (mesh) {
              worldChunk?.meshes.set(mesh.id, mesh);
            }
          } catch (exception) {
            console.error(exception);
            Sentry.captureException(exception);
          }
        }
      }
    }

    if (worldChunk.foilagePositions) {
      for (let [itemId, positions] of worldChunk.foilagePositions.entries()) {
        const blockID = worldChunk.foilageData.data[positions[0]];
        const variant = worldChunk.foilageVariants.data[positions[0]];
        const item = getItemVariant(variant);

        let url: string;
        let parent;
        for (let position of positions) {
          worldChunk.foilageData.unindex(position, tempPosition);
          x = tempPosition[0] + chunk.x;
          y = tempPosition[1] + chunk.y;
          z = tempPosition[2] + chunk.z;

          if (worldChunk.foilageStates && blockActions.has(blockID)) {
            let state = worldChunk.foilageStates.get(stateKey(x, y, z));

            if (state && state.url) {
              url = state.url;
            }
          } else {
            url = null;
          }

          if (!worldChunk.surfaceData.isEmpty) {
            const surfaceType = worldChunk.surfaceData.data[position];
            const surfaceItem = getItemVariant(
              worldChunk.surfaceVariants.data[position]
            );
            const maybeHasParent = surfaceType > 0;
            try {
              const mesh = this.insertFoilage(
                blockID,
                item,
                x,
                y,
                z,
                url,
                maybeHasParent
                  ? worldChunk.meshes.get(
                      instanceId(surfaceType, surfaceItem, x, y, z)
                    )
                  : null,
                worldChunk,
                chunk
              );

              if (mesh) {
                worldChunk?.meshes.set(mesh.id, mesh);
              }
            } catch (exception) {
              console.error(exception);
              Sentry.captureException(exception);
            }
          } else {
            try {
              const mesh = this.insertFoilage(
                blockID,
                item,
                x,
                y,
                z,
                url,
                null,
                worldChunk,
                chunk
              );

              if (mesh) {
                worldChunk?.meshes.set(mesh.id, mesh);
              }
            } catch (exception) {
              console.error(exception);
              Sentry.captureException(exception);
            }
          }
        }
      }
    }

    if (worldChunk.foilagePositions || worldChunk.surfacePositions) {
      console.log(
        "Inserted",
        (worldChunk.foilagePositions?.size ?? 0) +
          (worldChunk.surfacePositions?.size ?? 0),
        "at",
        containingChunk.pos
      );
    }

    containingChunk = null;
    this.loadedChunks.set(worldChunk, true);

    this.minimap.needsRender = true;
  };
  onUnloadChunk = (_id) => {
    const id = Symbol.for(_id);
    let worldChunk = this.worldChunks.get(id);
    if (!worldChunk) {
      return;
    }

    const scene: Scene = this.noa.rendering.getScene();
    scene.blockfreeActiveMeshesAndRenderingGroups = true;
    this.worldChunks.delete(id);
    const count = worldChunk.meshes.size;

    for (let mesh of worldChunk.meshes.values()) {
      if (mesh.metadata?.entityId) {
        this.noa.ents.deleteEntity(mesh.metadata.entityId);
      }

      mesh.dispose();
      this.noa.rendering.removeMeshFromScene(mesh);
      for (let submesh of mesh.getChildMeshes(false)) {
        this.noa.rendering.removeMeshFromScene(submesh);
      }
    }
    worldChunk.dispose();
    scene.blockfreeActiveMeshesAndRenderingGroups = false;
    if (count > 0) {
      debug("Unload Chunk", id, `(${count}) meshes`);
    } else {
      // debug("Unload Chunk", id, `(${count}) meshes`);
    }

    this.loadedChunks.delete(worldChunk);
  };

  actionManager: EntityActionManager;

  itemVariantForMesh = (mesh: FoilageMesh, x: number, y: number, z: number) => {
    const blockID = mesh.metadata.blockID;
    let variant = 0;

    if (isSurface(blockID)) {
      variant = this.getSurfaceVariant(x, y, z);
    } else if (isFoilage(blockID)) {
      variant = this.getFoilageVariant(x, y, z);
    }

    return getItemVariant(variant);
  };

  itemForMesh = (mesh: FoilageMesh, x: number, y: number, z: number) => {
    const blockID = mesh.metadata.blockID;
    let variant = 0;

    if (isSurface(blockID)) {
      variant = this.getSurfaceVariant(x, y, z);
    } else if (isFoilage(blockID)) {
      variant = this.getFoilageVariant(x, y, z);
    }

    return items.get(itemId(blockID, variant));
  };
}
