import { InventorySlots } from "lib/Data/InventorySlots";
import { BlockID } from "shared/BlockID";

const normalizeId = (item: symbol | string) =>
  typeof item === "symbol" ? Symbol.keyFor(item) : item;

export class PlayerInventory {
  get secondSlot() {
    return Symbol.for(this.inventorySlots.secondSlot);
  }
  get thirdSlot() {
    return Symbol.for(this.inventorySlots.thirdSlot);
  }
  get fourthSlot() {
    return Symbol.for(this.inventorySlots.fourthSlot);
  }
  get fifthSlot() {
    return Symbol.for(this.inventorySlots.fifthSlot);
  }
  get sixthSlot() {
    return Symbol.for(this.inventorySlots.sixthSlot);
  }
  get seventhSlot() {
    return Symbol.for(this.inventorySlots.seventhSlot);
  }

  set secondSlot(item) {
    this.inventorySlots.secondSlot = normalizeId(item);
  }
  set thirdSlot(item) {
    this.inventorySlots.thirdSlot = normalizeId(item);
  }
  set fourthSlot(item) {
    this.inventorySlots.fourthSlot = normalizeId(item);
  }
  set fifthSlot(item) {
    this.inventorySlots.fifthSlot = normalizeId(item);
  }
  set sixthSlot(item) {
    this.inventorySlots.sixthSlot = normalizeId(item);
  }
  set seventhSlot(item) {
    this.inventorySlots.seventhSlot = normalizeId(item);
  }

  id: number;
  inventorySlots: InventorySlots;

  constructor(...args) {}

  isBlockItem(id: number) {
    return id > 0 && id < BlockID.pickaxe;
  }

  updateSlotLists = () => {
    this.inventorySlots.update();
  };
}
