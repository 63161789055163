import { AbstractMesh, InstancedMesh, Mesh } from "@babylonjs/core";

export const getMesh = (noa, eid): Mesh | InstancedMesh => {
  if (noa.ents.hasComponent(eid, "mesh")) {
    let mesh = noa.ents.getMeshData(eid).mesh;

    if (mesh instanceof AbstractMesh) {
      return mesh;
    } else {
      return mesh;
    }
  } else if (noa.ents.hasComponent(eid, "playerMesh")) {
    return noa.ents.getState(eid, "playerMesh").meshes[0];
  } else if (noa.ents.hasComponent(eid, "parentMesh")) {
    const mesh = noa.ents.getState(eid, "parentMesh").mesh;
    if (mesh instanceof AbstractMesh) {
      return mesh;
    } else {
      return mesh;
    }
  } else {
    return null;
  }
};
