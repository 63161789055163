import { NengiIDType } from "../nengiTypes";
import { BaseEntity } from "./BaseEntity";

export class Inventory extends BaseEntity {
  firstSlot: number = 65535;
  secondSlot: number = 65535;
  thirdSlot: number = 65535;
  fourthSlot: number = 65535;
  fifthSlot: number = 65535;
  sixthSlot: number = 65535;

  static protocol = {
    firstSlot: { type: NengiIDType, interp: false },
    secondSlot: { type: NengiIDType, interp: false },
    thirdSlot: { type: NengiIDType, interp: false },
    fourthSlot: { type: NengiIDType, interp: false },
    fifthSlot: { type: NengiIDType, interp: false },
    sixthSlot: { type: NengiIDType, interp: false },
  };
}

export default Inventory;
