export default function (noa) {
  function setMovementState(state, inputs, camHeading) {
    state.jumping = !!inputs.jump;

    var fb = inputs.forward
      ? inputs.backward
        ? 0
        : 1
      : inputs.backward
      ? -1
      : 0;
    var rl = inputs.right ? (inputs.left ? 0 : 1) : inputs.left ? -1 : 0;

    if (fb === 0 && rl === 0) {
      state.running = false;
    }

    if (noa.camera.panWithMovement && !inputs.panCamera) {
      if (fb === 0) {
        state.running = false;
        state.locked = inputs.panCamera;
      } else {
        if (fb === -1) camHeading += Math.PI;
        state.running = true;
        state.heading = camHeading;
        state.locked = false;
      }
    } else {
      if ((fb | rl) === 0) {
        state.running = false;

        state.locked = inputs.panCamera;
      } else {
        state.running = true;
        if (fb) {
          if (fb === -1) camHeading += Math.PI;
          if (rl) {
            camHeading += (Math.PI / 4) * fb * rl; // didn't plan this but it works!
          }
        } else {
          camHeading += (rl * Math.PI) / 2;
        }
        state.heading = camHeading;
        state.locked = false;
      }
    }

    return state.heading;
  }

  return {
    name: "movementInputs",

    order: 20,

    state: {
      rotate: 0,
      locked: false,
      heading: 0,
      running: false,
      jumping: false,
    },

    onAdd: null,

    onRemove: null,

    system: function inputProcessor(dt, states) {
      var ents = noa.entities;
      var inputState = noa.inputs.state;
      const heading = noa.camera.heading;

      for (let i = 0; i < states.length; i++) {
        const state = states[i];
        var moveState = ents.getMovement(state.__id);
        setMovementState(moveState, inputState, heading);
      }
    },
  };
}
