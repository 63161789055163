import type { VertexData } from "@babylonjs/core";
import { USE_SHARED_ARRAY_BUFFER } from "lib/Data/USE_SHARED_ARRAY_BUFFER";

export class Submesh {
  constructor(id: number = 0) {
    this.id = id;
  }

  static withContent(id, colors, indices, normals, uvs, positions) {
    const submesh = new Submesh(id);
    submesh.colors = new Float32Array(colors);
    submesh.indices = new Uint16Array(indices);
    submesh.normals = new Int8Array(normals);
    submesh.uvs = new Float32Array(uvs);
    submesh.positions = new Float32Array(positions);
    submesh.isTypedArrays = true;
    return submesh;
  }

  convertToTypedArray() {
    if (this.isTypedArrays) {
      return;
    }

    const origPositions = this.positions;
    // const origIndices = this.indices;
    const origNormals = this.normals;
    const origColors = this.colors;
    const origUvs = this.uvs;
    const origTiles = this.tiles;

    if (USE_SHARED_ARRAY_BUFFER) {
      this.positions = new Float32Array(
        new SharedArrayBuffer(
          origPositions.length * Float32Array.BYTES_PER_ELEMENT
        )
      );
      this.positions.set(origPositions);
      // this.indices = new Uint16Array(origIndices);
      this.normals = new Float32Array(
        new SharedArrayBuffer(
          origNormals.length * Float32Array.BYTES_PER_ELEMENT
        )
      );
      this.normals.set(origNormals);
      this.colors = new Float32Array(
        new SharedArrayBuffer(
          origColors.length * Float32Array.BYTES_PER_ELEMENT
        )
      );
      this.colors.set(origColors);

      this.uvs = new Float32Array(
        new SharedArrayBuffer(origUvs.length * Float32Array.BYTES_PER_ELEMENT)
      );
      this.uvs.set(origUvs);
      this.tiles = new Float32Array(
        new SharedArrayBuffer(origTiles.length * Float32Array.BYTES_PER_ELEMENT)
      );
      this.tiles.set(origTiles);
    } else {
      this.positions = new Float32Array(origPositions);
      // this.indices = new Uint16Array(origIndices);
      this.normals = new Float32Array(origNormals);
      this.colors = new Float32Array(origColors);
      this.uvs = new Float32Array(origUvs);
      this.tiles = new Float32Array(origTiles);
    }

    origPositions.length = 0;
    // origIndices.length = 0;
    origNormals.length = 0;
    origColors.length = 0;
    origUvs.length = 0;
    origTiles.length = 0;
    this.isTypedArrays = true;
  }

  isTypedArrays = false;
  positions: Float32Array = [];
  indices: Float32Array = [];
  normals: Float32Array = [];
  colors: Float32Array = [];
  tiles: Float32Array = [];
  uvs: Float32Array = [];
  mergeable = false; // flag used during terrain meshing

  dispose() {
    if (!(this.positions instanceof Float32Array)) {
      this.positions.length = 0;
      this.indices.length = 0;
      this.normals.length = 0;
      this.colors.length = 0;
      this.uvs.length = 0;
      this.tiles.length = 0;
    }

    this.positions = null;
    this.indices = null;
    this.normals = null;
    this.colors = null;
    this.uvs = null;
    this.tiles = null;
  }
}
