import type {
  IAgoraRTC,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
  UID,
} from "agora-rtc-sdk-ng";
import EventEmitter from "eventemitter3";
import debug from "lib/log";
import { RNNoiseNode } from "lib/rnnoise/rnnoise-runtime";
import { VoiceEvent } from "shared/events";
import { getMicrophone } from "lib/GetUserMedia";
import * as Sentry from "@sentry/node";

export const userFromAgora = (id: string | number) => {
  return Number(id.toString().split("@")[0]);
};

let AgoraRTC: IAgoraRTC;

export const loadAgora = () => {
  if (!AgoraRTC && typeof window !== "undefined") {
    AgoraRTC = require("lib/vendor/AgoraRTCSDK");
    window.AgoraRTC = AgoraRTC;
  }

  return AgoraRTC;
};

const log = debug("[VoiceChat]");
log.enabled = true;

const DISABLE_IN_DEVELOPMENT = false;
// const DISABLE_IN_DEVELOPMENT = process.env.NODE_ENV === "development";
export enum VoiceChatStatus {
  pending = "pending",
  pendingAudioUnlock = "pendingAudioUnlock",
  noAudioTrack = "noAudioTrack",
  unsupported = "unsupported",
  connected = "CONNECTED",
  connecting = "CONNECTING",
  disconnected = "DISCONNECTED",
  disconnecting = "DISCONNECTING",
  failed = "failed",
}

const AGORA_STATUSES = {
  CONNECTED: VoiceChatStatus.connected,
  CONNECTING: VoiceChatStatus.connecting,
  DISCONNECTED: VoiceChatStatus.disconnected,
  DISCONNECTING: VoiceChatStatus.disconnecting,
};

export class VoiceChat {
  static client: VoiceChat;
  agora: IAgoraRTCClient;
  agoraUser: IAgoraRTCRemoteUser;

  userId: string;
  broadcastId: string;
  channelName: string;
  emitter: EventEmitter;

  static token: string;
  streamsByUserId = new Map<string, MediaStream>();

  constructor(userId: string, channelName: string, emitter: EventEmitter) {
    this.userId = userId;
    this.channelName = channelName;
    this.emitter = emitter;
  }

  mediaStream: MediaStream;
  audioTrack: MediaStreamTrack;
  _status: VoiceChatStatus = VoiceChatStatus.pending;

  get status() {
    return this._status;
  }

  get hasVoice() {
    return !!this.localMediaStream;
  }

  set status(status: VoiceChatStatus) {
    this._status = status;

    if (this.emitter) {
      this.emitter.emit(VoiceEvent.statusChange, status);
    }
  }

  muteSelf = () => {
    if (this.status !== VoiceChatStatus.connected) {
      return;
    }

    this.isMuted = true;
    return this.agoraAudioTrack.setVolume(0);
  };

  unmuteSelf = () => {
    this.isMuted = false;
    return this.agoraAudioTrack.setVolume(100);
  };

  isMuted: boolean = false;
  toggleMute = () => {
    if (this.isMuted) {
      this.unmuteSelf();
    } else {
      this.muteSelf();
    }

    this.emitter.emit(VoiceEvent.muteChange, this.isMuted);

    return this.isMuted;
  };

  error: string;
  startBroadcasting = (context: AudioContext) => {
    return this.addVoice(context);
  };

  fakeAudioTag: HTMLAudioElement;
  localMediaStream: MediaStream;
  localMediaTrack: MediaStreamTrack;
  agoraAudioTrack: ILocalAudioTrack;
  useRNNoise = true;
  hasRegisteredNoise = false;
  noiseNode: AudioWorkletNode;
  noiseNodeSource: MediaStreamAudioSourceNode;
  noiseNodeDestination: MediaStreamAudioDestinationNode;
  noiseNodeStream: MediaStream;
  updateMediaStreams = async (mediaStream: MediaStream) => {
    if (mediaStream) {
      this.localMediaStream = mediaStream;
      this.localMediaTrack = mediaStream.getAudioTracks()[0];

      if (!this.localMediaStream) {
        this.status = VoiceChatStatus.noAudioTrack;
        return Promise.reject();
      }

      if (!this.hasRegisteredNoise && this.useRNNoise) {
        try {
          if (!this.agora && !DISABLE_IN_DEVELOPMENT) {
            await Promise.all([
              this.start(),
              RNNoiseNode.register(this.context),
            ]);
          } else {
            await RNNoiseNode.register(this.context);
          }

          this.hasRegisteredNoise = true;
        } catch (exception) {
          console.error(exception);
          Sentry.captureException(exception);
          this.useRNNoise = false;
        }
      } else if (!this.agora && !DISABLE_IN_DEVELOPMENT) {
        await this.start();
      }

      if (this.useRNNoise) {
        if (this.noiseNodeDestination) {
          this.noiseNodeDestination.disconnect();
        }

        if (this.noiseNodeSource) {
          this.noiseNodeSource.disconnect();
        }

        if (this.noiseNode) {
          this.noiseNode.disconnect();
        } else {
          this.noiseNode = new RNNoiseNode(this.context);
        }

        this.noiseNodeSource = this.context.createMediaStreamSource(
          mediaStream
        );
        this.noiseNodeDestination = this.context.createMediaStreamDestination();

        this.noiseNodeSource.connect(this.noiseNode);
        this.noiseNode.connect(this.noiseNodeDestination);
        this.noiseNodeStream = this.noiseNodeDestination.stream;
      }
    } else {
      return Promise.reject();
    }
  };
  context: AudioContext;

  get voiceChatStream() {
    if (this.useRNNoise && this.noiseNodeStream) {
      return this.noiseNodeStream;
    } else {
      return this.localMediaStream;
    }
  }

  get voiceChatAudioTrack() {
    if (this.useRNNoise && this.noiseNodeStream) {
      return this.noiseNodeStream.getAudioTracks()[0];
    } else {
      return this.localMediaTrack;
    }
  }

  isLiveStreaming = false;
  private _url: string;

  handlePublish = () => {
    this.isStreaming = true;
    this.emitter.emit(VoiceEvent.startedPublishing, this.voiceChatStream);
  };

  findAgoraUserById = (id: UID) => {
    for (let i = 0; i < this.agora.remoteUsers.length; i++) {
      if (this.agora.remoteUsers[i].uid === id) {
        return this.agora.remoteUsers[i];
      }
    }

    return null;
  };
  handleSubscribe = (id: UID) => {
    let user = this.findAgoraUserById(id);
    if (!user) {
      log("User disconnected while trying to subscribe...", user);
      return;
    }

    const audioTrack = user.audioTrack;

    if (!audioTrack) {
      log("Missing audio track on published user...", user);
      return;
    }

    const track = audioTrack.getMediaStreamTrack();

    const mediaStream = new MediaStream();
    mediaStream.addTrack(track);

    this.mediaStreams.set(user, mediaStream);
    this.streamsByUserId.set(userFromAgora(user.uid), mediaStream);
    this.emitter.emit(VoiceEvent.newStream, user);
  };

  publishMediaStream = () => {
    if (this.agoraAudioTrack) {
      this.agoraAudioTrack.setVolume(100);
      return this.agora
        .publish([this.agoraAudioTrack])
        .then(this.handlePublish, console.error);
    } else {
      log("PUBLISH failed");
    }
  };

  setupMicrophone = (context: AudioContext) => {
    if (context) {
      this.context = context;
    }

    return getMicrophone(this.useRNNoise).then(this.updateMediaStreams);
  };

  configureAgoraTrack = () => {
    this.agoraAudioTrack = AgoraRTC.createCustomAudioTrack({
      mediaStreamTrack: this.voiceChatAudioTrack,
      encoderConfig: "speech_standard",
    });
  };

  agoraVideoTrack: ILocalVideoTrack;

  hasAddedVideoTrack = false;
  addVideoTrack = async (track: MediaStreamTrack) => {
    this.hasAddedVideoTrack = true;
  };

  get hasMicrophone() {
    return !!this.voiceChatAudioTrack;
  }

  addVoice = (context: AudioContext) => {
    if (!this.hasMicrophone) {
      return this.setupMicrophone(context)
        .then(this.configureAgoraTrack)
        .then(this.tryToPublishMediaStream);
    } else {
      this.configureAgoraTrack();
      return this.tryToPublishMediaStream();
    }
  };

  tryToPublishMediaStream = () => {
    if (!this.agoraAudioTrack) {
      log("Can't publish MediaStream without audio track.");
      return;
    }

    if (this.status === VoiceChatStatus.connected) {
      return this.publishMediaStream();
    } else {
      log("SKIP publishing before connected to Agora.");
    }
  };
  mediaStreams = new WeakMap<IAgoraRTCRemoteUser, MediaStream>();
  subscribeToUserStream = async (user: IAgoraRTCRemoteUser) => {
    const id = user.uid;
    if (id === this.userId) {
      return;
    }
    await this.agora.subscribe(user, "audio");
    await this.handleSubscribe(id);
  };

  start = async () => {
    if (!this.isSupported) {
      this.status = VoiceChatStatus.unsupported;
      return Promise.resolve(false);
    }

    if (DISABLE_IN_DEVELOPMENT) {
      return Promise.resolve(false);
    }

    if (this.agora) {
      return Promise.resolve(true);
    }

    loadAgora();

    this.agora = AgoraRTC.createClient({
      mode: "rtc",
      role: "host",
      codec: "h264",
    });

    this.agora.on("user-unpublished", (user) => {
      log("user-unpublished", user);

      if (user.uid === this.userId) {
        this.isStreaming = false;
        this.emitter.emit(VoiceEvent.stoppedPublishing);
        this.mediaStreams.delete(user);
        this.streamsByUserId.delete(userFromAgora(user.uid));
      } else {
        this.emitter.emit(VoiceEvent.removedStream, user);
        this.mediaStreams.delete(user);
        this.streamsByUserId.delete(userFromAgora(user.uid));
      }
    });

    this.agora.on("connection-state-change", (curState, prevState) => {
      const old = this.status;
      this.status = AGORA_STATUSES[curState];
      log(curState, this.status);

      if (
        this.status === VoiceChatStatus.connected &&
        old !== this.status &&
        this.agoraAudioTrack
      ) {
        this.publishMediaStream();
      }
    });

    this.agora.on("user-published", (user) => {
      log("user-published", user);
      if (user.uid !== this.userId) {
        this.subscribeToUserStream(user).catch((err) => {
          console.error(err);
          return this.joinWorldChannel().then(() => {
            this.subscribeToUserStream(user);
          });
        });
      }
    });

    this.agora.on("user-joined", (user) => {
      if (user.uid === this.userId) {
        this.agoraUser = user;
      } else {
        log("user-joined", user);
      }
    });

    this.agora.on("user-left", (user) => {
      if (user.uid === this.userId) {
        this.status = VoiceChatStatus.disconnected;
      } else {
        log("user-left", user);
        if (this.mediaStreams.has(user)) {
          this.mediaStreams.delete(user);
        }
      }
    });

    this.agora.on("exception", console.error);
    this.agora.on("error", console.error);

    return this.joinWorldChannel();
  };

  liveStreamUID: number;
  liveStreamChannel: string;

  joinWorldChannel = () => {
    return this.agora.join(
      process.env.AGORA_RTC_CLIENT,
      this.channelName,
      VoiceChat.token,
      this.userId
    );
  };

  isStreaming = false;
  static uid: number;

  get isSupported() {
    // if (typeof this._isSupported === "undefined") {
    //   this._isSupported = AgoraRTC.checkSystemRequirements();
    // }

    return true;
    // return this._isSupported;
  }

  _isSupported: boolean;
}
