import {
  AnimationGroup,
  AnimationPropertiesOverride,
  AssetContainer,
  Color3,
  Engine,
  Mesh,
  Scene,
  SceneLoader,
  Skeleton,
  StandardMaterial,
  Texture,
  Vector3,
} from "@babylonjs/core";
import "@babylonjs/loaders";
import "lib/MESHOPT";
import { AnimationState, getClearColor } from "../Player/PlayerMesh";
import {
  AvatarAnimationMap,
  BaseAvatar,
  normalizeColor,
  normalizeTextureURL,
} from "./BaseAvatar";

const animations: AvatarAnimationMap = {
  _default: { from: 42, to: 187 },
  attack: { from: 10, to: 55 },
  die: { from: 60, to: 82 },
  falling: { from: 127, to: 229 },
  falling_col: { from: 130, to: 150 },
  hurt: { from: 160, to: 180 },
  idle: { from: 0, to: 146 / 60 },
  in_air: { from: 188 / 60, to: 229 / 60 },
  jump: { from: 230 / 60, to: 328 / 60 },
  jump_gun: { from: 310, to: 338 },
  kick: { from: 350, to: 374 },
  rest: { from: 380, to: 381 },
  run: { from: 146 / 60, to: 187 / 60 },
  run_gun: { from: 420, to: 444 },
  walk: { from: 146 / 60, to: 187 / 60 },
  walk_gun: { from: 490, to: 522 },
  walk_push: { from: 530, to: 562 },
};

let _skeleton: Skeleton;
let _mesh: Mesh;
let _meshes: Array<Mesh> = [];
let _animationGroups: Array<AnimationGroup>;
const _loadPlayerMesh = async (
  scene: Scene,
  insertIntoScene: boolean,
  engine?: Engine
) => {
  return await SceneLoader.LoadAssetContainerAsync(
    "/skins/coolglassesgirl/",
    "coolglassesgirl.gltf",
    scene
  );
};

let container: AssetContainer;
const loadPlayerMesh = async (scene, id, insertIntoScene: boolean, engine) => {
  let _scene = scene;

  if (!container) {
    container = await _loadPlayerMesh(scene, insertIntoScene, engine);

    for (let group of container.animationGroups) {
      group.setWeightForAllAnimatables(1);
      for (var index = 0; index < group.targetedAnimations.length; index++) {
        var animation = group.targetedAnimations[index].animation;
        animation.enableBlending = true;
        if (group.name === AnimationState.in_air) {
          animation.blendingSpeed = 0.1;
        } else {
          animation.blendingSpeed = 0.05;
        }
      }
    }
    let root = container.createRootMesh();
    root.setEnabled(false);
  }

  const entries = container.instantiateModelsToScene(null, true);

  let parentMesh = entries.rootNodes[0];

  const mesh = entries.rootNodes[0].getChildMeshes(
    false,
    (mesh) => mesh.skeleton
  )[0];
  const material = entries.rootNodes[0].getChildMeshes(
    false,
    (mesh) => mesh.material
  )[0].material;
  const skeleton = entries.skeletons[0];
  skeleton.overrideMesh = parentMesh;
  var overrides = new AnimationPropertiesOverride();

  overrides.enableBlending = true;
  overrides.blendingSpeed = 0.1;

  skeleton.animationPropertiesOverride = overrides;

  return {
    mesh: mesh,
    parentMesh,
    material,
    meshes: entries.rootNodes,
    skeleton,
    scene: _scene,
    animationGroups: entries.animationGroups,
  };
};

export enum CoolGlassesGirlSkinStyle {
  metallic = "metallic",
  albedo = "albedo",
  rough = "rough",
}

export class CoolGlassesGirl extends BaseAvatar {
  constructor(scene, id) {
    super(scene, id);
  }

  animate(name: AnimationState, loop = true, speed = 1.0) {
    if (!this.skeleton) {
      return;
    }

    const animation =
      this.animations[name] ?? this.animations[AnimationState.idle];
    const current = this.animations[this.lastAnimationState];
    console.log(name, animation.name);
    if (!animation.isStarted) {
      animation.start(loop, speed);
    } else if (animation.isPlaying) {
      animation.restart();
    }

    if (animation) {
      animation.speedRatio = speed;
      animation.play(loop);
    }

    if (current && current.loopAnimation && current !== animation) {
      current.loopAnimation = false;
      current.stop();
    }

    if (!loop) {
      animation.onAnimationGroupEndObservable.addOnce(this.onAnimationEnd);
    }
  }

  onAnimationLoop = (group: AnimationGroup) => {
    if (group.name === this.animationState) {
      group.speedRatio = group.speedRatio * -1;
      group.play(true);
      console.log("LOOP");
    }
  };

  animateOnce(name: AnimationState) {
    if (!this.skeleton) {
      return;
    }

    const animation = this.animations[name];

    if (!animation) {
      return;
    }

    animation.start(false, 1);
    animation.onAnimationGroupEndObservable.addOnce(this.onAnimatedOnce);
  }

  animations: { [key: string]: AnimationGroup };

  static defaultBackgroundColor = new Color3(1, 1, 1);
  material: StandardMaterial;
  texture: Texture;
  static _animations = animations;
  backgroundImage: string = null;
  backgroundColor = new Color3(1, 1, 1);
  skinColor = new Color3(1, 1, 1);

  setBackgroundImage = (url: string) => {
    return;
    this.backgroundImage = normalizeTextureURL(url);

    if (!this.material) {
      return;
    }

    if (!url) {
      this.material.ambientTexture = null;
      return;
    }

    const ambientTexture = new Texture(url, this.scene);
    // const ambientTexture = new Texture(null, scene);

    ambientTexture.hasAlpha = false;

    ambientTexture.uScale = 2;
    ambientTexture.vScale = 2;

    this.material.ambientTexture = ambientTexture;
    this.material.ambientTexture.wrapU = Texture.MIRROR_ADDRESSMODE;
    this.material.ambientTexture.wrapV = Texture.MIRROR_ADDRESSMODE;
  };

  static _skinStyleMapping = {
    [CoolGlassesGirlSkinStyle.metallic]: {
      thumbnail: "/skin-thumbnails/bob_metallic.png",
      url: "/skins/bob_metallic.png",
    },
    [CoolGlassesGirlSkinStyle.rough]: {
      thumbnail: "/skin-thumbnails/bob_roughness.png",
      url: "/skins/bob_roughness.png",
    },
    [CoolGlassesGirlSkinStyle.albedo]: {
      thumbnail: "/skin-thumbnails/albedo.png",
      url: "/skins/bob_albedo.png",
    },
  };

  protected static _skinStyles = CoolGlassesGirlSkinStyle;

  protected static _skinStyleURL(skinStyle: CoolGlassesGirlSkinStyle) {
    return this._skinStyleMapping[skinStyle]?.url;
  }

  _reset = () => {
    if (this.skinStyle) {
      this.setSkinStyle(this.skinStyle);
    }

    if (this.backgroundColor) {
      this.setBackgroundColor(this.backgroundColor);
    }

    if (this.backgroundImage) {
      this.setBackgroundImage(this.backgroundImage);
    }

    if (this.skinColor) {
      this.setSkinColor(this.skinColor);
    }
  };

  setSkinStyle = (skinStyle: string) => {
    return;
    this.skinStyle =
      CoolGlassesGirlSkinStyle[skinStyle || CoolGlassesGirl.defaultSkinStyle] ||
      CoolGlassesGirl.defaultSkinStyle;

    if (!this.material) {
      return;
    }

    const url = this.constructor.skinStyleURL(skinStyle);
    if (url && this.material.diffuseTexture?.url !== url) {
      const texture = new Texture(url, this.scene);
      if (this.material.diffuseTexture) {
        this.material.diffuseTexture.dispose();
      }
      this.material.diffuseTexture = texture;
      this.texture = texture;
    }
  };

  static thumbnailURL = "/skins/coolglassesgirl/thumbnail.png";

  setSkinColor = (_color: Color3) => {
    return;
    const color = normalizeColor(_color);

    this.skinColor = color;
    if (!this.material) {
      return;
    }

    if (color) {
      this.material.ambientColor = color;
    } else {
      this.material.ambientColor = getClearColor(this.scene);
    }
  };

  setBackgroundColor = (_color: Color3) => {
    return;
    const color = normalizeColor(_color);

    this.backgroundColor = color;

    if (!this.material) {
      return;
    }

    if (color) {
      this.material.diffuseColor = color;
    } else {
      this.material.diffuseColor = CoolGlassesGirl.defaultBackgroundColor;
    }
  };

  _rotation = Vector3.Zero();

  static headBoneIndex;

  private static _defaultSkinStyle = CoolGlassesGirlSkinStyle.metallic;
  skinStyle = CoolGlassesGirlSkinStyle.metallic;

  _load = async (id: string, insertIntoScene: boolean, engine) => {
    const {
      mesh,
      skeleton,
      animationGroups,
      material,
      meshes,
      scene,
      parentMesh,
    } = await loadPlayerMesh(this.scene, this.id, insertIntoScene, engine);

    this.parentMesh = parentMesh;
    this._meshes = meshes;
    this._skeleton = skeleton;
    this._mesh = mesh;
    this._animationGroups = animationGroups;

    this.animations = {};

    for (let group of animationGroups) {
      this.animations[group.name] = group;
      group.onAnimationGroupEndObservable.add(this.onAnimationLoop);

      if (group.name === AnimationState.in_air) {
        this.animations[AnimationState.fall] = group;
      }

      if (group.name === AnimationState.run) {
        this.animations[AnimationState.walk] = group;
      }
    }

    this.material = material;
    // if (insertIntoScene) {
    //   this.parentMesh.scaling.set(1, 1, -1);
    // }

    if (typeof CoolGlassesGirl.headBoneIndex !== "number") {
      CoolGlassesGirl.headBoneIndex = this.skeleton.getBoneIndexByName(
        "mixamorig:Head"
      );
    }

    if (isFinite(CoolGlassesGirl.headBoneIndex)) {
      this._headBone = this.skeleton.bones[CoolGlassesGirl.headBoneIndex];
    }

    parentMesh.rotation.copyFrom(this._rotation);
    this._rotation = parentMesh.rotation;
    // this.texture = this.material.diffuseTexture;
  };
}
