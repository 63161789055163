import { Mesh, SubMesh, VertexData } from "@babylonjs/core";
import { TerrainMaterial } from "lib/Data/Shaders/TerrainMaterial/TerrainMaterial";
import { uniq } from "lodash";
import { mergeMeshData } from "noa-engine/lib/meshDataBuilder";
import { GreedyMesher } from "./GreedyMesher";
import { MeshBuilder } from "./MeshBuilder";
import { buildPaddedVoxelArray } from "./packing";
import { makeProfileHook1 } from "./util";

// enable for profiling..
const PROFILE_EVERY = 0; // 100

const profile_hook =
  PROFILE_EVERY > 0
    ? makeProfileHook1(PROFILE_EVERY, "TerrainMesher")
    : () => null;

/*
 *
 *          TERRAIN MESHER!!
 *
 */

const DEFAULT_AO_VALUES = [0.98, 0.8, 0.5];

var noa;
function _isMergeable({ id }) {
  const url = noa.registry.getMaterialTexture(id);
  const matData = noa.registry.getMaterialData(id);
  return !url && matData.alpha === 1 && !matData.renderMat;
}

let placeholderMat;

export class TerrainMesher {
  static greedyMesher = new GreedyMesher();
  static meshBuilder = new MeshBuilder();

  /*
   *
   * Entry point and high-level flow
   *
   */

  static meshChunk(
    chunk,
    matGetter,
    colGetter,
    ignoreMaterials,
    useAO = true,
    aoVals = DEFAULT_AO_VALUES,
    revAoVal = 1.0,
    __isMergable
  ) {
    profile_hook("start");
    noa = chunk.noa;

    const isMergeable = __isMergable || _isMergeable;

    // args
    const mats = matGetter || noa.registry.getBlockFaceMaterial;
    const cols = colGetter || noa.registry._getMaterialVertexColor;
    const ao = useAO === undefined ? noa.rendering.useAO : useAO;
    const vals = aoVals || noa.rendering.aoVals;
    const rev = isNaN(revAoVal) ? noa.rendering.revAoVal : revAoVal;
    const edgesOnly = chunk.isFull || chunk.isEmpty;

    if (!this.meshBuilder.noa) {
      this.meshBuilder.noa = chunk.noa;
    }

    return this._meshChunk(
      chunk.id,
      chunk.voxels,
      chunk._neighbors,
      edgesOnly,
      mats,
      cols,
      ao,
      vals,
      rev,
      noa.registry.getMaterialUVs,
      isMergeable,
      ignoreMaterials,
      noa.rendering.getScene()
    );
  }

  static _meshChunk(
    chunkID,
    _voxels,
    neighbors,
    edgesOnly,
    materials,
    vertexColors,
    ao,
    vals,
    rev,
    getUVs,
    isMergeable,
    ignoreMaterials,
    scene
  ) {
    // copy voxel data into array padded with neighbor values
    const voxels = buildPaddedVoxelArray(_voxels, neighbors);
    profile_hook("copy");

    // greedy mesher creates an array of Submesh structs

    const subMeshes = this.greedyMesher.mesh(
      voxels,
      materials,
      vertexColors,
      ao,
      vals,
      rev,
      edgesOnly,
      getUVs
    );

    // builds the babylon mesh that will be added to the scene
    let mesh;
    if (subMeshes.length) {
      mesh = this.meshBuilder.build(
        chunkID,
        subMeshes,
        ignoreMaterials,
        isMergeable,
        scene
      );

      profile_hook("terrain");
    }

    profile_hook("end");

    return mesh || null;
  }

  static getMeshData(
    chunkID,
    _voxels,
    neighbors,
    edgesOnly,
    materials,
    vertexColors,
    ao,
    vals,
    rev,
    getUVs,
    isMergeable,
    ignoreMaterials,
    scene
  ) {
    // copy voxel data into array padded with neighbor values
    const voxels = buildPaddedVoxelArray(_voxels, neighbors);
    profile_hook("copy");

    // greedy mesher creates an array of Submesh structs

    const subMeshes = this.greedyMesher.mesh(
      voxels,
      materials,
      vertexColors,
      ao,
      vals,
      rev,
      edgesOnly,
      getUVs
    );
    if (!subMeshes || subMeshes.length === 0) {
      return null;
    }

    const results = mergeMeshData(subMeshes, ignoreMaterials, isMergeable);

    const merged = subMeshes[0];
    const name = `chunk_${chunkID}`;
    const mats = new Array(results.matIDs.length);

    for (let i = 0; i < mats.length; i++) {
      if (!placeholderMat) {
        placeholderMat = new TerrainMaterial("placeholder", scene);
      }
      mats[i] = placeholderMat;
    }

    const tiles = subMeshes[0].tiles;

    return [
      this.meshBuilder.buildMeshFromSubmesh(
        merged,
        name,
        mats,
        results.vertices,
        results.indices,
        scene
      ),
      results.matIDs,
      tiles,
    ];
  }

  static processMeshData(
    _noa,
    chunkID,
    { positions, indices, normals, colors, uvs, tiles, matIDs, subMeshes },
    scene
  ) {
    noa = _noa;
    if (!this.meshBuilder.noa) {
      this.meshBuilder.noa = noa;
    }

    if (!matIDs || !matIDs.length) {
      return null;
    }

    const name = `chunk_${chunkID}`;
    const mats = new Array(matIDs.length);

    for (let i = 0; i < mats.length; i++) {
      mats[i] = this.meshBuilder.getTerrainMaterial(matIDs[i], false);
    }

    const mesh = new Mesh(name, scene);

    let vdat = new VertexData();

    vdat.positions = positions;
    vdat.indices = indices;
    vdat.normals = normals;
    vdat.colors = colors;
    vdat.uvs = uvs;

    vdat.applyToMesh(mesh);

    for (let {
      materialIndex,
      verticesStart,
      verticesCount,
      indexStart,
      indexCount,
    } of subMeshes) {
      new SubMesh(
        materialIndex,
        verticesStart,
        verticesCount,
        indexStart,
        indexCount,
        mesh
      );
    }

    mesh.setVerticesData("tile", tiles, true, 1);

    const materials = uniq(mats);

    mesh.material = materials[0];
    vdat = null;

    return mesh;
  }

  static meshChunkWithData(
    chunk,
    meshData,
    submesh,
    matGetter,
    colGetter,
    ignoreMaterials,
    useAO = true,
    aoVals = DEFAULT_AO_VALUES,
    revAoVal = 1.0,
    __isMergable
  ) {
    // profile_hook("start");
    const noa = chunk.noa;

    // args
    const mats = matGetter || noa.registry.getBlockFaceMaterial;
    const cols = colGetter || noa.registry._getMaterialVertexColor;
    const ao = useAO === undefined ? noa.rendering.useAO : useAO;
    const vals = aoVals || noa.rendering.aoVals;
    const rev = isNaN(revAoVal) ? noa.rendering.revAoVal : revAoVal;

    return this.meshBuilder.buildWithResults(
      chunk,
      chunk.id,
      meshData,
      ignoreMaterials,
      submesh
    );
  }
}

export default TerrainMesher;
