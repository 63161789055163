import { UniversalCamera, Vector3 } from "@babylonjs/core";
import * as Sentry from "@sentry/node";
import { AnimationState } from "game/Player/PlayerMesh";
import { PlayerInventory } from "lib/Data/PlayerInventory";
import { MapPlayer } from "lib/Data/WorldMap/RenderMapTile";
import { BlockID } from "shared/BlockID";
import { items } from "shared/items";
import { Player } from "./Player";
import { PlayerSpawnStatus } from "./PlayerSpawnStatus";

export class CurrentPlayer extends Player {
  get localEntityId() {
    if (this.noa) {
      return this.noa.playerEntity;
    } else {
      return null;
    }
  }

  get isOnGround() {
    return this.physicsBody.resting[1] === -1;
  }

  private _mapPlayer: MapPlayer;
  get mapPlayer(): MapPlayer {
    if (!this._mapPlayer) {
      this._mapPlayer = MapPlayer.fromPlayer(this);
    }

    if (this.remoteEntity) {
      this._mapPlayer.color = this.remoteEntity.color;
    }

    return this._mapPlayer;
  }

  determineAnimationState() {
    if (!this.localEntityId || !this.isLoaded) {
      return AnimationState.idle;
    }

    const body = this.noa.ents.getPhysicsBody(this.localEntityId);
    const isMovingVertically = body.resting[1] > -1;

    if (
      isMovingVertically &&
      body.velocity[1] < 0 &&
      !this.meshContainer.isAnimationEnded
    ) {
      return AnimationState.fall;
    }

    const moveSpeed = Math.max(
      Math.abs(body.velocity[0]),
      Math.abs(body.velocity[2])
    );
    const isSprintingInTick = moveSpeed > 6;
    let isSprinting = false;
    if (isSprintingInTick && this.sprintTickCount > 3) {
      isSprinting = true;
    } else if (moveSpeed <= 4) {
      this.sprintTickCount = 0;
    } else if (isSprintingInTick) {
      this.sprintTickCount++;
    }

    if (
      isMovingVertically &&
      (body.velocity[1] > 0 ||
        (body.velocity[1] < 0 && this.meshContainer.isAnimationEnded)) &&
      this.meshContainer.animationState !== AnimationState.fall
    ) {
      return AnimationState.in_air;
    } else if (moveSpeed > 0 && !isSprinting) {
      return AnimationState.walk;
    } else if (isSprinting) {
      return AnimationState.run;
    } else if (moveSpeed < 0) {
      return AnimationState.runBackards;
    }

    return AnimationState.idle;
  }

  get isCurrentPlayer() {
    return true;
  }

  set localEntityId(newValue) {}

  get canUpdateMovement() {
    return false;
  }

  get currentSlot() {
    return this.slots.indexOf(this.equippedItemId);
  }

  get currentItem() {
    return items.get(this.equippedItemId);
  }

  equippedItemId = null;

  get slots() {
    return [
      BlockID.pickaxe,
      this.inventory.secondSlot,
      this.inventory.thirdSlot,
      this.inventory.fourthSlot,
      this.inventory.fifthSlot,
      this.inventory.sixthSlot,
      this.inventory.seventhSlot,
    ];
  }

  inventory: PlayerInventory;
  renderTargetCamera: UniversalCamera;
  static hasSpawnedCurrentPlayerOnce = false;
  audioListenerProvider = () => {
    // Returns a static position
    if (this.meshContainer.mesh) {
      return this.meshContainer.mesh.absolutePosition;
    } else {
      return Vector3.ZeroReadOnly;
    }
  };

  spawn(entity, cameraMode) {
    return super.spawn(entity, cameraMode).then(() => {
      this.meshContainer.scene.audioListenerProvider = this.audioListenerProvider;

      if (!CurrentPlayer.hasSpawnedCurrentPlayerOnce) {
        Sentry.addGlobalEventProcessor((event) => {
          if (!event.extra) {
            event.extra = {
              x: this.spawnPoint[0],
              y: this.spawnPoint[1],
              character: this?.remoteEntity?.avatarType,

              z: this.spawnPoint[2],
            };
          } else {
            event.extra.x = this.spawnPoint[0];
            event.extra.y = this.spawnPoint[1];
            event.extra.character = this?.remoteEntity?.avatarType;
            event.extra.z = this.spawnPoint[2];
          }

          return event;
        });
      }
    });
  }

  despawn() {
    if (this.meshContainer) {
      this.meshContainer.dispose();
      this.meshContainer = null;
    }

    if (this.localEntityId) {
      this.noa.ents.removeComponent(this.localEntityId, "collideTerrain");
      this.noa.ents.removeComponent(this.localEntityId, "collideEntities");
      this.noa.ents.removeComponent(this.localEntityId, "playerMesh");
      this.noa.ents.removeComponent(this.localEntityId, "characterRotation");
    }

    this.spawnStatus = PlayerSpawnStatus.despawn;
  }

  update = () => {
    super.update();
  };

  rotateWithCamera = true;

  render = (dt: number) => {
    if (!this.isLoaded) {
      return;
    }

    if (this.noa.skyBox) {
      this.noa.skyBox.material.cameraOffset.y = this.noa.rendering._camera.globalPosition.y;
    }

    if (this.control) {
      this.control.render();
    }

    // this.meshContainer.avatar.headTilt = this.noa.camera.pitch;
    // super.render();
  };

  updatePosition = () => {};
  lastRotate = 0;
}
