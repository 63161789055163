import nengi from "nengi";

export class TeleportCommand {
  constructor(username: string) {
    this.username = username;
  }

  static protocol = {
    username: { type: nengi.UTF8String },
  };
}
