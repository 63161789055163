import classNames from "classnames";
import { GameContext, ModalProvider } from "components/GameContext";
import { HUD } from "components/HUD";
import { MinimapShadow } from "components/hud/minimap/MinimapShadow";
import { KeyboardHint } from "components/KeyboardHint";
import EventEmitter from "eventemitter3";
import { MenuState } from "game/MenuState";
import WorldChunkReaderWorker from "game/WorldChunkReader.worker";
import { isSafari } from "lib/browser";
import { textureImageSource } from "lib/Data/textures/textures";
import { MINIMAP_HEIGHT, MINIMAP_SIZE, MINIMAP_WIDTH } from "lib/MINIMAP_WIDTH";
import TerrainMeshWorker from "noa-engine/lib//TerrainMeshWorker.worker";
import * as React from "react";
import { Portal } from "react-portal";
import { Head } from "./Head";
import { isLiveStreamSupported } from "lib/isLiveStreamSupported";
import { LiveStreamBar } from "components/hud/livestream/LiveStreamBar";

let GameClass;

const PREFETCHES = [
  [textureImageSource, "fetch", "image/png", "use-credentials"],
  [
    "/_next/" +
      WorldChunkReaderWorker.toString().match(/(static\/.*.worker.js)/)[0],
    "script",
    "application/javascript",
    "use-credentials",
  ],
  [
    "/_next/" + TerrainMeshWorker.toString().match(/(static\/.*.worker.js)/)[0],
    "script",
    "application/javascript",
    "use-credentials",
  ],
  [
    "/vendor/rnnoise/worklet.js",
    "script",
    "application/javascript",
    "use-credentials",
  ],
  [
    "/vendor/rnnoise/processor.wasm",
    "fetch",
    "application/wasm",
    "use-credentials",
  ],
];

const PrefetchRoutes = React.memo(() =>
  PREFETCHES.map(([prefetch, _as, type, cors]) => (
    <link
      rel="preload"
      href={prefetch}
      key={prefetch}
      as={_as}
      type={type}
      crossOrigin={cors}
    />
  ))
);

if (typeof window !== "undefined") {
  const _Game = require("../game/Game");
  GameClass = _Game.Game;
}

const Minimap = React.memo(
  React.forwardRef((props, ref) => {
    const PlayerImageComponentType =
      typeof ImageBitmapRenderingContext !== "undefined" ? "canvas" : "img";
    return (
      <div
        ref={props.containerRef}
        className={classNames("MinimapContainer", {
          "MinimapContainer--autoScale": true,
          MinimapContainer: true,
          // "MinimapContainer--autoScale":
          //   typeof window === "undefined" ? false : !isSafari(),
        })}
      >
        <div
          id="game-minimap"
          className={classNames("MinimapViewport", {
            "MinimapViewport--debug": isSafari(),
          })}
        >
          <div ref={props.viewportRef} className="Layer">
            <canvas
              crossOrigin="anonymous"
              className="Minimap"
              id="background-minimap"
              ref={ref}
            />
            <canvas
              crossOrigin="anonymous"
              className="Minimap"
              id="players-minimap"
            />
          </div>

          <MinimapShadow />

          <PlayerImageComponentType
            className={
              PlayerImageComponentType === "img"
                ? "PlayerArrow--image"
                : "PlayerArrow--canvas"
            }
            id="player-image"
          />
        </div>

        <div className="MapFooter">
          <div className="KeyboardHints">
            <KeyboardHint>M</KeyboardHint>
            <div className="KeyboardSpacer" />
            <KeyboardHint>U</KeyboardHint>
            {isLiveStreamSupported && (
              <>
                <div className="KeyboardSpacer" />
                <KeyboardHint color="red">L</KeyboardHint>
              </>
            )}
          </div>

          <div className="Labels">
            <div className="MapHintIcon">MAP</div>
            <div className="KeyboardSpacer" />
            <div className="MapHintIcon">GET $25k</div>
            {isLiveStreamSupported && (
              <>
                <div className="KeyboardSpacer" />
                <div className="MapHintIcon MapHintIcon--red">LIVESTREAM</div>
              </>
            )}
          </div>
        </div>

        <style jsx global>{`
          #minimap-shadow {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            width: ${MINIMAP_WIDTH}px;
            height: ${MINIMAP_HEIGHT}px;
          }
        `}</style>

        <style jsx>{`
          .MinimapContainer {
            position: absolute;
            right: 24px;
            top: 24px;
            z-index: 1;
          }

          .MapFooter {
            margin-top: 24px;
            margin-left: auto;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            color: white;
          }

          .KeyboardSpacer {
            height: 8px;
          }

          .MapHintIcon {
            margin-left: 6px;
            font-weight: 500;
            height: 24px;
            display: flex;
            align-content: center;
            align-self: center;
            margin-top: 1px;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
          }

          .MinimapViewport {
            transform-origin: center;
            transition: opacity 0.1s linear;
            opacity: 1;
          }

          .MinimapContainer,
          .MinimapViewport,
          .Layer {
            height: ${MINIMAP_HEIGHT}px;
            width: ${MINIMAP_WIDTH}px;
          }

          .Layer {
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: #ccc;
            border: 1px solid #eee;

            overflow: hidden;
            border-radius: 50%;

            transition: transform 0.1s linear;

            transform: translateX(-50%) translateY(-50%) scale(1, -1);
          }

          .Minimap,
          .Layer {
            transform-origin: center;
          }

          .Minimap {
            width: ${MINIMAP_SIZE}px;
            object-fit: unset;
            position: absolute;
            top: 0;
            left: 0;
            height: ${MINIMAP_SIZE}px;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;

            transform: translate(-50%, -50%)
              translate(var(--minimap-x, 0px), var(--minimap-y, 0px));
          }

          #background-minimap {
            z-index: 0;
          }

          #players-minimap {
            z-index: 2;
          }

          .PlayerArrow--canvas,
          .PlayerArrow--image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform-style: preserve-3d;
            display: block;
            object-fit: contain;
            transition: transform 0.15s linear;
          }

          .PlayerArrow--canvas {
            transform: rotateY(180deg) rotate(var(--minimap-rotation, 0rad))
              translateY(-50%) translateX(-50%);
            z-index: 99;
          }

          .PlayerArrow--image {
            transform: rotateY(180deg) rotate(var(--minimap-rotation, 0rad))
              translateY(-50%) translateX(-50%) translateZ(0);
            z-index: 99;
          }

          @media (max-resolution: 2dppx), (-webkit-device-pixel-ratio: 2) {
            .MinimapContainer--autoScale .Layer {
              transform: scale(0.5, 0.5) translateX(-100%) translateY(-100%)
                scale(1, -1);
            }

            .PlayerArrow--canvas {
              transform: translateY(-50%) translateX(-50%) rotateY(180deg)
                rotate(var(--minimap-rotation, 0rad)) scale(0.5);
            }

            .PlayerArrow--image {
              transform: translateY(-50%) translateX(-50%) rotateY(180deg)
                rotate(var(--minimap-rotation, 0rad)) scale(1);
            }

            .MinimapContainer--autoScale .Layer {
              height: ${MINIMAP_HEIGHT * 2}px;
              width: ${MINIMAP_WIDTH * 2}px;
            }

            .MinimapContainer--autoScale .Minimap {
              width: ${MINIMAP_SIZE * 2}px;
              height: ${MINIMAP_SIZE * 2}px;
            }
          }
        `}</style>
      </div>
    );
  })
);

export const Game = () => {
  const containerRef = React.useRef<HTMLDivElement>();
  const game = React.useRef<GameClass>(null);
  const videoElementRef = React.useRef<HTMLVideoElement>();
  const playerStream = React.useRef<MediaStream>();
  const [socket, setSocket] = React.useState<EventEmitter>(null);
  const [menuState, setMenuState] = React.useState<MenuState>(MenuState.hidden);
  const minimapRef = React.useRef<HTMLCanvasElement>();
  const mapContainerRef = React.useRef<HTMLDivElement>();
  const viewportRef = React.useRef<HTMLDivElement>();
  const gameCanvas = React.useRef<HTMLCanvasElement>(null);
  const modalProvider = React.useRef<ModalProviderType>(null);
  const [gameContextValue, setContextValue] = React.useState();

  const onChangeMenuState = React.useCallback(
    (menuState) => {
      setMenuState((_menuState) => {
        game.current.menuState = menuState;
        return menuState;
      });
    },
    [setMenuState, game]
  );

  const onDismissHUD = React.useCallback(
    () => onChangeMenuState(MenuState.hidden),
    [onChangeMenuState]
  );

  React.useLayoutEffect(() => {
    if (!game.current) {
      game.current = new GameClass(
        containerRef.current,
        gameCanvas.current,
        minimapRef.current,
        viewportRef.current,
        mapContainerRef.current,
        onChangeMenuState
      );
      game.current.modal = modalProvider.current;
      game.current.minimapCanvas = minimapRef.current;
      setSocket(game.current.socket);

      setContextValue({ game: game.current });
      game.current.start().then(() => {});
    }

    return () => {
      game.current?.destroy();
    };
  }, [
    containerRef,
    game,
    videoElementRef,
    gameCanvas,
    playerStream,
    setSocket,
    modalProvider,
    gameCanvas,
    viewportRef,
    minimapRef,
    setContextValue,
    mapContainerRef,
    onChangeMenuState,
  ]);

  const onLoadWebcam = React.useCallback(
    (videoTag: HTMLVideoElement, stream: MediaStream) => {
      videoElementRef.current = videoTag;
      playerStream.current = stream;

      if (game.current?.isLoaded) {
        game.current.addCurrentPlayer("current", videoTag, stream);
      }
    },
    [videoElementRef, playerStream, game]
  );

  return (
    <GameContext.Provider value={gameContextValue}>
      <div className="GameContainer" ref={containerRef}>
        <Head
          title="Present � build & explore with friends"
          url="https://present.land"
          description="Present is a free always-evolving multiplayer game available on PC & Mac."
        />
        <PrefetchRoutes />
        <canvas
          width="100%"
          height="100%"
          id="noa-container"
          ref={gameCanvas}
        />
        {typeof window !== "undefined" && (
          <Minimap
            ref={minimapRef}
            viewportRef={viewportRef}
            containerRef={mapContainerRef}
          />
        )}
        {socket && (
          <HUD
            onLogin={game.current?.reconnect}
            onDismiss={onDismissHUD}
            game={game.current}
            currentPlayer={game.current?.currentPlayer}
            socket={socket}
            menuState={menuState}
          />
        )}
        <ModalProvider ref={modalProvider} />
      </div>
      <LiveStreamBar />
      <style jsx>{`
        .GameContainer,
        #noa-container {
          flex: 1;
          width: 100%;
          height: 100%;
          outline: 0 !important;
        }

        .GameContainer {
          position: relative;
        }
      `}</style>
      <style global jsx>{`
        * {
          user-select: none;
          -webkit-user-select: none;
        }
      `}</style>
    </GameContext.Provider>
  );
};

export default Game;
