import classNames from "classnames";
import getEmojiRegex from "emoji-regex/text";
import EventEmitter from "eventemitter3";
import dynamic from "next/dynamic";
import * as React from "react";
import { ChatEvent } from "shared/events";

const EmojiIcon = React.memo(() => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 28C9.371 28 4 22.627 4 16S9.371 4 16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12zm-4-16c-.894 0-1.5.938-1.5 2.244 0 1.331.7 1.756 1.5 1.756s1.5-.425 1.5-1.756C13.5 12.938 12.894 12 12 12zm8 0c-.894 0-1.5.938-1.5 2.244 0 1.331.7 1.756 1.5 1.756s1.5-.425 1.5-1.756C21.5 12.938 20.894 12 20 12zm1.625 7c-.694 0-2.061 2-5.625 2s-4.931-2-5.625-2a.35.35 0 00-.375.375C10 20.021 12.25 23 16 23s6-2.98 6-3.625a.35.35 0 00-.375-.375z"
    ></path>
  </svg>
));

const ChatIcon = React.memo(({}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    enableBackground="new 0 0 24 24"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M23.5 4a.5.5 0 00-.5.5V14H7V9.5a.5.5 0 00-.82-.385l-6 5a.5.5 0 000 .768l6 5a.504.504 0 00.532.069.5.5 0 00.288-.453v-4.5h16.5a.5.5 0 00.5-.5v-10A.5.5 0 0023.5 4z"
    ></path>
  </svg>
));

const emojiRegex: RegExp = getEmojiRegex();

const EmojiPicker = dynamic(() => import("./EmojiPicker"), {
  loading: () => null,
  ssr: false,
});

enum MessageType {
  chat,
  playerJoined,
}

const getMessageType = (message) => {
  if (message?.protocol?.name === "PlayerJoinedChatMessage") {
    return MessageType.playerJoined;
  } else {
    return MessageType.chat;
  }
};

const ChatInputComponent = React.forwardRef(
  ({ onSend, onFocus: _onFocus, onBlur: _onBlur }, ref) => {
    const input = React.useRef<HTMLInputElement>();
    const [isFocused, setFocused] = React.useState(false);
    const [hasText, setHasText] = React.useState(false);
    const [emojiQuery, setEmojiQuery] = React.useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
    const formRef = React.useRef<HTMLFormElement>();
    React.useImperativeHandle(ref, () => input.current);
    const emojiPickerRef = React.useRef();

    const onFocus = React.useCallback(
      (event) => {
        setFocused(true);
        _onFocus(event);
      },
      [setFocused, _onFocus]
    );

    const onBlur = React.useCallback(
      (event) => {
        _onBlur(event);

        setShowEmojiPicker(false);
        setEmojiQuery(null);

        setFocused(false);
        if (input.current) {
          input.current.value = "";
        }
      },
      [_onBlur, setShowEmojiPicker, setFocused, input]
    );

    const handleSend = React.useCallback(
      (event: React.FormEvent) => {
        if (event) {
          event.preventDefault();
        }

        const data = new FormData(formRef.current);

        if (data.get("body")?.length > 0) {
          formRef.current.reset();
          setHasText(false);
          onSend(data);
          input.current.blur();
          onBlur();
        }
      },
      [input, onSend, formRef, setHasText, onBlur]
    );

    const onChangeText = React.useCallback(
      (event) => {
        const hasText = event.target.value.trim().length > 0;
        setHasText(hasText);

        if (!hasText || !event.target.value.includes(":")) {
          setShowEmojiPicker(false);
          setEmojiQuery(null);
        } else {
          let semiColonIndex = -1;
          let hasAnySpaces = false;

          for (let i = event.target.selectionStart + 1; i > -1; i--) {
            if (event.target.value[i] === ":") {
              semiColonIndex = i;
              break;
            } else if (event.target.value[i] === " ") {
              hasAnySpaces = true;
              break;
            }
          }

          if (semiColonIndex > -1) {
            const emojiQuery = event.target.value.substr(
              semiColonIndex + 1,
              event.target.selectionStart - semiColonIndex
            );
            setEmojiQuery(emojiQuery);
          }
        }
      },
      [setHasText, setShowEmojiPicker, setEmojiQuery]
    );

    const handleSelectEmoji = React.useCallback(
      (emoji, evt) => {
        const textInput = input.current;
        if (!textInput) {
          return;
        }

        let hasAnySpaces = false;

        let semiColonIndex = -1;
        for (let i = textInput.selectionStart + 1; i > -1; i--) {
          if (textInput.value[i] === ":") {
            semiColonIndex = i;
            break;
          } else if (textInput.value[i] === " ") {
            hasAnySpaces = true;
            break;
          }
        }

        let endIndex = textInput.value.length - 1;

        if (semiColonIndex > -1) {
          for (let i = semiColonIndex + 1; i < textInput.value.length; i++) {
            if (textInput.value[i] === " ") {
              endIndex = i;
              break;
            }
          }
        }

        let original = textInput.value;
        let splicedString = textInput.value.substring(0, semiColonIndex - 1);
        const addLength = emoji.native.length;
        const removeLength = endIndex - semiColonIndex;

        splicedString += emoji.native;

        splicedString += original.substring(endIndex + 1);

        textInput.value = splicedString;
        textInput.selectionStart += removeLength - addLength;
        textInput.selectionEnd += removeLength - addLength;

        setShowEmojiPicker(false);
        setEmojiQuery(null);
      },
      [input, setEmojiQuery, setShowEmojiPicker]
    );

    const handleAppendEmoji = React.useCallback(
      (emoji, evt) => {
        const textInput = input.current;
        if (!textInput) {
          return;
        }

        if (textInput.value.endsWith(": ")) {
          textInput.value =
            textInput.value.substring(0, textInput.value.length - 3) +
            emoji.native;
        } else if (textInput.value.endsWith(" ")) {
          textInput.value += emoji.native;
        } else if (textInput.value.endsWith(":")) {
          textInput.value =
            textInput.value.substring(0, textInput.value.length - 1) +
            emoji.native;
        } else {
          textInput.value += " " + emoji.native;
        }

        textInput.focus();

        textInput.selectionStart = textInput.value.length - 1;
        textInput.selectionEnd = textInput.value.length - 1;

        setShowEmojiPicker(false);
        setEmojiQuery(null);
      },
      [input, setEmojiQuery, setShowEmojiPicker]
    );

    const handleKeyDown = React.useCallback(
      (event: KeyboardEvent) => {
        event.stopPropagation();

        if (
          (event.key === ":" && event.target.value.length === 0) ||
          (event.target.value[event.target.value.length - 1] === " " &&
            event.key === ":")
        ) {
          console.log("SHOW");
          setShowEmojiPicker(true);
          setEmojiQuery(null);
        } else if (event.key === "Backspace") {
          const toDelete = event.target.value.substr(
            input.current.selectionStart - 1
          );

          if (toDelete.includes(":")) {
            setShowEmojiPicker(false);
            setEmojiQuery(null);
          } else if (
            event.target.value.substr(input.current.selectionStart - 2) ===
              ":" &&
            !event.shiftKey &&
            !event.altKey
          ) {
            setShowEmojiPicker(true);
          }
        }

        if (event.keyCode === 32) {
          setShowEmojiPicker(false);
          setEmojiQuery(null);
        }

        if (
          (event.key === "ArrowUp" || event.key === "ArrowDown") &&
          emojiPickerRef.current
        ) {
          event.preventDefault();

          if (emojiPickerRef.current.incrementSelectedIndex) {
            if (event.key === "ArrowDown") {
              emojiPickerRef.current?.incrementSelectedIndex(1);
            } else {
              emojiPickerRef.current?.incrementSelectedIndex(-1);
            }
          }
        }

        if (
          event.key === "Enter" &&
          !event.shiftKey &&
          event.target.value.trim().replace(":", "").length > 0
        ) {
          event.preventDefault();

          if (emojiPickerRef.current && emojiPickerRef.current.getSelection()) {
            const selectedEmoji = emojiPickerRef.current.getSelection();
            handleSelectEmoji(selectedEmoji);
          } else {
            handleSend(event);
          }

          return;
        } else if (
          (event.key === "Enter" &&
            !event.shiftKey &&
            event.target.value.trim().replace(":", "").length === 0) ||
          event.key === "Escape"
        ) {
          event.preventDefault();
          event.target.blur();
          onBlur();
        } else if (event.key === "Enter") {
          event.preventDefault();
        }
      },
      [handleSend, onBlur, setHasText, emojiPickerRef, handleSelectEmoji]
    );

    const openEmojiPicker = React.useCallback(() => {
      setShowEmojiPicker(true);
      setEmojiQuery(null);

      if (input.current !== document.activeElement) {
        input.current.focus();
      }
    }, [input, setShowEmojiPicker, setEmojiQuery]);

    const stopEvent = React.useCallback((evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      evt.nativeEvent.stopImmediatePropagation();
    }, []);

    const focusInput = React.useCallback(
      (evt) => {
        evt.preventDefault();
        input.current.focus();
      },
      [input]
    );

    return (
      <div
        onClick={stopEvent}
        onMouseDown={stopEvent}
        onMouseUp={stopEvent}
        className="ChatInput-container"
      >
        {showEmojiPicker && (
          <div className="EmojiPickerContainer">
            <div className="EmojiSheet">
              <EmojiPicker
                onAppendEmoji={handleAppendEmoji}
                onPick={handleSelectEmoji}
                query={emojiQuery}
                pickerRef={emojiPickerRef}
                disableSearch={isFocused}
              />
            </div>
          </div>
        )}

        <form
          ref={formRef}
          tabIndex={-1}
          noValidate
          onSubmit={handleSend}
          className={classNames("ChatInput", {
            "ChatInput--hasText": hasText,
            "ChatInput--openEmojiPicker": showEmojiPicker,
          })}
        >
          <input
            type="text"
            required
            ref={input}
            name="body"
            rows={1}
            onFocus={onFocus}
            formNoValidate
            tabIndex={-1}
            onBlur={onBlur}
            onMouseDown={focusInput}
            autoComplete="off"
            minLength={1}
            onChange={onChangeText}
            id="chat-input"
            placeholder={"Say something nice"}
            cols={10}
            onKeyDown={handleKeyDown}
          />

          <div className="EnterKey">
            <div onClick={openEmojiPicker} className="EmojiIcon">
              <EmojiIcon />
            </div>

            <ChatIcon />
          </div>
        </form>

        <style jsx>{`
          .ChatInput {
            width: 100%;
            padding: 0 0;
            display: flex;
            border-top: 1px solid transparent;
            display: flex;
            z-index: 10;

            opacity: 0;
            position: relative;
          }

          .EnterKey {
            position: absolute;
            left: 0;
            right: 0;
            width: 100%;
            padding-right: 16px;
            justify-content: space-between;
            color: white;

            pointer-events: none;
            top: 0;
            display: flex;
            align-items: center;
            bottom: 0;
          }

          .ChatInput--openEmojiPicker .EmojiIcon,
          .EmojiIcon:hover {
            color: #ae65c5;
          }

          .ChatInput--openEmojiPicker .EmojiIcon {
            transform: scale(1.2);
          }

          .EmojiIcon {
            pointer-events: auto;
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            margin-left: 12px;
            margin-bottom: 2px;
            transition: transform 0.1s linear;
          }

          .EmojiSheet {
            position: absolute;
            transform: translateY(-100%);

            width: 100%;
            z-index: 10;
          }

          #chat-input {
            resize: none;
            width: 100%;
            background-color: none;
            padding: 12px 12px;
            margin: 0;
            border-bottom-left-radius: 4px;
            text-indent: 28px;
            border-bottom-right-radius: 4px;

            font-size: 14px;
            background-color: transparent;
            border-color: transparent;
            color: white;
          }

          .EnterKey {
          }

          #chat-input::placeholder {
            color: #aaa;
          }
        `}</style>
        <style jsx global>{`
          .ChatBox:hover .ChatInput {
            border-top-color: #666;
          }

          .ChatBox:hover textarea,
          textarea:focus,
          textarea:hover {
            background-color: #222;
          }

          #chat-input::placeholder {
            color: #aaa;
          }

          .ChatBox:hover .ChatInput,
          .ChatBox--focused .ChatInput {
            opacity: 1;
          }
        `}</style>
      </div>
    );
  }
);

const ChatInput = ({ emitter, onFocus, onBlur }: { emitter: EventEmitter }) => {
  const textInput = React.useRef<HTMLInputElement>();
  const lastCalledFocus = React.useRef<number>(0);

  const captureFocus = React.useCallback(() => {
    const now = new Date().getTime();
    if (now - lastCalledFocus.current < 20) {
      return;
    }
    lastCalledFocus.current = now;

    if (document.activeElement === textInput.current) {
      if (textInput.current.value.trim().length === 0) {
        textInput.current.blur();
        onBlur();
      }
      return;
    }

    textInput.current?.focus();

    onFocus();
  }, [textInput, onFocus, onBlur, lastCalledFocus]);

  React.useEffect(() => {
    emitter.on(ChatEvent.toggleInputFocus, captureFocus);

    return () => emitter.off(ChatEvent.toggleInputFocus, captureFocus);
  }, [emitter, captureFocus]);

  const handleFocus = React.useCallback(() => {
    emitter.emit(ChatEvent.didFocusInput);
    onFocus();
  }, [emitter, onFocus]);

  const handleBlur = React.useCallback(() => {
    emitter.emit(ChatEvent.didBlurInput);
    onBlur();
  }, [emitter, onBlur]);

  const handleSend = React.useCallback(
    (formData: FormData) => {
      emitter.emit(ChatEvent.sendMessage, formData.get("body"));
    },
    [emitter]
  );

  return (
    <ChatInputComponent
      onSend={handleSend}
      onFocus={handleFocus}
      onBlur={handleBlur}
      ref={textInput}
    />
  );
};

const MessageComponent = ({ body, username, color, sentAt }) => {
  const isEmojiOnly = React.useMemo(() => {
    return (
      body.length < 12 &&
      body
        .replace(emojiRegex, "")
        .replace(/[\s\n]/gm, "")
        .trim().length === 0
    );
  }, [body]);

  return (
    <div
      className={classNames("Message", { "Message--emojiOnly": isEmojiOnly })}
    >
      <span className={`Username`} style={{ color }}>
        {username}
      </span>
      <span className="Separator">: </span>
      {body}
      <style jsx>
        {`
          .Message {
            word-wrap: break-word;
            word-break: break-all;
          }

          .Message--emojiOnly {
            font-size: 42px;
          }

          .Separator {
            color: #ccc;
          }

          .Username {
            font-weight: 500;
          }

          .Username,
          .Separator {
            font-size: 16px;
          }
        `}
      </style>
    </div>
  );
};

const PlayerJoinedChatMessageComponent = ({ username, color }) => {
  return (
    <div className="Message">
      <span className={`Username`} style={{ color }}>
        {username}
      </span>{" "}
      joined the world
      <style jsx>{`
        .Message {
          color: #ccc;
          word-wrap: break-word;
          word-break: break-all;
        }

        .Username {
          font-weight: 500;
        }
      `}</style>
    </div>
  );
};

const MessageListComponent = ({ messages }) => {
  const scrollRef = React.useRef<HTMLDivElement>();
  const isInitialScroll = React.useRef(true);

  React.useLayoutEffect(() => {
    if (scrollRef.current && messages.length > 0) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        left: 0,
        behavior: isInitialScroll.current ? "auto" : "smooth",
      });

      isInitialScroll.current = false;
    }
  }, [messages, messages.length, scrollRef, isInitialScroll]);

  const renderMessage = React.useCallback(
    (message, index) => {
      if (message.type === MessageType.playerJoined) {
        return (
          <PlayerJoinedChatMessageComponent
            key={message.key || index}
            username={message.username}
            color={message.color}
            sentAt={message.sentAt}
          />
        );
      } else {
        return (
          <MessageComponent
            key={message.key || index}
            body={message.body}
            username={message.username}
            color={message.color}
            sentAt={message.sentAt}
          />
        );
      }
    },
    [messages, messages.length]
  );

  return (
    <div className={"MessageList"} ref={scrollRef}>
      <div className="MessagesContent">{messages.map(renderMessage)}</div>
      <style jsx>{`
        .MessageList {
          max-width: 380px;
          width: 100%;
          scroll-behavior: smooth;
          overscroll-behavior-y: contain;
          overflow-x: hidden;

          height; 380px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          height: 350px;
          max-height: 350px;

          padding: 12px 12px;

        }

        .MessagesContent {
          color: white;
          align-self: flex-start;
          margin-top: auto;
        }

        .MessageList::-webkit-scrollbar { width: 8px; height: 3px;}
        .MessageList::-webkit-scrollbar-button {  background-color: transparent; }
        .MessageList::-webkit-scrollbar-track {  background-color: #646464;}
        .MessageList::-webkit-scrollbar-track-piece { background-color: #000;}
        .MessageList::-webkit-scrollbar-thumb { height: 0px; background-color: #666; border-radius: 3px;}
        .MessageList::-webkit-scrollbar-corner { background-color: transparent;}

      `}</style>

      <style jsx global>{`
        .MessageList {
          opacity: 1;
        }

        @keyframes fade-message-list {
          0% {
            opacity: 1;
          }
          10% {
            opacity: 0.5;
          }

          95% {
            opacity: 0.5;
          }

          100% {
            opacity: 0;
          }
        }

        .ChatBox--faded {
          animation: fade-message-list 20s linear;
          animation-fill-mode: forwards;
          animation-delay: 500ms;
        }

        .ChatBox--faded:hover {
          animation: none;
        }
      `}</style>
    </div>
  );
};

const MessageList = ({ emitter, onResetFade }: { emitter: EventEmitter }) => {
  const [messages, _setMessages] = React.useState([]);

  const setMessages = React.useCallback(
    (cb) => {
      _setMessages(cb);

      onResetFade();
    },
    [_setMessages, onResetFade]
  );

  const onNewMessage = React.useCallback(
    (message) => {
      message.key = `${message.body}-${message.sentAt}-${message.username}`;

      setMessages((_messages) => {
        let messages = _messages;
        let hasIdenticalMessage = false;
        for (
          let i = messages.length - 1;
          i > Math.max(messages.length - 30, 0);
          i--
        ) {
          if (messages[i].key === message.key) {
            hasIdenticalMessage = true;
            break;
          }
        }

        if (!hasIdenticalMessage) {
          messages = messages.slice(0);
          messages.push(message);
        }

        return messages;
      });
    },
    [setMessages]
  );

  React.useEffect(() => {
    emitter.on(ChatEvent.newMessage, onNewMessage);

    return () => {
      emitter.off(ChatEvent.newMessage, onNewMessage);
    };
  }, [emitter, onNewMessage]);

  const onNewSystemMessage = React.useCallback(
    (message) => {
      message.type = getMessageType(message);
      message.key = `${message.type}-${message.nid}`;

      setMessages((_messages) => {
        let messages = _messages;
        let hasIdenticalMessage = false;

        for (
          let i = messages.length - 1;
          i > Math.max(messages.length - 30, 0);
          i--
        ) {
          if (messages[i].key === message.key) {
            hasIdenticalMessage = true;
            break;
          }
        }

        if (!hasIdenticalMessage) {
          messages = messages.slice(0);
          messages.push(message);
        }

        return messages;
      });
    },
    [setMessages]
  );

  React.useEffect(() => {
    emitter.on(ChatEvent.newSystemMessage, onNewSystemMessage);

    return () => {
      emitter.off(ChatEvent.newSystemMessage, onNewSystemMessage);
    };
  }, [emitter, onNewSystemMessage]);

  return <MessageListComponent messages={messages} />;
};

export const ChatBox = ({
  emitter,
  isActive,
}: {
  emitter: EventEmitter;
  isActive;
}) => {
  const [isFaded, setFaded] = React.useState(false);
  const [isFocused, setFocused] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(false);

  const fadeTimer = React.useRef<number>();

  const handleResetFade = React.useCallback(() => {
    if (fadeTimer.current) {
      window.clearTimeout(fadeTimer.current);
    }

    setFaded(false);
    fadeTimer.current = window.setTimeout(() => {
      if (document.activeElement instanceof HTMLTextAreaElement) {
        return;
      }

      setFaded(true);
    }, 2000);
  }, [setFaded, fadeTimer]);

  const handleFocus = React.useCallback(() => setFocused(true), [setFocused]);
  const handleBlur = React.useCallback(() => setFocused(false), [setFocused]);

  React.useEffect(() => {
    return () => {
      if (fadeTimer.current) {
        self.clearTimeout(fadeTimer.current);
      }
    };
  }, [fadeTimer]);

  if (!isActive) {
    return null;
  }

  return (
    <div onMouseLeave={handleResetFade} className="ChatBoxContainer">
      <div
        className={classNames("ChatBox", {
          "ChatBox--faded": isFaded && !isFocused,
          "ChatBox--focused": isFocused,
        })}
      >
        <div className="ChatBox-background" />
        <MessageList onResetFade={handleResetFade} emitter={emitter} />
        <ChatInput
          emitter={emitter}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>

      <style jsx>{`
        .ChatBoxContainer {
          pointer-events: auto;
          max-width: 380px;
          width: 100%;
          margin-bottom: 38px;
        }

        .ChatBox-background {
          opacity: 0;
          background-color: #111;
          backdrop-filter: blur(5px);
          border-radius: 4px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
          width: 100%;
          height: 100%;
          pointer-events: none;
        }

        .ChatBox {
          max-width: 380px;
          width: 100%;
          min-width: 300px;
          display: flex;
          flex-direction: column;
          flex: 1;
          position: relative;
        }

        .ChatBox:hover .ChatBox-background,
        .ChatBox--focused .ChatBox-background {
          opacity: 0.75;
        }

        .ChatBox:hover .ChatBox-background {
          transition-duration: 0ms;
        }
      `}</style>

      <style jsx global>{`
        .HUD--building .ChatBoxContainer {
          pointer-events: none;
        }

        .HUD--building .ChatBox .ChatBox-background {
          opacity: 0;
        }
      `}</style>
    </div>
  );
};
