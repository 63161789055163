let processor, instance, heapFloat32;
const WASM_PATH = "/vendor/rnnoise/processor.wasm";
const WORKLET_PATH = "/vendor/rnnoise/worklet.js";

let compileWASM = WebAssembly.compileStreaming;

if (typeof compileWASM !== "function") {
  compileWASM = async (f) =>
    await WebAssembly.compile(await (await f).arrayBuffer());
}

const compileProcessor = async () => {
  processor = await compileWASM(fetch(WASM_PATH));
};

export let RNNoiseNode;

let AudioWorkletNode = window.AudioWorkletNode || window.webkitAudioWorkletNode;

if (typeof AudioWorkletNode !== "undefined") {
  class RNNoiseWorkletNode extends AudioWorkletNode {
    static async register(context) {
      await compileProcessor();
      await context.audioWorklet.addModule(WORKLET_PATH);
    }

    constructor(context) {
      super(context, "rnnoise", {
        channelCountMode: "explicit",
        channelCount: 1,
        channelInterpretation: "speakers",
        numberOfInputs: 1,
        numberOfOutputs: 1,
        outputChannelCount: [1],
        processorOptions: {
          module: processor,
        },
      });
      this.port.onmessage = ({ data }) => {
        const e = Object.assign(new Event("status"), data);
        this.dispatchEvent(e);
        if (this.onstatus) this.onstatus(e);
      };
    }

    update(keepalive) {
      this.port.postMessage(keepalive);
    }
  }

  RNNoiseNode = RNNoiseWorkletNode;
}

const ScriptProcessorNode =
  window.ScriptProcessorNode || window.webkitScriptProcessorNode;

if (!AudioWorkletNode && typeof ScriptProcessorNode !== "undefined") {
  RNNoiseNode = Object.assign(
    function (context) {
      const processor = context.createScriptProcessor(512, 1, 1),
        state = instance.newState();
      let alive = true;
      processor.onaudioprocess = ({ inputBuffer, outputBuffer }) => {
        if (alive) {
          heapFloat32.set(
            inputBuffer.getChannelData(0),
            instance.getInput(state) / 4
          );
          const o = outputBuffer.getChannelData(0),
            ptr4 = instance.pipe(state, o.length) / 4;
          if (ptr4) o.set(heapFloat32.subarray(ptr4, ptr4 + o.length));
        }
      };
      processor.update = (keepalive) => {
        if (alive) {
          if (keepalive) {
            const e = Object.assign(new Event("status"), {
              vadProb: instance.getVadProb(state),
            });
            processor.dispatchEvent(e);
            if (processor.onstatus) processor.onstatus(e);
          } else {
            alive = false;
            instance.deleteState(state);
          }
        }
      };
      return processor;
    },
    {
      register: async () => {
        if (!instance)
          heapFloat32 = new Float32Array(
            (instance = (
              await WebAssembly.instantiate(await compileProcessor())
            ).exports).memory.buffer
          );
      },
    }
  );
}
