import nengi from "nengi";

export class UnloadChunkMessage {
  constructor({ x, y }) {
    this.x = x;
    this.y = y;
  }
  x: number;
  y: number;

  static protocol = {
    y: nengi.Float32,
    x: nengi.Float32,
  };
}

export default UnloadChunkMessage;
