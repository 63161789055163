import {
  BoundingInfo,
  IOctreeContainer,
  Mesh,
  OctreeBlock,
  Plane,
  SmartArray,
  Vector3,
  BoundingBox,
} from "@babylonjs/core";
import { compact } from "lodash";
import memoizee from "memoizee";

export let meshBlock = new Map<Mesh, OctreeBlock<Mesh>>();

export const clearMeshBlock = () => {
  meshBlock = new Map();
};

export class ChunkOctreeBlock extends OctreeBlock<Mesh> {
  constructor(
    minPoint: Vector3,
    maxPoint: Vector3,
    capacity: number,
    depth: number,
    maxDepth: number,
    creationFunc: (entry: T, block: OctreeBlock<T>) => void
  ) {
    super(minPoint, maxPoint, capacity, depth, maxDepth, creationFunc);
  }

  // intersector: SphereInt;

  select(frustumPlanes: Array<Plane>, selection: SmartArray<Mesh>) {
    // if (this.intersector) {
    // } else {
    return super.select(frustumPlanes, selection);
    // }
  }

  addEntry(entry: Mesh) {
    let alreadyInside = this.entries && this.entries.indexOf(entry);
    super.addEntry(entry);

    // if (this.entries && this.intersector && entry.getVertexBuffer) {
    //   const i = this.entries.indexOf(entry);
    //   if (i > -1) {
    //     const positions = entry.getVertexBuffer(VertexBuffer.PositionKind);

    //     if (!positions) {
    //       return;
    //     }

    //     const indices = Uint32Array.from(entry.getIndices());

    //     this.intersector.set(entry.name, indices, positions.getData());
    //   }
    // }
  }

  removeEntry(entry) {
    super.removeEntry(entry);
    let alreadyInside = this.entries && this.entries.indexOf(entry);

    // if (this.entries && this.intersector && entry.getVertexBuffer) {
    //   this.intersector.remove(entry.name);
    // }
  }

  createInnerBlocks() {
    ChunkOctreeBlock._CreateBlocks(
      this._minPoint,
      this._maxPoint,
      this.entries,
      this._capacity,
      this._depth,
      this._maxDepth,
      this,
      this._creationFunc
    );
  }
  _minPoint: Vector3;
  _maxPoint: Vector3;
  _capacity: number;
  _depth: number;
  _maxDepth: number;

  updateBoundingVectors() {
    const { minPoint, maxPoint } = this;

    this._boundingVectors = [
      minPoint.clone(),
      maxPoint.clone(),
      minPoint.clone(),
      minPoint.clone(),
      minPoint.clone(),
      maxPoint.clone(),
      maxPoint.clone(),
      maxPoint.clone(),
    ];
    this._boundingVectors[2].x = maxPoint.x;
    this._boundingVectors[3].y = maxPoint.y;
    this._boundingVectors[4].z = maxPoint.z;
    this._boundingVectors[5].z = minPoint.z;
    this._boundingVectors[6].x = minPoint.x;
    this._boundingVectors[7].y = minPoint.y;
  }

  addEntries(entries: Array<Mesh>) {
    let count = 0;

    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (!entry || !entry.getBoundingInfo) {
        continue;
      }
      if (this.intersectsBounds(entry.getBoundingInfo())) {
        count++;
      }
    }

    let offset = this.entries.length;
    this.entries.length += count;
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (!entry || !entry.getBoundingInfo) {
        continue;
      }

      if (this.intersectsBounds(entry.getBoundingInfo())) {
        this.entries[i + offset] = entry;
        count++;
      }
    }
  }

  intersectsBounds(bounds: BoundingInfo) {
    const box = bounds.boundingBox;

    return box.intersectsMinMax(this.minPoint, this.maxPoint);
  }

  public static onAddEntry(mesh: Mesh, block: ChunkOctreeBlock) {
    if (block.intersectsBounds(mesh.getBoundingInfo())) {
      block.entries.push(mesh);
      meshBlock.set(mesh, block);
    }
  }

  public static _CreateBlocks<T>(
    worldMin: Vector3,
    worldMax: Vector3,
    entries: T[],
    maxBlockCapacity: number,
    currentDepth: number,
    maxDepth: number,
    target: IOctreeContainer<T>,
    creationFunc: (entry: T, block: OctreeBlock<T>) => void
  ): void {
    if (!target.blocks) {
      target.blocks = new Array(8);
    }

    const blockSize = Vector3.Center(worldMax, worldMin);
    let localMin = Vector3.Zero();
    let localMax = Vector3.Zero();
    let i = 0;

    // Segmenting space
    for (var x = 0; x < 2; x++) {
      for (var y = 0; y < 2; y++) {
        for (var z = 0; z < 2; z++) {
          let block: ChunkOctreeBlock = target.blocks[i];

          localMin.x = blockSize.x * x;
          localMin.y = blockSize.y * y;
          localMin.z = blockSize.z * z;

          localMin.addToRef(worldMin, localMin);

          localMax.x = blockSize.x * (x + 1);
          localMax.y = blockSize.y * (y + 1);
          localMax.z = blockSize.z * (z + 1);

          localMax.addToRef(worldMax, localMax);
          const min = localMin.clone();
          const max = localMax.clone();

          if (!target.blocks[i]) {
            block = new ChunkOctreeBlock(
              min,
              max,
              maxBlockCapacity,
              currentDepth + 1,
              maxDepth,
              creationFunc
            );
            target.blocks[i] = block;
          } else {
            block.entries.length = 0;
          }

          block._minPoint = min;
          block._capacity = maxBlockCapacity;
          block._maxDepth = maxDepth;
          block._depth = currentDepth + 1;
          block._maxPoint = max;
          block.updateBoundingVectors();

          block.addEntries(entries);

          i++;
        }
      }
    }
  }
}
