import nengi from "nengi";

export class ClaimYourInviteMessage {
  constructor(username: string, currency: number) {
    this.username = username;
    this.currency = currency;
  }

  username: string;
  currency: number;

  static protocol = {
    username: { type: nengi.UTF8String },
    currency: { type: nengi.UInt32 },
  };
}
