import vec3 from "gl-vec3";

export default function (noa) {
  return {
    name: "mesh",

    order: 100,

    state: {
      mesh: null,
      offset: null,
    },

    onAdd: function (eid, state) {
      // implicitly assume there's already a position component
      var posDat = noa.ents.getPositionData(eid);
      if (state.mesh) {
        noa.rendering.addMeshToScene(state.mesh, false, posDat.position);
      } else {
        throw new Error(
          "Mesh component added without a mesh - probably a bug!"
        );
      }
      if (!state.offset) state.offset = new vec3.create();

      // set mesh to correct position
      var rpos = posDat._renderPosition;
      state.mesh.position = rpos.addInPlaceFromFloats(
        state.offset[0],
        state.offset[1],
        state.offset[2]
      );
    },

    onRemove: function (eid, state) {
      state.mesh.dispose();
    },

    renderSystem: function (dt, states) {
      // before render move each mesh to its render position,
      // set by the physics engine or driving logic
      states.forEach(updateMeshPosition);
    },
  };

  function updateMeshPosition(state) {
    var id = state.__id;

    if (state.offset[0] || state.offset[1] || state.offset[2]) {
      var rpos = noa.ents.getPositionData(id)._localPosition;
      state.mesh.position.set(
        rpos[0] + state.offset[0],
        rpos[1] + state.offset[1],
        rpos[2] + state.offset[2]
      );
    } else {
      var rpos = noa.ents.getPositionData(id)._renderPosition;
      if (rpos !== state.mesh.position) {
        state.mesh.position = rpos;
      }
    }
  }
}
