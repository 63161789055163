import { NengiIDType } from "../nengiTypes";
import nengi from "nengi";

export class UpdateStreamingStatusCommand {
  constructor(isStreaming: boolean) {
    this.isStreaming = isStreaming;
  }

  isStreaming: boolean;

  static protocol = {
    isStreaming: { type: nengi.Boolean },
  };
}
