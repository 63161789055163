import nengi from "nengi";
import { NengiIDType } from "../nengiTypes";

export class ConfirmUsernameMessage {
  constructor(email: string, username: string, player: number) {
    this.email = email;
    this.username = username;
    this.player = player;
  }

  email: string;
  username: string;
  player: number;

  static protocol = {
    email: { type: nengi.UTF8String },
    username: { type: nengi.UTF8String },
    player: { type: NengiIDType },
  };
}
