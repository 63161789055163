import { EventEmitter } from "eventemitter3";
import { ChunkID } from "noa-engine/lib/ChunkID";
import { meshChunk } from "noa-engine/lib/startTerrainMeshWorker";
import { Chunk } from "./chunk";
import { StringList } from "./StringList";
import { loopForTime, numberOfVoxelsInSphere } from "./util";

const PROFILE = 0;
const PROFILE_QUEUES = 0;

const defaultOptions = {
  chunkSize: 24,
  chunkAddDistance: 3,
  chunkRemoveDistance: 4,
  worldGenWhilePaused: true,
};

const USE_WORKER = true;

/**
 * @class
 * @typicalname noa.world
 * @emits worldDataNeeded(id, ndarray, x, y, z, worldName)
 * @emits chunkAdded(chunk)
 * @emits chunkBeingRemoved(id, ndarray, userData)
 * @classdesc Manages the world and its chunks
 *
 * Extends `EventEmitter`
 */

export class World extends EventEmitter {
  constructor(noa, opts) {
    super();
    this.noa = noa;
    opts = Object.assign({}, defaultOptions, opts);

    this.playerChunkLoaded = false;
    this.Chunk = Chunk;

    this.chunkSize = opts.chunkSize;
    this.chunkAddDistance = opts.chunkAddDistance;
    this.chunkRemoveDistance = opts.chunkRemoveDistance;

    if (this.chunkRemoveDistance < this.chunkAddDistance) {
      this.chunkRemoveDistance = this.chunkAddDistance;
    }

    // set this higher to cause chunks not to mesh until they have some neighbors
    this.minNeighborsToMesh = 6;

    // settings for tuning worldgen behavior and throughput
    this.maxChunksPendingCreation = 10;
    this.maxChunksPendingMeshing = 10;
    this.maxProcessingPerTick = 9; // ms
    this.maxProcessingPerRender = 5; // ms
    this.worldGenWhilePaused = opts.worldGenWhilePaused;

    // set up internal state
    this._cachedWorldName = "";
    this._lastPlayerChunkID = "";
    this._chunkStorage = {};
    initChunkQueues(this);
    initChunkStorage(this);

    // instantiate coord conversion functions based on the chunk size
    // use bit twiddling if chunk size is a power of 2
    const cs = this.chunkSize;
    if ((cs & (cs - 1)) === 0) {
      const shift = Math.log2(cs) | 0;
      const mask = (cs - 1) | 0;
      this._worldCoordToChunkCoord = (coord) => (coord >> shift) | 0;
      this._worldCoordToChunkIndex = (coord) => (coord & mask) | 0;
    } else {
      this._worldCoordToChunkCoord = (coord) => Math.floor(coord / cs) | 0;
      this._worldCoordToChunkIndex = (coord) => ((coord % cs) + cs) % cs | 0;
    }
  }

  /*
   *
   *
   *
   *
   *                  PUBLIC API
   *
   *
   *
   *
   */

  /** @param x,y,z */
  getBlockID(x, y, z) {
    const chunk = this._getChunkByCoords(x, y, z);
    if (!chunk) return 0;
    return chunk.get(
      this._worldCoordToChunkIndex(x),
      this._worldCoordToChunkIndex(y),
      this._worldCoordToChunkIndex(z)
    );
  }

  /** @param x,y,z */
  getBlockSolidity(x, y, z) {
    const chunk = this._getChunkByCoords(x, y, z);
    if (!chunk) return false;
    return !!chunk.getSolidityAt(
      this._worldCoordToChunkIndex(x),
      this._worldCoordToChunkIndex(y),
      this._worldCoordToChunkIndex(z)
    );
  }

  /** @param x,y,z */
  getBlockOpacity(x, y, z) {
    const id = this.getBlockID(x, y, z);
    return this.noa.registry.getBlockOpacity(id);
  }

  /** @param x,y,z */
  getBlockFluidity(x, y, z) {
    const id = this.getBlockID(x, y, z);
    return this.noa.registry.getBlockFluidity(id);
  }

  /** @param x,y,z */
  getBlockProperties(x, y, z) {
    const id = this.getBlockID(x, y, z);
    return this.noa.registry.getBlockProps(id);
  }

  /** @param x,y,z */
  getBlockObjectMesh(x, y, z) {
    const chunk = this._getChunkByCoords(x, y, z);
    if (!chunk) return 0;
    return chunk.getObjectMeshAt(
      this._worldCoordToChunkIndex(x),
      this._worldCoordToChunkIndex(y),
      this._worldCoordToChunkIndex(z)
    );
  }

  /** @param x,y,z */
  setBlockID(val, x, y, z) {
    const i = this._worldCoordToChunkCoord(x);
    const j = this._worldCoordToChunkCoord(y);
    const k = this._worldCoordToChunkCoord(z);
    const ix = this._worldCoordToChunkIndex(x);
    const iy = this._worldCoordToChunkIndex(y);
    const iz = this._worldCoordToChunkIndex(z);

    // logic inside the chunk will trigger a remesh for chunk and
    // any neighbors that need it
    const chunk = this._getChunk(i, j, k);
    if (chunk) chunk.set(ix, iy, iz, val);
  }

  /** @param x,y,z */
  isBoxUnobstructed(box) {
    const base = box.base;
    const max = box.max;
    for (let i = Math.floor(base[0]); i < max[0] + 1; i++) {
      for (let j = Math.floor(base[1]); j < max[1] + 1; j++) {
        for (let k = Math.floor(base[2]); k < max[2] + 1; k++) {
          if (this.getBlockSolidity(i, j, k)) return false;
        }
      }
    }
    return true;
  }

  /** client should call this after creating a chunk's worth of data (as an ndarray)
   * If userData is passed in it will be attached to the chunk
   * @param id
   * @param array
   * @param userData
   */
  setChunkData(id, array) {
    this.setMeshedChunk(id, array);
  }

  handleChunkNeighborUpdate = (list, chunk) => {
    for (let nab of list) {
      if (!nab || nab === chunk) return;
      if (nab._neighborCount > 20) queueChunkForRemesh(this, nab);
    }
  };

  setMeshedChunk(reqID, voxels) {
    const world = this;
    const arr = new Int32Array(reqID.split("|"));
    const i = arr[0];
    const j = arr[1];
    const k = arr[2];
    const worldName = arr[3];
    const id = getChunkID(i, j, k);
    world._chunkIDsPending.remove(id);

    // discard data if it's for a world that's no longer current
    if (worldName !== world.noa.worldName) return;
    // discard if chunk is no longer needed
    if (!world._chunkIDsKnown.includes(id)) return;
    if (world._chunkIDsToRemove.includes(id)) return;
    let chunk = world._chunkStorage[id];

    const playerPos = world.noa.ents.getPosition(world.noa.playerEntity);

    const playerX = world._worldCoordToChunkCoord(playerPos[0]);
    const playerY = world._worldCoordToChunkCoord(playerPos[1]);
    const playerZ = world._worldCoordToChunkCoord(playerPos[2]);

    if (playerX === i && playerY === j && playerZ === k)
      [(world.playerChunkLoaded = true)];

    if (!voxels && chunk) {
      const list = chunk._neighbors.data;
      world.handleChunkNeighborUpdate(list, chunk);
      world._chunkIDsToRemove.add(id);
      return;
    } else if (!voxels && !chunk) {
      clearNeighbors(world, i, j, k);
      // world._chunkIDsKnown.remove(id);
      // world._chunkIDsToRequest.remove(id);
      world._chunkIDsPending.remove(id);
      world._chunkIDsToMesh.remove(id);
      world._chunkIDsToMeshFirst.remove(id);

      return;
    } else if (!chunk) {
      // if chunk doesn't exist, create and init
      const size = world.chunkSize;
      chunk = new Chunk(world.noa, id, i, j, k, size, voxels, null, null);
      world._setChunk(i, j, k, chunk);
      chunk.requestID = reqID;

      updateNeighborsOfChunk(world, i, j, k, chunk);
    } else {
      chunk._updateVoxelArray(voxels, false, true);
      // world._chunkIDsToMesh.remove(chunk.id);
      // assume neighbors need remeshing
      const list = chunk._neighbors.data;
      world.handleChunkNeighborUpdate(list, chunk);
    }

    queueChunkForRemesh(world, chunk);
    profile_queues_hook("receive");
  }

  handleWorkerMesh(id, mesh) {
    const chunk = this._chunkStorage[id];
    this._chunkIDsMeshing.remove(id);
    if (!chunk) {
      return;
    }

    chunk.updateMeshesWithResults(mesh);
  }

  /** Tells noa to discard voxel data within a given `AABB` (e.g. because
   * the game client received updated data from a server).
   * The engine will mark all affected chunks for disposal, and will later emit
   * new `worldDataNeeded` events (if the chunk is still in draw range).
   * Note that chunks invalidated this way will not emit a `chunkBeingRemoved` event
   * for the client to save data from.
   */
  invalidateVoxelsInAABB(box) {
    invalidateChunksInBox(this, box);
  }

  /*
   *
   *
   *
   *                  internals:
   *
   *          tick functions that process queues and trigger events
   *
   *
   *
   */

  tick() {
    const tickStartTime = performance.now();

    // if world has changed, mark everything to be removed and re-requested
    if (this._cachedWorldName !== this.noa.worldName) {
      markAllChunksForRemoval(this);
      this._cachedWorldName = this.noa.worldName;
    }

    // current player chunk changed since last tick?
    const pos = getPlayerChunkCoords(this);
    const chunkID = getChunkID(pos[0], pos[1], pos[2]);
    const changedChunks = chunkID != this._lastPlayerChunkID;
    if (changedChunks) {
      this.emit("playerEnteredChunk", pos[0], pos[1], pos[2]);
      this._lastPlayerChunkID = chunkID;
    }
    profile_hook("start");
    profile_queues_hook("start");

    // possibly scan for chunks to add/remove
    if (changedChunks) {
      findDistantChunksToRemove(this, pos[0], pos[1], pos[2]);
      profile_hook("remQueue");
    }
    const numChunks = numberOfVoxelsInSphere(this.chunkAddDistance);
    if (changedChunks || this._chunkIDsKnown.count() < numChunks) {
      findNewChunksInRange(this, pos[0], pos[1], pos[2]);
      profile_hook("addQueue");
    }

    // process (create or mesh) some chunks, up to max iteration time
    loopForTime(this.maxProcessingPerTick, this.loopForMeshTick, tickStartTime);

    // when time is left over, look for low-priority extra meshing
    const dt = performance.now() - tickStartTime;
    if (dt + 2 < this.maxProcessingPerTick) {
      lookForChunksToMesh(this);
      profile_hook("looking");
      loopForTime(
        this.maxProcessingPerTick,
        this.loopForExtraMeshTick,
        tickStartTime,
        true
      );
    }

    profile_queues_hook("end", this);
    profile_hook("end");
  }

  loopForExtraMeshTick = () => {
    const done = processMeshingQueue(this, false);
    profile_hook("meshes");
    return done;
  };

  loopForMeshTick = () => {
    let done = processRequestQueue(this);
    profile_hook("requests");
    done = done && processRemoveQueue(this);
    profile_hook("removes");
    done = done && processMeshingQueue(this, false);
    profile_hook("meshes");
    return done;
  };

  render() {
    // on render, quickly process the high-priority meshing queue
    // to help avoid flashes of background while neighboring chunks update
    loopForTime(this.maxProcessingPerRender, this.loopForMeshingQueue);
  }

  loopForMeshingQueue = () => {
    return processMeshingQueue(this, true);
  };

  /*
   *
   *
   *
   *
   *                  debugging
   *
   *
   *
   *
   */

  report() {
    console.log("World report - playerChunkLoaded: ", this.playerChunkLoaded);
    _report(this, "  known:     ", this._chunkIDsKnown.arr, true);
    _report(this, "  to request:", this._chunkIDsToRequest.arr);
    _report(this, "  to remove: ", this._chunkIDsToRemove.arr);
    _report(this, "  creating:  ", this._chunkIDsPending.arr);
    _report(
      this,
      "  to mesh:   ",
      this._chunkIDsToMesh.arr.concat(this._chunkIDsToMeshFirst.arr)
    );
  }
}

/*
 *
 *
 *
 *              chunk IDs, storage, and lookup/retrieval
 *
 *
 *
 */

function getChunkID(i, j, k) {
  return ChunkID.encode(i, j, k);
  // // chunk coords -> canonical string ID
  // const id = Symbol.for(`${i}|${j}|${k}`);

  // return id;
}

function parseChunkID(id) {
  // chunk ID -> coords
  return ChunkID.decode(id);
  // const idParts = Symbol.keyFor(id).split("|");

  // const chunkID = new Int32Array(idParts.length);
  // chunkID[0] = parseInt(idParts[0], 10);
  // chunkID[1] = parseInt(idParts[1], 10);
  // chunkID[2] = parseInt(idParts[2], 10);

  // return chunkID;
}

function initChunkStorage(world) {
  // var chunkHash = ndHash([1024, 1024, 1024])
  world._getChunk = (i, j, k) => {
    const id = getChunkID(i, j, k);
    return world._chunkStorage[id] || null;
  };
  world._setChunk = (i, j, k, value) => {
    const id = getChunkID(i, j, k);
    if (value) {
      world._chunkStorage[id] = value;
    } else {
      delete world._chunkStorage[id];
    }
  };
  // chunk accessor for internal use
  world._getChunkByCoords = (x, y, z) => {
    const i = world._worldCoordToChunkCoord(x);
    const j = world._worldCoordToChunkCoord(y);
    const k = world._worldCoordToChunkCoord(z);
    return world._getChunk(i, j, k);
  };
}

function getPlayerChunkCoords(world) {
  const pos = world.noa.entities.getPosition(world.noa.playerEntity);
  const i = world._worldCoordToChunkCoord(pos[0]);
  const j = world._worldCoordToChunkCoord(pos[1]);
  const k = world._worldCoordToChunkCoord(pos[2]);
  return [i, j, k];
}

/*
 *
 *
 *
 *              chunk queues and queue processing
 *
 *
 *
 */

function initChunkQueues(world) {
  // queue meanings:
  //    Known:        all chunks existing in any queue
  //    ToRequest:    needed but not yet requested from client
  //    Pending:      requested, awaiting creation
  //    ToMesh:       created but not yet meshed
  //    ToMeshFirst:  priority meshing queue
  //    ToRemove:     chunks awaiting disposal
  world._chunkIDsKnown = new StringList();
  world._chunkIDsToRequest = new StringList();
  world._chunkIDsPending = new StringList();
  world._chunkIDsMeshing = new StringList();
  world._chunkIDsToMesh = new StringList();
  world._chunkIDsToMeshFirst = new StringList();
  world._chunkIDsToRemove = new StringList();
  // accessor for chunks to queue themselves for remeshing
  world._queueChunkForRemesh = (chunk) => {
    queueChunkForRemesh(world, chunk);
  };
}

// process neighborhood chunks, add missing ones to "toRequest" and "inMemory"
function findNewChunksInRange(
  { chunkAddDistance, _chunkIDsKnown, _chunkIDsToRequest },
  ci,
  cj,
  ck
) {
  const add = Math.ceil(chunkAddDistance);
  const addDistSq = chunkAddDistance * chunkAddDistance;
  const known = _chunkIDsKnown;
  const toRequest = _chunkIDsToRequest;
  // search all nearby chunk locations
  for (let i = ci - add; i <= ci + add; ++i) {
    for (let j = cj - add; j <= cj + add; ++j) {
      for (let k = ck - add; k <= ck + add; ++k) {
        const id = getChunkID(i, j, k);
        if (known.includes(id)) continue;
        const di = i - ci;
        const dj = j - cj;
        const dk = k - ck;
        const distSq = di * di + dj * dj + dk * dk;
        if (distSq > addDistSq) continue;
        known.add(id);
        toRequest.add(id);
      }
    }
  }
  sortIDListByDistanceFrom(toRequest, ci, cj, ck);
}

// rebuild queue of chunks to be removed from around (ci,cj,ck)
function findDistantChunksToRemove(world, ci, cj, ck) {
  const remDistSq = world.chunkRemoveDistance * world.chunkRemoveDistance;
  const toRemove = world._chunkIDsToRemove;

  for (let i = 0; i < world._chunkIDsKnown.count(); i++) {
    const id = world._chunkIDsKnown.arr[i];

    if (toRemove.includes(id)) continue;
    const loc = parseChunkID(id);
    const di = loc[0] - ci;
    const dj = loc[1] - cj;
    const dk = loc[2] - ck;
    const distSq = di * di + dj * dj + dk * dk;
    if (distSq < remDistSq) continue;
    // flag chunk for removal and remove it from work queues
    world._chunkIDsToRemove.add(id);
    world._chunkIDsToRequest.remove(id);
    world._chunkIDsToMesh.remove(id);
    world._chunkIDsToMeshFirst.remove(id);
  }

  sortIDListByDistanceFrom(toRemove, ci, cj, ck);
}

// invalidate chunks overlapping the given AABB
function invalidateChunksInBox(world, box) {
  const min = box.base.map((n) => Math.floor(world._worldCoordToChunkCoord(n)));
  const max = box.max.map((n) => Math.floor(world._worldCoordToChunkCoord(n)));
  world._chunkIDsKnown.forEach((id) => {
    const pos = parseChunkID(id);
    for (let i = 0; i < 3; i++) {
      if (pos[i] < min[i] || pos[i] > max[i]) return;
    }
    if (world._chunkIDsToRemove.includes(id)) return;
    world._chunkIDsToRequest.add(id);
  });
}

// when current world changes - empty work queues and mark all for removal
function markAllChunksForRemoval(world) {
  world._chunkIDsToRemove.copyFrom(world._chunkIDsKnown);
  world._chunkIDsToRequest.empty();
  world._chunkIDsToMesh.empty();
  world._chunkIDsToMeshFirst.empty();
  const loc = getPlayerChunkCoords(world);
  sortIDListByDistanceFrom(world._chunkIDsToRemove, loc[0], loc[1], loc[2]);
}

// incrementally look for chunks that could stand to be re-meshed
function lookForChunksToMesh(world) {
  const queue = world._chunkIDsKnown.arr;
  const ct = Math.min(50, queue.length);
  let numQueued =
    world._chunkIDsToMesh.count() + world._chunkIDsToMeshFirst.count();
  for (let i = 0; i < ct; i++) {
    lookIndex = (lookIndex + 1) % queue.length;
    const id = queue[lookIndex];
    const chunk = world._chunkStorage[id];
    if (!chunk) continue;
    const nc = chunk._neighborCount;
    if (nc < world.minNeighborsToMesh) continue;
    if (nc <= chunk._maxMeshedNeighbors) continue;
    queueChunkForRemesh(world, chunk);
    if (++numQueued > 10) return;
  }
}
var lookIndex = -1;

// run through chunk tracking queues looking for work to do next
function processRequestQueue(world) {
  const toRequest = world._chunkIDsToRequest;
  if (toRequest.isEmpty()) return true;
  // skip if too many outstanding requests, or if meshing queue is full
  const pending = world._chunkIDsPending.count();
  const toMesh = world._chunkIDsToMesh.count();
  if (pending >= world.maxChunksPendingCreation) return true;
  if (toMesh >= world.maxChunksPendingMeshing) return true;
  const id = toRequest.pop();
  requestNewChunk(world, id);
  return toRequest.isEmpty();
}

function processRemoveQueue(world) {
  const toRemove = world._chunkIDsToRemove;
  if (toRemove.isEmpty()) return true;
  removeChunk(world, toRemove.pop());
  return toRemove.isEmpty();
}

// similar to above but for chunks waiting to be meshed
function processMeshingQueue(world, firstOnly) {
  let queue = world._chunkIDsToMeshFirst;
  if (queue.isEmpty() && !firstOnly) queue = world._chunkIDsToMesh;
  if (queue.isEmpty()) return true;

  const id = queue.pop();
  if (world._chunkIDsToRemove.includes(id)) return;
  const chunk = world._chunkStorage[id];
  if (chunk) doChunkRemesh(world, chunk);
}

/*
 *
 *
 *
 *              chunk lifecycle - create / set / remove / modify
 *
 *
 *
 */

// create chunk object and request voxel data from client
function requestNewChunk(world, id) {
  const pos = parseChunkID(id);
  const i = pos[0];
  const j = pos[1];
  const k = pos[2];
  const size = world.chunkSize;
  const worldName = world.noa.worldName;
  const requestID = [i, j, k, worldName].join("|");
  const x = i * size;
  const y = j * size;
  const z = k * size;
  world._chunkIDsPending.add(id);
  world.emit("worldDataNeeded", requestID, null, x, y, z, worldName);
  profile_queues_hook("request");
}

// remove a chunk that wound up in the remove queue
function removeChunk(world, id) {
  const loc = parseChunkID(id);
  const chunk = world._getChunk(loc[0], loc[1], loc[2]);
  if (chunk) {
    world.emit(
      "chunkBeingRemoved",
      chunk.requestID,
      chunk.voxels,
      chunk.userData
    );
    world.noa.rendering.disposeChunkForRendering(chunk);
    chunk.dispose();
    profile_queues_hook("dispose");
    updateNeighborsOfChunk(world, loc[0], loc[1], loc[2], null);
  }
  world._setChunk(loc[0], loc[1], loc[2], null);
  world._chunkIDsKnown.remove(id);
  world._chunkIDsToMesh.remove(id);
  world._chunkIDsToMeshFirst.remove(id);
}

function queueChunkForRemesh(
  { minNeighborsToMesh, _chunkIDsToMeshFirst, _chunkIDsToMesh },
  chunk
) {
  const nc = chunk._neighborCount;
  const limit = Math.min(minNeighborsToMesh, 26);
  if (nc < limit) return;
  chunk._terrainDirty = true;
  const queue = nc === 26 ? _chunkIDsToMeshFirst : _chunkIDsToMesh;
  queue.add(chunk.id);
}

function doChunkRemesh(
  { _chunkIDsToMesh, _chunkIDsToMeshFirst, _chunkIDsMeshing },
  chunk
) {
  if (_chunkIDsMeshing.includes(chunk.id)) {
    return;
  }

  _chunkIDsToMesh.remove(chunk.id);
  _chunkIDsToMeshFirst.remove(chunk.id);
  _chunkIDsMeshing.add(chunk.id);

  if (USE_WORKER) {
    meshChunk(chunk);
  } else {
    chunk.updateMeshes();
  }

  profile_queues_hook("mesh");
}

/*
 *
 *
 *
 *          misc helpers and implementation functions
 *
 *
 *
 */

function sortIDListByDistanceFrom(list, i, j, k) {
  list.sort((id) => {
    const pos = parseChunkID(id);
    const dx = pos[0] - i;
    const dy = pos[1] - j;
    const dz = pos[2] - k;
    // bias towards keeping verticals together for now
    return dx * dx + dz * dz + Math.abs(dy);
  });
}

// keep neighbor data updated when chunk is added or removed
function updateNeighborsOfChunk(world, ci, cj, ck, chunk) {
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      for (let k = -1; k <= 1; k++) {
        if ((i | j | k) === 0) continue;
        const nid = getChunkID(ci + i, cj + j, ck + k);
        const neighbor = world._chunkStorage[nid];
        if (!neighbor) continue;
        if (chunk) {
          chunk._neighborCount++;
          chunk._neighbors.set(i, j, k, neighbor);
          neighbor._neighborCount++;
          neighbor._neighbors.set(-i, -j, -k, chunk);
          // flag for remesh when chunk gets its last neighbor
          if (neighbor._neighborCount === 26) {
            queueChunkForRemesh(world, neighbor);
          }
        } else {
          neighbor._neighborCount--;
          neighbor._neighbors.set(-i, -j, -k, null);
        }
      }
    }
  }
}

// keep neighbor data updated when chunk is added or removed
function clearNeighbors(world, ci, cj, ck) {
  for (let i = -1; i <= 1; i++) {
    for (let j = -1; j <= 1; j++) {
      for (let k = -1; k <= 1; k++) {
        if ((i | j | k) === 0) continue;
        const nid = getChunkID(ci + i, cj + j, ck + k);
        const neighbor = world._chunkStorage[nid];
        if (!neighbor) continue;

        neighbor._neighborCount++;

        // flag for remesh when chunk gets its last neighbor
        if (neighbor._neighborCount === 26) {
          queueChunkForRemesh(world, neighbor);
        }

        neighbor._neighbors.set(-i, -j, -k, null);
      }
    }
  }
}

function _report({ _chunkStorage }, name, arr, ext) {
  let full = 0;
  let empty = 0;
  let exist = 0;
  let surrounded = 0;
  const remeshes = [];
  arr.forEach((id) => {
    const chunk = _chunkStorage[id];
    if (!chunk) return;
    exist++;
    remeshes.push(chunk._timesMeshed);
    if (chunk.isFull) full++;
    if (chunk.isEmpty) empty++;
    if (chunk._neighborCount === 26) surrounded++;
  });
  let out = arr.length.toString().padEnd(8);
  out += `exist: ${exist}`.padEnd(12);
  out += `full: ${full}`.padEnd(12);
  out += `empty: ${empty}`.padEnd(12);
  out += `surr: ${surrounded}`.padEnd(12);
  if (ext) {
    const sum = remeshes.reduce((acc, val) => acc + val, 0);
    const max = remeshes.reduce((acc, val) => Math.max(acc, val), 0);
    const min = remeshes.reduce((acc, val) => Math.min(acc, val), 0);
    out += `times meshed: avg ${(sum / exist).toFixed(2)}`;
    out += `  max ${max}`;
    out += `  min ${min}`;
  }
  console.log(name, out);
}

var profile_hook = PROFILE ? makeProfileHook1(100, "world ticks:") : () => {};
var profile_queues_hook = () => {};
if (PROFILE_QUEUES)
  profile_queues_hook = ((every) => {
    let iter = 0;
    let counts = {};
    let queues = {};
    let started = performance.now();
    return function profile_queues_hook(state, world) {
      if (state === "start") return;
      if (state !== "end") return (counts[state] = (counts[state] || 0) + 1);
      queues.toreq = (queues.toreq || 0) + world._chunkIDsToRequest.count();
      queues.toget = (queues.toget || 0) + world._chunkIDsPending.count();
      queues.tomesh =
        (queues.tomesh || 0) +
        world._chunkIDsToMesh.count() +
        world._chunkIDsToMeshFirst.count();
      queues.tomesh1 =
        (queues.tomesh1 || 0) + world._chunkIDsToMeshFirst.count();
      queues.torem = (queues.torem || 0) + world._chunkIDsToRemove.count();
      if (++iter < every) return;
      const t = performance.now();
      const dt = t - started;
      const res = {};
      Object.keys(queues).forEach((k) => {
        const num = Math.round((queues[k] || 0) / iter);
        res[k] = `[${num}]`.padStart(5);
      });
      Object.keys(counts).forEach((k) => {
        const num = Math.round(((counts[k] || 0) * 1000) / dt);
        res[k] = `${num}`.padStart(3);
      });
      console.log(
        "chunk flow: ",
        `${res.toreq}-> ${res.request} req/s  `,
        `${res.toget}-> ${res.receive} got/s  `,
        `${res.tomesh}-> ${res.mesh} mesh/s  `,
        `${res.torem}-> ${res.dispose} rem/s  `,
        `(meshFirst: ${res.tomesh1.trim()})`
      );
      iter = 0;
      counts = {};
      queues = {};
      started = performance.now();
    };
  })(100);

export default World;
