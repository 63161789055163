import {
  AnimationGroup,
  Color3,
  Engine,
  Mesh,
  Scene,
  SceneLoader,
  Skeleton,
  StandardMaterial,
  Texture,
  Vector3,
} from "@babylonjs/core";
import { getClearColor } from "../Player/PlayerMesh";
import {
  AvatarAnimationMap,
  BaseAvatar,
  normalizeColor,
  normalizeTextureURL,
} from "./BaseAvatar";

const animations: AvatarAnimationMap = {
  _default: { from: 0, to: 3 },
  dance: { from: 14, to: 503 },
  gangnam: { from: 818, to: 1191 },
  run: { from: 517, to: 546 },
  idle: { from: 560, to: 810 },
};

let _skeleton: Skeleton;
let _mesh: Mesh;
let _meshes: Array<Mesh> = [];
let _animationGroups: Array<AnimationGroup>;
const _loadPlayerMesh = async (
  scene: Scene,
  insertIntoScene: boolean,
  engine?: Engine
) => {
  let meshes, skeletons, animationGroups;
  let _scene = scene;

  if (insertIntoScene) {
    const content = await SceneLoader.ImportMeshAsync(
      null,
      "/skins/rickastley/",
      "skin.glb",
      scene
    );

    meshes = content.meshes;
    skeletons = content.skeletons;
    animationGroups = content.animationGroups;
  } else {
    SceneLoader.ShowLoadingScreen = false;
    const scene = await SceneLoader.LoadAsync(
      "/skins/rickastley/",
      "skin.glb",
      engine
    );
    _scene = scene;
    meshes = scene.meshes;
    skeletons = scene.skeletons;
    animationGroups = scene.animationGroups;
  }

  const skeleton = skeletons[0];
  const mesh = meshes[0];
  meshes.forEach((mesh) => mesh.position.setAll(0));

  const material: StandardMaterial = meshes.filter((mesh) => mesh.material);

  material.diffuseColor = RickAstley.defaultBackgroundColor;

  return {
    skeleton,
    meshes,
    scene: _scene,
    material,
    mesh,
    animationGroups,
  };
};

const loadPlayerMesh = async (scene, id, insertIntoScene: boolean, engine) => {
  let _scene = scene;
  let material;

  if (!_skeleton || !_mesh || !_animationGroups) {
    const content = await _loadPlayerMesh(scene, insertIntoScene, engine);
    _skeleton = content.skeleton;
    _mesh = content.mesh;
    _meshes = content.meshes;
    _animationGroups = content.animationGroups;
    _scene = content.scene;
    material = content.material;
  }

  let mesh: Mesh,
    skeleton: Skeleton,
    meshes: Array<Mesh>,
    animationGroups: Array<AnimationGroup>;

  // if (insertIntoScene) {
  //   skeleton = _skeleton.clone("skeleton-" + id);
  //   meshes = _meshes.map((mesh, index) => mesh.clone(`player/${id}/${index}`));
  //   mesh = meshes[_meshes.indexOf(_mesh)];
  //   mesh.material = mesh.material.clone("mat-" + id);
  //   animationGroups = _animationGroups.map((animation) =>
  //     animation.clone(`player/${id}/animations-${animation.name}`, () => mesh)
  //   );
  // } else {
  skeleton = _skeleton;
  mesh = _mesh;

  meshes = _meshes;
  animationGroups = _animationGroups;
  meshes.forEach((mesh) => mesh.absoluteScaling.set(0.5, 0.5, 0.5));
  // }

  mesh.skeleton = skeleton;
  mesh.position.setAll(0);

  skeleton.enableBlending(0.1);

  return {
    mesh,
    material,
    meshes,
    skeleton,
    scene: _scene,
    animationGroups,
  };
};

export enum RickAstleySkinStyle {
  metallic = "metallic",
}

export class RickAstley extends BaseAvatar {
  constructor(scene, id) {
    super(scene, id);
  }

  static defaultBackgroundColor = new Color3(1, 1, 1);
  material: StandardMaterial;
  texture: Texture;
  static _animations = animations;
  backgroundImage: string = null;
  backgroundColor = new Color3(1, 1, 1);
  skinColor = new Color3(1, 1, 1);

  setBackgroundImage = (url: string) => {
    this.backgroundImage = normalizeTextureURL(url);

    if (!this.material) {
      return;
    }

    if (!url) {
      this.material.ambientTexture = null;
      return;
    }

    const ambientTexture = new Texture(url, this.scene);
    // const ambientTexture = new Texture(null, scene);

    ambientTexture.hasAlpha = false;

    ambientTexture.uScale = 2;
    ambientTexture.vScale = 2;

    this.material.ambientTexture = ambientTexture;
    this.material.ambientTexture.wrapU = Texture.MIRROR_ADDRESSMODE;
    this.material.ambientTexture.wrapV = Texture.MIRROR_ADDRESSMODE;
  };

  static _skinStyleMapping = {
    [RickAstleySkinStyle.metallic]: {
      thumbnail: "/skins/rickastley/texture.jpg",
      url: "/skins/rickastley/texture.jpg",
    },
  };

  protected static _skinStyles = RickAstleySkinStyle;

  protected static _skinStyleURL(skinStyle: RickAstleySkinStyle) {
    return this._skinStyleMapping[skinStyle]?.url;
  }

  _reset = () => {
    if (this.skinStyle) {
      this.setSkinStyle(this.skinStyle);
    }

    if (this.backgroundColor) {
      this.setBackgroundColor(this.backgroundColor);
    }

    if (this.backgroundImage) {
      this.setBackgroundImage(this.backgroundImage);
    }

    if (this.skinColor) {
      this.setSkinColor(this.skinColor);
    }
  };

  setSkinStyle = (skinStyle: string) => {
    this.skinStyle =
      RickAstleySkinStyle[skinStyle || RickAstley.defaultSkinStyle] ||
      RickAstley.defaultSkinStyle;

    if (!this.material) {
      return;
    }

    // const url = this.constructor.skinStyleURL(skinStyle);
    // if (url && this.material.diffuseTexture?.url !== url) {
    //   const texture = new Texture(url, this.scene);
    //   if (this.material.diffuseTexture) {
    //     this.material.diffuseTexture.dispose();
    //   }
    //   this.material.diffuseTexture = texture;
    //   this.texture = texture;
    // }
  };

  setSkinColor = (_color: Color3) => {
    const color = normalizeColor(_color);

    this.skinColor = color;
    if (!this.material) {
      return;
    }

    if (color) {
      this.material.ambientColor = color;
    } else {
      this.material.ambientColor = getClearColor(this.scene);
    }
  };

  setBackgroundColor = (_color: Color3) => {
    const color = normalizeColor(_color);

    this.backgroundColor = color;

    if (!this.material) {
      return;
    }

    if (color) {
      this.material.diffuseColor = color;
    } else {
      this.material.diffuseColor = RickAstley.defaultBackgroundColor;
    }
  };

  _rotation = Vector3.Zero();

  static headBoneIndex;

  private static _defaultSkinStyle = RickAstleySkinStyle.metallic;
  skinStyle = RickAstleySkinStyle.metallic;

  _load = async (id: string, insertIntoScene: boolean, engine) => {
    const {
      mesh,
      skeleton,
      animationGroups,
      material,
      meshes,
      scene,
    } = await loadPlayerMesh(this.scene, this.id, insertIntoScene, engine);

    this.scene = scene;
    this._meshes = meshes;
    this._skeleton = skeleton;
    this._mesh = mesh;
    this._animationGroups = animationGroups;

    this.material = material;
    if (insertIntoScene) {
      this._mesh.scaling.set(1, 1, -1);
    }

    this._mesh.rotation.copyFrom(this._rotation);
    this._rotation = this.mesh.rotation;
    if (typeof RickAstley.headBoneIndex !== "number") {
      RickAstley.headBoneIndex = this.skeleton.getBoneIndexByName("head");
    }

    this._headBone = this.skeleton.bones[RickAstley.headBoneIndex];

    this.texture = this.material.diffuseTexture;
  };
}
