import Dexie from "dexie";
import { CachedMapTile } from "lib/Data/WorldMap/CachedMapTile";

export class BaseData extends Dexie {
  mapTilesTable: Dexie.Table<CachedMapTile, any>;

  constructor(name = `talkingheads-${process.env.NODE_ENV}`, autoOpen = false) {
    super(name, { autoOpen });

    this.version(6)
      .stores({
        inventorySlots: "++id",
        imageUploads: "src",
        mapTiles: "id",
      })
      .upgrade((trans) => trans);

    this.mapTilesTable = this.table("mapTiles");
    this.mapTilesTable.mapToClass(CachedMapTile);
  }
}
