import {
  Engine,
  int,
  RenderTargetTexture,
  ShadowGenerator,
  VideoTexture,
} from "@babylonjs/core";
import { AdvancedDynamicTexture, Button } from "@babylonjs/gui";
import * as Sentry from "@sentry/node";
import EventEmitter from "eventemitter3";
import { AvatarClassType } from "game/Player/AvatarClassType";
import { Nameplate } from "game/Player/Nameplate";
import PlayerMesh, {
  AnimationState,
  SoundEffect,
} from "game/Player/PlayerMesh";
import { PlayerControl } from "game/textures/PlayerControl";
import Vec3 from "gl-vec3";
import NoaEngine from "noa-engine";
import PlayerCharacter from "shared/entity/PlayerCharacter";
import { normalizeColor, normalizeTextureURL } from "./Avatar/BaseAvatar";
import { PlayerSpawnStatus } from "./PlayerSpawnStatus";

let _movementAccessor;

export class Player {
  video: HTMLVideoElement;
  remoteEntityId: string;
  localEntityId: string;
  remoteEntity: PlayerCharacter;
  id: string;
  username: string;
  noa: NoaEngine;

  constructor(
    id: string,
    username: string,
    noa,
    remoteEntityId: string,
    localEntityId: string,
    remoteEntity: PlayerCharacter
  ) {
    this.id = id;
    this.username = username;
    this.remoteEntityId = remoteEntityId;
    this.localEntityId = localEntityId;
    this.remoteEntity = remoteEntity;

    if (remoteEntity) {
      this.spawnPoint[0] = remoteEntity.x;
      this.spawnPoint[1] = remoteEntity.y;
      this.spawnPoint[2] = remoteEntity.z;
    }

    if (noa) {
      this.meshContainer = new PlayerMesh({
        scene: noa.rendering.getScene(),
        isCurrentPlayer: this.isCurrentPlayer,
        id: this.id,
        AvatarType: AvatarClassType[remoteEntity.avatarType],
      });

      if (remoteEntity) {
        this.meshContainer.avatar.backgroundColor = normalizeColor(
          remoteEntity.color
        );
        this.meshContainer.avatar.backgroundImage = normalizeTextureURL(
          remoteEntity.backgroundImageURL
        );
        this.meshContainer.avatar.skinStyle = remoteEntity.skinStyle || null;
      }
    }

    this.noa = noa;
  }

  static size = {
    height: 1.5,
    width: 0.6,
  };

  meshContainer: PlayerMesh;
  spawnStatus = PlayerSpawnStatus.waiting;
  canSpawn = () => {};

  _movement;

  static defaultMovement = {
    // current state
    heading: 0, // radians
    running: false,
    jumping: false,

    // options:
    maxSpeed: 2,
    moveForce: 30,
    responsiveness: 15,
    runningFriction: 0,
    standingFriction: 2,

    airMoveMult: 0.5,
    jumpImpulse: 10,
    jumpForce: 12,
    jumpTime: 500, // ms
    airJumps: 1,

    // internal state
    _jumpCount: 0,
    _isJumping: 0,
    _currjumptime: 0,
  };

  get isCurrentPlayer() {
    return false;
  }

  respawn = () => {
    if (this.spawnStatus === PlayerSpawnStatus.spawning) {
      this.emitter.once(PlayerSpawnStatus.spawned, () => {
        this.respawn();
      });
    } else if (this.spawnStatus === PlayerSpawnStatus.spawned) {
      let sound = this.meshContainer?.sound;
      this.despawn(false);
      return this.spawn(this.remoteEntity, null).then(() => {
        this.meshContainer.sound = sound;
      });
    } else if (this.spawnStatus === PlayerSpawnStatus.despawn) {
    } else if (this.spawnStatus === PlayerSpawnStatus.waiting) {
    }
  };

  despawn(removeStream: boolean = false) {
    if (this.nameplate) {
      this.nameplate.dispose();
      this.nameplate = null;
    }

    if (this.meshContainer) {
      this.meshContainer.player = null;
      this.meshContainer.dispose(removeStream);
      this.meshContainer = null;
    }

    if (this.localEntityId) {
      this.noa.ents.deleteEntity(this.localEntityId);
    }

    this.spawnStatus = PlayerSpawnStatus.despawn;
  }

  emitter = new EventEmitter();
  renderTarget: RenderTargetTexture;

  async _spawn({ x, y, z, pitch, yaw, heading }, cameraMode) {
    const { width, height } = Player.size;
    const { noa } = this;
    this.spawnStatus = PlayerSpawnStatus.spawning;

    if (!_movementAccessor) {
      _movementAccessor = this.noa.ents.getStateAccessor("movement");
    }

    if (!this.meshContainer) {
      this.meshContainer = new PlayerMesh({
        scene: noa.rendering.getScene(),
        AvatarType: AvatarClassType[this.remoteEntity.avatarType],
      });
    }
    const meshContainer = this.meshContainer;

    await meshContainer.load(this.id, true, false);

    if (this.remoteEntity) {
      meshContainer.avatar.setSkinStyle(this.remoteEntity.skinStyle);
      meshContainer.avatar.setBackgroundColor(this.remoteEntity.color);
      meshContainer.avatar.setBackgroundImage(
        this.remoteEntity.backgroundImageURL
      );
    }

    const ents = noa.ents;

    if (this.isCurrentPlayer) {
      this.localEntityId = noa.playerEntity;

      const bounds = meshContainer.avatar.mesh.getHierarchyBoundingVectors(
        true
      );

      ents.addComponentAgain(this.localEntityId, "playerMesh", {
        meshes: this.meshContainer.avatar.meshes,
        offset: [0, 0, 0],
      });

      for (let mesh of this.meshContainer.avatar.meshes) {
        mesh.alwaysSelectAsActiveMesh = true;
        mesh.doNotSyncBoundingInfo = true;
      }

      // this.noa.rendering._camera.setTarget(
      //   meshContainer.avatar.parentMesh.position
      // );

      this.noa.ents.addComponentAgain(
        this.localEntityId,
        this.noa.ents.names.collideTerrain
      );
      this.noa.ents.addComponentAgain(
        this.localEntityId,
        this.noa.ents.names.collideEntities,
        {
          cylinder: true,
          collideBits: 1,
          collideMask: 1,
        }
      );
      ents.addComponentAgain(this.localEntityId, ents.names.movement, {
        airJumps: 20,
        heading: this.rotation.y,
      });
      this.noa.camera.heading = this.remoteEntity.yaw;

      this.movement = _movementAccessor(this.localEntityId);

      ents.addComponentAgain(this.localEntityId, "characterRotation", {
        player: this,
      });
      // this.noa.rendering.addMeshToScene(
      //   this.meshContainer.avatar.invisibleBox,
      //   false
      // );

      this.noa.rendering.addMeshToScene(this.meshContainer.avatar.mesh, false);
      meshContainer.avatar.parentMesh._enablePointerMoveEvents = false;
      meshContainer.avatar.mesh._enablePointerMoveEvents = false;
    } else {
      this.localEntityId = noa.ents.add(
        Vec3.fromValues(x, y, z), // starting location
        width,
        height,
        null,
        null,
        false,
        false
      );

      ents.addComponentAgain(this.localEntityId, "playerMesh", {
        meshes: meshContainer.avatar.meshes,
        offset: [0, 0, 0],
      });
      ents.addComponentAgain(this.localEntityId, ents.names.collideTerrain);
      ents.addComponentAgain(this.localEntityId, ents.names.collideEntities);
      // ents.addComponentAgain(this.localEntityId, ents.names.movement, {
      //   airJumps: 3,
      // });
      ents.addComponentAgain(this.localEntityId, "networkMovement");
      ents.addComponentAgain(this.localEntityId, "characterRotation", {
        player: this,
      });
      this.movement = this.noa.ents.getState(
        this.localEntityId,
        "networkMovement"
      );
      this.noa.rendering.addMeshToScene(this.meshContainer.avatar.mesh, false);
      // ents.getState(this.localEntityId, "physics").alwaysSmooth = false;
      if (heading) {
        this.rotation.y = heading;
      }
    }

    // this.shadowGenerator.addShadowCaster(this.meshContainer.mesh, true);
    this.movement.heading = this.remoteEntity.heading;

    this.spawnStatus = PlayerSpawnStatus.spawned;
    this.emitter.emit(this.spawnStatus);

    if (this.isCurrentPlayer) {
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: "spawnSelf",
      });
    } else {
      Sentry.addBreadcrumb({
        level: Sentry.Severity.Info,
        message: "spawn",
        data: {
          id: this.username,
        },
      });
    }
  }

  static hasLightSource = false;
  nameplate: Nameplate;
  shadowGenerator: ShadowGenerator;
  isFirstSpawn = true;
  spawn(entity, cameraMode) {
    return this._spawn(entity, cameraMode).then(() => {
      if (!this.nameplate) {
        this.nameplate = new Nameplate(
          this.noa.rendering.getScene(),
          this.noa,
          this.localEntityId,
          this.remoteEntity.avatarType,
          this.spawnPoint[0],
          this.spawnPoint[1],
          this.spawnPoint[2]
        );
      }
      this.meshContainer.nameplate = this.nameplate;
      this.nameplate.avatar = this.remoteEntity.avatarType;

      // if (!this.isCurrentPlayer) {
      this.nameplate.setupUsername(this.remoteEntity.username);
      // }

      if (!this.isCurrentPlayer) {
        return true;
      }

      Player.hasLightSource = true;

      if (this.stream && this.isFirstSpawn && Engine.audioEngine.unlocked) {
        this.loadStream(this.stream, Engine.audioEngine.audioContext);
      }

      this.isFirstSpawn = false;
    });
  }

  get rotation() {
    return this.meshContainer.rotation;
  }

  set rotation(rotation) {
    this.meshContainer.rotation.copyFrom(rotation);
  }

  static spawnPlayer = async (entity, noa, username: string) => {
    const player = new Player(playerUUID, username, noa, entityId, entity);
    await player.spawn(entity);
    return player;
  };

  activePosition = new Float32Array();
  videoTexture: VideoTexture | null = null;

  connectStream = (video: HTMLVideoElement, stream: MediaStream) => {
    this.stream = stream;
    this.video = video;
    this.materialID = `player-${this.id}`;

    VideoTexture.CreateFromStreamAsync(
      this.noa.rendering.getScene(),
      stream
    ).then((texture) => {
      this.videoTexture = texture;
      if (this.isLoaded) {
        const headTexture = this.meshContainer.playVideo(texture);
        const [x, y, z] = this.noa.entities.getPositionData(
          this.noa.playerEntity
        ).position;
        this.noa.ents.add(
          [x, y, z],
          2.0,
          2.0,
          headTexture,
          [0, 0.25, 0],
          false,
          true
        );
      }

      this.blockID = 10;
    });
  };

  blockID: int;

  texture: VideoTexture;
  materialID: string;
  control: PlayerControl;

  get isRunning() {
    return this.movement && this.movement.running;
  }

  get isOnGround() {
    return this.movement && this.movement.onGround;
  }

  update() {
    if (!this.isLoaded || this.spawnStatus !== PlayerSpawnStatus.spawned) {
      return;
    }

    const animationState = this.determineAnimationState();
    if (animationState != this.meshContainer.animationState) {
      this.meshContainer.animationState = animationState;
      this.meshContainer.avatar.animationTickCount = 0;
    } else {
      this.meshContainer.avatar.animationTickCount += 1;
    }

    let desiredSoundEffect = null;

    if (
      animationState === AnimationState.walk ||
      animationState === AnimationState.run ||
      animationState === AnimationState.runBackards
    ) {
      desiredSoundEffect = SoundEffect.footsteps;
    }

    if (
      desiredSoundEffect !== null &&
      !this.meshContainer.isPlayingSoundEffect(desiredSoundEffect)
    ) {
      this.meshContainer.playSoundEffect(
        desiredSoundEffect,
        !this.isCurrentPlayer,
        !this.isCurrentPlayer,
        desiredSoundEffect === SoundEffect.footsteps,
        !this.isCurrentPlayer
      );
    } else if (desiredSoundEffect === SoundEffect.footsteps) {
      const sound = this.meshContainer.soundEffects.get(desiredSoundEffect);

      if (sound && Engine.audioEngine?.unlocked) {
        sound.setPlaybackRate(animationState === AnimationState.walk ? 1 : 1.5);
      }
    }

    if (
      desiredSoundEffect === null &&
      this.meshContainer.isPlayingSoundEffect(SoundEffect.footsteps)
    ) {
      this.meshContainer.stopSoundEffect(SoundEffect.footsteps);
    }
  }

  stream: MediaStream;

  addStream = (stream: MediaStream, context: AudioContext) => {
    this.stream = stream;

    if (this.meshContainer?.isLoaded && context) {
      this.loadStream(stream, context);
    }
  };

  loadStream(stream: MediaStream, context: AudioContext) {
    this.meshContainer.createSoundFromStream(
      stream,
      context
      // context.createMediaStreamDestination(stream.stream)
    );
  }

  removeStream = (stream: MediaStream) => {
    if (this.meshContainer?.sound) {
      this.meshContainer.sound?.dispose();
      this.meshContainer.audioTag?.remove();
      this.meshContainer.sound = null;
      this.meshContainer.audioTag = null;
    }
  };

  get chunkX() {
    return this.noa.world._worldCoordToChunkCoord(this.position[0]);
  }

  get chunkY() {
    return this.noa.world._worldCoordToChunkCoord(this.position[1]);
  }

  get chunkZ() {
    return this.noa.world._worldCoordToChunkCoord(this.position[2]);
  }

  get isLoaded() {
    return this.meshContainer && this.meshContainer.isLoaded;
  }

  spawnPoint = new Float32Array([0, 0, 0]);

  gui: AdvancedDynamicTexture;
  usernameButton: Button;
  movement: {
    // current state
    heading: number; // radians
    running: boolean;
    moveSpeed: number;
    sprintMoveMult: 2;
    jumping: boolean;

    // options:
    maxSpeed: number;
    moveForce: number;
    responsiveness: number;
    runningFriction: number;
    standingFriction: number;

    airMoveMult: number;
    jumpImpulse: number;
    jumpForce: number;
    jumpTime: number;
    airJumps: number;
  };
  localSpawnPoint = Vec3.create();

  sprintTickCount = 0;
  determineAnimationState() {
    if (!this.localEntityId || !this.isLoaded) {
      return AnimationState.idle;
    }

    const isFalling = this.movement.distance[1] > 0;
    const isMovingVertically = Math.abs(this.movement.distance[1]) > 0;

    if (
      isMovingVertically &&
      isFalling &&
      !this.meshContainer.isAnimationEnded
    ) {
      return AnimationState.fall;
    }

    const moveSpeed = this.movement.moveSpeed;
    const isSprintingInTick = moveSpeed > 6;
    let isSprinting = false;
    if (isSprintingInTick && this.sprintTickCount > 3) {
      isSprinting = true;
    } else if (moveSpeed <= 4) {
      this.sprintTickCount = 0;
    } else if (isSprintingInTick) {
      this.sprintTickCount++;
    }

    if (
      isMovingVertically &&
      (isFalling ||
        (this.movement.jumping && this.meshContainer.isAnimationEnded)) &&
      this.meshContainer.animationState !== AnimationState.fall
    ) {
      return AnimationState.in_air;
    } else if (moveSpeed > 0 && !isSprinting) {
      return AnimationState.walk;
    } else if (isSprinting) {
      return AnimationState.run;
    } else if (moveSpeed < 0) {
      return AnimationState.runBackards;
    }

    return AnimationState.idle;
  }

  get canUpdateMovement() {
    return true;
  }

  get position(): [number, number, number] {
    if (this.spawnStatus !== PlayerSpawnStatus.spawned) {
      return [0, 0, 0];
    }

    return this.noa.ents.getPosition(this.localEntityId);
  }

  get physicsBody() {
    if (this.spawnStatus !== PlayerSpawnStatus.spawned) {
      return null;
    }

    if (!this.localEntityId || this.spawnStatus !== PlayerSpawnStatus.spawned) {
      return null;
    }

    return this.noa.ents.getPhysicsBody(this.localEntityId);
  }

  movement;

  move = (x, y, z, yaw, pitch) => {
    if (!this.canUpdateMovement) {
      return;
    }

    // Vec3.copy(this.movement.oldMovePosition, this.movement.movePosition);
    const physics = this.noa.ents.getStateAccessor("physics")(
      this.localEntityId
    );

    this.noa.ents.setPosition(this.localEntityId, [x, y, z]);

    if (typeof yaw === "number") {
    }
  };

  render(...args) {
    if (!this.isLoaded) {
      return;
    }

    // this.meshContainer.avatar.headTilt = this.rotation.y;

    this.meshContainer.render(...args);
    if (this.control) {
      this.control.render();
    }
  }

  dispose = () => {
    this.despawn(true);

    if (this.control) {
      this.control.dispose();
    }

    Sentry.addBreadcrumb({
      message: "despawn",
      data: { id: this.username },
    });
  };
}
