import { Scalar } from "@babylonjs/core";
import aabb from "aabb-3d";
import vec3 from "gl-vec3";
import sweep from "voxel-aabb-sweep";

const defaults = {
  inverseX: false,
  inverseY: false,
  sensitivityX: 48,
  sensitivityY: 48,
  initialZoom: 0,
  zoomSpeed: 0.2,
};

/**
 * @class
 * @typicalname noa.camera
 * @classdesc Manages the camera, exposes camera position, direction, mouse sensitivity.
 */

export class Camera {
  constructor(noa, opts) {
    this.noa = noa;

    /**
     * `noa.camera` uses the following options (from the root `noa(opts)` options):
     * ```js
     * {
     *   inverseX: false,
     *   inverseY: false,
     *   sensitivityX: 15,
     *   sensitivityY: 15,
     *   initialZoom: 0,
     *   zoomSpeed: 0.2,
     * }
     * ```
     */
    opts = Object.assign({}, defaults, opts);

    /** Horizontal mouse sensitivity.
     * Same scale as Overwatch (typical values around `5..10`)
     */
    this.sensitivityX = opts.sensitivityX;

    /** Vertical mouse sensitivity.
     * Same scale as Overwatch (typical values around `5..10`)
     */
    this.sensitivityY = opts.sensitivityY;

    /** Mouse look inverse (horizontal) */
    this.inverseX = opts.inverseX;

    /** Mouse look inverse (vertical) */
    this.inverseY = opts.inverseY;

    /** Camera yaw angle (read only)
     *
     * Returns the camera's rotation angle around the vertical axis. Range: `0..2π`
     */
    this.heading = 0;

    /** Camera pitch angle (read only)
     *
     * Returns the camera's up/down rotation angle. Range: `-π/2..π/2`.
     * (The pitch angle is clamped by a small epsilon, such that
     * the camera never quite points perfectly up or down.
     */
    this.pitch = 0;

    /** Entity ID of a special entity that exists for the camera to point at.
     *
     * By default this entity follows the player entity, so you can
     * change the player's eye height by changing the `follow` component's offset:
     * ```js
     * var followState = noa.ents.getState(noa.camera.cameraTarget, 'followsEntity')
     * followState.offset[1] = 0.9 * myPlayerHeight
     * ```
     *
     * For customized camera controls you can change the follow
     * target to some other entity, or override the behavior entirely:
     * ```js
     * // make cameraTarget stop following the player
     * noa.ents.removeComponent(noa.camera.cameraTarget, 'followsEntity')
     * // control cameraTarget position directly (or whatever..)
     * noa.ents.setPosition(noa.camera.cameraTarget, [x,y,z])
     * ```
     */
    this.cameraTarget = this.noa.ents.createEntity(["position"]);

    // follow component and default offset
    const eyeOffset = 0.9 * noa.ents.getPositionData(noa.playerEntity).height;
    noa.ents.addComponent(this.cameraTarget, "followsEntity", {
      entity: noa.playerEntity,
      offset: [0, eyeOffset, -opts.initialZoom],
    });

    /** How far back the camera is zoomed from the camera target */
    this.zoomDistance = opts.initialZoom;

    /** How quickly the camera moves to its `zoomDistance` (0..1) */
    this.zoomSpeed = opts.zoomSpeed;

    /** Current actual zoom distance. This differs from `zoomDistance` when
     * the camera is in the process of moving towards the desired distance,
     * or when it's obstructed by solid terrain behind the player. */
    this.currentZoom = opts.initialZoom;

    // internals
    this._dirVector = vec3.fromValues(0, 1, 0);
  }

  /*
   *
   *  Local position functions for high precision
   *
   */
  _localGetTargetPosition() {
    const pdat = this.noa.ents.getPositionData(this.cameraTarget);
    pdat._renderPosition.toArray(_camPos);
    return _camPos;
  }

  _localGetPosition() {
    const loc = this._localGetTargetPosition();
    if (this.currentZoom === 0) return loc;
    return vec3.scaleAndAdd(loc, loc, this._dirVector, -this.currentZoom);
  }

  /**
   * Camera target position (read only)
   *
   * This returns the point the camera looks at - i.e. the player's
   * eye position. When the camera is zoomed
   * all the way in, this is equivalent to `camera.getPosition()`.
   */
  getTargetPosition() {
    const loc = this._localGetTargetPosition();
    return this.noa.localToGlobal(loc, globalCamPos);
  }

  /**
   * Returns the current camera position (read only)
   */
  getPosition() {
    const loc = this._localGetPosition();
    return this.noa.localToGlobal(loc, globalCamPos);
  }

  /**
   * Returns the camera direction vector (read only)
   */
  getDirection() {
    return this._dirVector;
  }

  /*
   *
   *
   *
   *          internals below
   *
   *
   *
   */

  /*
   *  Called before render, if mouseLock etc. is applicable.
   *  Consumes input mouse events x/y, updates camera angle and zoom
   */

  // panWithMovement = !isChrome();
  panWithMovement = true;
  xCameraVeloicty = 1;
  yCameraVeloicty = 1;

  applyInputsToCamera() {
    // dx/dy from input state
    const state = this.noa.inputs.state;

    if (state.panCamera) {
      // convert to rads, using (sens * 0.0066 deg/pixel), like Overwatch
      const conv = (0.0066 * Math.PI) / 180;
      let dy = state.dy * this.sensitivityY * conv;
      let dx = state.dx * this.sensitivityX * conv;
      if (this.inverseY) dy = -dy;
      if (this.inverseX) dx = -dx;

      // normalize/clamp angles, update direction vector
      const twopi = 2 * Math.PI;
      this.heading += dx < 0 ? dx + twopi : dx;
      if (this.heading > twopi) this.heading -= twopi;
      const maxPitch = Math.PI / 2 - 0.001;
      this.pitch = Math.max(-maxPitch, Math.min(maxPitch, this.pitch + dy));

      vec3.set(this._dirVector, 0, 0, 1);
      vec3.rotateX(this._dirVector, this._dirVector, origin, this.pitch);
      vec3.rotateY(this._dirVector, this._dirVector, origin, this.heading);
    } else if (this.panWithMovement) {
      this.xCameraVeloicty = Math.max(
        Math.min(1.2, this.xCameraVeloicty),
        -0.2
      );
      this.yCameraVeloicty = Math.max(
        Math.min(1.2, this.yCameraVeloicty),
        -0.2
      );

      let _dx = 0;
      let _dy = 0;

      if (state.left && !state.forward && !state.backward) {
        _dx = -12;
        this.xCameraVeloicty += 0.5;
      } else if (state.left) {
        _dx = -16;
        this.xCameraVeloicty += 0.005;
      } else if (state.right && !state.forward && !state.backward) {
        _dx = 12;
        this.xCameraVeloicty += 0.5;
      } else if (state.right) {
        _dx = 16;
        this.xCameraVeloicty += 0.005;
      } else {
        this.xCameraVeloicty = 1;
      }

      _dx = _dx * this.xCameraVeloicty;

      // convert to rads, using (sens * 0.0066 deg/pixel), like Overwatch
      let conv = (0.0066 * Math.PI) / 180;
      let dx = _dx * (this.noa.camera.sensitivityY * 0.5) * conv;
      if (this.noa.camera.inverseY) dy = -dy;
      if (this.noa.camera.inverseX) dx = -dx;

      // normalize/clamp angles, update direction vector
      this.noa.camera.heading += dx < 0 ? dx + Scalar.TwoPi : dx;
      if (this.noa.camera.heading > Scalar.TwoPi)
        this.noa.camera.heading -= Scalar.TwoPi;

      vec3.set(this._dirVector, 0, 0, 1);
      vec3.rotateY(this._dirVector, this._dirVector, origin, this.heading);
    }
  }

  /*
   *  Called before all renders, pre- and post- entity render systems
   */

  updateBeforeEntityRenderSystems() {
    // zoom update
    this.currentZoom += (this.zoomDistance - this.currentZoom) * this.zoomSpeed;
  }

  updateAfterEntityRenderSystems() {
    // // clamp camera zoom not to clip into solid terrain
    // const maxZoom = cameraObstructionDistance(this);
    // if (this.currentZoom > maxZoom) this.currentZoom = maxZoom;
  }
}

var _camPos = vec3.create();

var globalCamPos = [];

var origin = vec3.create();

/*
 *  check for obstructions behind camera by sweeping back an AABB
 */

function cameraObstructionDistance(self) {
  if (!_camBox) {
    const off = self.noa.worldOriginOffset;
    _camBox = new aabb([0, 0, 0], vec3.clone(_camBoxVec));
    _getVoxel = (x, y, z) =>
      self.noa.world.getBlockSolidity(x + off[0], y + off[1], z + off[2]);
    vec3.scale(_camBoxVec, _camBoxVec, -0.5);
  }
  _camBox.setPosition(self._localGetPosition());
  _camBox.translate(_camBoxVec);
  const dist = Math.max(self.zoomDistance, self.currentZoom) + 0.1;
  vec3.scale(_sweepVec, self.getDirection(), -dist);
  return sweep(_getVoxel, _camBox, _sweepVec, _hitFn, true);
}

var _camBoxVec = vec3.fromValues(0.8, 0.8, 0.8);
var _sweepVec = vec3.create();
var _camBox;
var _getVoxel;
var _hitFn = () => true;

// workaround for this Chrome 63 + Win10 bug
// https://bugs.chromium.org/p/chromium/issues/detail?id=781182
function bugFix(state) {
  const dx = state.dx;
  const dy = state.dy;
  const wval = document.body.clientWidth / 6;
  const hval = document.body.clientHeight / 6;
  const badx = Math.abs(dx) > wval && dx / lastx < -1;
  const bady = Math.abs(dy) > hval && dy / lasty < -1;
  if (badx || bady) {
    state.dx = lastx;
    state.dy = lasty;
    lastx = dx > 0 ? 1 : -1;
    lasty = dy > 0 ? 1 : -1;
  } else {
    if (dx) lastx = dx;
    if (dy) lasty = dy;
  }
}

var lastx = 0;
var lasty = 0;

export default Camera;
