import { MinimapTileStore } from "lib/Data/MinimapTileStore";
import debug from "lib/log";
import Pbf from "pbf";
import { MapChunkRequest } from "shared/MapChunk/MapChunkRequestSchema";
import { Pool, spawn, Transfer } from "threads";
import WorldChunkReader from "../WorldChunkReader.worker";
import { THREAD_COUNT } from "./THREAD_COUNT";

const log = debug("Mappy");

debug.enable(log.namespace);

let threadIndex = -1;
let chunkReader: Pool;

export let tileClient: WebSocket;

const pendingMessages = [];
let reconnectionAttemptCount = 0;
let lastRetried = 0;

export const requestMapChunk = (request) => {
  const pbf = new Pbf();
  MapChunkRequest.write(request, pbf);
  if (window.LOG_MAP_CHUNK_TIME) {
    console.time("[Fetch] MapChunk – " + request.id);
  }
  const data = pbf.finish();

  if (
    tileClient.readyState === tileClient.CLOSED ||
    tileClient.readyState === tileClient.CLOSING
  ) {
    const now = new Date().getTime();

    if (now - lastRetried > 50) {
      log(
        `Not connected to tile server. Attempting reconnection ${reconnectionAttemptCount}`
      );
      const onCustomMapChunk = tileClient.__onCustomMapChunk;
      startTileClient(onCustomMapChunk);
      reconnectionAttemptCount++;
      lastRetried = now;
    }

    pendingMessages.push(data.buffer);
    return;
  }

  if (tileClient.readyState === tileClient.CONNECTING) {
    pendingMessages.push(data.buffer);
  } else {
    // 241.6ms buffer WITHOUT transfer in safari desktop
    // tileClient.send(data.buffer);

    // 201.6ms buffer WITH transfer in safari desktop
    tileClient.send(data.buffer);
  }
};

function onReceiveChunk(resp) {
  if (!resp) {
    return;
  }

  if (resp.isEmpty) {
    return { id: resp.id };
  }

  tileClient.__onCustomMapChunk({
    id: resp.id,
    requiredFoilageMeshes: resp.requiredFoilageMeshes,
    requiredSurfaceMeshes: resp.requiredSurfaceMeshes,
    foilageData: new Uint16Array(resp.foilageData),
    surfaceData: new Uint16Array(resp.surfaceData),
    surfaceVariants: new Uint32Array(resp.surfaceVariants),
    foilageVariants: new Uint32Array(resp.foilageVariants),
    surfaceStates: resp.surfaceStates,
    states: resp.states,
    voxels: new Uint16Array(resp.voxels),
    chunk: resp.chunk,
    foilageStates: resp.foilageStates,
  });
  resp = null;
}

if (!chunkReader) {
  chunkReader = Pool(
    () => {
      const worker: Worker = new WorldChunkReader();

      threadIndex++;
      const port = MinimapTileStore.channels[threadIndex].port1;

      worker.postMessage(port, [port]);

      return spawn(worker);
    },
    {
      size: Math.max(THREAD_COUNT / 2 - 1, 2),
    }
  );
}

export const startTileClient = (onCustomMapChunk) => {
  tileClient = new WebSocket(process.env.TILE_WEBSOCKET_HOST);
  tileClient.binaryType = "arraybuffer";

  tileClient.onopen = () => {
    log(`Connected to tile server!`);
    while (pendingMessages.length > 0) {
      const message = pendingMessages.shift();
      tileClient.send(message);
    }
    lastRetried = Infinity;
    reconnectionAttemptCount = 0;
  };
  tileClient.onclose = () => {
    log(`Disconnected from tile server :(`);

    lastRetried = Infinity;
    reconnectionAttemptCount = 0;
  };

  tileClient.__onCustomMapChunk = onCustomMapChunk;

  function handleTask(task, buffer) {
    return task(Transfer(buffer, [buffer]));
  }

  tileClient.onmessage = (ev: MessageEvent) => {
    const buffer: Buffer = ev.data;

    chunkReader.queue((task) => handleTask(task, buffer)).then(onReceiveChunk);
  };
};
