export enum HUDEvent {
  equipItem = "hud_selectSlot",
  changePlayer = "hud_changePlayer",
  selectSlot = "hud_equipItem",
  selectitem = "hud_selectItem",
  inventoryChange = "hud_inventoryChange",
  toggleInventory = "hud_toggleInventory",
  controlsChange = "hud_controlsChange",
  currencyChange = "hud_currencyChange",
  announce = "hud_announce",
}
