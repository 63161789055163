export enum BannerVariant {
  bannerBanner = 0,
}

export enum BongVariant {
  bong = 0,
}

export enum CoronaVariant {
  corona = 0,
}

export enum PictureVariant {
  pictureLandscape = 0,
  picturePortrait = 1,
  pictureSquareLarge = 2,
  pictureSquareSmall = 3,
}

export enum RugVariant {
  rugCircular = 0,
  rugSquare = 1,
}

export enum StarVariant {
  star = 0,
}

export enum TelevisionVariant {
  television = 0,
}