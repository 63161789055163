import EntComp from "ent-comp";
import vec3 from "gl-vec3";
import { setPhysicsFromPosition } from "../components/physics";
// var EntComp = require('../../../../npm-modules/ent-comp')
import { updatePositionExtents } from "../components/position";

const defaults = {
  shadowDistance: 10,
};

/**
 * @class Entities
 * @typicalname noa.ents
 * @classdesc Wrangles entities. Aliased as `noa.ents`.
 *
 * This class is an instance of [ECS](https://github.com/andyhall/ent-comp),
 * and as such implements the usual ECS methods.
 * It's also decorated with helpers and accessor functions for getting component existence/state.
 *
 * Expects entity definitions in a specific format - see source `components` folder for examples.
 */

let entityExt = new Float32Array(6);

export class Entities extends EntComp {
  constructor(noa, opts) {
    // inherit from the ECS library
    super();

    this.noa = noa;
    opts = Object.assign({}, defaults, opts);

    // properties
    /** Hash containing the component names of built-in components. */
    this.names = {};

    // optional arguments to supply to component creation functions
    const componentArgs = {
      shadow: opts.shadowDistance,
    };

    // Bundler magic to import everything in the ../components directory
    // each component module exports a default function: (noa) => compDefinition
    const reqContext = require.context("../components/", false, /\.js$/);
    reqContext.keys().forEach((name) => {
      // convert name ('./foo.js') to bare name ('foo')
      const bareName = /\.\/(.*)\.js/.exec(name)[1];
      const arg = componentArgs[bareName] || undefined;
      let compFn = reqContext(name);
      if (compFn.default) compFn = compFn.default;
      const compDef = compFn(noa, arg);
      const comp = this.createComponent(compDef);
      this.names[bareName] = comp;
    });

    // decorate the entities object with accessor functions
    /** @param id */
    this.isPlayer = (id) => {
      return id === noa.playerEntity;
    };

    /** @param id */
    this.hasPhysics = this.getComponentAccessor(this.names.physics);

    /** @param id */
    this.cameraSmoothed = this.getComponentAccessor(this.names.smoothCamera);

    /** @param id */
    this.hasMesh = this.getComponentAccessor(this.names.mesh);

    // position functions
    /** @param id */
    this.hasPosition = this.getComponentAccessor(this.names.position);
    const getPos = this.getStateAccessor(this.names.position);

    /** @param id */
    this.getPositionData = (id) => {
      if (this.hasComponent(id, "position")) {
        return getPos(id);
      } else if (this.hasComponent(id, "parentMesh")) {
        const parentState = this.getState(id, "parentMesh");
        const parentId = parentState.parent?.metadata?.entityId;

        if (parentId) {
          return this.getPositionData(parentId);
        }
      } else {
        return null;
      }
    };

    /** @param id */
    this._localGetPosition = (id) => {
      return this.getPositionData(id)._localPosition;
    };

    /** @param id */
    this.getPosition = (id) => {
      return this.getPositionData(id).position;
    };

    /** @param id */
    this._localSetPosition = (id, pos) => {
      const posDat = getPos(id);
      vec3.copy(posDat._localPosition, pos);

      const networkMovement = this.getState(id, "networkMovement");
      if (networkMovement) {
        networkMovement.localPosition.copyFromFloats(pos[0], pos[1], pos[2]);
      }
      updateDerivedPositionData(id, posDat);
    };

    /** @param id, positionArr */
    this.setPosition = (id, pos, _yarg, _zarg) => {
      // check if called with "x, y, z" args
      if (typeof pos === "number") pos = [pos, _yarg, _zarg];
      // convert to local and defer impl
      const loc = noa.globalToLocal(pos, null, []);
      this._localSetPosition(id, loc);
    };

    /** @param id, xs, ys, zs */
    this.setEntitySize = (id, xs, ys, zs) => {
      const posDat = getPos(id);
      posDat.width = (xs + zs) / 2;
      posDat.height = ys;
      updateDerivedPositionData(id, posDat);
    };

    // called when engine rebases its local coords
    this._rebaseOrigin = function (delta) {
      const states = this.getStatesList(this.names.position);
      const networkMovementAccessor = this.getStateAccessor("networkMovement");

      for (let i = 0; i < states.length; i++) {
        const state = states[i];
        const id = state.__id;

        vec3.subtract(state._localPosition, state._localPosition, delta);
        const networkMovement = networkMovementAccessor(id);
        if (networkMovement) {
          networkMovement.localPosition.addInPlaceFromFloats(
            delta[0] * -1,
            delta[1] * -1,
            delta[2] * -1
          );
        }

        updateDerivedPositionData(state.__id, state);
      }
    };

    // helper to update everything derived from `_localPosition`
    function updateDerivedPositionData(id, posDat) {
      posDat._renderPosition.copyFromFloats(
        posDat._localPosition[0],
        posDat._localPosition[1],
        posDat._localPosition[2]
      );
      vec3.add(posDat.position, posDat._localPosition, noa.worldOriginOffset);
      updatePositionExtents(posDat);
      const physDat = getPhys(id);
      if (physDat) setPhysicsFromPosition(physDat, posDat);
    }

    // physics
    var getPhys = this.getStateAccessor(this.names.physics);
    this.getPhysics = getPhys;
    this.getPhysicsBody = (id) => {
      return getPhys(id).body;
    };

    // misc
    this.getMeshData = this.getStateAccessor(this.names.mesh);
    this.getMovement = this.getStateAccessor(this.names.movement);
    this.getCollideTerrain = this.getStateAccessor(this.names.collideTerrain);
    this.getCollideEntities = this.getStateAccessor(this.names.collideEntities);

    // pairwise collideEntities event - this is for client to override
    this.onPairwiseEntityCollision = (id1, id2) => {};
  }

  /*
   *
   *    ENTITY MANAGER API
   *
   *  note most APIs are on the original ECS module (ent-comp)
   *  these are some overlaid extras for noa
   *
   */

  /** @param id,name,state */
  addComponentAgain(id, name, state) {
    // removes component first if necessary
    if (this.hasComponent(id, name)) this.removeComponent(id, name, true);
    this.addComponent(id, name, state);
  }

  /** @param x,y,z */
  isTerrainBlocked(x, y, z) {
    // checks if terrain location is blocked by entities
    const off = this.noa.worldOriginOffset;
    const xlocal = Math.floor(x - off[0]);
    const ylocal = Math.floor(y - off[1]);
    const zlocal = Math.floor(z - off[2]);
    const blockExt = [
      xlocal + 0.001,
      ylocal + 0.001,
      zlocal + 0.001,
      xlocal + 0.999,
      ylocal + 0.999,
      zlocal + 0.999,
    ];
    const list = this.getStatesList(this.names.collideTerrain);
    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        continue;
      }
      const id = list[i].__id;
      const ext = this.getPositionData(id)._extents;
      if (extentsOverlap(blockExt, ext)) return true;
    }
    return false;
  }

  getEntityIn(x, y, z, withComponent) {
    // checks if terrain location is blocked by entities
    const off = this.noa.worldOriginOffset;
    const xlocal = Math.floor(x - off[0]);
    const ylocal = Math.floor(y - off[1]);
    const zlocal = Math.floor(z - off[2]);
    entityExt[0] = xlocal + 0.001;
    entityExt[1] = ylocal + 0.001;
    entityExt[2] = zlocal + 0.001;
    entityExt[3] = xlocal + 0.999;
    entityExt[4] = ylocal + 0.999;
    entityExt[5] = zlocal + 0.999;

    const list = withComponent
      ? this.getStatesList(withComponent).map(this.getPositionForState)
      : this.getStatesList(this.names.position);

    for (let i = 0; i < list.length; i++) {
      if (!list[i]) {
        continue;
      }

      const id = list[i].__id;
      const ext = this.getPositionData(id)._extents;
      if (extentsOverlap(entityExt, ext)) return id;
    }
    return null;
  }

  getPositionForState = ({ __id }) => this.getPositionData(__id);

  /** @param box */
  getEntitiesInAABB({ base, max }, withComponent) {
    // extents to test against
    const off = this.noa.worldOriginOffset;
    const testExtents = [
      base[0] + off[0],
      base[1] + off[1],
      base[2] + off[2],
      max[0] + off[0],
      max[1] + off[1],
      max[2] + off[2],
    ];
    // entity position state list
    const entStates = withComponent
      ? this.getStatesList(withComponent).map(({ __id }) =>
          this.getPositionData(__id)
        )
      : this.getStatesList(this.names.position);
    // run each test
    const hits = [];
    entStates.forEach(({ _extents, __id }) => {
      if (extentsOverlap(testExtents, _extents)) {
        hits.push(__id);
      }
    });
    return hits;
  }

  /**
   * Helper to set up a general entity, and populate with some common components depending on arguments.
   *
   * Parameters: position, width, height [, mesh, meshOffset, doPhysics, shadow]
   *
   * @param position
   * @param width
   * @param height..
   */
  add(
    position,
    width,
    // required
    height,
    mesh,
    meshOffset,
    doPhysics,
    shadow
  ) {
    const self = this;

    // new entity
    const eid = this.createEntity();

    // position component
    this.addComponent(eid, this.names.position, {
      position: position || [0, 0, 0],
      width,
      height,
    });

    // rigid body in physics simulator
    if (doPhysics) {
      // body = this.noa.physics.addBody(box)
      this.addComponent(eid, this.names.physics);
      const body = this.getPhysicsBody(eid);

      // handler for physics engine to call on auto-step
      const smoothName = this.names.smoothCamera;
      body.onStep = () => {
        self.addComponentAgain(eid, smoothName);
      };
    }

    // mesh for the entity
    if (mesh) {
      if (!meshOffset) meshOffset = vec3.create();
      this.addComponent(eid, this.names.mesh, {
        mesh,
        offset: meshOffset,
      });
    }

    return eid;
  }
}

function extentsOverlap(extA, extB) {
  if (extA[0] > extB[3]) return false;
  if (extA[1] > extB[4]) return false;
  if (extA[2] > extB[5]) return false;
  if (extA[3] < extB[0]) return false;
  if (extA[4] < extB[1]) return false;
  if (extA[5] < extB[2]) return false;
  return true;
}
export default Entities;
