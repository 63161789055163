import { BlockID } from "../BlockID";
import { PlaceBlockInterface, SetBlockProtocol } from "../nengiTypes";

export class PlaceBlockCommand implements PlaceBlockInterface {
  constructor({ x, y, z, blockType, url = "", urlSide = -1, variant = 0 }) {
    this.x = x;
    this.y = y;
    this.variant = variant;
    this.z = z;
    this.blockType = blockType;
    this.url = url;
    this.urlSide = urlSide;
    this.error = 0;
  }

  x: number;
  error: number;
  y: number;
  variant: number;
  z: number;
  urlSide: number;
  blockType: BlockID;
  url: string;

  static protocol = SetBlockProtocol;
}
