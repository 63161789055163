import textures from "./packed/texture.json";
import textureImageSource from "./packed/texture.png";
import { USE_IMAGE_BITMAP } from "lib/Data/USE_IMAGE_BITMAP";
import { fetchDrawableImage } from "lib/Data/fetchDrawableImage";

export { textureImageSource };

export let image: HTMLImageElement =
  typeof Image !== "undefined"
    ? new Image(textures.size[0], textures.size[1])
    : null;

// for (let name in t.frames) { k.frames[name] = [t.frames[name][0] / t.size[0], t.frames[name][1] / t.size[1], t.frames[name][2] / t.size[0], t.frames[name][3] / t.size[1] ] }

export const loadImage = (): Promise<HTMLImageElement | ImageBitmap> => {
  return fetchDrawableImage(
    textureImageSource,
    textures.size[0],
    textures.size[1]
  );
};

// jq '.frames | to_entries | map({key: .key, value: [.value.frame.x, .value.frame.y, .value.frame.w, .value.frame.h]}) | from_entries' | pbcopy

export default textures.frames;
export const size = textures.size;
