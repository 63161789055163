import CharacterButton1x from "./CharacterIcon.png";
import CharacterButton2x from "./CharacterIcon@2x.png";
import CharacterButton3x from "./CharacterIcon@3x.png";
import InventoryButton1x from "./Inventory.png";
import InventoryButton2x from "./Inventory@2x.png";
import InventoryButton3x from "./Inventory@3x.png";
import InviteButton1x from "./Invite.png";
import InviteButton2x from "./Invite@2x.png";
import InviteButton3x from "./Invite@3x.png";
import PhotoButton1x from "./Photo.png";
import PhotoButton2x from "./Photo@2x.png";
import PhotoButton3x from "./Photo@3x.png";
import PickaxeButton1x from "./Pickaxe.png";
import PickaxeButton2x from "./Pickaxe@2x.png";
import PickaxeButton3x from "./Pickaxe@3x.png";
import WebcamButton1x from "./Webcam.png";
import WebcamButton2x from "./Webcam@2x.png";
import WebcamButton3x from "./Webcam@3x.png";

export const CharacterButton = {
  "1x": CharacterButton1x,
  "2x": CharacterButton2x,
  "3x": CharacterButton3x,
};

export const InviteButton = {
  "1x": InviteButton1x,
  "2x": InviteButton2x,
  "3x": InviteButton3x,
};

export const PhotoButton = {
  "1x": PhotoButton1x,
  "2x": PhotoButton2x,
  "3x": PhotoButton3x,
};

export const PickaxeButton = {
  "1x": PickaxeButton1x,
  "2x": PickaxeButton2x,
  "3x": PickaxeButton3x,
};

export const WebcamButton = {
  "1x": WebcamButton1x,
  "2x": WebcamButton2x,
  "3x": WebcamButton3x,
};

export const InventoryButton = {
  "1x": InventoryButton1x,
  "2x": InventoryButton2x,
  "3x": InventoryButton3x,
};
