import {
  AssetContainer,
  Color3,
  Engine,
  Mesh,
  Scene,
  StandardMaterial,
  Texture,
  TransformNode,
  Vector3,
} from "@babylonjs/core";
import { getClearColor } from "../Player/PlayerMesh";
import {
  AvatarAnimationMap,
  BaseAvatar,
  loadAssets,
  normalizeColor,
  normalizeTextureURL,
} from "./BaseAvatar";

const animations: AvatarAnimationMap = {
  _default: { from: 0, to: 1 },
  attack: { from: 10, to: 55 },
  die: { from: 60, to: 82 },
  falling: { from: 90, to: 120 },
  falling_col: { from: 130, to: 150 },
  hurt: { from: 160, to: 180 },
  idle: { from: 190, to: 222 },
  in_air: { from: 230, to: 260 },
  jump: { from: 270, to: 298 },
  jump_gun: { from: 310, to: 338 },
  kick: { from: 350, to: 374 },
  rest: { from: 380, to: 381 },
  run: { from: 390, to: 414 },
  run_gun: { from: 420, to: 444 },
  walk: { from: 450, to: 482 },
  walk_gun: { from: 490, to: 522 },
  walk_push: { from: 530, to: 562 },
};

let _container: AssetContainer | Scene;
const loadPlayerMesh = async (
  scene: Scene,
  id,
  insertIntoScene: boolean,
  engine?: Engine
) => {
  if (!_container) {
    _container = await loadAssets("/skins/", "skin.babylon", scene, engine);

    if (insertIntoScene) {
      const root = _container.createRootMesh();
      root.scaling.set(-1, 1, -1);
    }
  }

  let entries;
  let parentMesh: Mesh;

  if (insertIntoScene) {
    entries = _container.instantiateModelsToScene(null, true);
    parentMesh = entries.rootNodes[0];
  } else {
    entries = _container;
    parentMesh = new TransformNode("parent", _container);
    _container.meshes.forEach((mesh) => mesh.setParent(parentMesh));
  }

  const skeleton = entries.skeletons[0];
  const mesh = parentMesh.getChildMeshes()[0];
  const diffuseTexture = new Texture("/skins/bob_metallic.png", scene);
  const material: StandardMaterial = mesh.material;

  material.diffuseTexture = diffuseTexture;
  material.diffuseColor = RobotAvatar.defaultBackgroundColor;
  material.emissiveTexture = new Texture("/skins/bob_emit.png", scene);

  skeleton.enableBlending(0.1);
  skeleton.overrideMesh = parentMesh;
  return {
    skeleton,
    parentMesh: parentMesh,
    meshes: [parentMesh, mesh],
    scene: insertIntoScene ? scene : _container,
    material,
    mesh,
    animationGroups: entries.animationGroups,
  };
};

export enum RobotAvatarSkinStyle {
  metallic = "metallic",
  albedo = "albedo",
  rough = "rough",
}

export class RobotAvatar extends BaseAvatar {
  constructor(scene, id) {
    super(scene, id);
  }

  static defaultBackgroundColor = new Color3(1, 1, 1);
  material: StandardMaterial;
  texture: Texture;
  static _animations = animations;
  backgroundImage: string = null;
  backgroundColor = new Color3(1, 1, 1);
  skinColor = new Color3(1, 1, 1);

  setBackgroundImage = (url: string) => {
    this.backgroundImage = normalizeTextureURL(url);

    if (!this.material) {
      return;
    }

    if (!url) {
      this.material.ambientTexture = null;
      return;
    }

    const ambientTexture = new Texture(url, this.scene);
    // const ambientTexture = new Texture(null, scene);

    ambientTexture.hasAlpha = false;

    ambientTexture.uScale = 2;
    ambientTexture.vScale = 2;

    this.material.ambientTexture = ambientTexture;
    this.material.ambientTexture.wrapU = Texture.MIRROR_ADDRESSMODE;
    this.material.ambientTexture.wrapV = Texture.MIRROR_ADDRESSMODE;
  };

  static _skinStyleMapping = {
    [RobotAvatarSkinStyle.metallic]: {
      thumbnail: "/skin-thumbnails/bob_metallic.png",
      url: "/skins/bob_metallic.png",
    },
    [RobotAvatarSkinStyle.rough]: {
      thumbnail: "/skin-thumbnails/bob_roughness.png",
      url: "/skins/bob_roughness.png",
    },
    [RobotAvatarSkinStyle.albedo]: {
      thumbnail: "/skin-thumbnails/albedo.png",
      url: "/skins/bob_albedo.png",
    },
  };

  protected static _skinStyles = RobotAvatarSkinStyle;

  protected static _skinStyleURL(skinStyle: RobotAvatarSkinStyle) {
    return this._skinStyleMapping[skinStyle]?.url;
  }

  _reset = () => {
    if (this.skinStyle) {
      this.setSkinStyle(this.skinStyle);
    }

    if (this.backgroundColor) {
      this.setBackgroundColor(this.backgroundColor);
    }

    if (this.backgroundImage) {
      this.setBackgroundImage(this.backgroundImage);
    }

    if (this.skinColor) {
      this.setSkinColor(this.skinColor);
    }
  };

  setSkinStyle = (skinStyle: string) => {
    this.skinStyle =
      RobotAvatarSkinStyle[skinStyle || RobotAvatar.defaultSkinStyle] ||
      RobotAvatar.defaultSkinStyle;

    if (!this.material) {
      return;
    }

    const url = this.constructor.skinStyleURL(skinStyle);
    if (url && this.material.diffuseTexture?.url !== url) {
      const texture = new Texture(url, this.scene);
      if (this.material.diffuseTexture) {
        this.material.diffuseTexture.dispose();
      }
      this.material.diffuseTexture = texture;
      this.texture = texture;
    }
  };

  setSkinColor = (_color: Color3) => {
    const color = normalizeColor(_color);

    this.skinColor = color;
    if (!this.material) {
      return;
    }

    if (color) {
      this.material.ambientColor = color;
    } else {
      this.material.ambientColor = getClearColor(this.scene);
    }
  };

  static thumbnailURL = "/skins/robot/thumbnail.png";
  setBackgroundColor = (_color: Color3) => {
    const color = normalizeColor(_color);

    this.backgroundColor = color;

    if (!this.material) {
      return;
    }

    if (color) {
      this.material.diffuseColor = color;
    } else {
      this.material.diffuseColor = RobotAvatar.defaultBackgroundColor;
    }
  };

  _rotation = Vector3.Zero();

  static headBoneIndex;

  private static _defaultSkinStyle = RobotAvatarSkinStyle.metallic;
  skinStyle = RobotAvatarSkinStyle.metallic;

  _load = async (id: string, insertIntoScene: boolean, engine) => {
    const {
      mesh,
      skeleton,
      animationGroups,
      material,
      meshes,
      scene,
      parentMesh,
    } = await loadPlayerMesh(this.scene, this.id, insertIntoScene, engine);

    this.parentMesh = parentMesh;
    if (!insertIntoScene) {
      this.scene = scene;
    }
    this._meshes = meshes;
    this._skeleton = skeleton;
    this._mesh = mesh;
    this._animationGroups = animationGroups;

    this.material = material;

    this.parentMesh.rotation = this._rotation;
    if (typeof RobotAvatar.headBoneIndex !== "number") {
      RobotAvatar.headBoneIndex = this.skeleton.getBoneIndexByName("head");
    }

    this._headBone = this.skeleton.bones[RobotAvatar.headBoneIndex];

    this.texture = this.material.diffuseTexture;
  };
}
