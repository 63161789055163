import {
  AssetsManager,
  EventState,
  Mesh,
  MeshAssetTask,
  Scene,
  Vector3,
} from "@babylonjs/core";
import { blockActions, EntityAction } from "game/EntityActionManager";
import {
  FoilageAlignment,
  FoilageMesh,
  FOILAGE_SCALE,
  getAlignment,
  getRotation,
} from "game/meshes/FoilageMesh";
import { BlockID } from "shared/BlockID";
import FOILAGE from "shared/foilages.json";
import { blockMap, itemId, items, ItemVariant } from "shared/items";
import SURFACES from "shared/surfaces.json";
import normalizeLoadedMesh from "./normalizeLoadedMesh";
import { GLBFetcher, GLBProgressStatus } from "lib/GLBFetcher";
import { fromItemIdToLists } from "shared/items/itemId";

export type FoilageLoadCallback = (mesh: FoilageMesh, id: string) => void;

const normalizeMesh = (mesh: AbstractMesh) => {
  if (mesh.makeGeometryUnique) {
    mesh.makeGeometryUnique();
  }

  if (mesh.position) {
    mesh.position = mesh.position.clone();
  }

  if (mesh.scaling) {
    mesh.scaling = mesh.scaling.clone();
  }

  if (mesh.rotation) {
    mesh.rotation = mesh.rotation.clone();
  }
};

export const getFoilageVersion = (
  blockID: number,
  variant: ItemVariant,
  alignment: FoilageAlignment
) => {
  return Symbol.for(
    `m__${blockID}/${variant.variant}/r${getRotation(variant)}@${
      variant.scale + 1
    }x`
  );
};

export class FoilageLoader {
  assetsManager: AssetsManager;
  scene: Scene;

  meshes = new Map<string, FoilageMesh>();
  meshSizes = new Map<string, Array<Vector3>>();

  constructor(scene: Scene) {
    this.scene = scene;
  }

  glbFetcher = GLBFetcher.fetcher;

  boundedMeshes = new Map<string, Mesh>();
  versionedMeshes = new Map<symbol, Mesh>();

  getMesh = (blockId: BlockID, item: ItemVariant) => {
    const id = itemId(blockId, item.variant);

    if (!this.meshes.has(id)) {
      let glb = this.glbFetcher.glbs.get(id);

      if (!glb || !glb.meshes) {
        return null;
      }

      const actions = blockActions.get(blockId);
      if (actions && actions.includes(EntityAction.changeURL)) {
      }

      const meshes = glb.meshes;

      const originalMesh = normalizeLoadedMesh(meshes, blockId, item);
      originalMesh.setEnabled(false);

      this.meshes.set(id, originalMesh);
      // this.boundedMeshes.set(id, mesh);
    }

    const alignment = getAlignment(item);

    const version = getFoilageVersion(blockId, item, alignment);

    if (this.versionedMeshes.has(version)) {
      return this.versionedMeshes.get(version);
    } else {
      const base = this.meshes.get(id);

      let versionedMesh: Mesh = base.instantiateHierarchy(null, {
        doNotInstantiate: true,
      });

      const scale = FOILAGE_SCALE[item.scale];
      const rotation = getRotation(item);

      versionedMesh.metadata = base.metadata;
      versionedMesh.position = Vector3.Zero();

      const children = versionedMesh.getChildren(null, false);
      children.forEach(normalizeMesh);

      versionedMesh.scaling = new Vector3(scale, scale, scale);
      versionedMesh.rotation = new Vector3(0, rotation, 0);

      versionedMesh.makeGeometryUnique();
      versionedMesh.bakeCurrentTransformIntoVertices(true);
      versionedMesh.freezeWorldMatrix(versionedMesh.computeWorldMatrix(true));

      for (let mesh of children) {
        if (mesh.freezeWorldMatrix) {
          mesh.freezeWorldMatrix(mesh.computeWorldMatrix(true));
        }
      }

      versionedMesh.setEnabled(false);
      this.versionedMeshes.set(version, versionedMesh);
      return versionedMesh;
    }
  };

  isFoilageLoaded(id: symbol) {
    return (
      this.glbFetcher.glbs?.get(id)?.importStatus === GLBProgressStatus.done
    );
  }

  loadRequiredMeshes = (list: Array<string>) => {
    const blockIDs = new Array(list.length);
    const variantIDs = new Array(list.length);

    fromItemIdToLists(list, blockIDs, variantIDs);

    return this.glbFetcher.fetch(blockIDs, variantIDs, this.scene);
  };
}
