import { HUDEvent } from "components/HUDEvent";
import EventEmitter from "eventemitter3";
import * as React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

const AnnouncementComponent = ({
  sticky = false,
  duration = 5000,
  message,
  hotkey,
  Icon,
  label,
}) => (
  <div className="AnnouncementContainer">
    <div className="Announcement">{message}</div>

    <style jsx>{`
      .AnnouncementContainer {
        position: absolute;
        top: 60px;
        color: white;
        left: 0;
        right: 0;
        display: grid;
        grid-template-rows: min-content min-content min-content;
        align-items: center;
        justify-content: center;
      }

      .Game {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
      }

      .Game-title {
        font-size: 24px;
        font-weight: bold;
        margin-left: 6px;
        -webkit-text-stroke: 1px black;
        -webkit-text-fill: 1px white;
        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
        text-transform: uppercase;
      }

      .Announcement {
        font-size: 48px;
        font-weight: bold;
        -webkit-text-stroke: 2px black;
        -webkit-text-fill: 2px white;

        -webkit-text-fill-color: white; /* Will override color (regardless of order) */
      }

      .Hotkey {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .Label,
      .Announcement,
      .Hotkey,
      .Game {
        transition: opacity 1s ease-in-out;
        transition-property: opacity, transform;
        opacity: 1;
        transform: scale(1);
      }
    `}</style>

    <style jsx global>{`
      .Hotkey svg {
        margin-left: 12px;
      }

      .AnnouncementContainer svg {
        filter: drop-shadow(0 0 1px black);
      }

      .Announcement-exit .Announcement {
        opacity: 0;
        transform: scale(0.95) translateX();
      }
    `}</style>
  </div>
);

export const Announcement = ({ emitter }: { emmiter: EventEmitter }) => {
  const [announcement, setAnnouncement] = React.useState(null);
  const timer = React.useRef<number>(null);

  const handleAnnouncement = React.useCallback(
    (event) => {
      if (timer.current) {
        self.clearTimeout(timer.current);
      }
      console.log("HANDLE ANNOUNCEMENT");
      setAnnouncement(event);

      timer.current = self.setTimeout(() => {
        setAnnouncement(null);
      }, event.duration || 300);
    },
    [setAnnouncement, timer]
  );

  React.useEffect(() => {
    emitter.on(HUDEvent.announce, handleAnnouncement);
    return () => {
      emitter.off(HUDEvent.announce, handleAnnouncement);
    };
  }, [emitter, handleAnnouncement]);

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, [timer]);

  return (
    <div className="AnnouncementList">
      <TransitionGroup className="AnnouncementGroup">
        {announcement && (
          <CSSTransition
            timeout={announcement ? announcement.duration : undefined}
            in={!!announcement}
            classNames="Announcement"
          >
            <AnnouncementComponent
              sticky={announcement?.sticky}
              message={announcement?.message}
              hotkey={announcement?.hotkey}
              Icon={announcement?.icon}
              label={announcement?.label}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};
