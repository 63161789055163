import {
  BoundingBoxGizmo,
  AbstractMesh,
  Mesh,
  TransformNode,
  Material,
  MeshBuilder,
  StandardMaterial,
  Vector3,
  Vector4,
  PBRMetallicRoughnessMaterial,
  Color3,
  Scene,
} from "@babylonjs/core";
import { BlockID } from "shared/BlockID";
import { ItemVariant } from "shared/items";
import {
  PictureVariant,
  TelevisionVariant,
  BannerVariant,
} from "shared/foilage";
import {
  offsetsByScale,
  sizes,
  createScreenPlane,
} from "game/meshes/ScreenMesh";

let screenMat: PBRMetallicRoughnessMaterial = null;

const loadScreenmat = (scene: Scene) => {
  screenMat = new PBRMetallicRoughnessMaterial("screen", scene);
  screenMat.metallic = 0;
  screenMat.roughness = 0.5;
  screenMat.baseColor = Color3.White();

  screenMat.freeze();

  return screenMat;
};

const isRootMesh = (mesh) => mesh.name.includes("root");

export const deleteMeshesById = {
  [BlockID.television]: {
    [TelevisionVariant.television]: "object1_primitive0",
  },
  // [BlockID.banner]: {
  //   [BannerVariant.bannerBanner]: "node0_primitive0",
  // },
  [BlockID.picture]: {
    [PictureVariant.pictureLandscape]: "object1_1",
    [PictureVariant.picturePortrait]: "object1_1",
    [PictureVariant.pictureSquareLarge]: "object1_1",
    [PictureVariant.pictureSquareSmall]: "object1_1",
  },
};

export default function normalizeLoadedMesh(
  _meshes: Array<AbstractMesh>,
  blockId: BlockID,
  item: ItemVariant
) {
  let mesh: Mesh = _meshes.find(isRootMesh);

  let toDelete = deleteMeshesById[blockId];
  if (toDelete) {
    toDelete = toDelete[item.variant];
  }

  if (toDelete) {
    const deleteThis: Array<Mesh> = mesh.getChildren(
      (child) => child.name === toDelete,
      false
    );
    if (deleteThis.length) {
      deleteThis[0].dispose(false, true);
    }
  }
  const children: Array<Mesh> = mesh.getChildMeshes(false);
  // if (children.length === 1) {
  //   mesh.dispose(true);
  //   mesh = children[0];
  // }

  mesh.hasVertexAlpha = true;
  mesh.setEnabled(false);

  let replaceMat: Material;
  let boxPos: Vector3;
  if (
    blockId === BlockID.banner &&
    item.variant === BannerVariant.bannerBanner
  ) {
    replaceMat = children.find(
      (mesh) => mesh.material.name !== "Metal Material"
    ).material;
    boxPos = offsetsByScale[blockId][item.variant][0];
  }

  let isFirst = true;

  let deleteThis = [];

  for (let mat of children) {
    if (mat.material) {
      mat.material.backFaceCulling = false;

      if (replaceMat && mat.material.name === replaceMat.name) {
        deleteThis.push(mat);
      } else {
        mat.material.freeze();
      }
    }
    mesh.hasVertexAlpha = true;
    mat.isPickable = true;
  }

  if (deleteThis.length) {
    const bounds = deleteThis[0].getHierarchyBoundingVectors(true);
    const size = bounds.max.subtract(bounds.min);
    if (!screenMat) {
      loadScreenmat(mesh.getScene());
    }

    for (let i = 0; i < deleteThis.length; i++) {
      let _mesh = deleteThis[i];
      let box = MeshBuilder.CreatePlane("screen", {
        width: size.x * 1.01,
        height: size.y * 1.01,
        sideOrientation: i,
      });

      box.material = screenMat;
      box.setParent(mesh);

      if (i === 1) {
        box.position = boxPos
          .clone()
          .addInPlaceFromFloats(0, 0, sizes[blockId][item.variant][2] / 2);
      } else {
        box.scaling.set(1, -1, 1);
        box.bakeCurrentTransformIntoVertices();

        box.position = boxPos
          .clone()
          .addInPlaceFromFloats(0, 0, sizes[blockId][item.variant][2] / -2);
      }

      _mesh.dispose(false, true);
    }
  }

  mesh.isPickable = true;
  mesh.setEnabled(false);

  return mesh;
}
